import React from "react";
import AffiliatesSection from "../containers/affiliates";
import BecomeAffiliate from "../containers/affiliates/become-affiliate";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageLoader from "../components/page-loader";
import { getCurrentUser } from "../helpers/Utils";
import { useState, useEffect } from "react";

const AffiliatesPage = () => {
    const [isAffiate, setAffilate] = useState("");
    //Get the role of user
    const getRole = () => {
        let role = [];

        let affilate = "";
        role = getCurrentUser().role;
        if (role !== null) {
            role.map((item) => {
                return (affilate = item.label_affiliate);
            });
            setAffilate(affilate);
        }
    };
    useEffect(() => {
        if (getCurrentUser()) {
            getRole();
        }
    }, [isAffiate]);
    return (
        <React.Fragment>
            <PageLoader />
            <Layout>
                <SEO title="Soul Tribe Online Community – Affiliate Area" />
                <div className="wrapper">
                    <Header />
                    <PageBanner title="Affiliate Area" />

                    {isAffiate && isAffiate !== "" ? (
                        <AffiliatesSection />
                    ) : (
                        <BecomeAffiliate />
                    )}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default AffiliatesPage;
