import React, { Component } from "react";
import { setCurrentUser } from "../../helpers/Utils";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from "axios";

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            error: "",
            isLoading: false,
        };
        this.onUserLogin = this.onUserLogin.bind(this);
    }

    onUserLogin = (e) => {
        this.setState({
            isLoading: true,
        });
        e.preventDefault();
        var self = this;
        axios
            .post(`${process.env.REACT_APP_API_URL}/login`, {
                email: this.state.email,
                password: this.state.password,
            })
            .then(function (res) {
                if (res.data.success === false) {
                    self.setState({
                        error: res.data.message,
                        isLoading: false,
                    });
                } else {
                    const user_data = res.data.data.user;
                    setCurrentUser(user_data);
                    self.setState({
                        error: "",
                        isLoading: false,
                    });
                    if (res.data.data.user.is_join_for_free_user === 1) {
                        window.location.href = "/plans";
                    } else {
                        window.location.href = "/lovestream";
                    }
                }
            });
    };

    render() {
        const { error, isLoading } = this.state;
        let button = (
            <button
                id="contactSubmit"
                type="submit"
                className="btn btn-dark btn-hover-dark w-100"
            >
                Login
            </button>
        );


        if (error) {
            toast.error(error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                error: ''
            })
        }

        if (isLoading === true) {
            button = (
                <button
                    id="contactSubmit"
                    type="button"
                    className="btn disabled btn-dark btn-hover-dark w-100"
                    disabled
                >
                    Loading...
                </button>
            );
        }

        return (
            <form onSubmit={this.onUserLogin} id="contactForm" className="row">
                <div className="col-12"></div>
                <div className="col-12 mb-7">
                    <input
                        type="email"
                        className="form-control"
                        id="name"
                        name="email"
                        placeholder="Email Address"
                        onChange={(e) =>
                            this.setState({ email: e.target.value })
                        }
                    />
                </div>
                <div className="col-12 mb-5">
                    <input
                        type="password"
                        className="form-control"
                        id="email"
                        name="password"
                        placeholder="Password"
                        onChange={(e) =>
                            this.setState({ password: e.target.value })
                        }
                    />
                </div>
                <div className="col-12">{button}</div>
            </form>
        );
    }
}

export default ContactForm;
