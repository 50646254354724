import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../../components/section-title";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const IconBoxContainer = ({ classOption }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };
  return (
    <div
      className={`feature-section position-relative feature-top ${classOption}`}
    >
      <img
        className="path-img"
        src={`${process.env.PUBLIC_URL}/images/feature/shape.png`}
        alt="images_not_found"
      />
      <div className="container custom-container">
        <SectionTitle classOption="text-center" title="What is Soul Tribe?" />
        <div className="row g-0 align-items-center">
          <div className="col-12">
            <div id="grid" className="grid row">
              <div className="grid-item mb-2">
                <Link
                  className="h-100 d-block"
                  to={process.env.PUBLIC_URL + `/classes`}
                >
                  <div className="feature-card bg-light card-redesign h-100">
                    <span className="card-shape card-shape-light"></span>
                    <span className="card-shape card-shape-dark"></span>
                    <img src="/images/feature/logo/1.png" alt=" feature logo" />
                    <h4 className="title my-6">LIVE & RECORDED CLASSES</h4>
                    <p>
                      A library of classes newly added each week + Live Stream
                      offerings with community connection
                    </p>
                  </div>
                </Link>
              </div>
              <div className="grid-item mb-2">
                <Link
                  className="h-100 d-block"
                  to={process.env.PUBLIC_URL + `/course`}
                >
                  <div className="feature-card bg-light card-redesign h-100">
                    <span className="card-shape card-shape-light"></span>
                    <span className="card-shape card-shape-dark"></span>
                    <img src="/images/feature/logo/2.png" alt=" feature logo" />
                    <h4 className="title my-6">COURSES</h4>
                    <p>
                      Dive deep into Yoga, Gene Keys, Human Design, Astrology,
                      Healing Modalitites and Daily Practice to expand your life
                      experience.
                    </p>
                  </div>
                </Link>
              </div>
              <div className="grid-item mb-2">
                <Link
                  className="h-100 d-block"
                  to={process.env.PUBLIC_URL + `/lovestream`}
                >
                  <div className="feature-card bg-light card-redesign h-100">
                    <span className="card-shape card-shape-light"></span>
                    <span className="card-shape card-shape-dark"></span>
                    <img src="/images/feature/logo/3.png" alt=" feature logo" />
                    <h4 className="title my-6">COMMUNITY</h4>
                    <p>
                      Daily Live Meditation/Gene Keys Practice, Live classes,
                      and online community and tribe groups to privately and
                      authentically connect and thrive with support of other
                      like-hearted individuals from wherever you are in the
                      world.
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="abt_slide">
        <div className="abt_slide-content">
          <div className="container">
            <div className="abt_slide-content-flex">
              <SectionTitle
                classOption="title-section"
                title="Soul Tribe is for YOU!"
              />
              <p>
                Soul Tribe is perfect for you if you are looking for seasoned
                teachers, techniques, and community to assist in the development
                of your human potential. Soul Tribe contains the tools of Yoga,
                Meditation, Sound Healing, Inspirational and Health-Oriented
                Lectures, and an ever-evolving Community of people wanting to
                grow and expand in their consciousness and connect with others
                on a similar journey.
              </p>
            </div>
          </div>
        </div>
        <div className="abt_slide-imgs">
          <Slider {...settings}>
            <img src="images/about/11.jpeg" alt="" />
            <img src="images/about/22.jpeg" alt="" />
            <img src="images/about/33.jpeg" alt="" />
            <img src="images/about/44.jpg" alt="" />
            <img src="images/about/55.jpg" alt="" />
            <img src="images/about/66.jpeg" alt="" />
          </Slider>
        </div>
      </div>
    </div>
  );
};

IconBoxContainer.propTypes = {
  classOption: PropTypes.string,
};

IconBoxContainer.defaultProps = {
  classOption: "section-pb",
};

export default IconBoxContainer;
