/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";
import TribeMemberGrid from "../../../components/member/tribe-members";
import MemberGrid from "../../../components/member";
import TribeMemberItem from "../tribe-members";
import SendInvite from "../send-invites";
import TribeEmailOptions from "../email-option";
import TribePhotos from "../tribe-photos";
import TribeDocument from "../tribe-document";
import TribeDiscussion from "../tribe-discussion";
import TribeAdminItem from "../tribe-admin";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Loader from "../../../components/loader";
import LoveStreamPost from "../../../components/lovestream/Post";
import LoveStreamForm from "../../../components/lovestream/Form";
import { getCurrentUser } from "../../../helpers/Utils";
import TribeRequest from "../class-details/tribe-requests";
import axios from "axios";
import {
  convertFromHTML,
  ContentState,
  EditorState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlert from "react-bootstrap-sweetalert";
import Modal from "react-modal";
import Dropzone from "react-dropzone-uploader";
import Select from "react-select";
import { useHistory } from "react-router-dom";

let SelectedMembers = [];
let matchedUser;

const customStyles = {
  content: {
    position: "unset",
    maxWidth: "850px",
    width: "550px",
    right: "auto",
    border: "none",
    bottom: "auto",
    padding: "0 35px 35px",
    margin: "0 auto",
  },
};

const addOrganizerModalStyles = {
  content: {
    position: "unset",
    maxWidth: "850px",
    width: "550px",
    right: "auto",
    overflow: "unset",
    border: "none",
    bottom: "auto",
    padding: "0",
    margin: "0 auto",
  },
};

toast.configure({
  autoClose: 3000, //false to disable
  closeOnClick: true,
  pauseOnHover: false,
  pauseOnFocusLoss: false,
});

let tribeSingleId = "";

const TribeDetailsContainer = ({ slug, tab }) => {
  const [feed, setFeed] = useState([]);
  const [loader, setLoader] = useState(<Loader />);
  const [group, setGroup] = useState("");
  const [groupSubAdmin, setGroupSubAdmin] = useState("");
  const [groupImage, setGroupImage] = useState("");
  const [groupCover, setGroupCover] = useState("");
  const [error, setError] = useState("");
  const [allMember, setAllMember] = useState([]);
  const [requestError, setRequestError] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [pagination, setPagination] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [threadLoading, setThreadLoading] = useState(false);
  const [request, setRequest] = useState([]);
  const [checked, setChecked] = useState(false);
  const [messages, setMessages] = useState("");
  const [tribeDescription, setTribeDescription] = useState("");
  const [tribePrivacy, setTribePrivacy] = useState("");
  const [sure, setSure] = useState(false);
  const [leaveSure, setLeaveSure] = useState(false);
  const [tribeLoader, setTribeLoader] = useState(<Loader />);
  const [documentLoader, setDocumentLoader] = useState(<Loader />);
  const [documentError, setDocumentError] = useState("");
  const [photosError, setPhotosError] = useState("");

  const [tribePhotos, setTribePhotos] = useState([]);
  const [tribeDocuments, setTribeDocuments] = useState([]);
  const [uploadTribePhotos, setUploadTribePhotos] = useState("");
  const [photosInfo, setPhotosInfo] = useState("<p></p>");
  const [documentInfo, setDocumentInfo] = useState("<p></p>");
  const [tribePhotosModal, setTribePhotosModal] = useState(false);
  const [tribeDocumentstModal, setTribeDocumentstModal] = useState(false);

  const [addOrganizerModal, setAddOrganizerModal] = useState(false);

  const history = useHistory();

  const getMember = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/show-members-limited-data`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        setAllMember(res.data.data);
      });
  };

  const handleClick = () => setChecked(!checked);

  const clearDefault = () => {
    setSuccess("");
    setError("");
  };

  const fetchClassDetail = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/tribe/${slug}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        setLoader("");
        localStorage.setItem("tribeKey", slug);
        setGroup(res.data.data);
        tribeSingleId = res.data.data.id;
        setTribePrivacy(res.data.data.group_type);
        setGroupSubAdmin(res.data.data.sub_admin);
        if (res.data.data.sub_admin?.length) {
          res.data.data.sub_admin.map((item) =>
            SelectedMembers.push(item.value)
          );
        }
        let html = res.data.data.description
          ? res.data.data.description
          : "<p></p>";
        let blocksFromHTML = convertFromHTML(html);
        let content = ContentState.createFromBlockArray(blocksFromHTML);
        setTribeDescription(EditorState.createWithContent(content));
        if (tribeSingleId !== "") {
          callApi();
          getTribeRequests();
        }
      });
  };

  const deleteTribegroup = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/tribe/manage/delete-tribe?id=${group.id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if ((res.data.data.success = true)) {
          toast.success(res.data.message);
          setTimeout(function () {
            window.location.href = "/tribes";
          }, 3000);
        }
      });
  };

  const callApi = () => {
    setLoader(<Loader />);
    setFeed([]);
    setPagination("");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/feeds`,
        {
          tribe_id: tribeSingleId,
        },
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoader("");
        if (res.data.success === true) {
          setFeed(res.data.data.data);
          setCurrentPage(res.data.data.current_page);
          setPagination(res.data.data.next_page_url);
        }
      });
  };

  const getAllFeedNext = (page = currentPage) => {
    setLoading(true);
    setPagination("");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/all-feeds?page=${page}&tribe_id=${tribeSingleId}`,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.success === true) {
          setCurrentPage(res.data.data.current_page);
          setPagination(res.data.data.next_page_url);
          let nextData = res.data.data.data;
          let setmultiple = [...feed, ...nextData];
          setFeed(setmultiple);
        }
      });
  };

  const nextLink = () => {
    setPagination("");
    getAllFeedNext(currentPage + 1);
  };

  const joinGroup = () => {
    setSure(false);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/tribe-group-access`,
        {
          tribe_id: group.id,
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser() && getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setGroup(res.data.data);
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const leaveGroup = () => {
    setLeaveSure(false);
    setLoader(<Loader />);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/leave-tribe`,
        {
          tribe_id: group.id,
          user_id: getCurrentUser().id,
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser() && getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        fetchClassDetail();
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const updateInfo = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/tribe/manage/detail`,
        {
          id: group.id,
          group_type: tribePrivacy,
          title: e.target.title.value,
          description: draftToHtml(
            convertToRaw(tribeDescription.getCurrentContent())
          ),
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser() && getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.success !== true) {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setGroup(res.data.data);
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const updateInfoProfile = (e) => {
    setLoading(true);
    e.preventDefault();
    var formData = new FormData();
    formData.append("id", group.id);
    formData.append("image", groupImage);
    axios
      .post(`${process.env.REACT_APP_API_URL}/tribe/manage/photo`, formData, {
        headers: {
          Authorization: `Bearer ${getCurrentUser() && getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        setLoading(false);
        if (res.data.success !== true) {
          setError(res.data.message);
          setSuccess("");
        } else {
          setGroup(res.data.data);
          setError("");
          setSuccess(res.data.message);
        }
      });
  };

  const updateGroupImage = (e) => {
    setGroupImage(e.target.files[0]);
  };

  const updateInfoCover = (e) => {
    setLoading(true);
    e.preventDefault();
    var formData = new FormData();
    formData.append("id", group.id);
    formData.append("cover_image", groupCover);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/tribe/manage/cover-photo`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser() && getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.success !== true) {
          setError(res.data.message);
          setSuccess("");
        } else {
          setGroup(res.data.data);
          setError("");
          setSuccess(res.data.message);
        }
      });
  };

  const updateGroupCover = (e) => {
    setGroupCover(e.target.files[0]);
  };

  const deleteCover = () => {
    setDeleteLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/tribe/manage/delete-cover-photo`,
        {
          id: group.id,
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser() && getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setDeleteLoading(false);
        if (res.data.success !== true) {
          setError(res.data.message);
          setSuccess("");
        } else {
          setError("");
          setSuccess(res.data.message);
          setGroup(res.data.data);
        }
      });
  };

  const deleteImage = () => {
    setDeleteLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/tribe/manage/delete-photo`,
        {
          id: group.id,
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser() && getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        setDeleteLoading(false);
        if (res.data.success !== true) {
          setError(res.data.message);
          setSuccess("");
        } else {
          setError("");
          setSuccess(res.data.message);
          setGroup(res.data.data);
        }
      });
  };

  const getTribeRequests = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/private-tribe-request?tribe_id=${tribeSingleId}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setRequest(res.data.data);
        setRequestError(res.data.message);
      });
  };

  const getTribePhotos = () => {
    setTribePhotos([]);
    setTribeLoader(<Loader />);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/get-tribe-photos?tribe_id=${tribeSingleId}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setTribeLoader("");
        if (res.data.success !== true) {
          setPhotosError(res.data.message);
        } else {
          setPhotosError("");
          setTribePhotos(res.data.data);
        }
      });
  };

  const getTribeDocuments = () => {
    setTribeDocuments([]);
    setDocumentLoader(<Loader />);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/tribe/document/get?tribe_id=${tribeSingleId}`,
        {},
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setDocumentLoader("");
        if (res.data.success !== true) {
          setDocumentError(res.data.message);
        } else {
          setDocumentError("");
          setTribeDocuments(res.data.data);
        }
      });
  };

  const getNoteTribe = (classDescription) => {
    setMessages(classDescription);
  };

  const utility = {};
  utility["loading"] = loading;
  utility["getTribeRequests"] = getTribeRequests;
  utility["setLoading"] = setLoading;

  const onEditorStateChange = (tribeDescription) => {
    setTribeDescription(tribeDescription);
  };

  const changeTribePrivacy = (e) => {
    setTribePrivacy(e.target.value);
  };

  const postTribeDocuments = (files) => {
    setTribeDocumentstModal(false);
    setTribeLoader(<Loader />);
    var formData = new FormData();
    let selectedImages = [];
    files?.map((item) => selectedImages.push(item.file));
    for (const key of Object.keys(selectedImages)) {
      formData.append("document[]", selectedImages[key]);
    }
    formData.append("message", documentInfo);
    formData.append("privacy", "public");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/add-post?tribe_id=${tribeSingleId}`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.success === true) {
          getTribeDocuments();
          setPhotosInfo("<p></p>");
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          files.forEach((f) => f.remove());
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const postTribePhotos = (files) => {
    setTribePhotosModal(false);
    setTribeLoader(<Loader />);
    let selectedImages = [];
    files?.map((item) => selectedImages.push(item.file));
    var formData = new FormData();
    for (const key of Object.keys(selectedImages)) {
      formData.append("image[]", selectedImages[key]);
    }
    formData.append("message", photosInfo);
    formData.append("privacy", "public");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/add-post?tribe_id=${tribeSingleId}`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.success === true) {
          getTribePhotos();
          setDocumentInfo("<p></p>");
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          files.forEach((f) => f.remove());
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  if (group) {
    let usersArray = group.sub_admin_id?.split(",").map(Number);
    matchedUser = usersArray?.includes(getCurrentUser().id);
  }

  const tribeSendMessage = () => {
    // history.push(`/messages`);
    setThreadLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/create-tribe-thread`,
        {
          tribe_id: tribeSingleId,
        },
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setThreadLoading(false);
        history.push(`/messages/${res.data.data.id}`);
      });
  };

  let members = [];
  if (group.members !== undefined) {
    {
      group.members &&
        group.members?.map((member) => {
          members.push({ value: member.id, label: member.name });
        });
    }
  }
  let tribeOrganizers = [];
  if (groupSubAdmin !== undefined) {
    {
      groupSubAdmin &&
        groupSubAdmin?.map((member) => {
          tribeOrganizers.push({ value: member.id, label: member.name });
        });
    }
  }

  const getselectedMember = (e) => {
    SelectedMembers = [];
    e.map((item) => SelectedMembers.push(item.value));
  };

  const sendInvite = (e) => {
    console.log(SelectedMembers);
    setLoading(true);
    if (e !== undefined) {
      e.preventDefault();
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/update-tribe/${group.id}`,
        {
          sub_admin_id: SelectedMembers.join(","),
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.success) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setGroupSubAdmin(res.data.data);
          setAddOrganizerModal(false);
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };

  useEffect(() => {
    fetchClassDetail();
    getMember();
  }, []);

  return (
    <div className="tribe_detail section-py mt-5">
      {sure && (
        <SweetAlert
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="btn btn-primary"
          title="Are you sure?"
          btnSize="sm"
          onConfirm={() => joinGroup()}
          onCancel={() => setSure(false)}
        ></SweetAlert>
      )}
      {leaveSure && (
        <SweetAlert
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="btn btn-primary"
          title="Are you sure?"
          btnSize="sm"
          onConfirm={() => leaveGroup()}
          onCancel={() => setLeaveSure(false)}
        ></SweetAlert>
      )}
      <Modal
        ariaHideApp={false}
        isOpen={tribePhotosModal}
        style={customStyles}
        contentLabel="Add Tribe Photo"
      >
        <div className="modal-header px-0">
          <h5>Upload</h5>
          <i
            onClick={() => setTribePhotosModal(false)}
            className="fa fa-times"
          />
        </div>
        <div className="request-data-style text-center">
          <div className="uploadphoto">
            <textarea
              className="form-control mb-3"
              onChange={(e) => setPhotosInfo(e.target.value)}
            ></textarea>
            <Dropzone
              onSubmit={postTribePhotos}
              inputContent="Drop images here to upload"
              accept="image/*"
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={tribeDocumentstModal}
        style={customStyles}
        contentLabel="Add Tribe Photo"
      >
        <div className="modal-header px-0">
          <h5>Upload</h5>
          <i
            onClick={() => setTribeDocumentstModal(false)}
            className="fa fa-times"
          />
        </div>
        <div className="request-data-style text-center">
          <div className="uploadphoto">
            <textarea
              placeholder="Write something about your documents, to be shown on the group feed"
              className="form-control mb-3"
              onChange={(e) => setDocumentInfo(e.target.value)}
            ></textarea>
            <Dropzone
              onSubmit={postTribeDocuments}
              inputContent="Drop files here to upload"
              accept=".pdf, .docx, .doc"
            />
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={addOrganizerModal}
        style={addOrganizerModalStyles}
        contentLabel="Add Organizer"
      >
        <div className="modal-header">
          <h5>Add Organizer</h5>
          <i
            onClick={() => setAddOrganizerModal(false)}
            className="fa fa-times"
          />
        </div>
        <div className="modal-body">
          <form onSubmit={(e) => sendInvite(e)} id="sendinviteform">
            <div className="form-group">
              <label>Select member</label>
              <Select
                onChange={(e) => getselectedMember(e)}
                options={members}
                defaultValue={tribeOrganizers}
                isMulti
              />
            </div>
            <div className="form-group mt-4">
              {loading ? (
                <button type="button" className="btn btn-primary">
                  Loading
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add
                </button>
              )}
            </div>
            <div className="form-group mt-4">
              {error ? <p className="alert alert-danger py-1">{error}</p> : ""}
            </div>
          </form>
        </div>
      </Modal>
      <div className="container">
        {loader ? (
          loader
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div id="cover-image-container">
                <div className="has-cover-image">
                  <img
                    alt="IMG"
                    className="header-cover-img"
                    src={group.cover_image}
                  />
                </div>
                <div
                  id="item-header-cover-image"
                  className="item-header-wrap bb-enable-cover-img"
                >
                  <div id="item-header-avatar">
                    <img alt="IMG" src={group.image} />
                  </div>
                  <div id="item-header-content">
                    <div className="flex align-items-center bp-group-title-wrap">
                      <h2 className="bb-bp-group-title">{group.title}</h2>
                      <p className="bp-group-meta bp-group-status">
                        <span className="group-visibility private">
                          {group.group_type} Group
                        </span>
                      </p>
                    </div>
                    <div
                      className="group-description mt-5"
                      dangerouslySetInnerHTML={{
                        __html: group.description,
                      }}
                    />
                    <div id="item-actions" className="group-item-actions">
                      <h4 className="bp-title">Organizer</h4>
                      <ul id="group-admins">
                        <li>
                          <img
                            alt="IMG"
                            src={group.teacher && group.teacher.image}
                            data-toggle="tooltip"
                            data-placement="right"
                            title={group.teacher && group.teacher.name}
                          />
                        </li>
                      </ul>
                    </div>
                    <div className="bp-generic-meta groups-meta action">
                      <div className="generic-button">
                        {group.member_type === null ? (
                          <>
                            {getCurrentUser() ? (
                              <>
                                {group.group_type === "public" ? (
                                  <>
                                    {group.is_join === 1 ? (
                                      <button
                                        className="btn btn-dark btn-hover-dark butt-profile-resp"
                                        type="button"
                                        onClick={() => setLeaveSure(true)}
                                      >
                                        Leave group
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {group.is_join === "" ? (
                                      <button
                                        className="btn btn-dark btn-hover-dark butt-profile-resp"
                                        onClick={() => setSure(true)}
                                        type="button"
                                      >
                                        Join group
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {group.group_type === "private" ? (
                                  <>
                                    {group.is_join === 1 ? (
                                      <button
                                        className="btn btn-dark btn-hover-dark butt-profile-resp"
                                        type="button"
                                        onClick={() => setLeaveSure(true)}
                                      >
                                        Leave group
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {group.is_join === 0 ? (
                                      <button className="btn btn-dark btn-hover-dark butt-profile-resp">
                                        Request Sent
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {group.is_join === "" ? (
                                      <button
                                        className="btn btn-dark btn-hover-dark butt-profile-resp"
                                        onClick={() => setSure(true)}
                                        type="button"
                                      >
                                        Request Access
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {group.is_join === 2 ? (
                                      <button
                                        className="btn btn-dark btn-hover-dark butt-profile-resp"
                                        onClick={() => setSure(true)}
                                        type="button"
                                      >
                                        Request Access
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <button className="btn btn-dark btn-hover-dark butt-profile-resp">
                            {group.member_type}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {group.is_join === 1 ? (
                <div className="tribe_tabs mt-5">
                  <Tabs defaultIndex={tab !== null ? tab : "0"}>
                    <TabList>
                      <Tab onClick={() => callApi()}>Feed</Tab>
                      <Tab>Member </Tab>
                      {getCurrentUser().id === group?.teacher.id ||
                      matchedUser ? (
                        <Tab>Manage</Tab>
                      ) : (
                        ""
                      )}
                      <Tab onClick={getTribeDocuments}>Documents</Tab>
                      <Tab onClick={getTribePhotos}>Photos</Tab>
                      <Tab>Discussions</Tab>
                      <Tab>Send Invites</Tab>
                      <Tab>Email Options</Tab>
                    </TabList>
                    <TabPanel>
                      <div className="post">
                        <div className="post_header">
                          <div className="current-user">
                            <img alt="IMG" src={getCurrentUser().image} />
                            <span>{getCurrentUser().name}</span>
                          </div>
                          <LoveStreamForm
                            data={group}
                            callApi={callApi}
                            mentionAllMember={allMember}
                          />
                        </div>
                        {loader}
                        <InfiniteScroll
                          dataLength={feed && feed.length}
                          next={pagination !== undefined ? nextLink : ""}
                          hasMore={pagination !== undefined ? true : false}
                          loader={pagination !== "" ? <Loader /> : ""}
                        >
                          {feed &&
                            feed &&
                            feed?.map((item, i) => (
                              <LoveStreamPost
                                i={i}
                                post={item}
                                mentionAllMember={allMember}
                                pageindex={currentPage}
                              />
                            ))}
                        </InfiniteScroll>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="mb-2">Organizers</h5>
                        {getCurrentUser().id === group.teacher.id ? (
                          <button
                            onClick={() => setAddOrganizerModal(true)}
                            className="btn btn-primary py-2 px-4"
                          >
                            Add Organizer
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 mb-7">
                          <MemberGrid classOption="p-0" data={group.teacher} />
                        </div>
                        {groupSubAdmin?.length
                          ? groupSubAdmin.map((item) => (
                              <div className="col-lg-4 col-sm-6 mb-7">
                                <MemberGrid classOption="p-0" data={item} />
                              </div>
                            ))
                          : ""}
                      </div>
                      {/* <TribeAdminItem data={group.sub_admin} /> */}
                      <h5 className="mb-2">Members</h5>
                      <div className="row">
                        {group.members?.map((member, key) => {
                          return (
                            <TribeMemberGrid
                              classOption="p-0"
                              key={key}
                              data={member}
                              isTribe="1"
                              group={group}
                            />
                          );
                        })}
                      </div>
                      {/* <TribeMemberItem data={group.members} /> */}
                    </TabPanel>
                    {getCurrentUser().id === group.teacher.id || matchedUser ? (
                      <TabPanel>
                        <div className="tribe_tabs">
                          <Tabs className="vertical_tabs">
                            <TabList>
                              <Tab onClick={clearDefault}>Details</Tab>
                              <Tab onClick={clearDefault}>Photo</Tab>
                              <Tab onClick={clearDefault}>Cover Photo </Tab>
                              {getCurrentUser().id === group.teacher.id ? (
                                <Tab onClick={clearDefault}>Request </Tab>
                              ) : (
                                ""
                              )}
                              <Tab>Delete</Tab>
                            </TabList>
                            <TabPanel>
                              <form onSubmit={(e) => updateInfo(e)}>
                                <div className="form-group mt-4">
                                  <label className="m-0">Group Name</label>
                                  <input
                                    name="title"
                                    type="text"
                                    defaultValue={group.title}
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-group mt-4">
                                  <label className="m-0">
                                    Group Description
                                  </label>
                                  <div className="border">
                                    <Editor
                                      editorState={tribeDescription}
                                      wrapperClassName="demo-wrapper"
                                      editorClassName="demo-editor"
                                      onEditorStateChange={onEditorStateChange}
                                      toolbar={{
                                        options: ["inline", "list"],
                                        inline: {
                                          options: [
                                            "bold",
                                            "italic",
                                            "underline",
                                            "strikethrough",
                                          ],
                                        },
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mt-4">
                                  <label>Privacy Options</label>
                                  <div className="bp-radio-wrap">
                                    <div className="bp-radio-wrap">
                                      <input
                                        type="radio"
                                        name="group_type"
                                        id="group_type-public"
                                        className="bs-styled-radio"
                                        value="public"
                                        checked={tribePrivacy === "public"}
                                        onChange={(e) => changeTribePrivacy(e)}
                                      />
                                      <label htmlFor="group_type-public">
                                        This is a public tribe
                                      </label>
                                    </div>
                                    <ul className="group-option">
                                      <li>
                                        Any site member can join this tribe.
                                      </li>
                                      <li>
                                        This tribe will be listed in the tribes
                                        directory and in search results.
                                      </li>
                                      <li>
                                        Tribe content and activity will be
                                        visible to any site member.
                                      </li>
                                    </ul>
                                    <div className="bp-radio-wrap">
                                      <input
                                        type="radio"
                                        name="group_type"
                                        id="group_type-private"
                                        className="bs-styled-radio"
                                        value="private"
                                        checked={tribePrivacy === "private"}
                                        onChange={(e) => changeTribePrivacy(e)}
                                      />
                                      <label htmlFor="group_type-private">
                                        This is a private tribe
                                      </label>
                                    </div>
                                    <ul className="group-option">
                                      <li>
                                        Only people who request membership and
                                        are accepted can join the tribe.
                                      </li>
                                      <li>
                                        This tribe will be listed in the tribes
                                        directory and in search results.
                                      </li>
                                      <li>
                                        Tribe content and activity will only be
                                        visible to members of the tribe.
                                      </li>
                                    </ul>
                                    {/*<div className="bp-radio-wrap"> 
                                                                        <input 
                                                                            type="radio" 
                                                                            name="group_type" 
                                                                            id="group_type-hidden" 
                                                                            className="bs-styled-radio" 
                                                                            value="hidden"
                                                                            checked={tribePrivacy === 'hidden'}
                                                                            onChange={(e) => changeTribePrivacy(e)}
                                                                        />
                                                                        <label htmlFor="group_type-hidden">This is a hidden tribe</label>
                                                                    </div>
                                                                    <ul className="group-option">
                                                                        <li>Only people who are invited can join the tribe.</li>
                                                                        <li>This tribe will not be listed in the tribes directory or search results.</li>
                                                                        <li>Tribe content and activity will only be visible to members of the tribe.</li>
                                                                    </ul>*/}
                                  </div>
                                </div>
                                {/* <div className="form-group mt-4"> */}
                                {/*     <input name="name" type="checkbox" /> &nbsp; */}
                                {/*     <label className="m-0">Notify group members of these changes via email</label> */}
                                {/* </div> */}
                                <div className="form-group mt-4">
                                  {loading ? (
                                    <button
                                      className="btn btn-primary mb-3"
                                      disabled
                                    >
                                      Loading
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-primary mb-3"
                                    >
                                      Save Changes
                                    </button>
                                  )}
                                </div>
                              </form>
                            </TabPanel>

                            <TabPanel>
                              <form
                                className="edit-profile"
                                onSubmit={(e) => updateInfoProfile(e)}
                              >
                                <div className="form-group mt-4">
                                  <div className="upload_opt">
                                    <input
                                      onChange={(e) => updateGroupImage(e)}
                                      id="attached"
                                      className="d-none"
                                      type="file"
                                      name="image"
                                    />
                                    <label htmlFor="attached" className="pl-3">
                                      <i className="fa fa-camera"></i> Change
                                      Profle Image
                                      {groupImage.name ? (
                                        <>
                                          <br />
                                          <span>{groupImage.name}</span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </label>

                                    <br />
                                  </div>
                                </div>
                                <div className="form-group mt-4">
                                  {loading ? (
                                    <button
                                      className="btn btn-primary mb-3"
                                      disabled
                                    >
                                      Loading
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-primary mb-3"
                                    >
                                      Save Changes
                                    </button>
                                  )}
                                  {error ? (
                                    <>
                                      <span className="alert alert-danger d-table py-1">
                                        {error}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {success ? (
                                    <>
                                      <span className="alert alert-success d-table py-1">
                                        {success}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </form>
                              <div className="mt-5">
                                <p>
                                  To remove the existing group photo, please use
                                  the delete group profile photo button.
                                </p>
                                {deleteLoading ? (
                                  <button className="btn btn-danger" disabled>
                                    Loading
                                  </button>
                                ) : (
                                  <button
                                    onClick={deleteImage}
                                    className="btn btn-danger"
                                  >
                                    Delete Group Photo
                                  </button>
                                )}
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <form
                                className="edit-profile"
                                onSubmit={(e) => updateInfoCover(e)}
                              >
                                <div className="form-group mt-4">
                                  <p>
                                    The Cover Photo will be used to customize
                                    the header of your group.
                                  </p>
                                  <div className="upload_opt">
                                    <input
                                      onChange={(e) => updateGroupCover(e)}
                                      id="attached"
                                      className="d-none"
                                      type="file"
                                      name="image"
                                    />
                                    <label htmlFor="attached" className="pl-3">
                                      <i className="fa fa-camera"></i> Change
                                      Cover Image
                                      {groupCover.name ? (
                                        <>
                                          <br />
                                          <span>{groupCover.name}</span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </label>

                                    <br />
                                  </div>
                                </div>
                                <p className="alert alert-warning mt-3">
                                  For best results, upload an image that is
                                  1950px by 450px or larger.
                                </p>
                                <div className="form-group mt-4">
                                  {loading ? (
                                    <button
                                      className="btn btn-primary mb-3"
                                      disabled
                                    >
                                      Loading
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-primary mb-3"
                                    >
                                      Save Changes
                                    </button>
                                  )}
                                  {error ? (
                                    <>
                                      <span className="alert alert-danger d-table py-1">
                                        {error}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {success ? (
                                    <>
                                      <span className="alert alert-success d-table py-1">
                                        {success}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </form>
                              <div className="mt-5">
                                <p>
                                  If you'd like to remove the existing group
                                  cover photo but not upload a new one, please
                                  use the delete group cover photo button.
                                </p>
                                {deleteLoading ? (
                                  <button className="btn btn-danger" disabled>
                                    Loading
                                  </button>
                                ) : (
                                  <button
                                    onClick={deleteCover}
                                    className="btn btn-danger"
                                  >
                                    Delete Group Cover Photo
                                  </button>
                                )}
                              </div>
                            </TabPanel>
                            {getCurrentUser().id === group.teacher.id ? (
                              <TabPanel>
                                <div className="tribe-private-req">
                                  <div className="myclasses">
                                    {requestError ? (
                                      <span className="text-danger">
                                        {requestError}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    <table className="table">
                                      <tbody>
                                        {request?.map((item, i) => (
                                          <TribeRequest
                                            data={item}
                                            tribeId={group.id}
                                            i={i}
                                            setLoading={setLoading}
                                            getTribeRequests={getTribeRequests}
                                            utility={utility}
                                          />
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </TabPanel>
                            ) : (
                              ""
                            )}
                            <TabPanel>
                              <div className="alert alert-warning" role="alert">
                                WARNING: Deleting this group will completely
                                remove ALL content associated with it. There is
                                no way back. Please be careful with this option.
                              </div>
                              <div className="form-check">
                                <input
                                  onClick={handleClick}
                                  defaultChecked={checked}
                                  type="checkbox"
                                />
                                <label
                                  className="form-check-label label-check"
                                  htmlFor="flexCheckChecked"
                                >
                                  I understand the consequences of deleting this
                                  group.
                                </label>
                              </div>
                              {checked ? (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={deleteTribegroup}
                                >
                                  Delete Group
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary disabled"
                                >
                                  Delete Group
                                </button>
                              )}
                            </TabPanel>
                          </Tabs>
                        </div>
                      </TabPanel>
                    ) : (
                      ""
                    )}
                    <TabPanel>
                      {documentLoader}
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-2">Documents</h5>
                        <button
                          onClick={() => setTribeDocumentstModal(true)}
                          className="btn btn-primary btn-sm"
                        >
                          Add Document
                        </button>
                      </div>
                      <table className="table table-bordered mt-5">
                        <tr>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Visibility</th>
                          <th></th>
                        </tr>
                        {tribeDocuments &&
                          tribeDocuments?.map((documents, i) => (
                            <TribeDocument tribeDocuments={documents} i={i} />
                          ))}
                      </table>
                    </TabPanel>
                    {/* <TabPanel> */}
                    {/* </TabPanel> */}
                    <TabPanel>
                      {tribeLoader}
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-2">Photos</h5>
                        <button
                          onClick={() => setTribePhotosModal(true)}
                          className="btn btn-primary btn-sm"
                        >
                          Add Photos
                        </button>
                      </div>
                      <div className="row photos-tab mt-5">
                        {tribePhotos &&
                          tribePhotos?.map((photo, i) => (
                            <TribePhotos tribePhotos={photo} i={i} />
                          ))}
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <TribeDiscussion tribeSingleId={tribeSingleId} />
                    </TabPanel>
                    <TabPanel>
                      <SendInvite tribeSingleId={tribeSingleId} />
                    </TabPanel>
                    <TabPanel>
                      <h5 className="mb-2">Email Subscription Options</h5>
                      <TribeEmailOptions
                        tribeSingleId={tribeSingleId}
                        emailSubscription={group && group.email_subscription}
                      />
                    </TabPanel>
                  </Tabs>
                  <div className="tribe_members"></div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TribeDetailsContainer.propTypes = {
  data: PropTypes.object,
};

export default TribeDetailsContainer;
