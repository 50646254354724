import React from "react";
import { NavLink } from 'react-router-dom'

const NoPage = () => {
    return (
        <React.Fragment>
            <div className="notfound">
                <div className="notfound_cont">
                    <h1 style={{
                        fontSize: '200px',
                        fontWeight: '900'
                    }}>404</h1>
                    <h2 style={{
                        fontSize: '20px',
                        fontWeight: '900',
                        textTransform: 'uppercase',
                        margin: '0 0 30px'
                    }}>This page could not be found.</h2>
                    <NavLink to="/" className="btn btn-primary">Go Back Home</NavLink>
                </div>
            </div>
        </React.Fragment>
    );
};

export default NoPage;
