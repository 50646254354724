import React from "react";
import PropTypes from "prop-types";
import Button from "../button";
import { useLocation } from "react-router-dom";

const Intro = ({ data }) => {
    const location = useLocation();

    return (
        <div
            className={`hero-slider ${location.pathname !== "/join-for-free" ? '' : 'h-auto'}`}
            style={{ backgroundImage: `url(${data.image})` }}
        >
            <div className="container">
                <div className="row align-items-center banner-container">
                    <div className="col-lg-12 text-center">
                        <div className="hero-slide-content">
                            <h2 className="title animated mb-3">
                                {location.pathname === "/join-for-free"
                                    ? "10 Days of Well-full-ness"
                                    : data.title}
                            </h2>
                            {location.pathname !== "/join-for-free" ? (
                                <>
                                    <h2 className="title animated mb-3">
                                        {data.title_two}
                                    </h2>
                                    <h4 className="animated delay1 py-3">
                                        {data.sub_title}
                                    </h4>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
                {location.pathname !== "/join-for-free" ? (
                    <div className="row align-items-center mt-10 button-design">
                        <div className="col-lg-12 text-center">
                            <Button
                                classOption="btn btn-lg animated delay1 btn-dark btn-hover-dark px-10 button-intro"
                                text={data.button_name}
                                path={process.env.PUBLIC_URL + "/tribes"}
                            />
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

Intro.propTypes = {
    data: PropTypes.object,
};

export default Intro;
