import React, { useState, useEffect } from "react";
import LoveStreamPost from "../lovestream/Post";
import LoveStreamForm from "../lovestream/Form";
import { getCurrentUser, getQuotationId } from "../../helpers/Utils";
import axios from "axios";
import Loader from "../loader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import InfiniteScroll from "react-infinite-scroll-component";
import InviteTable from "./inviteTable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let recipient = [];


const InviteMembers = () => {
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState([]);
    const [recipientData, setRecipientData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sentInvites, setSentInvites] = useState();
    const [sendInviteError, setSendInviteError] = useState([]);
    const [loader, setLoader] = useState(<Loader />);

    const [emailInvites, setInvites] = useState(
        `An invitation from ${getCurrentUser().name} to join Soul Tribe Online`
    );

    const getInviteData = (e) => {
        recipient = e;
        setRecipientData({ ...recipientData, recipient });
    };

    const sentInvite = (e) => {
        setLoader(<Loader />);
        setSuccess("");
        e.preventDefault();
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/member/soultribe-sent-invites`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                setSentInvites(res.data.data);
            });
    };

    const emailInvite = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/member/soultribe-invite`,
                {
                    recipient,
                    subject: e.target.subject.value,
                    message: e.target.message.value,
                },
                {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.success === false) {
                    setSuccess("");
                    toast.error(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    setError("");
                    toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
    };

    useEffect(() => {

    }, []);
    return (
        <Tabs>
            <TabList>
                <Tab>Send Invites</Tab>
                <Tab onClick={sentInvite}>Sent Invites</Tab>
            </TabList>
            <TabPanel>
                <form className="w-100" onSubmit={emailInvite}>
                    <div className="row">
                        <div className="col-12 mb-7">
                            <InviteTable onChange={getInviteData} />
                        </div>
                        <div className="col-12 mb-7">
                            <label>
                                Customize the text of the invitation subject.
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                defaultValue={`An invitation from ${
                                    getCurrentUser().name
                                } to join Soul Tribe Online`}
                                name="subject"
                            />
                        </div>
                        <div className="col-12 mb-7">
                            <label>
                                Customize the text of the invitation email. A
                                link to register will be sent with the email.
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                defaultValue={`You have been invited by ${
                                    getCurrentUser().name
                                } to join the Soul Tribe Online community.`}
                                name="message"
                            />
                        </div>
                        <div className="col-12">
                            {loading ? (
                                <button
                                    disabled
                                    className="btn btn-dark btn-hover-dark"
                                >
                                    Loading
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="btn btn-dark btn-hover-dark"
                                >
                                    Send Invites
                                </button>
                            )}
                        </div>
                        <div className="col-12">
                            <p style={{ color: "red" }}>{sendInviteError}</p>
                            <p style={{ color: "green" }}>{success}</p>
                        </div>
                    </div>
                </form>
            </TabPanel>
            <TabPanel>
                {loader}
                <p>You have sent invitation emails to the following people.</p>
                <div className="table-class-flow">
                    <table className="table table-bordered" id="emails">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Invited</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        {sentInvites &&
                            sentInvites.map((item, i) => (
                                <>
                                    <tbody>
                                        <tr>
                                            <td key={i}>{item.name}</td>
                                            <td key={i}>{item.email}</td>
                                            <td key={i}>{item.created_at}</td>
                                            <td key={i}>invited</td>
                                        </tr>
                                    </tbody>
                                </>
                            ))}
                    </table>
                </div>
            </TabPanel>
        </Tabs>
    );
}

export default InviteMembers;
