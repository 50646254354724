import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ScrollToTop from "../components/scroll-to-top";
import LoveStreamForm from "../components/lovestream/Form";
import LoveStreamPost from "../components/lovestream/Post";
import SEO from "../components/seo";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import Loader from "../components/loader";
import { getCurrentUser } from "../helpers/Utils";
import axios from "axios";
import PageLoader from "../components/page-loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LovestreamPage = () => {
    const query = useQuery();
    const [feed, setFeed] = useState([]);
    const [memberLoader, setMemberLoader] = useState(<Loader />);
    const [currentPage, setCurrentPage] = useState("");
    const [pagination, setPagination] = useState("");
    const [loading, setLoading] = useState(false);
    const [activeUser, setActiveUser] = useState([]);
    const [allMember, setAllMember] = useState([]);
    const [upcomingClass, setUpcomingClass] = useState([]);
    const [upcomingClassesLoader, setUpcomingClassesLoader] = useState(
        <Loader />
    );
    const [hashtag] = useState(
        query.get("hashtag") ? query.get("hashtag") : ""
    );
    const [isTeacher, setisTeacher] = useState("");

    const callApi = () => {
        setPagination("");
        setFeed([]);
        axios
            .get(`${process.env.REACT_APP_API_URL}/all-feeds?q=${hashtag}`, {
                headers: {
                    "Content-type": "multipart/form-data",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.success === true) {
                    setFeed(res.data.data.data);
                    setCurrentPage(res.data.data.current_page);
                    setPagination(res.data.data.next_page_url);
                }
            });
    };

    const getAllFeedNext = (page = currentPage) => {
        setLoading(true);
        setPagination("");
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/all-feeds?page=${page}&q=${hashtag}`,
                {
                    headers: {
                        "Content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.success === true) {
                    setCurrentPage(res.data.data.current_page);
                    setPagination(res.data.data.next_page_url);
                    let nextData = res.data.data.data;
                    let setmultiple = [...feed, ...nextData];
                    setFeed(setmultiple);
                }
            });
    };

    const getActiveUsers = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/online-user`, {
                headers: {
                    "Content-type": "multipart/form-data",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setMemberLoader("");
                if (res.data.success === true) {
                    setActiveUser(res.data.data);
                }
            });
    };

    const UpComingLiveClass = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/upcoming-live-classes`, {
                headers: {
                    "Content-type": "multipart/form-data",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.success === true) {
                    setUpcomingClassesLoader("");
                    setUpcomingClass(res.data.data);
                }
            });
    };

    const nextLink = () => {
        setPagination("");
        getAllFeedNext(currentPage + 1);
    };

    const getRole = () => {
        let role = [];
        let teacher = "";
        role = getCurrentUser().role;
        if (role !== null) {
            role.map((item) => {
                return (teacher = item.label_teacher);
            });
            setisTeacher(teacher);
        }
    };

    const getMember = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/show-members-limited-data`, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setAllMember(res.data.data);
            });
    };

    useEffect(() => {
        if (getCurrentUser()) {
            getMember();
            getRole();
            callApi();
            getActiveUsers();
            UpComingLiveClass();
        }
    }, []);

    return (
        <React.Fragment>
            <PageLoader />
            <Layout>
                <SEO title="Soul Tribe Online Community – Create Class" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Love Stream"
                        excerpt=""
                        image="./images/contact/1.png"
                    />
                    {getCurrentUser().warning !== null ? (
                        <NavLink
                            title={getCurrentUser().name}
                            to="/edit-profile"
                        >
                            <div className="alert alert-warning p-1 text-center">
                                {getCurrentUser().warning}
                            </div>
                        </NavLink>
                    ) : (
                        ""
                    )}
                    <section className="section-py">
                        <div className="container">
                            {getCurrentUser() ? (
                                <div className="love_row">
                                    <div className="love_row-col right_bar">
                                        <div className="post">
                                            <div className="post_header">
                                                <div className="current-user">
                                                    <img
                                                        src={
                                                            getCurrentUser()
                                                                .image
                                                        }
                                                    />
                                                    <span>
                                                        {getCurrentUser().name}
                                                    </span>
                                                </div>
                                                <LoveStreamForm
                                                    callApi={callApi}
                                                    mentionAllMember={allMember}
                                                />
                                            </div>
                                            <>
                                                <InfiniteScroll
                                                    dataLength={feed.length}
                                                    next={
                                                        pagination !== null
                                                            ? nextLink
                                                            : ""
                                                    }
                                                    hasMore={
                                                        pagination !== null
                                                            ? true
                                                            : false
                                                    }
                                                    loader={
                                                        <h4 className="text-center">
                                                            {pagination !==
                                                            null ? (
                                                                <Loader />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </h4>
                                                    }
                                                >
                                                    {feed &&
                                                        feed.map((item, i) => (
                                                            <LoveStreamPost
                                                                post={item}
                                                                pageindex={
                                                                    currentPage
                                                                }
                                                                mentionAllMember={
                                                                    allMember
                                                                }
                                                                i={i}
                                                            />
                                                        ))}
                                                </InfiniteScroll>
                                            </>
                                        </div>
                                    </div>
                                    <div className="love_row-col">
                                        <div className="sidebar-widget mb-2">
                                            <h4>Recently Active Members</h4>
                                            {memberLoader}
                                            <ul className="item-list">
                                                {activeUser.map((user, i) => (
                                                    <li
                                                        className="vcard"
                                                        key={i}
                                                    >
                                                        <NavLink
                                                            title={user.name}
                                                            to={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                `/member/${user.id}`
                                                            }
                                                        >
                                                            <div className="item-avatar">
                                                                <img
                                                                    src={
                                                                        user.image
                                                                    }
                                                                />
                                                            </div>
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="sidebar-widget mb-2">
                                            <h4>Upcoming Live Class</h4>
                                            {upcomingClassesLoader}
                                            <ul className="liveclass">
                                                {upcomingClass.map(
                                                    (item, i) => (
                                                        <>
                                                            {i < 2 ? (
                                                                <NavLink
                                                                    title={
                                                                        item.title
                                                                    }
                                                                    to={
                                                                        process
                                                                            .env
                                                                            .PUBLIC_URL +
                                                                        `/event/${item.slug}`
                                                                    }
                                                                >
                                                                    <li className="liveclass-list">
                                                                        <div className="item-avatar">
                                                                            <img
                                                                                src={
                                                                                    item.image
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="item">
                                                                            <div className="item-title">
                                                                                <h6>
                                                                                    {
                                                                                        item.title
                                                                                    }
                                                                                </h6>
                                                                                <h6 className="m-0">
                                                                                    <small className="m-0">
                                                                                        <i
                                                                                            className="fa fa-calendar mr-3"
                                                                                            aria-hidden="true"
                                                                                        ></i>
                                                                                        {
                                                                                            item.modified_class_date
                                                                                        }{" "}
                                                                                        <br />
                                                                                        <i
                                                                                            className="fa fa-clock-o"
                                                                                            aria-hidden="true"
                                                                                        ></i>
                                                                                        {
                                                                                            item.start_time
                                                                                        }{" "}
                                                                                        -{" "}
                                                                                        {
                                                                                            item.end_time
                                                                                        }
                                                                                    </small>
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </NavLink>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            clear: "both",
                                        }}
                                    ></div>
                                </div>
                            ) : (
                                <>
                                    <p>
                                        The Lovestream is the social media page
                                        free of advertisements and algorithms.
                                        This space is for community connection,
                                        sharing and inspiration. Please stop by,
                                        introduce yourself and share something
                                        that energizes you!
                                    </p>
                                    <NavLink
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/registration"
                                        }
                                    >
                                        <button className="btn btn-dark btn-hover-dark">
                                            Join Now
                                        </button>
                                    </NavLink>
                                </>
                            )}
                        </div>
                    </section>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default LovestreamPage;
