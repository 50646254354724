import { useState, useEffect } from "react";
import axios from "axios";
import { getCurrentUser } from "../../helpers/Utils";

function GetLinkPreview(text) {
  const [previewLink, setPreviewLink] = useState("");

  const getLinkPreview = () => {
    let contentForPreview = text.text;

    var urlRegex = /(https?:\/\/[^\s]+)/g;
    contentForPreview.replace(urlRegex, function (url) {
      if (url) {
        setPreviewLink("");
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/preview-link?url=${url.replace(
              "&nbsp",
              ""
            )}`
          )
          .then(function (res) {
            setPreviewLink(res.data.data);
          })
          .catch(function (error) {
            console.log(error.response);
          });
      }
    });
  };

  useEffect(() => {
    getLinkPreview();
  }, []);

  return (
    <>
      {previewLink !== "" ? (
        <div className="preview-link-box">
          <p
            dangerouslySetInnerHTML={{
              __html: previewLink,
            }}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default GetLinkPreview;
