import React from "react";
import CreateClassForm from "../create-class-form";


const CreateClassPage = ( data ) => {

    return (
        <section className="contact-section mt-5 upload-class">
            <CreateClassForm 
                data={data}
            />
        </section>
    );
};

export default CreateClassPage;