import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BaseInfo from './base-info';
import ChangeCover from './change-cover';
import ChangePassword from './change-password';
import ChangeProfileImage from './profile-image';
import EmailSetting from './email-settings';
 
function EditProfileTabs() {
	return (
		<div className="edit-profile section-py">
            <div className="container">
            	<div className="tribe_tabs mt-5">
                    <Tabs className="vertical_tabs">
                        <TabList>
                            <Tab><i className="fa fa-edit"></i> Edit</Tab>
                            <Tab><i className="fa fa-id-badge"></i> Profile Photo</Tab>
                            <Tab><i className="fa fa-image"></i> Cover Photo</Tab>
                            <Tab><i className="fa fa-lock"></i> Change Password</Tab>
                            <Tab><i className="fa fa-gear"></i> Email Settings</Tab>
                        </TabList>
                        <TabPanel>
                        	<BaseInfo />
                        </TabPanel>
                        <TabPanel>
                        	<ChangeProfileImage />
                        </TabPanel>
                        <TabPanel>
                        	<ChangeCover />
                        </TabPanel>
                        <TabPanel>
                            <ChangePassword />
                        </TabPanel>
                        <TabPanel>
                            <EmailSetting />
                        </TabPanel>
                    </Tabs>
	            </div>
            </div>
        </div>
	)
}

export default EditProfileTabs;