import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MemberGrid from "../../../components/member";
import useSWR from "swr";
import { getCurrentUser } from '../../../helpers/Utils';
import axios from "axios";
import Loader from "../../../components/loader";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useLocation } from "react-router-dom";
import 'react-tabs/style/react-tabs.css';
import InfiniteScroll from 'react-infinite-scroll-component'

//Funtion for getting the router params
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

let defaultSearchValue

function MemberItemContainer() {
    let query = useQuery();
    const [tabId, setTabId] = useState(query.get('tab'))
    const [data, setdata] = useState([]);
    const [myData, setMyData] = useState([]);
    const [loader, setLoader] = useState(<Loader />)
    const [allLoader, setAllLoader] = useState(<Loader />)
    const [error, setError] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [pagination, setPagination] = useState('')
    const [myPagination, setMyPagination] = useState('')
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [allMembers, setAllMembers] = useState('')
    const [myConnectionCount, setMyConnectionCount] = useState('')

    const getMembers = (e) => {
        setdata([])
        setAllLoader(<Loader />)
        setPagination('')

        var url = `${process.env.REACT_APP_API_URL}/members`;

        if (e) {
            url = `${process.env.REACT_APP_API_URL}/members?search=${e}`
        }

        axios.get(url,
            {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${getCurrentUser().token}`
                },
            }
        )
            .then(function (res) {
                setAllLoader('')
                if (res.data.success !== false) {
                    setPagination(res.data.data.member.next_page_url)
                    setAllMembers(res.data.data.total_members_count)
                    setdata(res.data.data.member.data);
                    setError('')
                } else {
                    setError(res.data.message)
                }
            })
    }

    const getMembersNext = (page = currentPage) => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/members?page=${page}`, {
            headers: {
                Authorization: `Bearer ${getCurrentUser() && getCurrentUser().token}`,
            }
        })
            .then(function (res) {
                setLoading(false)
                if (res.data.success !== false) {
                    setCurrentPage(res.data.data.member.current_page)
                    let nextData = res.data.data.member.data
                    let setmultiple = [...data, ...nextData]
                    setdata(setmultiple)
                    setPagination(res.data.data.member.next_page_url)
                }
            })
    }
    const nextLink = () => {
        getMembersNext(currentPage + 1)
    }

    const getMyMembers = (e) => {
        var url = `${process.env.REACT_APP_API_URL}/member/my-connections`;

        if (e) {
            url = `${process.env.REACT_APP_API_URL}/member/my-connections?search=${e}`
        }
        setMyData([])
        setLoader(<Loader />)
        axios.get(
            url,
            {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${getCurrentUser().token}`
                },
            }
        )
            .then(function (res) {
                setLoader('')
                if (res.data.success !== false) {
                    setMyData(res.data.data.my_connections);
                    setMyConnectionCount(res.data.data.my_connections_count);
                }
            })
    }

    const filter = (e) => {
        defaultSearchValue = e.target.value
        setPagination('')
        setdata([])
        setAllLoader(<Loader />)
        setTimeout(() => {
            getMembers(e.target.value)
        }, 2000);
        setCurrentPage(1)

    }
    const Myfilter = (e) => {
        getMyMembers(e.target.value)
        setCurrentPage(1)
    }

    useEffect(() => {
        getMembers();
        getMyMembers()
    }, []);
    return (
        <section className="blog-section section-py members__page">
            <div className="container">
                <Tabs className="vertical_tabs" defaultIndex={tabId !== null ? tabId : 0}>
                    <TabList>
                        <Tab>All Members ({allMembers})</Tab>
                        <Tab>My Connections ({myConnectionCount})</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="searchandfilter text-right m-0">
                            <ul className="m-0">
                                <li className="w-auto">
                                    <input type="search" name="ofsearch" defaultValue={defaultSearchValue} onChange={filter} placeholder="Search …" />
                                </li>
                            </ul>
                        </div>
                        {allLoader}
                        <InfiniteScroll
                            dataLength={data && data.length}
                            next={pagination ? nextLink : ''}
                            hasMore={search === '' ? true : false}
                            loader={
                                <h4 className="text-center">
                                    {pagination ? (<Loader />) : ''}
                                </h4>
                            }
                        >
                            <div className="row">
                                {error}
                                {data && data.map((member, key) => (
                                    <div
                                        key={key}
                                        className="col-lg-3 col-sm-6 mb-7"
                                    >
                                        <MemberGrid
                                            classOption="p-0"
                                            key={key}
                                            data={member}
                                        />
                                    </div>
                                ))}
                            </div>
                        </InfiniteScroll>
                    </TabPanel>
                    <TabPanel>

                        {loader ? (<>{loader}</>) : (
                            <>
                                {myData.length === 0 ? 'Sorry, no members were found' : ''}
                            </>
                        )}
                        <div className="row  members__list">
                            {myData && myData.map((member, key) => (
                                <div
                                    key={key}
                                    className="col-lg-3 col-sm-6 mb-7"
                                >
                                    <MemberGrid
                                        classOption="p-0"
                                        key={key}
                                        data={member}
                                    />
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </section>
    );
};


export default MemberItemContainer;
