import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { setCurrentUser, getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlert from "react-bootstrap-sweetalert";

let user = "";
function SingleClass(classes) {
    const [data, setData] = useState(classes.classes);
    const [isTeacher, setisTeacher] = useState("");
    const [sure, setSure] = useState(false);

    const removeClass = (e) => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/delete-class`,
                {
                    class_id: e,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setData("");
                setSure(false);
                toast.success(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };
    if (getCurrentUser() && getCurrentUser().token !== "") {
        user = getCurrentUser().token;
    }

    //Get the role of user
    let teacher = "";
    const getRole = () => {
        let role = [];

        role = getCurrentUser().role;
        if (user && role !== null) {
            role.map((item) => {
                return (teacher = item.label_teacher);
            });
        }
    };
    useEffect(() => {
        if (user !== "") {
            getRole();
            setisTeacher(teacher);
        }
    }, [isTeacher]);

    if (data !== "") {
        return (
            <>
                <tr key={data.id}>
                    <td>
                        <div className="d-flex align-items-center w-100">
                            <img src={data.image} alt="img" />
                            <h3
                                className="text-capitalize"
                                style={{ marginLeft: "20px" }}
                            >
                                <Link
                                    to={
                                        process.env.PUBLIC_URL +
                                        `/class/${data.slug}`
                                    }
                                >
                                    {data.title}
                                </Link>
                            </h3>
                        </div>
                    </td>
                    {isTeacher && isTeacher === "teacher" ? (
                        <>
                            <td className="text-right">
                                <Link
                                    to={
                                        process.env.PUBLIC_URL +
                                        `/editclass?ref=${data.slug}`
                                    }
                                >
                                    {user &&
                                    getCurrentUser().id === data.teacher.id ? (
                                        <i className="fa fa-edit"></i>
                                    ) : (
                                        ""
                                    )}
                                </Link>
                                <button
                                    style={{ marginLeft: "15px" }}
                                    onClick={() => setSure(true)}
                                >
                                    {user &&
                                    getCurrentUser().id === data.teacher.id ? (
                                        <i className="fa fa-trash"></i>
                                    ) : (
                                        ""
                                    )}
                                </button>
                            </td>
                        </>
                    ) : (
                        ""
                    )}
                </tr>
                {sure && (
                    <SweetAlert
                        showCancel
                        confirmBtnText="Yes, delete it!"
                        confirmBtnBsStyle="btn btn-primary"
                        title="Are you sure?"
                        btnSize="sm"
                        onConfirm={() => removeClass(data.id)}
                        onCancel={() => setSure(false)}
                    >
                        You will not be able to recover this class!
                    </SweetAlert>
                )}
            </>
        );
    } else {
        return "";
    }
}

export default SingleClass;
