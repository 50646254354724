import React, { useState, useEffect } from "react";
import Layout from "../layouts/index";
import SEO from "../components/seo";
import PageLoader from "../components/page-loader";
import Logo from "../components/logo";
import axios from "axios";
import IntroContainer from "../containers/home/intro";
import { NavLink, Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { setCurrentUser } from "../helpers/Utils";

const MotherTreeRetreat = () => {
    const [affiliate, setAffiliate] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const registerForFree = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/join-for-free`,
                {
                    name: e.target.name.value,
                    email: e.target.email.value,
                    affiliate_id: e.target.affiliate_id.value,
                },
                {
                    headers: {
                        "Content-type": "application/json",
                    },
                }
            )
            .then(function (res) {
                if (res.data.success) {
                    toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("myForm").reset();
                    setCurrentUser(res.data.data.user);
                    history.push("/plans");
                } else {
                    setLoading(false);
                    toast.error(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
    };
    const getAffiliate = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/affiliates`, {
                headers: {
                    "Content-type": "application/json",
                },
            })
            .then(function (res) {
                setAffiliate(res.data.data);
            });
    };

    useEffect(() => {
        getAffiliate();
    }, []);

    return (
        <React.Fragment>
            <PageLoader />
            <Layout>
                <SEO title="Soul Tribe Mother Tree Retreat: San Diego" />
                <div className="wrapper">
                    <header className="header-section position-relative">
                        <div className="container">
                            <div className="header-logo text-center">
                                <Logo
                                    classOption="d-block"
                                    image={`${process.env.PUBLIC_URL}/images/logo/soultribe.png`}
                                />
                            </div>
                        </div>
                    </header>
                    <div className="marketing_form mt-5 pt-5">
                        <div className="container">
                            <div className="title-section title-section text-center">
                                <h3 className="title">
                                    Soul Tribe Mother Tree Retreat: San Diego
                                </h3>
                            </div>
                            <div className="row text-center py-5">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <h4>Date</h4>
                                            <p>Saturday 18-19th March</p>
                                        </div>
                                        <div className="col-sm-4">
                                            <h4>Time</h4>
                                            <p>10:00AM - 5:00PM</p>
                                        </div>
                                        <div className="col-sm-4">
                                            <h4>Cost</h4>
                                            <p>$299</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt-5 mt-md-0">
                                    <h4>Where</h4>
                                    <p>San Diego</p>
                                </div>
                            </div>
                            <img
                                className="mw-100 m-auto d-block pb-5"
                                src={`${process.env.PUBLIC_URL}/images/soul-tribe-mother-tree-retreat-page.png`}
                                alt="s"
                            />
                            <div
                                className="timing mt-4"
                                style={{
                                    // maxWidth: "800px",
                                    margin: "0 auto",
                                }}
                            >
                                <h3
                                    style={{
                                        fontWeight: "600",
                                    }}
                                    className="text-center mb-4"
                                >
                                    Schedule
                                </h3>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th
                                                className="text-center p-2"
                                                colSpan="2"
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: "600",
                                                        fontFamily:
                                                            "Open Sans, sans-serif",
                                                        color: "#263238",
                                                    }}
                                                >
                                                    Saturday
                                                </span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                Arriving Coffee/Tea
                                            </td>
                                            <td className="text-center p-2">
                                                08:30AM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                Honoring the Land, the Mother
                                                Trees and a brief sharing the
                                                Overstory of the Land.
                                            </td>
                                            <td className="text-center p-2">
                                                11:00AM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                Lunch - Served in the garden
                                            </td>
                                            <td className="text-center p-2">
                                                12:00AM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                Medicine Walk
                                            </td>
                                            <td className="text-center p-2">
                                                01:00PM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                Holotropic Breathwork
                                            </td>
                                            <td className="text-center p-2">
                                                02:00PM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                Sharing around the Fire
                                            </td>
                                            <td className="text-center p-2">
                                                02:45PM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                Walk in Beauty Poetry
                                            </td>
                                            <td className="text-center p-2">
                                                03:45PM
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                className="text-center p-2"
                                                colSpan="2"
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: "600",
                                                        fontFamily:
                                                            "Open Sans, sans-serif",
                                                        color: "#263238",
                                                    }}
                                                >
                                                    Sunday
                                                </span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                Arrival
                                                Coffee/Tea/Croissants/Muffins/Energy
                                                Bars
                                            </td>
                                            <td className="text-center p-2">
                                                08:30AM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                Sensitivity as a Super Power
                                                with Susan Trees and a brief
                                                sharing the Overstory of the
                                                Land.
                                            </td>
                                            <td className="text-center p-2">
                                                09:00AM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                Shiva-Yoga Nidra Sound Journey
                                            </td>
                                            <td className="text-center p-2">
                                                10:15AM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                Sharing about morning
                                                experiences
                                            </td>
                                            <td className="text-center p-2">
                                                11:30AM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">Lunch</td>
                                            <td className="text-center p-2">
                                                12:30PM
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                Closing Circle and Regenerative
                                                Community Design touring the
                                                land, living technologies and
                                                systems as the teachings
                                            </td>
                                            <td className="text-center p-2">
                                                01:30PM
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="et_pb_text_inner">
                                        <ul>
                                            <li>
                                                <p>
                                                    In ecstatic communion with
                                                    our online Soul Tribe Online
                                                    Community, we are very happy
                                                    to present our first
                                                    retreat!
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    JOIN US for a 2-Day
                                                    immersion into the ancestral
                                                    Mother Trees, surrounded in
                                                    the beautiful Pacific Beach
                                                    community, honoring and
                                                    connecting into Kumeyaay
                                                    land.&nbsp; Together, we
                                                    will cultivate community,
                                                    threaded with divine wisdom,
                                                    innate curiosity and humane
                                                    connection.&nbsp;&nbsp;&nbsp;
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Retreat Offerings
                                                    Include:&nbsp; Sensitivity
                                                    is Your SuperPower, Walk in
                                                    Beauty Poetry, Yoga Nidra
                                                    Sound Journey, Beach
                                                    Medicine Walks, Regenerative
                                                    Community Design teachings,
                                                    holotropic breathwork
                                                    session, yoga, community
                                                    fresh organic meals, and so
                                                    much more.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Lunches, light breakfast and
                                                    snacks included.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    Location:&nbsp; A private
                                                    home, built in 1912, on
                                                    Kumeyaay land, gifted to the
                                                    late Donal and Florence
                                                    Hord, both world renowned
                                                    artists.&nbsp; The beautiful
                                                    garden was designed by the
                                                    legendary Kate Sessions,
                                                    known as the Grandmother of
                                                    Balboa Park and the
                                                    cultivator of the Mother
                                                    Trees on the land.
                                                </p>
                                            </li>
                                        </ul>
                                        <div className="list">
                                            <h3>Spaceholders</h3>
                                            <div className="list-single">
                                                <img
                                                    src="https://www.soulbodyojai.com/wp-content/uploads/2023/01/web.CY1A2650.jpg"
                                                    alt="Kristen"
                                                />
                                                <div className="members_content">
                                                    <h4>Kristen</h4>
                                                    <p>
                                                        spends most of her time
                                                        these days co-creating
                                                        new ways of living on
                                                        Planet Earth, mentoring,
                                                        rebuilding,
                                                        contemplating, and
                                                        working in her garden.
                                                    </p>
                                                    <p>
                                                        She is a Mother Earth
                                                        activist, regenerative
                                                        community builder, and
                                                        Kundalini student
                                                        teacher.
                                                    </p>
                                                    <p className="p1">
                                                        Kristen believes
                                                        honoring our inner
                                                        selves is to strengthen
                                                        our resolve to serve our
                                                        committed life and show
                                                        up for others.
                                                    </p>
                                                    <p>
                                                        She is the mom to three
                                                        amazing adult daughters,
                                                        next-generation leaders,
                                                        and a hip-wise
                                                        grandmother to the most
                                                        beautiful starseed!
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="list-single">
                                                <img
                                                    src="https://www.soulbodyojai.com/wp-content/uploads/2023/01/Screenshot-2023-01-01-at-8.51.05-AM.png"
                                                    alt="Tara Matthews"
                                                />
                                                <div className="members_content">
                                                    <h4>Tara Matthews</h4>
                                                    <p>
                                                        Tara Matthews,L.Ac is an
                                                        accomplished yoga
                                                        teacher of over 25 years
                                                        and full time
                                                        acupuncturist and
                                                        Practitioner of Chinese
                                                        Medicine. Founder and
                                                        spaceholder at
                                                        SoulTribeOnline.com and
                                                        Soul Body Ojai, a
                                                        healing center and yoga
                                                        studio in Ojai,
                                                        California, she enjoys
                                                        teaching, leading sacred
                                                        journeys and seeing
                                                        clients to bring about
                                                        higher vibrational
                                                        healing states. She
                                                        brings a unique
                                                        perspective to Kundalini
                                                        Yoga informed by years
                                                        of practical and
                                                        mystical experience in
                                                        many complementary
                                                        modalities.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="list-single">
                                                <img
                                                    src="https://www.soulbodyojai.com/wp-content/uploads/2023/01/WP_20190803_13_18_00_Pro.jpg"
                                                    alt="Alison Dhuanna"
                                                />
                                                <div className="members_content">
                                                    <h4>Alison Dhuanna</h4>
                                                    <p>
                                                        Alison Dhuanna from the
                                                        UK is an Astrologer
                                                        specializing in the
                                                        Goddess Asteroids and
                                                        Gene Keys. She is also a
                                                        Poet and Priestess
                                                        giving Gong Baths
                                                        regularly as Dreaming
                                                        Temples.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="list-single">
                                                <img
                                                    src="https://www.soulbodyojai.com/wp-content/uploads/2023/01/Screenshot_20221231_120231_Gallery.jpg"
                                                    alt="Shiva Preet"
                                                />
                                                <div className="members_content">
                                                    <h4>Shiva Preet</h4>
                                                    <p>
                                                        Shiva Preet weaves
                                                        Sacred Sound, Intention
                                                        and Ceremony into her
                                                        teachings. An Ojai
                                                        Native (Chumash lineage)
                                                        Sound Healer, Kundalini,
                                                        and Yoga Nidra
                                                        facilitator.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="list-single">
                                                <img
                                                    src="https://panel.soultribeonline.com/storage/users/5fea845b4b92a-bpfull.jpg"
                                                    alt="Susan von Thun"
                                                />
                                                <div className="members_content">
                                                    <h4>Susan von Thun</h4>
                                                    <p>
                                                        Susan von Thun is a
                                                        scientist, communicator,
                                                        yogi, and healer. With
                                                        the perspective of a
                                                        logical scientist, she
                                                        has navigated her own
                                                        spiritual journey with a
                                                        curious, open mind.
                                                        Through yoga,
                                                        meditation, and a
                                                        variety of healing
                                                        modalities, she has
                                                        awakened many of her
                                                        innate gifts as an
                                                        intuitive healer and is
                                                        passionate about helping
                                                        others become aware of
                                                        their own gifts. In all
                                                        of her offerings, it is
                                                        her intention to provide
                                                        subtle, yet practical
                                                        tools that will support
                                                        your expansion and help
                                                        you remember your own
                                                        divinity. She is a Hatha
                                                        and Kundalini yoga and
                                                        meditation teacher, an
                                                        intuitive healer,
                                                        certified master in Usui
                                                        and Kundalini Reiki and
                                                        other advanced reiki
                                                        techniques. She is also
                                                        certified in Crystal
                                                        healing, Inner Chakra
                                                        healing, Breathwork,
                                                        Cellular Alchemy, NLP,
                                                        Parts therapy, Angel
                                                        healing, and has been
                                                        attuned to a variety of
                                                        planetary and spirit
                                                        guide energies to help
                                                        you on your journey. In
                                                        all sessions with Susan,
                                                        you will receive exactly
                                                        the healing energy and
                                                        guidance that your soul
                                                        is ready and asking for
                                                        to ignite a deep
                                                        remembrance of your
                                                        Self.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="register_now my-5 py-5 text-center">
                                                <h3>
                                                    Two Day Registration Rates
                                                </h3>
                                                <p>
                                                    <a
                                                        href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=CX86LK2LJAZWJ"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Registration HERE:
                                                        $299.00{" "}
                                                        <i className="icofont-maximize"></i>
                                                    </a>
                                                </p>
                                                <p>
                                                    <a
                                                        href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=PLGKFHN2P3LR4"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Assisted Registration
                                                        (lower income) HERE:
                                                        $222.00{" "}
                                                        <i className="icofont-maximize"></i>
                                                    </a>
                                                </p>
                                                <p>
                                                    <a
                                                        href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=UJM4HFHUAHVGG"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Supporting Registration
                                                        (Thank you!): $333.00{" "}
                                                        <i className="icofont-maximize"></i>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer-section">
                        <div className="container">
                            <div className="copy-right-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="copyright-info text-center">
                                                <span>
                                                    © {new Date().getFullYear()}{" "}
                                                    <Link
                                                        to="https://soultribeonline.com/"
                                                        target="_blank"
                                                    >
                                                        Soul Tribe Online
                                                    </Link>
                                                    . All rights reserved.
                                                    &nbsp;
                                                    <Link
                                                        to={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/privacy"
                                                        }
                                                    >
                                                        Privacy Policy
                                                    </Link>{" "}
                                                    &nbsp;| &nbsp;
                                                    <Link
                                                        to={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/terms-conditions"
                                                        }
                                                    >
                                                        Terms & Conditions
                                                    </Link>
                                                </span>
                                                <p className="m-0">
                                                    Questions? Send us an email
                                                    at{" "}
                                                    <Link
                                                        to="#"
                                                        onClick={(e) => {
                                                            window.location.href =
                                                                "mailto:info@soultribeonline.com";
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        info@soultribeonline.com
                                                    </Link>
                                                </p>
                                                <p>
                                                    For support email at{" "}
                                                    <Link
                                                        to="#"
                                                        onClick={(e) => {
                                                            window.location.href =
                                                                "mailto:support@soultribeonline.com";
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        support@soultribeonline.com
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default MotherTreeRetreat;
