import React, { useState, useEffect } from "react";
import ClassGrid from "../../../components/class";
import { Link } from "react-router-dom";
import SingleClass from "./class";
import { getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";

let user = "";

function MyLiveClasses(data, getLiveClasses) {
    const [isTeacher, setisTeacher] = useState("");

    const removeClass = (e) => {
        axios
        .post(
            `${process.env.REACT_APP_API_URL}/delete-class`,
            {
                class_id: e,
            },
            {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {

        });
    };

    if (getCurrentUser() && getCurrentUser().token !== "") {
        user = getCurrentUser().token;
    }

    //Get the role of user
    let teacher = "";
    const getRole = () => {
        let role = [];

        role = getCurrentUser().role;
        if (user && role !== null) {
            role.map((item) => {
                return (teacher = item.label_teacher);
            });
        }
    };
    useEffect(() => {
        if (user !== "") {
            getRole();
            setisTeacher(teacher);
        }
    }, [isTeacher]);

    return (
        <div className="row">
            {data.data && data.data.map((classes, key) => (
                <SingleClass classOption="p-0" key={key} data={classes} />
            ))}
        </div>
    );
}

export default MyLiveClasses;
