import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Loader from '../../../components/loader'
import ClassGrid from '../../../components/class'
import { setCurrentUser, getCurrentUser } from '../../../helpers/Utils'
import axios from 'axios'
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { NavLink } from 'react-router-dom'

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}
const ClassItemContainer = () => {
  //query selector for browser selector
  let query = useQuery()
  const [data, setData] = useState([])
  const [flitterresData, setFillterResData] = useState([])
  const [loader, setLoader] = useState(<Loader />)
  const [filter, setFilter] = useState('')
  const [error, setError] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [fillterPage, setFillterPage] = useState(1)
  const [pagination, setPagination] = useState('')
  const [filterPagination, setFilterPagination] = useState('')
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [cateID, setCateID] = useState('')
  const [teacherID, setteacherID] = useState('')
  const [lengthID, setLengthID] = useState('')
  const [reset, setReset] = useState(false)
  const [filterData, setFilterData] = useState(false)

  let category_id = ''
  let length_id = ''
  let teacher_id = ''

  const getFillter = () => {
    var url = ''
    if (query.get('category_id')) {
      category_id = query.get('category_id')
      url = `${process.env.REACT_APP_API_URL}/classes?category_id=${category_id}`
      setCateID(category_id)
    } else if (query.get('length_id')) {
      length_id = query.get('length_id')
      url = `${process.env.REACT_APP_API_URL}/classes?length_id=${length_id}`
      setLengthID(length_id)
    } else if (query.get('teacher_id')) {
      teacher_id = query.get('teacher_id')
      url = `${process.env.REACT_APP_API_URL}/classes?teacher_id=${teacher_id}`
      setteacherID(teacher_id)
    } else {
      url = `${process.env.REACT_APP_API_URL}/classes`
    }

    setData([])
    setLoaded(true)
    axios.get(url).then(function (res) {
      if (res.data.success === true) {
        setPagination(res.data.data.next_page_url)
        setLoader('')
        let nextData = res.data.data.data
        let setmultiple = [...data, ...nextData]
        setData(setmultiple)
        setError('')
      }
    })
  }


  const setFilterItems = (e) => {
    e.preventDefault()
    setError('')
    setFilterData(true)
    setReset(true)
    setFillterPage(1)
    localStorage.setItem('teacher_id', e.target.teacher.value)
    localStorage.setItem('length_id', e.target.length.value)
    localStorage.setItem('category_id', e.target.category.value)
    localStorage.setItem('filterSearch', e.target.ofsearch.value)
    getAllClasses()
  }

  const getAllClasses = (page = fillterPage) => {
    let length_id = localStorage.getItem('length_id')
    let teacher_id = localStorage.getItem('teacher_id')
    let cate_id = localStorage.getItem('category_id')
    let search = localStorage.getItem('filterSearch')
    setLoader(<Loader />)
    var url = `${process.env.REACT_APP_API_URL}/classes?category_id=${cate_id}&length_id=${length_id}&teacher_id=${teacher_id}&search=${search}&page=${page}`

    axios.get(url).then(function (res) {
      setLoader('')
      if (res.data.success === true) {
        if (page === 1) {
          setFillterPage(res.data.data.current_page)
          setFilterPagination(res.data.data.next_page_url)
          setFillterResData(res.data.data.data)
        } else {
          setFillterPage(res.data.data.current_page)
          setFilterPagination(res.data.data.next_page_url)
          let filtter = res.data.data.data
          let setmultiple = [...flitterresData, ...filtter]
          setFillterResData(setmultiple)
          setError('')
        }
      } else {
        setError(res.data.message)
        setFillterResData([])
        setFillterPage(1)
        setFilterPagination('')
      }
    })
  }

  useEffect(() => {
    if (!loaded) {
      getFillter()
    }
  }, [reset, filterData, fillterPage])

  const restAllclass = () => {
    setError('')
    setFillterResData([])
    setCateID('')
    setteacherID('')
    setLengthID('')
    setFilterData(false)
  }

  const getAllClassesNext = (page = currentPage) => {
    setFillterResData([])
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/classes?page=${page}`)
      .then(function (res) {
        setLoading(false)
        if (res.data.success === true) {
          setPagination(res.data.data.next_page_url)
          setCurrentPage(res.data.data.current_page)
          let nextData = res.data.data.data
          let setmultiple = [...data, ...nextData]
          setData(setmultiple)
        }
      })
  }

  const classFilter = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/class-filters`)
      .then(function (res) {
        setFilter(res.data.data)
      })
  }

  const nextLink = () => {
    getAllClassesNext(currentPage + 1)
  }

  const nextFilterClass = () => {
    getAllClasses(fillterPage + 1)
  }

  useEffect(() => {
    if (!filterData) {
      classFilter()
    }
  }, [flitterresData])

  let class_item = ''

  if (data !== undefined) {
    class_item = (
      <>
        {data &&
          data.map((classes, i) => {
            return (
              <div key={i} className="col-lg-3 col-md-4 col-sm-6 mb-7">
                <ClassGrid classOption="p-0" data={classes} />
              </div>
            )
          })}
      </>
    )
  }
  let fillter_item = ''

  if (flitterresData !== undefined) {
    fillter_item = (
      <>
        {flitterresData &&
          flitterresData.map((classes, i) => {
            return (
              <div key={i} className="col-lg-3 col-md-4 col-sm-6 mb-7">
                <ClassGrid classOption="p-0" data={classes} />
              </div>
            )
          })}
      </>
    )
  }

  return (
    <section className="blog-section section-py classes_page">
      <div className="container">
        <p>
          A selection of our most recent classes is shown here, but we have a
          huge library available for you! Use the search bar to find the classes
          that best support your needs and availability.
        </p>
        <div className="searchandfilter">
          <form onSubmit={(e) => setFilterItems(e)}>
            <ul className="m-0">
              <li>
                <input
                  type="text"
                  name="ofsearch"
                  placeholder="Search …"
               
                />
              </li>
              <li>
                {cateID && cateID !== '' ? (
                  <select className="postform" value={cateID}>
                    {category_id !== '' ? (
                      <option
                        value={category_id}
                        defaultValue="selected"
                      ></option>
                    ) : (
                      <option value="" defaultValue="selected">
                        All Class Categories
                      </option>
                    )}
                    {filter &&
                      filter.class_categories.map((category, i) => (
                        <option key={i} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </select>
                ) : (
                  <select name="category" id="category" className="postform">
                    <option value="" defaultValue="selected">
                      All Class Categories
                    </option>

                    {filter &&
                      filter.class_categories.map((category, i) => (
                        <option key={i} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </select>
                )}
              </li>

              <li>
                {lengthID && lengthID !== '' ? (
                  <select className="postform" value={lengthID}>
                    {length_id !== '' ? (
                      <option
                        value={length_id}
                        defaultValue="selected"
                      ></option>
                    ) : (
                      <option value="" defaultValue="selected">
                        All Categories
                      </option>
                    )}
                    {filter &&
                      filter.class_lengths.map((length, i) => (
                        <option key={i} value={length.id}>
                          {length.name}
                        </option>
                      ))}
                  </select>
                ) : (
                  <select name="length" id="length" className="postform">
                    <option value="" defaultValue="selected">
                      All Categories
                    </option>

                    {filter &&
                      filter.class_lengths.map((length, i) => (
                        <option key={i} value={length.id}>
                          {length.name}
                        </option>
                      ))}
                  </select>
                )}
              </li>

              <li>
                {teacherID && teacherID !== '' ? (
                  <select className="postform" value={teacherID}>
                    {teacher_id !== '' ? (
                      <option
                        value={teacher_id}
                        defaultValue="selected"
                      ></option>
                    ) : (
                      <option value="" defaultValue="selected">
                        All Teachers
                      </option>
                    )}
                    {filter &&
                      filter.teachers.map((teacher, i) => (
                        <option key={i} value={teacher.id}>
                          {teacher.name}
                        </option>
                      ))}
                  </select>
                ) : (
                  <select name="teacher" id="teacher" className="postform">
                    <option value="" defaultValue="selected">
                      All Teachers
                    </option>

                    {filter &&
                      filter.teachers.map((teacher, i) => (
                        <option key={i} value={teacher.id}>
                          {teacher.name}
                        </option>
                      ))}
                  </select>
                )}
              </li>
              <li>
                <button
                  className="btn btn-primary d-inline-block"
                  type="submit"
                >
                  Search
                </button>

                <button
                  className="btn class-reset px-1 d-inline-block"
                  onClick={restAllclass}
                  type="reset"
                >
                  Reset
                </button>
              </li>
            </ul>
          </form>
        </div>
        <div className="row mb-n7">
          <div className="col-12">
            {loader}
            {error}
          </div>
          {filterData ? (
            <InfiniteScroll
              dataLength={flitterresData && flitterresData.length}
              next={filterPagination ? nextFilterClass : ''}
              hasMore={true}
              loader={
                <h4 className="text-center">
                  {filterPagination ? (<Loader />): ''}
                </h4>
              }
            >
              <div className="row">{fillter_item}</div>
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              dataLength={data && data.length}
              next={pagination ? nextLink : ''}
              hasMore={true}
              loader={
                <h4 className="text-center">
                  {pagination ? (<Loader />) : ''}
                </h4>
              }
            >
              <div className="row">{class_item}</div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </section>
  )
}

export default ClassItemContainer
