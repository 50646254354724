import React, { useState, useEffect } from "react";
import Layout from "../layouts/index";
import SEO from "../components/seo";
import PageLoader from "../components/page-loader";
import Logo from "../components/logo";
import axios from "axios";
import TestimonialContainer from "../containers/global/testimonial";
import IntroContainer from "../containers/home/intro";
import { NavLink, Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { setCurrentUser } from "../helpers/Utils";

const JoinForFree = () => {
    const [affiliate, setAffiliate] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory()

    const registerForFree = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/join-for-free`,
                {
                    name: e.target.name.value,
                    email: e.target.email.value,
                    affiliate_id: e.target.affiliate_id.value
                },
                {
                    headers: {
                        "Content-type": "application/json",
                    },
                }
            )
            .then(function (res) {
                if (res.data.success) {
                    toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    document.getElementById("myForm").reset();
                    setCurrentUser(res.data.data.user);
                    history.push("/plans");
                } else {
                    setLoading(false);
                    toast.error(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
    };
    const getAffiliate = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/affiliates`, {
                headers: {
                    "Content-type": "application/json",
                },
            })
            .then(function (res) {
                setAffiliate(res.data.data);
            });
    };

    useEffect(() => {
        getAffiliate();
    }, []);

    return (
        <React.Fragment>
            <PageLoader />
            <Layout>
                <SEO title="10 Days of Well-full-ness" />
                <div className="wrapper">
                    <header className="header-section position-relative">
                        <div className="container">
                            <div className="header-logo text-center">
                                <Logo
                                    classOption="d-block"
                                    image={`${process.env.PUBLIC_URL}/images/logo/soultribe.png`}
                                />
                            </div>
                        </div>
                    </header>
                    <div className="marketing_form mt-5 pt-5">
                        <div className="container">
                            <div className="title-section title-section text-center pb-5">
                                <h3 className="title">
                                    Join us for this FREE journey for creating
                                    abundance in everyday moments!
                                </h3>
                            </div>
                            <img className="mw-100 m-auto d-block mb-5 pb-5" src={`${process.env.PUBLIC_URL}/images/joinfree.jpeg`} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <h5>About this event</h5>
                                    <p>
                                        <i>
                                            <b>
                                                30 minutes each day to rest, and taking time to tune-into yourself.
                                            </b>
                                        </i>
                                    </p>
                                    <p>
                                        To intentionally relax and recalibrate your systems to create new waves of abundance by tuning within.
                                    </p>
                                    <p>
                                        Exploring wellness rituals such as mindfulness, self-worth and wealth creation practices, breath work, art, healing, and soul-care.
                                    </p>
                                    <p>
                                        <b>
                                            Relax, breath, meditate, and respond
                                            from within.
                                        </b>
                                    </p>
                                    <p>Well-full-ness Themes:</p>
                                    <ul className="list-group">
                                        <li>
                                            Self-Care as Soul-Care, Daily Soul-Care Rituals- Jennifer Marcaccini
                                        </li>
                                        <li>
                                            EFT Tapping for Self-Worth - Amy Piper
                                        </li>
                                        <li>
                                            Foods as Medicine for Summer - Tara Matthews

                                        </li>
                                        <li>
                                            A Playful Art Journeying Experience - Eve Hansra
                                        </li>
                                        <li>
                                            Exploring Synarchy and your Infinite Potential - Alison McCabe
                                        </li>
                                        <li>
                                            The Emotional Yogi, atuning to your emotional body - Mehtab Benton
                                        </li>
                                        <li>
                                            Breathing into Your Inner Light, Susan von Thun
                                        </li>
                                        <li>
                                            Presence Practices for Wholeness - Aelah Sophia
                                        </li>
                                        <li>
                                            Yoga Nidra Sound Journey for Rest-Fullness - Shiva Preet
                                        </li>
                                    </ul>
                                    <p>
                                        <b>
                                            A FREE LIVE Journey, with an opportunity to get to know our dynamic community.
                                        </b>
                                    </p>
                                    <p>
                                        You can access a recording if you can't join live.
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="text-center pt-5 ">
                                        <h3
                                            style={{
                                                fontWeight: "700",
                                            }}
                                            className="title"
                                        >
                                            Register Now
                                        </h3>
                                    </div>
                                    <div className="loginsignup">
                                        <form
                                            onSubmit={(e) => registerForFree(e)}
                                            id="myForm"
                                        >
                                            <div className="form-group mb-4">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label>Select Affiliate</label>
                                                <select className="form-control" name="affiliate_id">
                                                    <option value="" disabled selected>--</option>
                                                    {affiliate.map((item, i) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                    <option value="0">Other/None</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                {loading ? (
                                                    <button className="btn btn-dark btn-hover-dark w-100 disabled" disabled>
                                                        Loading
                                                    </button>
                                                ) : (
                                                    <button className="btn btn-dark btn-hover-dark w-100">
                                                        Get Access Now
                                                    </button>
                                                )}
                                            </div>
                                            <div class="mt-5">
                                                Already have an account? <Link
                                                    to={process.env.PUBLIC_URL + "/login"}
                                                >
                                                    Sign in
                                                </Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TestimonialContainer />
                    <footer className="footer-section">
                        <div className="container">
                            <div className="copy-right-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="copyright-info text-center">
                                                <span>
                                                    © {new Date().getFullYear()}{" "}
                                                    <Link
                                                        to="https://soultribeonline.com/"
                                                        target="_blank"
                                                    >
                                                        Soul Tribe Online
                                                    </Link>
                                                    . All rights reserved.
                                                    &nbsp;
                                                    <Link
                                                        to={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/privacy"
                                                        }
                                                    >
                                                        Privacy Policy
                                                    </Link>{" "}
                                                    &nbsp;| &nbsp;
                                                    <Link
                                                        to={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/terms-conditions"
                                                        }
                                                    >
                                                        Terms & Conditions
                                                    </Link>
                                                </span>
                                                <p className="m-0">
                                                    Questions? Send us an email
                                                    at{" "}
                                                    <Link
                                                        to="#"
                                                        onClick={(e) => {
                                                            window.location.href =
                                                                "mailto:info@soultribeonline.com";
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        info@soultribeonline.com
                                                    </Link>
                                                </p>
                                                <p>
                                                    For support email at{" "}
                                                    <Link
                                                        to="#"
                                                        onClick={(e) => {
                                                            window.location.href =
                                                                "mailto:support@soultribeonline.com";
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        support@soultribeonline.com
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default JoinForFree;
