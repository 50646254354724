import React from "react";
import PropTypes from "prop-types";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import TribeDetailsContainer from "../containers/tribe/class-details";
// import BlogData from "../data/blog.json";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { useLocation } from "react-router-dom";

const TribeDetailsPage = () => {
  const location = useLocation();
  // Extracting the last part of the path as the slug
  const slug = location.pathname.split("/tribe/")[1];

  let tab = "1";

  const search = useLocation().search;
  tab = new URLSearchParams(search).get("tab");

  return (
    <React.Fragment>
      <Layout>
        <SEO title="Soul Tribe Online Community – Blog Details" />
        <div className="wrapper">
          <Header />
          <TribeDetailsContainer slug={slug} tab={tab} />
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

TribeDetailsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default TribeDetailsPage;
