import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { setCurrentUser, getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TribeRequest( props ) {
    const [acceptLoading, setAcceptLoading] = useState(false)
    const [rejectLoading, setRejectLoading] = useState(false)
    const [success, setSuccess] = useState('')

    const tribeRequest = (requestStatus, tribeid, userid) => {
        props.setLoading(true)
        axios.post(
            `${process.env.REACT_APP_API_URL}/accept-reject-tribe-request`, {
                user_id: userid,
                tribe_id: tribeid,
                status: requestStatus,
            },
            {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${getCurrentUser().token}`
                },
            }
        )
        .then(function (res) {
            props.setLoading(false);
            setAcceptLoading(false);
            setRejectLoading(false);
            props.getTribeRequests();
            toast.success(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    const accept = (requestStatus, tribeid, userid, res) => {
        setAcceptLoading(true)
        tribeRequest(requestStatus,tribeid, userid);
    }

    const reject = (requestStatus, tribeid, userid, res) => {
        setRejectLoading(true)
        tribeRequest(requestStatus, tribeid, userid);
    }

    return (
        <tr key={props.i}>
            <td>
                <div className="d-flex align-items-center w-100">
                    <img src={props.data.image} alt="img" />
                    <h3 className="text-capitalize px-3">
                        <NavLink to={process.env.PUBLIC_URL + '/member/' + props.data.id}>
                            {props.data.name}
                        </NavLink>
                    </h3>
                </div>
            </td>
            <td className="text-right text-right">
                <div className="d-flex justify-content-end align-items-center w-100">
                    {acceptLoading ? (
                        <button className="btn btn-primary py-2 px-4 request-width-profile mx-2" disabled>Loading</button>
                    ) : (
                        <button onClick={() => accept(1, props.tribeId , props.data.id)} className="btn btn-primary py-2 px-4 request-width-profile mx-2">Accept</button>
                    )}
                    {rejectLoading ? (
                        <button className="btn btn-primary py-2 px-4 request-width-profile mx-2" disabled>Loading</button>
                    ) : (
                        <button onClick={() => reject(2, props.tribeId , props.data.id)} className="btn btn-primary py-2 px-4 request-width-profile mx-2">Reject</button>
                    )}
                </div>
            </td>
        </tr>
    )
}

export default TribeRequest;