import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getCurrentUser} from '../../helpers/Utils'

const ClassDetailsWrap = ({ data }) => {
  const [favorite, setFavorite] = useState(data.is_favourite)
  let classcate = ''
  let classLenth = ''
  // let length_id = ''
  // let catgory_id = ''

  // data.length && data.length.map((item, i) => (length_id = item.id))
  // data.categories && data.categories.map((item, i) => (catgory_id = item.id))

  if (data.length !== null) {
    classLenth = (
      <>
        {data.levels &&
          data.levels.map((item, i) => <span key={i}>{item.name} </span>)}
      </>
    )
  }
  if (data.categories !== null) {
    classcate = (
      <>
        {data.categories &&
          data.categories.map((item, i) => { 
            return <span key={`demo_snap_${i}`}>{ (i ? ', ' : '') + item.name }</span>
             
          })
  }
      </>
   
  
    )}

  const addFavorite = () => {
    if (favorite === 1) {
      setFavorite(0)
    } else {
      setFavorite(1)
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/favourite-class`,
        {
          class_id: data.id,
        },
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        },
      )
      .then(function (res) {
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
  }

  return (
    <div className="blog-details-wrap">
      <div className="class__video">
        {data.video_embed_code ? (
          <iframe
            title="This is a unique title"
            width="100%"
            height="515"
            src={data.video_embed_code}
            allow="fullscreen"
            allowFullScreen
          ></iframe>
        ) : (
          ''
        )}
      </div>
      <table className="table table-bordered table-striped text-center table-responsive class-table">
        <tbody>
          <tr>
            <td className="p-1">
              <span>
                <strong>Teacher</strong>
              </span>
            </td>
            <td className="p-1">
              <span>
                <strong>Categories</strong>
              </span>
            </td>
            <td className="p-1">
              <span>
                <strong>Level</strong>
              </span>
            </td>
          </tr>
          <tr>
            {/* <Router> */}
            <td className="p-1">
              {/* <NavLink */}
              {/*   to={ */}
              {/*     process.env.PUBLIC_URL + */}
              {/*     `/classes?teacher_id=${data.teacher_id}` */}
              {/*   } */}
              {/* > */}
                <span>{data && data.teacher && data.teacher.name}</span>{' '}
              {/* </NavLink> */}
            </td>
            <td className="p-1">
              {/* <NavLink */}
              {/*   to={ */}
              {/*     process.env.PUBLIC_URL + `/classes?category_id=${catgory_id}` */}
              {/*   } */}
              {/* > */}
                {classcate}
              {/* </NavLink> */}
            </td>
            <td className="p-1">
              {/* <NavLink */}
              {/*   to={process.env.PUBLIC_URL + `/classes?length_id=${length_id}`} */}
              {/* > */}
                {classLenth}
              {/* </NavLink> */}
            </td>
            {/* </Router> */}
          </tr>
        </tbody>
      </table>
      <p dangerouslySetInnerHTML={{ __html: data.description}} />
      {favorite === 1 ? (
        <button
          className="btn btn-primary my-5 py-3"
          onClick={(e) => addFavorite()}
        >
          <i className="fa fa-heart"></i>  Remove from Favorite
        </button>
      ) : (
        <button
          className="btn btn-dark my-5 py-3"
          onClick={(e) => addFavorite()}
        >
          <i className="fa fa-heart"></i> Add To Favorite
        </button>
      )}
    </div>
  )
}

ClassDetailsWrap.propTypes = {
  data: PropTypes.object,
}

export default ClassDetailsWrap
