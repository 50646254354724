import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import Logo from "../../logo";
import { getCurrentUser } from "../../../helpers/Utils";

const MobileMenu = ({ show, onClose }) => {
  const [isTeacher, setisTeacher] = useState("teacher");

  const getRole = () => {
    let role = [];
    let teacher = "";
    role = getCurrentUser().role;
    if (role !== null) {
      role.map((item) => {
        return (teacher = item.label_member);
      });
      setisTeacher(teacher);
    }
  };

  useEffect(() => {
    if (getCurrentUser()) {
      getRole();
    }
  }, []);

  const local = localStorage.getItem("localKey");

  const classlocal = localStorage.getItem("classKey");

  const tribelocal = localStorage.getItem("tribeKey");

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };
  return (
    <div className={`offcanvas-modal ${show ? "show" : ""}`}>
      <div className="offcanvas-dialog">
        <div className="menu-content">
          <div className="offcanvas-header">
            <Logo
              classOption="offcanvas-logo d-inline-block"
              image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
            />
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>

          <nav id="offcanvasNav" className="offcanvas-menu">
            <ul className="menu-header-resp">
              <li className="li-resp-mobile">
                <NavLink
                  exact
                  isactiveactiveclassname="active"
                  to={process.env.PUBLIC_URL + "/live-classes"}
                >
                  Live
                </NavLink>
              </li>
              <li className="li-resp-mobile">
                <NavLink
                  isactiveactiveclassname="active"
                  to={process.env.PUBLIC_URL + "/classes"}
                  isActive={(match, location) => {
                    if (
                      location.pathname === `/class/${classlocal}` ||
                      location.pathname.startsWith("/classes")
                    ) {
                      return true;
                    }
                  }}
                >
                  Classes
                </NavLink>
              </li>
              <li className="li-resp-mobile">
                <NavLink
                  isactiveactiveclassname="active"
                  to={process.env.PUBLIC_URL + "/tribes"}
                  isActive={(match, location) => {
                    if (
                      location.pathname === `/tribe/${tribelocal}` ||
                      location.pathname.startsWith("/tribes")
                    ) {
                      return true;
                    }
                  }}
                >
                  Tribes
                </NavLink>
              </li>
              <li className="li-resp-mobile">
                <NavLink
                  isactiveactiveclassname="active"
                  to={process.env.PUBLIC_URL + "/teacher"}
                >
                  Teachers
                </NavLink>
              </li>
              {getCurrentUser() ? (
                <li className="li-resp-mobile">
                  <NavLink
                    isactiveactiveclassname="active"
                    to={process.env.PUBLIC_URL + "/lovestream"}
                  >
                    Love Stream
                  </NavLink>
                </li>
              ) : (
                ""
              )}
              {getCurrentUser() ? (
                <>
                  <li className="li-resp-mobile">
                    <NavLink
                      to={process.env.PUBLIC_URL + "/members"}
                      activeClassName="active"
                      isActive={(match, location) => {
                        if (
                          location.pathname === `/member/${local}` ||
                          location.pathname.startsWith("/members")
                        ) {
                          return true;
                        }
                      }}
                    >
                      Members
                    </NavLink>
                  </li>
                  <div className="next-list-mobile"></div>
                  <li className="li-resp-mobile">
                    <NavLink to={"/profile"} className="sub-menu-link pointer">
                      Profile
                    </NavLink>
                  </li>
                  <li className="li-resp-mobile">
                    <NavLink
                      to="/profile/classes"
                      className="sub-menu-link pointer"
                    >
                      My Classes
                    </NavLink>
                  </li>
                  {(isTeacher && isTeacher === "teacher") ||
                  getCurrentUser().allow_access ? (
                    <>
                      <li className="li-resp-mobile">
                        <NavLink
                          to={"/create-live-class"}
                          className="sub-menu-link pointer"
                        >
                          Create Live Class
                        </NavLink>
                      </li>
                      <li className="li-resp-mobile">
                        <NavLink
                          to={"/profile/classes"}
                          className="sub-menu-link pointer"
                        >
                          Upload Class
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  <li className="li-resp-mobile">
                    <NavLink
                      to={"/profile/tribes"}
                      className="sub-menu-link pointer"
                    >
                      Tribes
                    </NavLink>
                  </li>
                  <li className="li-resp-mobile">
                    <NavLink to={"/profile"} className="sub-menu-link pointer">
                      Account
                    </NavLink>
                  </li>
                  {isTeacher && isTeacher === "member" ? (
                    <li className="li-resp-mobile">
                      <NavLink
                        to={"/membership-account"}
                        className="sub-menu-link pointer"
                      >
                        Membership
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="sub-menu-item cursor-pointer li-resp-mobile">
                    <a
                      className="sub-menu-span pointer"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </a>
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
          </nav>
        </div>
        {getCurrentUser() ? (
          ""
        ) : (
          <div className="text-center mt-2">
            <NavLink to={process.env.PUBLIC_URL + "/login"}>
              <button className="btn btn-primary mobile-button">Login</button>
            </NavLink>
            <NavLink to={process.env.PUBLIC_URL + "/registration"}>
              <button className="btn btn-secondary mobile-button">
                Sign Up
              </button>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MobileMenu;
