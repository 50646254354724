import React from "react";
import ContactForm from "../../components/contact-form";

const ContactContainer = () => {
    return (
        <section className="contact-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-lg-6 mb-7">
                        <div className="contact-title-section">
                            <h3 className="title">Get in touch</h3>
                            <ContactForm />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="contact-address text-left contact-title-section">
                            <h3 className="title m-0">Let's get in touch</h3>
                            <p>
                                Top rated construction packages we pleasure
                                rationally encounter consequences interesting who loves or pursue or
                                desires
                            </p>
                            <ul className="list-unstyled mb-0">
                                <li>
                                    <i className="fas fa-map-marker-alt fa-2x"></i>
                                    <p>San Francisco, CA 94126, USA</p>
                                </li>

                                <li>
                                    <i className="fas fa-phone mt-4 fa-2x"></i>
                                    <p><a href="tel:23456789">+ 01 234 567 89</a></p>
                                </li>

                                <li>
                                    <i className="fas fa-envelope mt-4 fa-2x"></i>
                                    <p><a href="mailto:support@soultribeonline.com">support@soultribeonline.com</a></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactContainer;
