import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
// import BrandContainer from "../containers/global/brand/index";
import IconBoxContainer from "../containers/global/icon-box";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import PageLoader from '../components/page-loader'

const AboutPage = () => {
    return (
        <React.Fragment>
            <Layout>
            <PageLoader />
                <SEO title="Soul Tribe Online Community – About" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="About us"
                        image="./images/banner/1.png"
                    />
                    <IconBoxContainer classOption="section-pt" />
                    {/* <BrandContainer /> */}
                    {/* <AboutContainer /> */}
                    {/* <ServiceListContainer /> */}
                    {/* <TestimonialReverse /> */}
                    {/* <FunFactContainer classOption="mt-0 mt-lg-0" /> */}
                    
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default AboutPage;
