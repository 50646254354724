import { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { getCurrentUser } from "../../helpers/Utils";
import { toast } from "react-toastify";
import Modal from "react-modal";
import EditComment from "./edit-comment";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const likeCountsStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		padding: "20px 0",
		bottom: "auto",
		minHeight: "400px",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		maxWidth: "545px",
		width: "100%",
	},
};
const editCommentModalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		maxWidth: "845px",
		width: "100%",
		// height: '80%'
	},
};

function SingleComment(props) {
	const [sure, setSure] = useState(false);
	const [data, setData] = useState(props.data && props.data);
	const [likecount, setLikeCount] = useState(props.data.comment_like);
	const [isLike, setIsLike] = useState(props.data.is_liked);
	const [likeCountModal, setLikeCountModal] = useState(false);
	const [likedBy, setLikedBy] = useState(props.data.liked_by);
	const [isEditModal, setIsEditModal] = useState(false);
	const [moreInfo, setMoreInfo] = useState(false);

	const likeComment = (like) => {
		if (like === 1) {
			setIsLike(1);
		} else {
			setIsLike(0);
		}
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/post-comment-like`,
				{
					post_id: props.postId,
					comment_id: data.id,
					is_liked: like,
				},
				{
					headers: {
						Authorization: `Bearer ${getCurrentUser().token}`,
					},
				}
			)
			.then(function (res) {
				setLikeCount(res.data.likes_count);
				setLikedBy(res.data.liked_by);
			});
	};

	const deleteComment = (commentId) => {
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/delete-tribe-comment?comment_id=${commentId}`,
				{},
				{
					headers: {
						"Content-type": "application/json",
						Authorization: `Bearer ${getCurrentUser().token}`,
					},
				}
			)
			.then(function (res) {
				setData("");
				toast.success(res.data.message, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	};

	if (data !== "") {
		return (
			<>
				{isEditModal ? (
					<Modal isOpen={true} style={editCommentModalStyles}>
						<i
							className="fa fa-window-close float-right"
							onClick={(e) => setIsEditModal(false)}
							aria-hidden="true"
							style={{
								marginRight: "15px",
							}}
						></i>
						<EditComment
							data={data}
							setItem={setData}
							postId={props.postId}
							allMember={props.allMember}
							setModalIsOpen={setIsEditModal}
							setMoreInfo={setMoreInfo}
						/>
					</Modal>
				) : (
					""
				)}
				<Modal isOpen={likeCountModal} style={likeCountsStyles}>
					<i
						className="fa fa-window-close float-right"
						onClick={(e) => setLikeCountModal(false)}
						aria-hidden="true"
						style={{
							marginRight: "15px",
						}}
					></i>
					<div className="countmodal">
						<Tabs className="vertical_tabs">
							<TabList>
								<Tab>
									<img
										alt="ss"
										style={{
											transform: "translate(0, -4px)",
										}}
										src={`${process.env.PUBLIC_URL}/images/like.png`}
										title="Like"
									/>
									&nbsp;Like
								</Tab>
							</TabList>
							<TabPanel>
								<ul className="post">
									{likedBy &&
										likedBy.map((item, i) => (
											<li key={i}>
												<div className="posted border-0 m-0">
													<div className="posted_header py-2">
														<NavLink
															to={`/member/${item.id}`}
														>
															<img
																alt="ss"
																src={item.image}
															/>
														</NavLink>
														<NavLink
															to={`/member/${item.id}`}
														>
															{item.name}
														</NavLink>
													</div>
												</div>
											</li>
										))}
								</ul>
							</TabPanel>
						</Tabs>
					</div>
				</Modal>
				<div className="comment-single" key={data.id}>
					<div className="posted_header">
						{data.user && (
							<NavLink
								className="user_thumb"
								to={
									process.env.PUBLIC_URL +
									`/member/${data.user && data.user.id}`
								}
							>
								<img
									alt="ss"
									src={data.user && data.user.image}
								/>
							</NavLink>
						)}
						<div className="comment_info">
							<div className="single_cmt">
								<div className="d-flex justify-content-between align-items-center">
									<span>
										<NavLink
											to={
												process.env.PUBLIC_URL +
												`/member/${
													data.user && data.user.id
												}`
											}
										>
											{data.user && data.user.name}
										</NavLink>
										<br />
										<small
											style={{
												color: "#888",
												fontSize: "12px",
												display: "block",
											}}
										>
											{data.formatted_created_time}
										</small>
									</span>
									<div className="position-relative">
									{data.user &&
										getCurrentUser().id === data.user.id ? 
											<button
												className="px-2"
												onClick={() =>
													setMoreInfo(!moreInfo)
												}
											>
												<i
													style={{
														color: "#555",
													}}
													className="fa fa-ellipsis-v"
												></i>
											</button>
										: ''}
										{moreInfo ? (
											<div className="info_menu">
												<ul>
													<li
														className="cursor-pointer"
														onClick={() =>
															setIsEditModal(true)
														}
													>
														<i className="fa fa-pencil"></i>{" "}
														Edit
													</li>
													{sure ? (
														<li
															className="cursor-pointer text-danger"
															onClick={() =>
																deleteComment(
																	data.id
																)
															}
														>
															<i className="fa fa-trash"></i>{" "}
															Sure?
														</li>
													) : (
														<li
															className="cursor-pointer"
															onClick={() =>
																setSure(true)
															}
														>
															<i className="fa fa-trash"></i>{" "}
															Delete
														</li>
													)}
												</ul>
											</div>
										) : (
											""
										)}
									</div>
								</div>
								<div className="comment_info-desc m-0">
									<p
										dangerouslySetInnerHTML={{
											__html: data.comment,
										}}
									/>
									{data.attachment ? (
										<img
											alt="ss"
											className="mw-100 w-100"
											src={data.attachment}
											className="mw-100"
										/>
									) : (
										""
									)}
								</div>
							</div>
							<div className="px-3 pt-0">
								<div className="generic-button likesec mt-1">
									<button
										onClick={() => setLikeCountModal(true)}
										className="cursor-pointer"
									>
										<small>
											<img
												alt="ss"
												style={{
													transform:
														"translate(0, -4px)",
												}}
												src={`${process.env.PUBLIC_URL}/images/like.png`}
												title="Like"
											/>{" "}
											{likecount && likecount}
										</small>{" "}
										&nbsp;
									</button>
									{isLike === 0 ? (
										<small
											type="button"
											onClick={() => likeComment(1)}
										>
											Like
										</small>
									) : (
										<small
											type="button"
											onClick={() => likeComment(0)}
											style={{
												color: "#060062",
												fontWeight: "900",
											}}
										>
											Like
										</small>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return "";
	}
}
export default SingleComment;
