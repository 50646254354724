import React, { useState } from "react";
import { setCurrentUser, getCurrentUser } from "../../helpers/Utils";
import axios from "axios";
import {
  convertFromHTML,
  ContentState,
  EditorState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import timezones from "timezones-list";

function BaseInfo() {
  const [user] = useState(getCurrentUser());
  const [loading, setLoading] = useState(false);
  const html =
    getCurrentUser().about !== null ? getCurrentUser().about : "<p></p>";
  const blocksFromHTML = convertFromHTML(html);
  const content = ContentState.createFromBlockArray(blocksFromHTML);

  const [about, setAbout] = useState(EditorState.createWithContent(content));

  const updateInfo = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/member/update-profile`,
        {
          name: e.target.name.value,
          time_zone: e.target.time_zone.value,
          date_of_birth: e.target.date_of_birth.value,
          email: e.target.email.value,
          astrological_sign: e.target.astrological_sign.value,
          where_i_live: e.target.where_i_live.value,
          about: draftToHtml(convertToRaw(about.getCurrentContent())),
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.success === true) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setCurrentUser(res.data.data);
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const onEditorStateChange = (about) => {
    setAbout(about);
  };

  return (
    <div className="profile public">
      <div className="bp-widget base">
        <h3 className="screen-heading profile-group-title">
          Edit "Base" Information
        </h3>
        <form onSubmit={(e) => updateInfo(e)}>
          <div className="form-group mt-4">
            <label className="m-0">Name</label>
            <input
              name="name"
              type="text"
              defaultValue={user.name}
              className="form-control"
              maxLength="20"
            />
          </div>
          <div className="form-group mt-4">
            <label className="m-0">Email </label>
            <input
              name="email"
              defaultValue={user.email}
              type="email"
              className="form-control"
            />
          </div>
          <div className="form-group mt-4">
            <label className="m-0">Birthday </label>
            <input
              name="date_of_birth"
              defaultValue={user.date_of_birth}
              type="date"
              className="form-control"
            />
          </div>
          <div className="form-group astrological mt-4">
            <label className="m-0">Astrological Sign</label>
            <select
              className="form-control"
              defaultValue={user.astrological_sign}
              name="astrological_sign"
            >
              <option>Aries</option>
              <option>Taurus</option>
              <option>Gemini</option>
              <option>Cancer</option>
              <option>Leo</option>
              <option>Virgo</option>
              <option>Libra</option>
              <option>Scorpio</option>
              <option>Sagittarius</option>
              <option>Capricorn</option>
              <option>Aquarius</option>
              <option>Pisces</option>
            </select>
          </div>
          <div className="form-group mt-4">
            <label className="m-0">Where I Live</label>
            <input
              name="where_i_live"
              type="text"
              defaultValue={user.where_i_live}
              className="form-control"
            />
          </div>
          <div className="form-group astrological mt-4">
            <label className="m-0">Choose Timezone</label>
            <select
              className="form-control"
              defaultValue={user.time_zone}
              name="time_zone"
            >
              <option value="" hidden>
                --
              </option>
              {timezones &&
                timezones.map((item, i) => (
                  <option value={item.tzCode} key={i}>
                    {item.label}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group mt-4">
            <label className="m-0">About Me</label>
            <div className="border bg-light">
              <Editor
                editorState={about}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: ["inline", "list"],
                  inline: {
                    options: ["bold", "italic", "underline", "strikethrough"],
                  },
                }}
              />
            </div>
          </div>
          <div className="form-group mt-4">
            {loading ? (
              <button
                className="btn btn-primary m-2 py-3"
                type="button"
                disabled
              >
                Loading
              </button>
            ) : (
              <button className="btn btn-primary m-2 py-3" type="submit">
                Save Changes
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
export default BaseInfo;
