import React, { useRef, useEffect } from "react";
import LoadingBar from "react-top-loading-bar";

const App = () => {
    // const ref = useRef(null);
    // const handleLoadSomething = () => {
    //     ref.current.continuousStart();
    //     setTimeout(() => {
    //         ref.current.complete();
    //     }, 1000);
    // };
    // useEffect(() => {
    //     handleLoadSomething();
    // }, []);
    return (
        <div>
            {/* <LoadingBar color="#181c51" ref={ref} /> */}
      {/*       <button onClick={handleLoadSomething}> */}
      {/*   Start Continuous Loading Bar */}
      {/* </button> */}
        </div>
    );
};
export default App;
