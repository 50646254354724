import React, { useState, useEffect } from "react";
import { getCurrentUser } from "../../helpers/Utils";
import axios from "axios";
import Loader from "../loader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Document from "./document";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Documents() {
    
    const [loader, setLoader] = useState(<Loader />);
    const [loading, setLoading] = useState(false);
    const [document, setDocument] = useState([]);
    const [documentFile, setDocumentFile] = useState("");
    const [setDocumentError] = useState("");
    const [documentMessage, setDocumentMessage] = useState("");
    const [documentGetError, setDocumentGetError] = useState("");

    const getDocument = (e) => {
        setLoader(<Loader />);
        setDocument([]);
        var url = `${process.env.REACT_APP_API_URL}/member/documents?user_id=${
            getCurrentUser().id
        }`;
        if (e !== undefined) {
            e.preventDefault();
            url = `${process.env.REACT_APP_API_URL}/member/documents?user_id=${
                getCurrentUser().id
            }&search=${e.target.value}`;
        }
        axios
            .get(url, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setLoader("");
                if (res.data.success === true) {
                    setDocument(res.data.data);
                    setDocumentGetError("");
                } else {
                    setDocumentGetError(res.data.message);
                }
            });
    };
    
    const filter = (e) => {
        getDocument(e);
    };

    const uploadDocument = (e) => {
        e.preventDefault();
        var formData = new FormData();
        formData.append("title", e.target.title.value);
        formData.append("document", documentFile);
        formData.append("type", e.target.type.value);
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/member/documents/store`,
                formData,
                {
                    headers: {
                        "content-type": "multipart/form-data",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.success !== true) {
                    toast.error(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setDocumentMessage("");
                } else {
                    setDocumentFile("");
                    setDocumentError("");
                    toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
    };

    const file = (e) => {
        setDocumentFile(e.target.files[0]);
    };

    /*const deleteFile = (documentId) => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/member/documents/delete?id=${documentId}`,
                {},
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                getDocument();
            });
    };
*/
    const downloadfile = (fileDownload) => {
        window.open(fileDownload);
    };

    useEffect(() => {
        getDocument();  
    }, [])

    return (
        <>
            <Tabs>
                <TabList>
                    <Tab onClick={() => getDocument()}>Documents</Tab>
                    <Tab>Upload Document</Tab>
                    <input
                        type="text"
                        name="ofsearch"
                        onChange={filter}
                        placeholder="Search …"
                        className="doc-search"
                    />
                </TabList>
                <TabPanel>
                    <h3 className="screen-heading profile-group-title mb-7">
                        Documents
                    </h3>
                    <div className="table-responsive">
                        <table className="table documents table-border">
                            <tbody>
                                {document.map((item, i) => (
                                    <Document item={item} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {loader}
                    {documentGetError}
                </TabPanel>
                <TabPanel>
                    <h3 className="screen-heading profile-group-title">
                        Documents
                    </h3>
                    <form onSubmit={(e) => uploadDocument(e)}>
                        <div className="row">
                            <div className="form-group col-12 pb-4">
                                <label>Title</label>
                                <input
                                    name="title"
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-12 pb-4">
                                <label>Upload File</label>
                                <input
                                    onChange={(e) => file(e)}
                                    type="file"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-12 pb-4">
                                <label>Privacy</label>
                                <select name="type" className="form-control">
                                    <option value="public" selected>
                                        Public
                                    </option>
                                    <option value="all_members">
                                        All Members
                                    </option>
                                    <option value="only_me">Only me</option>
                                    <option value="my_connections">
                                        My Connections
                                    </option>
                                </select>
                            </div>
                            <div className="form-group col-12 pb-4">
                                {loading ? (
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        disabled
                                    >
                                        Loading
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Update
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </TabPanel>
            </Tabs>
        </>
    );
}

export default Documents;
