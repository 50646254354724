import React, { Component } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            error: "",
            success: "",
            isLoading: false
        };
        this.onUserLogin = this.onUserLogin.bind(this);
    }

    onUserLogin = (e) => {
        this.setState({
            isLoading: true
        })
        e.preventDefault();
        var self = this;
        axios.post(
            `${process.env.REACT_APP_API_URL}/forget-password-link`,
            {
                email: this.state.email,
            }
        )
        .then(function (res) {
            if (res.data.success !== false) {
                self.setState({
                    error: "",
                    success: res.data.message,
                    isLoading: false
                });
                window.location.href = '/login';
            } else {
                self.setState({
                    error: res.data.message,
                    success: "",
                    isLoading: false
                });
            }
        })
    };

    render() {
        const { error, success, isLoading } = this.state;
        let errorMessage = '';
        let successMessage = '';
        let button = (
            <button id="contactSubmit" type="submit" className="btn btn-dark btn-hover-dark w-100">Get New Pasword</button>
        ) 

        if (error) {
            toast.error(error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                error: ''
            })
        }

        if (success) {
            toast.success(success, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                success: ''
            })
        }

        if (isLoading === true) {
            button = <button id="contactSubmit" className="btn btn-dark btn-hover-dark w-100" disabled>Loading...</button>;
        }


        return (
            <form
                onSubmit={this.onUserLogin}
                id="contactForm"
                className="row"
            >
                <div className="col-12">
                    {errorMessage}
                    {successMessage}
                </div>
                <div className="col-12 mb-4">
                    <label>Email Address</label>
                    <input
                        type="email"
                        className="form-control"
                        id="name"
                        name="email"
                        placeholder="e.g. info@soultribeonline.com"
                        onChange={(e) => this.setState({ 'email': e.target.value})}
                    />
                </div>
                <div className="col-12">
                   {button}
                </div>
            </form>
        );
    }
}

export default ContactForm;
