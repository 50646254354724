import React from "react";
import MembershipAccount from "../containers/membership-account";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageLoader from "../components/page-loader";

const MemberAccountPage = () => {
  return (
    <React.Fragment>
      <PageLoader />
      <Layout>
        <SEO title="Soul Tribe Online Community – Membership Account" />
        <div className="wrapper">
          <Header />
          <PageBanner title="Membership Account" />
          <MembershipAccount />
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default MemberAccountPage;
