import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import CreateGroupForm from "../components/create-group/Form";
import PageLoader from '../components/page-loader'

function CreateGroup() {
	// const [user, setUser] = useState([])

	return (
		<React.Fragment>
        <PageLoader />
            <Layout>
                <SEO title="Soul Tribe Online Community – Create Group" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Create Group"
                        image="./images/contact/1.png"
                    />
                    <div className="faq-section section-py">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-8">
                                    <CreateGroupForm />
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
	)
}
export default CreateGroup;
