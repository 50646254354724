import React, {useState} from 'react'
import { setCurrentUser, getCurrentUser } from '../../helpers/Utils';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ChangeProfileImage() {
    const [image, setImage] = useState(getCurrentUser().image)
    const [imagePreviewProfile, setImagePreviewProfile] = useState(getCurrentUser().image)
    const [profileLoading, setProfileLoading] = useState(false);

	const updateCover = (e) => {
		e.preventDefault();
        setProfileLoading(true)
		var formData = new FormData();
        formData.append('image', image);
        formData.append('type', 'profile_image');
        axios.post(
            `${process.env.REACT_APP_API_URL}/member/change-user-image`, formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Bearer ${getCurrentUser().token}`
                },
            }
        )
        .then(function (res) {
            setProfileLoading(false)
            if (res.data.success === true) {
                toast.success(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setCurrentUser(res.data.data);
            } else {
                toast.error(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    const feedAttach = (e) => {
        setImage(e.target.files[0])
        setImagePreviewProfile(URL.createObjectURL(e.target.files[0]))
    }
	return (
		<div className="change_image">
            <div className="bp-widget base">
               	<h3 className="screen-heading profile-group-title">Change Profile Photo</h3>
				<form onSubmit={updateCover}>
					<div className="form-group mt-4">
					    <div className="upload_opt">
                            <input id="attached" accept="image/png, image/gif, image/jpeg" className="d-none" type="file" name="cover_image" onChange={(e) => feedAttach(e)} />
                            <label htmlFor="attached" className="pl-3">
	                            {imagePreviewProfile ? (
                                    <>
                                        <img alt="dd" className="uploaded__img" src={imagePreviewProfile} />
                                        <br />
                                        <div className="d-inline-block">
                                            <span type="button" className="btn btn-dark mt-4 w-auto">Change image</span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                       <i className="fa fa-camera"></i> Change Profle Image
                                        <br />
                                        <span>{image && image.name}</span>
                                    </>
                                )}
                            </label>

                            <br />
                        </div>
					</div>
					<div className="form-group mt-4 text-center">
                    {profileLoading ? (
                             <button className="btn btn-primary m-2 py-3" disabled>Loading</button>
                        ) : (
                            <button className="btn btn-primary m-2 py-3" type="submit">Save Changes</button>
                        )}
					</div>
				</form>
            </div>
        </div>
	)
}
export default ChangeProfileImage;