import React, { useState, useEffect } from "react";
import { getCurrentUser, setCurrentUser } from "../../helpers/Utils.js";
import axios from "axios";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import MaskInput from "react-maskinput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader";

const Payment = (props) => {
  const [promotionCode, setPromotionCode] = useState(false);
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState("");

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const proceedToPay = (e) => {
    e.preventDefault();
    // setLoading(true);
    setLoader(<Loader />);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/member/create-customer-card`,
        {
          coupon: e.target.coupon?.value,
          card_number: e.target.card_number.value,
          exp_date: e.target.exp_date.value,
          cvc: e.target.cvc.value,
          name: e.target.name.value,
          stripe_price: props.selectedPlan.stripe_price,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status === true) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setError("");
          setCurrentUser(res.data.data);
          window.location.href = "/edit-profile";
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setSuccess("");
          setLoader("");
        }
      });
  };

  const changePlan = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/member/change-customer-plan`,
        {
          stripe_price: props.selectedPlan.stripe_price,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        // setLoading(false);
        if (res.data.status) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setError("");
          props.getPlans();
          setCurrentUser(res.data.data);
          // props.setModalIsOpen(false)
          window.location.href = "/?membership=updated";
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setSuccess("");
        }
      });
  };
  let paymentContent = "";

  // if (!props.subscriptionStatus) {
  paymentContent = (
    <div id="PaymentForm">
      {loader}
      <h4>Set up your credit or debit card</h4>
      <div className="row">
        <div className="col-md-5">
          <Cards
            cvc={cvc}
            expiry={expiry}
            focused={focus}
            name={name}
            number={number}
            className="m-0"
          />
          <div className="card_demo">
            <img
              style={{
                marginRight: "5px",
              }}
              src={`${process.env.PUBLIC_URL}/images/1.png`}
            />
            <img
              style={{
                marginRight: "5px",
              }}
              src={`${process.env.PUBLIC_URL}/images/2.png`}
            />
            <img
              style={{
                marginRight: "5px",
              }}
              src={`${process.env.PUBLIC_URL}/images/3.png`}
            />
            <img
              style={{
                marginRight: "5px",
              }}
              src={`${process.env.PUBLIC_URL}/images/4.png`}
            />
          </div>
        </div>
        <div className="col-md-7">
          <form className="w-100" onSubmit={(e) => proceedToPay(e)}>
            <div className="row">
              <div className="col-12">
                <MaskInput
                  autofocus="true"
                  alwaysShowMask
                  maskChar="_"
                  mask="0000 0000 0000 0000"
                  size={16}
                  name="card_number"
                  placeholder="Card Number"
                  onChange={(e) => setNumber(e.target.value)}
                  className="form-control mb-3"
                  onFocus={(e) => handleInputFocus(e)}
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name="name"
                  placeholder="Card Holder Name"
                  onChange={(e) => setName(e.target.value)}
                  className="form-control mb-3"
                  onFocus={(e) => handleInputFocus(e)}
                  maxLength="24"
                />
              </div>
              <div className="col-8">
                <MaskInput
                  alwaysShowMask
                  maskChar="_"
                  mask="00/00"
                  size={4}
                  name="exp_date"
                  placeholder="Card Number"
                  onChange={(e) => setExpiry(e.target.value)}
                  className="form-control"
                  onFocus={(e) => handleInputFocus(e)}
                />
              </div>
              <div className="col-4">
                <input
                  type="text"
                  name="cvc"
                  placeholder="CVV"
                  onChange={(e) => setCvc(e.target.value)}
                  className="form-control"
                  onFocus={(e) => handleInputFocus(e)}
                  maxLength="3"
                />
              </div>
              <div className="col-12">
                {promotionCode ? (
                  <input
                    type="text"
                    name="coupon"
                    placeholder="Add Promotion Code"
                    className="form-control mt-3 mb-3"
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setPromotionCode(false);
                      }
                    }}
                  />
                ) : (
                  <small
                    className="cursor-pointer mt-1 d-block text-primary"
                    onClick={() => setPromotionCode(true)}
                  >
                    Add Promotion Code
                  </small>
                )}
              </div>
              <div className="col-12 text-right">
                {number ? (
                  <>
                    {loading ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        Proceed to pay
                      </button>
                    )}
                  </>
                ) : (
                  <button type="button" className="btn btn-primary" disabled>
                    Proceed to pay
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
  // } else if (props.subscriptionStatus) {
  //   paymentContent = (
  //     <div className="plan-selection">
  //       <h1>Change Subscription Plan</h1>
  //       <ul className="plan-selection-items">
  //         <li className="modal-plan-item current">
  //           <h2>CURRENT PLAN: {getCurrentUser().membership_name}</h2>
  //           <span
  //             className="d-inline-block"
  //             dangerouslySetInnerHTML={{
  //               __html: getCurrentUser().membership_description,
  //             }}
  //           />
  //           <p className="d-inline-block text-capitalize">
  //             {getCurrentUser().membership_price
  //               ? `$${getCurrentUser().membership_price}/`
  //               : ""}
  //             {getCurrentUser().membership_cycle}{" "}
  //           </p>
  //         </li>
  //         <li className="modal-plan-item">
  //           <h2>NEW PLAN : {props.selectedPlan.name}</h2>
  //           <span
  //             className="d-inline-block"
  //             dangerouslySetInnerHTML={{
  //               __html: props.selectedPlan.description,
  //             }}
  //           />
  //           <p className="d-inline-block">
  //             ${props.selectedPlan.price}/{props.selectedPlan.cycle}{" "}
  //           </p>
  //         </li>
  //       </ul>
  //       <div className="text-center">
  //         {loading ? (
  //           <button type="button" className="btn btn-primary" disabled>
  //             Loading
  //           </button>
  //         ) : (
  //           <button
  //             type="button"
  //             onClick={changePlan}
  //             className="btn btn-primary"
  //           >
  //             Confirm Change
  //           </button>
  //         )}
  //         {/* {error ? (
  //                       <span className="alert alert-danger d-block p-1 w-100 text-center mt-2">
  //                           {error}
  //                       </span>
  //                   ) : (
  //                       ""
  //                   )}
  //                   {success ? (
  //                       <span className="alert alert-success d-block p-1 w-100 text-center mt-2">
  //                           {success}
  //                       </span>
  //                   ) : (
  //                       ""
  //                   )} */}
  //       </div>
  //     </div>
  //   );
  // }

  return <>{paymentContent}</>;
};

export default Payment;
