import React, { useState, useEffect } from "react";
import axios from "axios";
import { getCurrentUser } from "../../helpers/Utils";
import {
  convertFromHTML,
  ContentState,
  EditorState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import Loader from "../loader";
import timezones from "timezones-list";
import Modal from "react-modal";

let selectedDays = [];
let selectedDaysName = [];
const customStyles = {
  content: {
    position: "unset",
    maxWidth: "850px",
    width: "95%",
    right: "auto",
    border: "none",
    bottom: "auto",
    padding: "0",
    margin: "0 auto",
  },
};

function CreateLiveClassForm(props) {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(<Loader />);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [classTitle, setClassTitle] = useState("");
  const [classTimezone, setClassTimezone] = useState("");
  const [startDateFormated, setStartDateFormated] = useState("");
  const [endDateFormated, setEndDateFormated] = useState("");
  const [classZoomlink, setClassZoomlink] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(startDate);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isRepeat, setIsRepeat] = useState(0);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState("4A4A4A");
  const [liveClass, setLiveClass] = useState("");
  const [liveDesc, setLiveDesc] = useState(EditorState.createEmpty());
  const [selectedTimezone, setSelectedTimezone] = useState("");

  const history = useHistory();

  var options = [
    { value: 1, label: "Every Monday" },
    { value: 2, label: "Every Tuesday" },
    { value: 3, label: "Every Wednesday" },
    { value: 4, label: "Every Thursday" },
    { value: 5, label: "Every Friday" },
    { value: 6, label: "Every Saturday" },
    { value: 7, label: "Every Sunday" },
  ];

  const getClass = () => {
    setLoader(<Loader />);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/live-class/${props.slug}/${props.id}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLiveClass(res.data.data);
        const html =
          res.data.data.description !== null
            ? res.data.data.description
            : "<p></p>";
        const blocksFromHTML = convertFromHTML(html);
        const content = ContentState.createFromBlockArray(blocksFromHTML);
        setLiveDesc(EditorState.createWithContent(content));
        setStartDate(new Date(res.data.data.start_date));
        setEndDate(new Date(res.data.data.end_date));
        setStartTime(new Date(`Wed, 27 July 2016 ${res.data.data.start_time}`));
        setEndTime(new Date(`Wed, 27 July 2016 ${res.data.data.end_time}`));
        if (res.data.data.event_color) {
          setColor(res.data.data.event_color);
        }
        setImagePreview(res.data.data.image);
        setIsRepeat(res.data.data.is_repeat);
        selectedDays = Array.isArray(res.data.data.days_of_week_formatted)
          ? res.data.data.days_of_week_formatted.map((x) => x.value)
          : [];
        selectedDaysName = options
          .filter((option) => res.data.data.days_of_week.includes(option.value))
          .map((option) => option.label);
        setLoader("");
      });
  };

  useEffect(() => {
    if (props.slug) {
      getClass();
    } else {
      setLoader("");
    }
  }, []);

  const updateLiveClass = (e) => {
    var startToday = new Date(startDate);
    var startDd = String(startToday.getDate()).padStart(2, "0");
    var startMm = String(startToday.getMonth() + 1).padStart(2, "0");
    var startYyyy = startToday.getFullYear();

    setStartDateFormated(startYyyy + "-" + startMm + "-" + startDd);

    var endToday = new Date(endDate);
    var endDd = String(endToday.getDate()).padStart(2, "0");
    var endMm = String(endToday.getMonth() + 1).padStart(2, "0");
    var endYyyy = endToday.getFullYear();

    setEndDateFormated(endYyyy + "-" + endMm + "-" + endDd);
    e.preventDefault();
    setClassTitle(e.target.title.value);
    setClassTimezone(e.target.timezone.value);
    setClassZoomlink(e.target.zoom_link.value);
    setConfirmModal(true);
  };

  const confirmedClass = () => {
    setLoading(true);
    var formData = new FormData();
    formData.append("title", classTitle);
    formData.append("timezone", classTimezone);
    formData.append("days_of_week", selectedDays);
    formData.append("start_date", startDateFormated);
    formData.append("end_date", endDateFormated);
    formData.append("start_time", new Date(startTime).toLocaleTimeString());
    formData.append("end_time", new Date(endTime).toLocaleTimeString());
    formData.append("is_repeat", isRepeat);
    formData.append("zoom_link", classZoomlink);
    formData.append(
      "description",
      draftToHtml(convertToRaw(liveDesc.getCurrentContent()))
    );
    formData.append("event_color", color);
    formData.append("image", image);

    let APIUrl = "create-live-class";
    if (props.slug) {
      APIUrl = `update-live-class/${props.id}`;
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/${APIUrl}`, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        // setLoading(false);
        if (res.data.success) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          history.push("/live-classes");
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setLoading(false);
      });
  };

  const imageAttach = (e) => {
    setImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  function selectDays(val) {
    selectedDays = Array.isArray(val) ? val.map((x) => x.value) : [];
    selectedDaysName = Array.isArray(val) ? val.map((x) => x.label) : [];
  }

  const isCheckRepeat = (e) => {
    if (e.target.checked === false) {
      setIsRepeat(0);
    } else {
      setIsRepeat(1);
    }
  };

  const handleClick = () => {
    setDisplayColorPicker(true);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColor(color.hex.replace("#", ""));
  };

  const onEditorStateChange = (liveDesc) => {
    setLiveDesc(liveDesc);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: "#" + color,
      },
      swatch: {
        padding: "3px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <>
      <div>
        {loader}
        <form onSubmit={updateLiveClass}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label>Class Title</label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  defaultValue={liveClass && liveClass.title}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => isCheckRepeat(e)}
                    checked={isRepeat !== 0 ? "checked" : ""}
                  />
                  Repeat Class &nbsp;
                </label>
                {props.slug ? (
                  liveClass.days_of_week_formatted !== undefined && (
                    <Select
                      defaultValue={liveClass.days_of_week_formatted}
                      isMulti={true}
                      options={options}
                      onChange={selectDays}
                      isDisabled={isRepeat === 0 ? true : false}
                    />
                  )
                ) : (
                  <Select
                    isMulti={true}
                    options={options}
                    onChange={selectDays}
                    isDisabled={isRepeat === 0 ? true : false}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group mb-4">
                <label>Class Description</label>
                <div className="border bg-light">
                  <Editor
                    editorState={liveDesc}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                      options: ["inline", "list"],
                      inline: {
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                        ],
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label>Beginning Date (if applicable) of Class</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  minDate={new Date()}
                  className="form-control"
                  endDate={endDate}
                  dateFormat="yyyy-MM-dd"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label>End Date (if applicable) of Class</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  className="form-control"
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="yyyy-MM-dd"
                  readOnly={isRepeat === 0 ? true : false}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label>Start Time</label>
                <DatePicker
                  className="form-control"
                  selected={startTime}
                  onChange={(date) => setStartTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="p"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label>End Time</label>
                <DatePicker
                  className="form-control"
                  selected={endTime}
                  onChange={(date) => setEndTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="p"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label>Zoom Link</label>
                <input
                  type="text"
                  className="form-control"
                  name="zoom_link"
                  defaultValue={liveClass.zoom_link}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-4">
                <label>Class Thumbnail</label>
                <div className="d-flex align-items-start">
                  <input
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={imageAttach}
                    accept="image/*"
                  />
                  {imagePreview ? (
                    <img
                      style={{
                        paddingLeft: "15px",
                      }}
                      height="50"
                      alt="ss"
                      src={imagePreview}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-5">
              <label className="w-100">Choose Timezone</label>
              {loader === "" ? (
                <select
                  style={{
                    appearance: "auto",
                    padding: "17px 5px",
                  }}
                  className="form-control"
                  name="timezone"
                  defaultValue={liveClass.timezone}
                >
                  {timezones &&
                    timezones.map((item, i) => (
                      <option value={item.tzCode} key={i}>
                        {item.label}
                      </option>
                    ))}
                </select>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6 mb-5">
              <label className="w-100">Event Color</label>
              <div style={styles.swatch} onClick={() => handleClick()}>
                <div style={styles.color} />
              </div>
              {displayColorPicker ? (
                <div style={styles.popover}>
                  <div style={styles.cover} onClick={() => handleClose()} />
                  <SketchPicker
                    color={color}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              ) : null}
            </div>
            <div className="col-md-12">
              <div className="form-group mb-4 text-center">
                {loading ? (
                  <button type="button" className="btn btn-primary" disabled>
                    Loading
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={confirmModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-header text-center">
          <h5 className="text-center mb-0">Class Preview</h5>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-6">
              <label className="m-0">Name</label>
              <p
                style={{
                  minHeight: "39px",
                  wordWrap: "break-word",
                }}
                className="border px-1 py-1"
              >
                {classTitle}
              </p>
            </div>
            <div className="col-sm-6">
              <label className="m-0">Timezone</label>
              <p
                style={{
                  minHeight: "39px",
                  wordWrap: "break-word",
                }}
                className="border px-1 py-1"
              >
                {classTimezone}
              </p>
            </div>
            <div className="col-sm-6 mt-3">
              <label className="m-0">Is repeat</label>
              <p
                style={{
                  minHeight: "39px",
                  wordWrap: "break-word",
                }}
                className="border px-1 py-1"
              >
                {isRepeat ? "Yes" : "No"}
              </p>
            </div>
            {isRepeat ? (
              <div className="col-sm-6 mt-3">
                <label className="m-0">Days of week</label>
                <p
                  style={{
                    minHeight: "39px",
                    wordWrap: "break-word",
                  }}
                  className="border px-1 py-1"
                >
                  {selectedDaysName?.map((item, index) => (
                    <span key={index}>
                      {item}
                      {index !== selectedDaysName.length - 1 && " | "}
                    </span>
                  ))}
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="col-sm-6 mt-3">
              <label className="m-0">Start date</label>
              <p
                style={{
                  minHeight: "39px",
                  wordWrap: "break-word",
                }}
                className="border px-1 py-1"
              >
                {startDateFormated}
              </p>
            </div>
            <div className="col-sm-6 mt-3">
              <label className="m-0">End date</label>
              <p
                style={{
                  minHeight: "39px",
                  wordWrap: "break-word",
                }}
                className="border px-1 py-1"
              >
                {endDateFormated}
              </p>
            </div>
            <div className="col-sm-6 mt-3">
              <label className="m-0">Start time</label>
              <p
                style={{
                  minHeight: "39px",
                  wordWrap: "break-word",
                }}
                className="border px-1 py-1"
              >
                {new Date(startTime).toLocaleTimeString()}
              </p>
            </div>
            <div className="col-sm-6 mt-3">
              <label className="m-0">End time</label>
              <p
                style={{
                  minHeight: "39px",
                  wordWrap: "break-word",
                }}
                className="border px-1 py-1"
              >
                {new Date(endTime).toLocaleTimeString()}
              </p>
            </div>
            <div className="col-sm-6 mt-3">
              <label className="m-0">Zoom link</label>
              <p
                style={{
                  minHeight: "39px",
                  wordWrap: "break-word",
                }}
                className="border px-1 py-1"
              >
                {classZoomlink}
              </p>
            </div>
            <div className="col-sm-6 mt-3">
              <label className="m-0">Event color</label>
              <span
                className="mt-2"
                style={{
                  width: "50px",
                  height: "28px",
                  background: "#" + color,
                  display: "block",
                }}
              />
            </div>
            <div className="col-sm-12 mt-3">
              <label className="m-0">Description</label>
              <p
                style={{
                  minHeight: "39px",
                  wordWrap: "break-word",
                }}
                className="border px-1 py-1"
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(
                    convertToRaw(liveDesc.getCurrentContent())
                  ),
                }}
              ></p>
            </div>
            <div className="col-sm-12 mt-3">
              <label className="m-0">Image</label>
              <br />
              {imagePreview ? (
                <img className="mw-100 mt-2" src={imagePreview} alt="22" />
              ) : (
                "--"
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-seconday"
            onClick={() => setConfirmModal(false)}
          >
            Edit
          </button>
          {loading ? (
            <button className="btn btn-primary disable" disabled>
              Loading
            </button>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => confirmedClass()}
            >
              Publish
            </button>
          )}
        </div>
      </Modal>
    </>
  );
}
export default CreateLiveClassForm;
