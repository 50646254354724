import React, { useState } from "react";
import PropTyps from "prop-types";
import { setAffiliateId, getAffiliateId } from '../../utils';

const customStyles = {
    content: {
        position: "unset",
        maxWidth: "650px",
        width: "100%",
        right: "auto",
        border: "1px solid rgb(204, 204, 204)",
        bottom: "auto",
        padding: "0",
        margin: "120px auto",
    },
};
  var ref_code = localStorage.getItem("ref_id");

const TeamCard = ({ data }) => {
    const [id, setId] = React.useState({'teacher_id': ''});
    const [radioButton, setRadioButton] = useState(false);


    const updateAffliateId = (e) => {
        if (getAffiliateId() === e.target.value) {
            setAffiliateId('')
            var radio = document.querySelector('input[type=radio][name=teacher_id]:checked');
            radio.checked = false;
        } else {
            setAffiliateId(e.target.value);
        }
    }


    return (
        <div>
            <input type="radio" id={`affiliate${data.id}`} onClick={(e) => updateAffliateId(e)} value={data.id} name="teacher_id" />
            <label for={`affiliate${data.id}`} className="register_teachers-single">
                <div className="new-team-members-list mb-0">
                    <div className="thumb">
                        <img src={data.image} alt="images" />
                    </div>
                    <div className="content">
                        <h3 className="title">{data.name}</h3>
                    </div>
                </div>
            </label>
        </div>
    );
};

export default TeamCard;
