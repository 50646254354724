import React, { useEffect, useState} from "react";
import SectionTitle from "../../../components/section-title";
import { Swiper, SwiperSlide } from "swiper/react";
import Testimonial from "../../../components/testimonial";
import SwiperCore, { Navigation } from "swiper";
import useSWR from "swr";
import Loader from "../../../components/loader";
import axios from 'axios';

SwiperCore.use([Navigation]);

const TestimonialContainer = () => {
    const [data, setData] = useState([])

    const getData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/testimonials`, {})
        .then(function (res) {
            setData(res.data.data);
        }).catch(function (err) {
            console.log(err)
        });
    }
    
    useEffect(() => {
        getData();
    }, []);

    const swiperOption = {
        loop: false,
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
            nextEl: ".testimonial-carousel .swiper-button-next",
            prevEl: ".testimonial-carousel .swiper-button-prev",
        },
    };
    // const sceneEl = useRef(null);
    
    let testimonial = '';
    if (data !== undefined) {
        testimonial = <Swiper {...swiperOption}>
            {data &&
                data.map(
                    (testimonial, key) => {
                        return (
                            <SwiperSlide key={key}>
                                <Testimonial
                                    key={key}
                                    data={testimonial}
                                />
                            </SwiperSlide>
                        );
                    }
                )}
        </Swiper>
    } else {
        testimonial = <Loader />
    }

//     useEffect(() => {
//         const parallaxInstance = new Parallax(sceneEl.current, {
//             relativeInput: true,
//         });
// 
//         parallaxInstance.enable();
// 
//         return () => parallaxInstance.disable();
//     }, []);
    return (
        <div className="testimonial-section section-py position-relative">
            <img
                className="tetimonial-shape"
                src={`${process.env.PUBLIC_URL}/images/testimonial/shape.png`}
                alt=""
            />
            <div className="container">
                <div className="row mb-n7">
                    {/* <div className="col-xl-7 col-lg-6 mb-7"> */}
                    {/*     <div */}
                    {/*         className="testimonial-photo scene mt-10 mt-lg-0" */}
                    {/*         id="scene" */}
                    {/*         ref={sceneEl} */}
                    {/*     > */}
                    {/*         <div data-depth="0.2"> */}
                    {/*             <img */}
                    {/*                 src={`${process.env.PUBLIC_URL}/images/testimonial/1.png`} */}
                    {/*                 alt="Buznex" */}
                    {/*             /> */}
                    {/*         </div> */}
                    {/*     </div> */}
                    {/* </div> */}
                    <div className="col-12 mb-7">
                        <div className="testimonial-wrap text-center testimonial-space">
                            <SectionTitle
                                classOption="title-section"
                                title="Testimonials"
                            />
                            {/* <h5 className="happy-customer"> */}
                            {/*     More over{" "} */}
                            {/*     <span className="font-weight-bold">2500</span>{" "} */}
                            {/*     happy customer */}
                            {/* </h5> */}
                        </div>
                        <div className="testimonial-carousel position-relative text-center">
                            {testimonial}
                            <div className="text-center mt-2">
                                <div className="swiper-button-prev d-inline-block">
                                    <i className="icofont-rounded-left"></i>prev
                                </div>
                                <div className="swiper-button-next d-inline-block">
                                    Next
                                    <i className="icofont-rounded-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialContainer;
