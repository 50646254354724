import React, { useState } from "react";
import PropTypes from "prop-types";
import ClassDetailsWrap from "../../../components/class-details";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { getCurrentUser } from "../../../helpers/Utils";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";
import {
    convertFromHTML,
    ContentState,
    EditorState,
    convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const SingleComment = (props) => {
    const [comment, setComment] = useState(props.data);
    const [otherInfo, setOtherInfo] = useState(false);
    const [sure, setSure] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const html = props.data.comment !== null ? props.data.comment : "<p></p>";
    const blocksFromHTML = convertFromHTML(html);
    const content = ContentState.createFromBlockArray(blocksFromHTML);
    const [defPostContent, setDefPostContent] = useState(
        EditorState.createWithContent(content)
    );
    const onEditorStateChange = (defPostContent) => {
        setDefPostContent(defPostContent);
    };

    if (otherInfo) {
        const concernedElement = document.querySelector(".info_menu");
        document.addEventListener("mousedown", (event) => {
            if (concernedElement.contains(event.target)) {
            } else {
                setOtherInfo(false);
            }
        });
    }

    const deleteFile = () => {
        setSure(false);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/delete-class-comment?comment_id=${comment.id}&class_id=${comment.class_id}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                toast.success(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setComment("");
            })
            .catch(function (err) {
                toast.error(err.response.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    const editAction = () => {
        setIsEditing(true);
        setOtherInfo(false);
    };

    const updateComment = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/update-class-comment`,
                {
                    class_id: comment.class_id,
                    comment_id: comment.id,
                    comment: draftToHtml(
                        convertToRaw(defPostContent.getCurrentContent())
                    ),
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                setIsEditing(false);
                if (res.data.success) {
                    e.target.reset();
                    setComment(res.data.data);
                    toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
    };

    let isDeleteOption;

    if (comment.user_id === getCurrentUser().id) {
        isDeleteOption = (
            <>
                <button onClick={() => setOtherInfo(!otherInfo)}>
                    <i
                        style={{
                            color: "#555",
                        }}
                        className="fa fa-ellipsis-v"
                    />
                </button>
                {otherInfo ? (
                    <div className="info_menu">
                        <ul>
                            <li
                                className="cursor-pointer"
                                onClick={() => editAction()}
                            >
                                Edit
                            </li>
                            <li
                                className="cursor-pointer"
                                onClick={() => setSure(true)}
                            >
                                Delete
                            </li>
                        </ul>
                    </div>
                ) : (
                    ""
                )}
            </>
        );
    } else if (props.classTeacherId === getCurrentUser().id) {
        isDeleteOption = (
            <>
                <button onClick={() => setOtherInfo(!otherInfo)}>
                    <i
                        style={{
                            color: "#555",
                        }}
                        className="fa fa-ellipsis-v"
                    />
                </button>
                {otherInfo ? (
                    <div className="info_menu">
                        <ul>
                            <li
                                className="cursor-pointer"
                                onClick={() => setSure(true)}
                            >
                                Delete
                            </li>
                        </ul>
                    </div>
                ) : (
                    ""
                )}
            </>
        );
    }

    if (comment !== "") {
        return (
            <>
                <div
                    className="comments_single d-flex justify-content-between align-items-start"
                    key={comment && comment.id}
                >
                    <div
                        className="d-flex"
                        style={{
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                width: "30px",
                            }}
                        >
                            <img
                                src={comment && comment.user_image}
                                width="30"
                                height="30"
                                className="rounded-circle"
                                alt="comment"
                            />
                        </div>
                        <div
                            style={{
                                width: "calc(100% - 30px)",
                            }}
                            className="px-3"
                        >
                            <h6>
                                <NavLink
                                    to={`/member/${comment.user_id}`}
                                    className="d-inline-block p-0"
                                >
                                    {comment && comment.user_name}
                                </NavLink>{" "}
                                on {comment && comment.date} at{" "}
                                {comment && comment.time}
                            </h6>
                            {isEditing ? (
                                <form onSubmit={(e) => updateComment(e)}>
                                    <div className="border mt-2">
                                        <Editor
                                            editorState={defPostContent}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={
                                                onEditorStateChange
                                            }
                                            toolbar={{
                                                options: ["inline", "list"],
                                                inline: {
                                                    options: [
                                                        "bold",
                                                        "italic",
                                                        "underline",
                                                        "strikethrough",
                                                    ],
                                                },
                                            }}
                                            mention={{
                                                separator: " ",
                                                trigger: "@",
                                                suggestions: props.list,
                                            }}
                                        />
                                    </div>
                                    {loading ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm my-2 py-2 px-4"
                                        >
                                            Loading
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-sm my-2 py-2 px-4"
                                        >
                                            Update
                                        </button>
                                    )}
                                </form>
                            ) : (
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: comment && comment.comment,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="position-relative px-2">
                        {isDeleteOption}
                    </div>
                </div>
                {sure && (
                    <SweetAlert
                        showCancel
                        confirmBtnText="Yes, delete it!"
                        confirmBtnBsStyle="btn btn-primary"
                        title="Are you sure?"
                        btnSize="sm"
                        onConfirm={() => deleteFile()}
                        onCancel={() => setSure(false)}
                    ></SweetAlert>
                )}
            </>
        );
    } else {
        return "";
    }
};

export default SingleComment;
