import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Loader from "../../components/loader";
import CourseGrid from "../../components/course";
import { setCurrentUser, getCurrentUser } from "../../helpers/Utils";
import axios from "axios";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { NavLink } from "react-router-dom";

const CourseItemContainer = () => {
	const [data, setData] = useState([]);
	const [loader, setLoader] = useState(<Loader />);
	const [error, setError] = useState('');

	const getCourse = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/courses`, {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${getCurrentUser().token}`,
				},
			})
			.then(function (res) {
				setLoader('')
				if (res.data.success === true) {
					setData(res.data.data);
					setError('')
				} else {
					setError(res.data.message)
				}
			});
	};

	useEffect(() => {
		getCourse();
	},[]);
	return (
		<section className="blog-section section-py classes_page">
			<div className="container">
				<div className="row">
					{loader}
					{error}
					{data.map((course, i) => (
						<CourseGrid course={course} key={i} />
					))}
				</div>
			</div>
		</section>
	);
};

export default CourseItemContainer;
