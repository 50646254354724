import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import Subscription from "../containers/home/subscription";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import PageLoader from '../components/page-loader'
import { getCurrentUser } from '../helpers/Utils'

const SubPlans = () => {

    let title = ''
    if (getCurrentUser().allow_access === "0") {
        title = 'Choose your Plan'
    } else {
        title = 'Update Membership'
    }

    return (
        <React.Fragment>
        <PageLoader />
            <Layout>
                <SEO title="High Vibe Social Network & Sanctuary | Soul Tribe Online" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={title}
                    />
                    <section className="section-py pb-0">
                        <Subscription />
                    </section>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default SubPlans;
