import React, { useState, useEffect } from "react";
import { getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Carousel, { Modal, ModalGateway } from "react-images";

let singlePhotoId = ''
const TribePhotos = (props) => {
	const [singlePhoto, setSinglePhoto] = useState(props.tribePhotos);
	const [selectedPhoto, setSelectedPhoto] = useState(false);
	const [sure, setSure] = useState(false);
	const [currentImage, setCurrentImage] = useState(0);
  	const [viewerIsOpen, setViewerIsOpen] = useState(false);
	// const [singlePhotoId, setSinglePhotoId] = useState([]);

	const selectSinglePhoto = (id) => {
		singlePhotoId = [id]
		deleteTribePhotos()
	}

	const deleteTribePhotos = (e) => {
	setSure(false);
    axios
        .post(
            `${process.env.REACT_APP_API_URL}/remove-tribe-photos`,
            {
                photo_ids: singlePhotoId,
            },
            {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {
        	setSinglePhoto('');
        	toast.success(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    };

    const modalPhoto = [
		{
			src: singlePhoto.photo,
			width: 4,
			height: 3
		}
 	];

    const openLightbox = () => {
	    setViewerIsOpen(true);
	};

	const closeLightbox = () => {
		setCurrentImage(0);
		setViewerIsOpen(false);
	};

    if (singlePhoto !== '') {
		return (
			<>
				<ModalGateway>
			        {viewerIsOpen ? (
			          <Modal onClose={closeLightbox}>
			            <Carousel
			              views={modalPhoto.map(x => ({
			                ...x,
			                srcset: x.srcSet,
			                caption: x.title
			              }))}
			            />
			          </Modal>
			        ) : null}
			    </ModalGateway>
				<div
					key={props.i}
					className="col col-lg-3 col-md-4 col-md-4 col-sm-6 mb-5"
				>
					{sure && <SweetAlert
	                    showCancel
	                    confirmBtnText="Yes, delete it!"
	                    confirmBtnBsStyle="btn btn-primary"
	                    title="Are you sure?"
	                    btnSize="sm"
	                    onConfirm={() => selectSinglePhoto(singlePhoto.id)}
	                    onCancel={() => setSure(false)}
	                >
	                </SweetAlert>}
					{singlePhoto && (
						<div className={`photos-tab-single ${selectedPhoto ? 'checked' : ''}`}>
							{getCurrentUser().id == singlePhoto.user_id ?
								<div className="photo_options">
									<div className="photo_options-select">
										{/*<input type="checkbox" id={`tp${singlePhoto.id}`} onChange={(e) => selectSinglePhoto(e, singlePhoto.id)} />
										<label htmlFor={`tp${singlePhoto.id}`}>
											<span></span>
										</label>*/}
									</div>
									<div className="photo_options-other">
										<div className="contact-profile-info">
											<i
												onClick={() => setSure(true)}
												className="fa fa-trash"
											></i>
										</div>
									</div>
								</div>
							: ''}

							<img
								className="photos-tab-thumb cursor-pointer"
								src={singlePhoto.photo}
								alt="tribe-photo"
								onClick={openLightbox}
							/>
						</div>
					)}
				</div>
			</>
		);
	} else {
		return "";
	}
};

export default TribePhotos;
