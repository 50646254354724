import React, { useEffect, useState } from "react";
import SectionTitle from "../../../components/section-title";

import { Link } from "react-router-dom";
import axios from "axios";
import { getCurrentUser } from "../../../helpers/Utils";

const HomeBlog = () => {
    const [data, setdata] = useState([]);

    const callApi = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/classes`)
            .then(function (res) {
                setdata(res.data.data.data);
            });
    };

    useEffect(() => {
        callApi();
    }, []);

    // if (!data) return <div className="text-center">Loading blogs...</div>;

    return (
        <div className="blog-section">
            <div className="container">
                <SectionTitle
                    classOption="text-center"
                    // subTitle="CLASSES POST"
                    title="Recent Classes"
                />
                <div className="row mb-n7 mt-5">
                    {data &&
                        data.map((blog, i) => (
                            <div className="col-lg-4 col-sm-6 mb-7" key={i}>
                                {i < 3 ? (
                                    <div className="blog-card">
                                        <div
                                            className={`thumb bg-light text-center`}
                                        >
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    `/class/${blog.slug}`
                                                }
                                            >
                                                <img
                                                    src={blog.image}
                                                    alt="img"
                                                />
                                            </Link>
                                        </div>
                                        <div className="blog-content">
                                            <h3 className="title">
                                                <Link
                                                    to={
                                                        process.env.PUBLIC_URL +
                                                        `/class/${blog.slug}`
                                                    }
                                                >
                                                    {blog.title}
                                                </Link>
                                            </h3>
                                            <span
                                                style={{
                                                    fontSize: "12px",
                                                    lineHeight: "24px",
                                                }}
                                            >
                                                {blog.teacher.name}
                                                &nbsp; | &nbsp;
                                                {blog.categories.map(
                                                    (item, i) => (
                                                        <>
                                                            {i < 1 ? (
                                                                <span key={i}>
                                                                    {item.name}
                                                                </span>
                                                            ) : (
                                                                <span key={i}>
                                                                    ,{" "}
                                                                    {item.name}
                                                                </span>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                                &nbsp; | &nbsp;
                                                {blog.levels.map((item, i) => (
                                                    <>
                                                        {i < 1 ? (
                                                            <span key={i}>
                                                                {item.name}
                                                            </span>
                                                        ) : (
                                                            <span key={i}>
                                                                , {item.name}
                                                            </span>
                                                        )}
                                                    </>
                                                ))}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default HomeBlog;
