import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
    setAffiliateId,
    getAffiliateId,
    getPlanId,
    setCurrentUser,
    getCurrentUser,
} from "../../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let ref_code = "";

const OtherInfo = (props) => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirm_password] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [teacherData, setTeacherData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState("");
    const [teacherClass, setTeacherClass] = useState("d-inline-block");
    const [planClass, setPlanClass] = useState("d-none");
    const [register, setRegister] = useState("d-none");

    const userRegistration = (e) => {
        e.preventDefault();
        ref_code = localStorage.getItem("ref_id");

        if (ref_code > -1) {
            setAffiliateId(ref_code);
        }
        setIsLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/register`, {
                teacher_id: getAffiliateId() ? getAffiliateId() : '17',
                membership_id: getPlanId(),
                name: name,
                email: email,
                password: password,
                confirm_password: confirm_password,
            })
            .then(function (res) {
                if (res.data.success === false) {
                    toast.error(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setSuccess("");
                    setIsLoading(false);
                } else {
                    const user_data = res.data.data.user.data;
                    setCurrentUser(user_data);
                    setError("");
                    window.location.href = "/plans";
                }
            });
    };

    return (
        <div className="loginsignup">
            <form id="contactForm" onSubmit={(e) => userRegistration(e)}>
                <div className="registration__info">
                    <h6 className="mb-5">
                        Enter following details to create new account
                    </h6>
                    <label className="d-block mt-4">
                        Name
                        <input
                            className="form-control mb-2"
                            onChange={(e) => setName(e.target.value)}
                            name="name"
                            type="text"
                            placeholder="Full name"
                            autofocus="true"
                        />
                    </label>
                    <label className="d-block mt-4">
                        Email
                        <input
                            className="form-control mb-2"
                            onChange={(e) => setEmail(e.target.value)}
                            name="email"
                            type="email"
                            placeholder="e.g. info@soultribeonline.com"
                        />
                    </label>
                    <label className="d-block mt-4">
                        Password
                        <input
                            className="form-control mb-2"
                            onChange={(e) => setPassword(e.target.value)}
                            name="password"
                            type="password"
                            placeholder="Set password"
                        />
                    </label>
                    <label className="d-block mt-4">
                        Confirm Password
                        <input
                            className="form-control mb-2"
                            onChange={(e) =>
                                setConfirm_password(e.target.value)
                            }
                            name="confirm_password"
                            type="password"
                            placeholder="Repeat password"
                        />
                    </label>
                    <div className="py-3">
                        <label>
                            <input type="checkbox" required /> Yes, I agree to
                            the{" "}
                            <Link to={process.env.PUBLIC_URL + "/privacy"}>
                                Privacy Policy
                            </Link>{" "}
                            |{" "}
                            <Link
                                to={
                                    process.env.PUBLIC_URL + "/terms-conditions"
                                }
                            >
                                Terms & Conditions
                            </Link>{" "}
                            View the Terms of Service & Privacy Policy
                        </label>
                    </div>
                    {isLoading ? (
                        <button
                            type="button"
                            className="btn btn-dark disabled btn-hover-dark m-auto d-block mt-2"
                            disabled
                        >
                            Loading...
                        </button>
                    ) : (
                        <button
                            type="submit"
                            className="btn btn-dark btn-hover-dark m-auto d-block mt-2"
                        >
                            Select membership plan
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default OtherInfo;
