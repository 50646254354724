import React, { useState } from "react";
import Modal from "react-modal";
const customStyles = {
    content: {
        position: "unset",
        maxWidth: "calc(100% - 50px)",
        width: "100%",
        height: "calc(100% - 50px)",
        right: "auto",
        border: "none",
        bottom: "auto",
        padding: "0 10px",
        margin: "0 auto",
    },
};

const TribePostDocument = (props) => {
	const [pdfView, setPdfView] = useState(false);

    var fileExtension = props.data.document.split('.').pop();

	return (
		<>
			 <Modal ariaHideApp={false} isOpen={pdfView} style={customStyles} contentLabel="Add Tribe Photo">
                <div className="modal-header px-0">
                    <h5>{props.data.name}</h5>
                    <i onClick={() => setPdfView(false)} className="fa fa-times" />
                </div>
                {fileExtension === 'pdf' ? 
                    <iframe title="Document" width="100%" src={`${props.data.document}`} style={{height: 'calc(100% - 74px)'}}></iframe>
                : ''}
                {fileExtension === 'doc' ? 
                    <iframe title="Document" width="100%" src={`https://view.officeapps.live.com/op/embed.aspx?src=${props.data.document}&embedded=true`} style={{height: 'calc(100% - 74px)'}}></iframe>
                : ''}
                {fileExtension === 'docx' ? 
                    <iframe title="Document" width="100%" src={`https://view.officeapps.live.com/op/embed.aspx?src=${props.data.document}&embedded=true`} style={{height: 'calc(100% - 74px)'}}></iframe>
                : ''}
            </Modal>
			<div className="border p-3">
	            <div className="d-flex">
	                <button onClick={() => setPdfView(true)} target="_blank" className="text-dark">
	                    <i className="fa fa-file"></i> &nbsp;
	                    {props.data.name}
	                </button>
	            </div>
	        </div>
    	</>
	);
};

export default TribePostDocument;