import React, { useState } from "react";
import { getCurrentUser } from "../../helpers/Utils";
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import "react-toastify/dist/ReactToastify.css";

const customStyles = {
  content: {
    position: "unset",
    maxWidth: "850px",
    width: "440px",
    right: "auto",
    border: "none",
    bottom: "auto",
    padding: "35px",
    margin: "0 auto",
    background: "#fef7da",
  },
};
let matchedUser;
const TribeMemberGrid = ({ data, classOption, isTribe, group }) => {
  const history = useHistory();
  const [memberRemoved, setMemberRemoved] = useState(true);
  const [status, setStatus] = useState(data?.request_status);
  const [loading, setLoading] = useState(false);
  const [threadLoading, setThreadLoading] = useState(false);
  const [sendRequest, setSendRequest] = useState(false);
  const [disconnect, setDisconnect] = useState(false);
  const [sure, setSure] = useState(false);

  const sendMemberRequest = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/member/send-friend-request`,
        {
          member_id: data.id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        setSendRequest(false);
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const disconnectMember = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/member/remove-connection`,
        {
          member_id: data.id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        setDisconnect(false);
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const checkThread = () => {
    setThreadLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/thread`,
        {
          message: "",
          group_id: "",
          receiver_id: data.id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setThreadLoading(false);
        history.push(`/messages/${res.data.data.id}`);
      });
  };

  const changeStatus = () => {
    sendMemberRequest();
    setStatus(0);
  };

  const removeMemberReq = () => {
    disconnectMember();
    setStatus(null);
  };

  const removeFromTibe = () => {
    var formData = new FormData();
    formData.append("id", group.id);
    formData.append("member_id", data.id);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/remove-member-from-tribe`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setMemberRemoved(false);
      });
  };

  if (group) {
    let usersArray = group.sub_admin_id?.split(",").map(Number);
    matchedUser = usersArray?.includes(getCurrentUser().id);
  }

  if (memberRemoved) {
    return (
      <div className="col-lg-4 col-sm-6 mb-7">
        <div className="blog-card border">
          <div className="thumb bg-light text-center p-0">
            <NavLink
              to={process.env.PUBLIC_URL + `/member/${data.id}`}
              className="position-relative d-inline-block"
            >
              <img src={data.image} alt="img" />
              {data.is_active === "yes" ? <span className="active"></span> : ""}
            </NavLink>
          </div>
          <div className="members">
            <div className="py-2 text-center">
              <h3 className="title text-capitalize">
                <NavLink to={process.env.PUBLIC_URL + `/member/${data.id}`}>
                  {data.name}
                </NavLink>
              </h3>
              {/* <span className="blog-meta date email-blog"> */}
              {/*     {data.email} */}
              {/* </span> */}
            </div>
            {getCurrentUser().id === data.id ? (
              ""
            ) : (
              <div className="d-flex justify-content-around align-content-center align-items-center mt-2">
                {status === null ? (
                  <>
                    <span
                      className="text-center py-1 border-top d-block"
                      // onClick={changeStatus}
                      onClick={() => setSendRequest(true)}
                    >
                      <i
                        className="fa fa-user-plus"
                        aria-hidden="true"
                        title="Connect"
                      ></i>
                    </span>
                  </>
                ) : (
                  ""
                )}
                {status === 0 ? (
                  <span
                    onClick={() => setDisconnect(true)}
                    className="text-center py-1 border-top d-block"
                  >
                    <i
                      className="fa fa-user-times"
                      aria-hidden="true"
                      title=""
                    ></i>
                  </span>
                ) : (
                  ""
                )}
                {status === 1 ? (
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    className="text-center text-danger py-1 border-top d-block"
                    onClick={() => setDisconnect(true)}
                  >
                    <i className="fa fa-times" /> Remove
                  </span>
                ) : (
                  ""
                )}
                {data.member_thread === null ? (
                  <>
                    {threadLoading ? (
                      <span className="text-center py-1 border-top d-block">
                        <i className="fa fa-spinner"></i>
                      </span>
                    ) : (
                      <span
                        onClick={() => checkThread()}
                        className="text-center py-1 border-top d-block"
                      >
                        <i className="fa fa-envelope"></i>
                      </span>
                    )}
                  </>
                ) : (
                  <span className="text-center py-1 border-top d-block">
                    <NavLink
                      to={
                        process.env.PUBLIC_URL +
                        `/messages/${data.member_thread}`
                      }
                    >
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </NavLink>
                  </span>
                )}
                {isTribe ? (
                  getCurrentUser().id == group.teacher_id || matchedUser ? (
                    <span
                      onClick={() => setSure(true)}
                      className="text-center py-1 border-top d-block"
                    >
                      <i
                        className="fa fa-trash text-danger"
                        aria-hidden="true"
                      ></i>
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            )}
            <Modal
              ariaHideApp={false}
              isOpen={sendRequest}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="request-data-style text-center">
                <h5 className="text-center mb-5">
                  <i className="fa fa-user-plus fa-5x" />
                  <br />
                  <br />
                  Confirm you are requesting connection with this member
                </h5>
                <div>
                  {loading ? (
                    <button className="btn btn-primary py-2 px-3 m-1 " disabled>
                      Loading
                    </button>
                  ) : (
                    <button
                      onClick={changeStatus}
                      className="btn btn-success py-2 px-3 m-1"
                    >
                      Yes, continue.
                    </button>
                  )}
                  <button
                    onClick={() => setSendRequest(false)}
                    className="btn btn-danger py-2 px-3 m-1"
                  >
                    No, I changed my mind.
                  </button>
                </div>
              </div>
            </Modal>
            <Modal
              ariaHideApp={false}
              isOpen={disconnect}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="request-data-style text-center">
                <h5 className="text-center mb-5">
                  <i className="fa fa-user-times fa-5x" />
                  <br />
                  <br />
                  Do you really want to perform this action?
                </h5>
                <div>
                  {loading ? (
                    <button className="btn btn-primary py-2 px-3 m-1 " disabled>
                      Loading
                    </button>
                  ) : (
                    <button
                      onClick={removeMemberReq}
                      className="btn btn-danger py-2 px-3 m-1"
                    >
                      Yes, continue.
                    </button>
                  )}
                  <button
                    onClick={() => setDisconnect(false)}
                    className="btn btn-warning py-2 px-3 m-1"
                  >
                    No, I changed my mind.
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        {sure && (
          <SweetAlert
            showCancel
            confirmBtnText="Yes"
            confirmBtnBsStyle="btn btn-primary"
            title="Are you sure?"
            btnSize="sm"
            onConfirm={() => removeFromTibe()}
            onCancel={() => setSure(false)}
          ></SweetAlert>
        )}
      </div>
    );
  } else return "";
};

export default TribeMemberGrid;
