import React from "react";
import BlogItemContainer from "../containers/blog/blog-item";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageLoader from '../components/page-loader'

const TeachersPage = () => {
    return (
        <React.Fragment>
        <PageLoader />
            <Layout>
                <SEO title="Soul Tribe Online Community – Teachers" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Teachers"
                        excerpt=""
                        image="./images/blog/banner.png"
                    />
                    <BlogItemContainer />
                    
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default TeachersPage;
