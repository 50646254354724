import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import { getCurrentUser, setCurrentUser } from "../../helpers/Utils";
import Loader from "../../components/loader";
import PaymentMethod from "./payment-method";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import moment from "moment";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "80%",
  },
};

const changePlanStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "405px",
    width: "100%",
    // height: '80%'
  },
};

const MembershipAccount = () => {
  const [invoice, setInvoice] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(<Loader />);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deactivatePlanModalIsOpen, setDeactivatePlanModalIsOpen] =
    useState(false);
  const [activatePlanModalIsOpen, setActivatePlanModalIsOpen] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [subscription, setSubscription] = useState("");
  const [subscriptionData, setSubscriptionData] = useState("");

  const printButton = () => {
    window.print();
  };

  const getSubscription = () => {
    setLoader(<Loader />);
    setSubscription("");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/stripe/subscriptions/${
          getCurrentUser().stripe_subscription_id
        }`
        // {
        //   headers: {
        //     "content-type": "application/json",
        //     Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        //   },
        // }
      )
      .then(function (res) {
        setSubscription(res.data);
        getProductName(res.data.plan.product);
        setLoader("");
      });
  };

  const getProductName = (productID) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/stripe/products/${productID}`, {
        // headers: {
        //   "content-type": "application/json",
        //   Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        // },
      })
      .then(function (res) {
        setSubscriptionData(res.data);
      });
  };

  const invoiceList = () => {
    setInvoice([]);
    setLoader(<Loader />);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/stripe/invoices?customer=${
          getCurrentUser().stripe_customer_id
        }`
        // {
        //   headers: {
        //     "content-type": "application/json",
        //     Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        //   },
        // }
      )
      .then(function (res) {
        setLoader("");
        setInvoice(res.data.data);
      });
  };

  useEffect(() => {
    invoiceList();
    if (getCurrentUser() && getCurrentUser().stripe_subscription_id !== null) {
      getSubscription();
    }
  }, []);

  const activateMembership = () => {
    setLoading(true);
    var cardToken = {
      cancel_at_period_end: false,
    };
    var formBodyToken = [];
    for (var property in cardToken) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(cardToken[property]);
      formBodyToken.push(encodedKey + "=" + encodedValue);
    }
    formBodyToken = formBodyToken.join("&");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/stripe/subscriptions/${
          getCurrentUser().stripe_subscription_id
        }`,
        formBodyToken
        // {
        //   headers: {
        //     "content-type": "application/x-www-form-urlencoded",
        //     Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        //   },
        // }
      )
      .then(function (res) {
        toast.success("Your membership has been reactivated successfully.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        setActivatePlanModalIsOpen(false);
        axios
          .get(`${process.env.REACT_APP_API_URL}/member/profile`, {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          })
          .then(function (res) {
            const user_data = res.data.data;
            setCurrentUser(user_data);
            if (res.data.data.stripe_subscription_id !== null) {
              getSubscription();
            }
          })
          .catch(function (err) {
            setCurrentUser("");
            window.location.href = "/login";
          });
      })
      .catch(function (err) {
        console.log("err", err);
      });
  };

  const cancelMembership = () => {
    setLoading(true);
    var cardToken = {
      cancel_at_period_end: true,
    };
    var formBodyToken = [];
    for (var property in cardToken) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(cardToken[property]);
      formBodyToken.push(encodedKey + "=" + encodedValue);
    }
    formBodyToken = formBodyToken.join("&");
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/stripe/subscriptions/${
          getCurrentUser().stripe_subscription_id
        }`,
        formBodyToken
        // {
        //   headers: {
        //     "content-type": "application/x-www-form-urlencoded",
        //     Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        //   },
        // }
      )
      .then(function (res) {
        toast.success("Your membership has been canceled successfully.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        setDeactivatePlanModalIsOpen(false);
        axios
          .get(`${process.env.REACT_APP_API_URL}/member/profile`, {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          })
          .then(function (res) {
            const user_data = res.data.data;
            setCurrentUser(user_data);
            if (res.data.data.stripe_subscription_id !== null) {
              getSubscription();
            }
          })
          .catch(function (err) {
            setCurrentUser("");
            window.location.href = "/login";
          });
      })
      .catch(function (err) {
        console.log("err", err);
      });
  };

  return (
    <section className="contact-section section-py mt-5">
      <div className="container">
        <Tabs className="tribe_tabs">
          <TabList>
            <Tab>Account</Tab>
            {/* <Tab>Billing</Tab> */}
            <Tab onClick={invoiceList}>Invoices</Tab>
          </TabList>
          <TabPanel>
            <div className="row">
              <div className="profile public">
                <div className="bp-widget base">
                  <h3 className="screen-heading profile-group-title">
                    My Membership{" "}
                    <small>
                      (
                      <NavLink className="" to="/plans">
                        View all membership plans
                      </NavLink>
                      )
                    </small>
                  </h3>
                  <div
                    style={{
                      width: "100%",
                      overflow: "auto",
                    }}
                  >
                    <table
                      className="table table-bordered my-4"
                      style={{
                        minWidth: "700px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Membership</th>
                          <th>Status</th>
                          <th>Billing</th>
                          <th>
                            {subscription &&
                            subscription.status !== "canceled" ? (
                              <>
                                {subscription.cancel_at_period_end
                                  ? "End  date"
                                  : "Next bill date"}
                              </>
                            ) : (
                              "End  date"
                            )}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="pmpro_account-membership-levelname">
                            {subscriptionData.name}
                          </td>
                          <td className="text-capitalize">
                            {subscription && subscription.status}
                          </td>
                          <td className="pmpro_account-membership-levelfee">
                            <p className="text-capitalize">
                              {subscription &&
                                `${
                                  subscription.plan.amount / 100
                                } ${subscription.plan.currency.toUpperCase()}/${
                                  subscription.plan.interval
                                }`}
                            </p>
                          </td>
                          <td className="pmpro_account-membership-expiration">
                            {subscription &&
                            subscription.status !== "canceled" ? (
                              <>
                                {subscription.cancel_at_period_end ? (
                                  <span className="text-danger">
                                    Cancels at{" "}
                                    {moment
                                      .unix(subscription.current_period_end)
                                      .format("DD MMMM YYYY")}
                                  </span>
                                ) : (
                                  moment
                                    .unix(subscription.current_period_end)
                                    .format("DD MMMM YYYY")
                                )}
                              </>
                            ) : subscription.ended_at ? (
                              <span className="text-danger">
                                {moment
                                  .unix(subscription.ended_at)
                                  .format("DD MMMM YYYY")}
                              </span>
                            ) : (
                              <span className="text-danger">
                                {moment
                                  .unix(subscription.current_period_end)
                                  .format("DD MMMM YYYY")}
                              </span>
                            )}
                          </td>
                          <td className="pmpro_account-membership-expiration">
                            {subscription ? (
                              subscription.status !== "canceled" ? (
                                subscription.cancel_at_period_end ? (
                                  <button
                                    className="btn p-2"
                                    type="button"
                                    onClick={() =>
                                      setActivatePlanModalIsOpen(true)
                                    }
                                  >
                                    Activate now
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn p-2"
                                    onClick={() =>
                                      setDeactivatePlanModalIsOpen(true)
                                    }
                                  >
                                    Cancel membership
                                  </button>
                                )
                              ) : (
                                <NavLink className="" to="/plans">
                                  Start New Membership
                                </NavLink>
                              )
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <PaymentMethod />
                <div className="bp-widget personal-info">
                  <h3 className="screen-heading profile-group-title">
                    My Account
                  </h3>
                  <table className="table table-bordered my-4">
                    <tbody>
                      <tr className="field_10 field_birthday field_order_1 optional-field visibility-public field_type_datebox">
                        <td className="label">
                          <p>
                            <b>Name: </b>
                            {getCurrentUser().name}
                          </p>
                          <p>
                            <b>Email: </b>
                            {getCurrentUser().email}
                          </p>
                          <p>
                            <NavLink
                              to={process.env.PUBLIC_URL + "/edit-profile"}
                            >
                              Edit Profile
                            </NavLink>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div
              className="w-100 text-center bg-light border border-1"
              style={{
                overflow: "auto",
                width: "100%",
              }}
            >
              <table
                className="table table-bordered mt-5"
                style={{
                  minWidth: "700px",
                }}
              >
                <thead className="t-head">
                  <tr>
                    <th scope="col">INVOICE #</th>
                    <th scope="col" width="175px">
                      DATE
                    </th>
                    <th scope="col" width="50px">
                      AMOUNT
                    </th>
                    <th scope="col">MEMBERSHIP</th>
                    <th scope="col">DOWNLOAD</th>
                  </tr>
                </thead>
                {invoice &&
                  invoice.map((item, i) => (
                    <tbody>
                      <tr key={i}>
                        <td
                          style={{
                            whiteSpace: "noWrap",
                          }}
                        >
                          <a
                            href={item.invoice_pdf}
                            target="-blank"
                            title="Download PDF"
                          >
                            #{item.number}
                          </a>
                        </td>
                        <td>
                          {moment.unix(item.created).format("DD MMMM YYYY")}
                        </td>
                        <td align="left">${item.total / 100}</td>
                        <td
                          align="left"
                          style={{
                            "word-wrap": "unset",
                          }}
                        >
                          {item?.lines?.data[0]?.description}
                        </td>
                        <td>
                          <a
                            href={item.invoice_pdf}
                            target="-blank"
                            title="Download PDF"
                          >
                            <i class="fa fa-file-pdf"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>
              {loader}
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <Modal isOpen={deactivatePlanModalIsOpen} style={changePlanStyles}>
        <i
          className="fa fa-window-close float-right"
          onClick={(e) => setDeactivatePlanModalIsOpen(false)}
          aria-hidden="true"
        ></i>
        <div className="plan-selection">
          <h2 className="text-center mb-5">We are sorry to see you go!</h2>
          <p>
            After cancellation, you will loose access from the portal. Are you
            sure you want to cancel your membership?
            <br />
            <br />
            If you have any feedback please write to us at{" "}
            <a href="mailto:info@soultribeonline.com">
              info@soultribeonline.com
            </a>
          </p>
          <div className="text-center">
            {loading ? (
              <button type="button" className="btn btn-danger" disabled>
                Loading
              </button>
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => cancelMembership()}
                  className="btn btn-danger btn-sm"
                >
                  {" "}
                  Proceed to the cancellation
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal isOpen={activatePlanModalIsOpen} style={changePlanStyles}>
        <i
          className="fa fa-window-close float-right"
          onClick={(e) => setActivatePlanModalIsOpen(false)}
          aria-hidden="true"
        ></i>
        <div className="plan-selection">
          <h2 className="text-center mb-5">Are you sure</h2>
          <div className="text-center">
            {loading ? (
              <button type="button" className="btn btn-success btn-sm" disabled>
                Loading
              </button>
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => activateMembership()}
                  className="btn btn-success btn-sm"
                >
                  {" "}
                  Proceed to the reactivate membership
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default MembershipAccount;
