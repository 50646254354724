import React, { useEffect, useState, useCallback} from "react";
import ContactForm from "../../components/contact-form";
import SingleThread from "../../components/message/single-threads";
import AllThread from "../../components/message/all-threads";
import Loader from "../../components/loader";
import { getCurrentUser } from "../../helpers/Utils";
import axios from "axios";
import Pusher from "pusher-js";
import debounce from 'lodash.debounce';
import io from "socket.io-client";
import { useSelector } from 'react-redux'
import { NavLink, useHistory } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

let sendTypingReq = true;
let clearTimerId;

const MessagesContainer = () => {

    const history = useHistory()

    const [thread, setThread] = useState();
    const [loading, setLoading] = useState(false);
    const [threadLoading, setThreadLoading] = useState(false);
    const [threadChat, setThreadChat] = useState("");
    const [threadChatHead, setThreadChatHead] = useState("");
    const [loader, setLoader] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [openUserId, setOpenUserId] = useState("");
    const [message, setMessage] = useState("");
    const [typing, setTyping] = useState("");
    const [typingData, setTypingData] = useState("");
    const [text, setText] = useState("");
    const [attachment, setAttachment] = useState(false);
    const [image, setImage] = useState("");
    const [video, setVideo] = useState("");
    const [error, setError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const threads = (e) => {
        var url = `${process.env.REACT_APP_API_URL}/messages`;
        if (e !== undefined) {
            e.preventDefault();
            if (e.target.value) {
                url = `${process.env.REACT_APP_API_URL}/connection-messages?search=${e.target.value}`;
            }
        } else {
            var url = `${process.env.REACT_APP_API_URL}/messages`;
        }
        axios
            .get(url, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setThreadLoading(false)
                if (res.data.data.length === 0) {
                    setThread([]);
                }
                if (res.data.success === true) {
                    setThread(res.data.data);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            });
    };
    const filter = (e) => {
        setThreadLoading(true)
        setTimeout(() => {
            threads(e);
        }, 2000);
    };

    const sendMessage = () => {
        setMessage("");
        setTypingData('')
        setLoading(true)
        const thread_id = localStorage.getItem("thread_id");
        // setLoading(true);
        var formData = new FormData();
        formData.append("thread_id", thread_id);
        formData.append("body", draftToHtml(convertToRaw(editorState.getCurrentContent())));
        formData.append("group_id", "");
        formData.append("image", image);
        formData.append("video", video);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/single-chat`,
                formData,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                document.getElementById("new-message-form").reset();
                setImage('');
                setVideo('');
                setEditorState(EditorState.createEmpty())
            });
    };

    const getSingleThread = (thread_id) => {
        if (thread_id) {
            setLoader(<Loader />);
            setThreadChat("");
            setThreadChatHead("");
            axios
            .get(
                `${process.env.REACT_APP_API_URL}/user-messages?thread_id=${thread_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.success === true) {
                    setCurrentPage(res.data.data.message.current_page);
                    setPagination(res.data.data.message.next_page_url);
                    setThreadChat(res.data.data.messages.data);
                    setThreadChatHead(res.data.data);
                }
            });
        }
    };


    const getSingleThreadNext = (page = currentPage) => {
        axios
        .get(
            `${process.env.REACT_APP_API_URL}/user-messages?thread_id=${localStorage.getItem("thread_id")}&page=${page}`,
            {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {
            setNextLoading(false)
            if (res.data.success === true) {
                setCurrentPage(res.data.data.message.current_page);
                setPagination(res.data.data.message.next_page_url);
                let nextData = res.data.data.messages.data;
                let setmultiple = [...nextData, ...threadChat];
                setThreadChat(setmultiple);
            }
        });
    };

    const nextPageChat = () => {
        setNextLoading(true);
        getSingleThreadNext(currentPage + 1)
    }

    const setForSingleThread = (e) => {
        localStorage.setItem("thread_id", e);
        getSingleThread(e);
        window.history.pushState("", "", `/messages/${e}`);
    };

    const myHandleReturn = (e) => {
        sendMessage();
    }

    const keyBindingFn = (event) => {
        if (event.keyCode === 13 && event.ctrlKey) {
        } else if (event.keyCode === 13) {
            sendMessage();
        }
    };

    const isTyping = (text) => {
        // Setting text to send in chat
        const thread_id = localStorage.getItem('thread_id');
        
        // If send typing request is true then send to server
        if (sendTypingReq && draftToHtml(convertToRaw(editorState.getCurrentContent())).length) {
            axios.get(
                `${process.env.REACT_APP_API_URL}/is-typing?thread_id=${thread_id}`,
                {
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${getCurrentUser().token}`
                    },
                }
            );
            sendTypingReq = false
            setTimeout(function() {
                sendTypingReq = true
            }, 500);
        }
    };

    const handleUserTyping = useCallback((type, data) => {
        if (type === 'is_typing' && data.senderId !== getCurrentUser().id) {
            setTypingData(<div className="loader">Loading...</div>)
            clearTimeout(clearTimerId)
            clearTimerId = setTimeout(function() {
                setTypingData('')
            }, 900);
        }
    }, []);

    const addAttachement = () => {
        if (attachment === true) {
            setAttachment(false);
        } else setAttachment(true);
    };

    if (attachment === true) {
        const concernedElement = document.querySelector(".attached");
        document.addEventListener("mousedown", (event) => {
            if (concernedElement.contains(event.target)) {
            } else {
                setAttachment(false);
            }
        });
    }

    const imageAttach = (e) => {
        setImage(e.target.files[0]);
        setAttachment(false);
    };

    const videoAttach = (e) => {
        setVideo(e.target.files[0]);
        setAttachment(false);
    };

    useEffect(() => {
        const threadID = history.location.pathname.replace(/\D/g,'')
        localStorage.setItem('thread_id', threadID)
        threads();
        getSingleThread(threadID);

        history.listen((location) => {
            getSingleThread(location.pathname.replace(/\D/g,''));
        })

        const socket = io(process.env.REACT_APP_SOCKET_URL);
        socket.on(localStorage.getItem('thread_id'), handleUserTyping);
        return () => {
            socket.off(localStorage.getItem('thread_id'), handleUserTyping);
        };

    }, [history]);

    return (
        <section className="contact-section">
            <div id="frame">
                <div id="sidepanel">
                    <div id="profile">
                        <div className="wrap">
                            <p>
                                <img
                                    id="profile-img"
                                    src={getCurrentUser().image}
                                    className="online"
                                    alt=""
                                />
                                {getCurrentUser().name}
                            </p>
                        </div>
                    </div>
                    <div id="search">
                        <label htmlFor="">
                            {threadLoading ? <div className="custom_loader"></div> : <i className="fa fa-search" aria-hidden="true"></i>}
                        </label>
                        <input
                            type="text"
                            placeholder="Search contacts..."
                            onChange={(e) => filter(e)}
                        />
                    </div>
                    <div className="text-center">{error}</div>
                    <div id="contacts">
                        <ul>
                            {thread &&
                            thread.map((item, i) => (
                                <AllThread
                                    data={item}
                                    threadId={item.id}
                                    i={i}
                                    setForSingleThread={setForSingleThread}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="content">
                    {loader}
                    {threadChat === "" ? (
                        ""
                    ) : (
                        <SingleThread data={threadChat} threadChatHead={threadChatHead} pagination={pagination} nextPageChat={nextPageChat} nextLoading={nextLoading} />
                    )}
                    {threadChat ? (
                        <div className="message-input">
                            <form
                                id="new-message-form"
                                onSubmit={(text) => sendMessage(text)}
                            >
                                <p
                                    style={{
                                        margin: "0",
                                        opacity: "0.7",
                                        marginLeft: "15px",
                                        fontSize: "13px",
                                        color: "#2b3d4fe6",
                                    }}
                                >
                                    {typingData}
                                </p>
                                <div className="wrap">
                                    <div className="attached">
                                        <div className="attached_files">
                                            {image.name ? (
                                                <span>{image.name}</span>
                                            ) : (
                                                ""
                                            )}{" "}
                                            &nbsp;
                                            {video.name ? (
                                                <span>{video.name}</span>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        {attachment ? (
                                            <ul>
                                                <li>
                                                    <input
                                                        accept="image/png, image/gif, image/jpeg"
                                                        onChange={imageAttach}
                                                        type="file"
                                                        className="d-none"
                                                        id="photoUpload"
                                                    />
                                                    <label htmlFor="photoUpload">
                                                        Photo
                                                    </label>
                                                </li>
                                                <li>
                                                    <input
                                                        accept="video/mp4,video/x-m4v,video/*"
                                                        onChange={videoAttach}
                                                        type="file"
                                                        className="d-none"
                                                        id="videoUpload"
                                                    />
                                                    <label htmlFor="videoUpload">
                                                        Video
                                                    </label>
                                                </li>
                                            </ul>
                                        ) : (
                                            ""
                                        )}
                                        <button
                                            onClick={addAttachement}
                                            type="button"
                                            className="add_attachment"
                                        >
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                    <div className="px-2 w-100">
                                        <div style={{
                                            background: '#fff',
                                            borderRadius: '20px',
                                        }}>
                                            <Editor
                                                editorState={editorState}
                                                onEditorStateChange={setEditorState}
                                                toolbar={{
                                                  options: ['inline', 'emoji'],
                                                  inline: {
                                                    options: ['bold', 'italic', 'underline']
                                                  }
                                                }}
                                                onChange={(text) => isTyping(text)}
                                                // handleReturn={() => myHandleReturn()}
                                                keyBindingFn={(e) => keyBindingFn(e)}
                                            />
                                        </div>
                                    </div>
                                    {/*<InputEmoji
                                            value={text}
                                            onChange={(text) => isTyping(text)}
                                            cleanOnEnter
                                            onEnter={sendMessage}
                                            placeholder="Type a message"
                                        />*/}
                                    {loading ? (
                                        <button
                                            className="submit send_btn"
                                            type="button"
                                        >
                                            <i className="fa fa-spinner"></i>
                                        </button>
                                    ) : (
                                        <button
                                            className="submit send_btn"
                                            type="button"
                                            onClick={sendMessage}
                                        >
                                            <i
                                                className="fa fa-paper-plane"
                                                aria-hidden="true"
                                            ></i>
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </section>
    );
};

export default MessagesContainer;
