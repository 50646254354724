import React, { useRef, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import LoginForm from '../../components/login'
import { getCurrentUser } from '../../helpers/Utils'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import axios from 'axios'
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/loader";

const AffiliatesSection = () => {
    const [error, setError] = useState('')
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(<Loader />)
    const [copySuccess, setCopySuccess] = useState('Copy')
    const [currentPage, setCurrentPage] = useState('');
    const [pagination, setPagination] = useState('');

    const textAreaRef = useRef(null)
    const user_id = `https://soultribeonline.com/registration?ref=${getCurrentUser().id}`

    const getAffiliate = () => {
        axios
        .get(
            `${process.env.REACT_APP_API_URL}/member/affiliates/referrals`,
            {
                headers: {
                    'Content-type': 'multipart/form-data',
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            },
        )
        .then(function (res) {
            setLoader(false)
            if (res.data.success) {
                setData(res.data.data.data)
                setCurrentPage(res.data.data.current_page);
                setPagination(res.data.data.next_page_url);
            } else {
                setError(res.data.message)
            }
        })
    }

    const getAffiliateNext = (page = currentPage) => {
        setPagination('')
        setLoader(<Loader />)
        axios
        .get(
            `${process.env.REACT_APP_API_URL}/member/affiliates/referrals?page=${page}`,
            {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {
            setLoader('')
            if (res.data.success === true) {
                setCurrentPage(res.data.data.current_page);
                setPagination(res.data.data.next_page_url);
                let nextData = res.data.data.data;
                let setmultiple = [...data, ...nextData];
                setData(setmultiple);
            }
        });
    };


    const nextLink = () => {
        setPagination('')
        getAffiliateNext(currentPage + 1);
    };


    function copyToClipboard(e) {
        textAreaRef.current.select()
        document.execCommand('copy')
        e.target.focus()
        setCopySuccess('Copied!')
        
        setTimeout(function(){
            setCopySuccess('Copy')
        }.bind(this),3000);
    }
    useEffect(() => {
        getAffiliate()
    }, [])
    return (
        <section className="contact-section section-py mt-5">
            <div className="container">
                <div className="text-right mb-5">
                    <p>Total Earning: <b>${getCurrentUser().referral_amount}</b></p>
                </div>
                <Tabs className="tribe_tabs">
                    <TabList>
                        <Tab>Affiliate URLs</Tab>
                        <Tab>Referrals</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="profile public">
                            <div className="bp-widget base affiliate">
                                <h3 className="screen-heading profile-group-title">
                                Affiliate URLs
                                </h3>
                                {/* <p>Your affiliate ID is: {getCurrentUser().id}</p> */}
                                <p>Your referral URL is:</p>
                                <div className="affiliate_share">
                                    <input className="affiliate_share-url" readOnly ref={textAreaRef} style={{width:"100%"}}
                                        value={user_id}/>
                                    {document.queryCommandSupported('copy') && (
                                    <>
                                        <button
                                            className="btn btn-primary affiliate_share-copy"
                                            onClick={copyToClipboard}
                                        >
                                            {copySuccess}
                                        </button>
                                    </>
                                    )}
                                </div>
                            </div>                              
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="profile public">    
                            <div className="bp-widget base">
                                <h3 className="screen-heading profile-group-title">Referrals</h3>
                                <div className="table-responsive">
                                    <InfiniteScroll
                                        dataLength={data.length}
                                        next={pagination !== null ? nextLink : ''}
                                        hasMore={pagination !== null ? true : false}
                                    >
                                        <table className="table table-bordered my-4">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Name</th>
                                                    <th className="text-center">Amount</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, i) => (
                                                    <tr key={i}>
                                                        <td className="pmpro_account-membership-levelname text-center">
                                                            {item.user ? item.user.name : '--'}
                                                        </td>
                                                        <td className="pmpro_account-membership-levelname text-center">
                                                            ${item.amount}
                                                        </td>
                                                        <td className="pmpro_account-membership-levelname">
                                                            Subscription payment for Premium {item.description}
                                                        </td>
                                                        <td className="pmpro_account-membership-levelname">
                                                            {item.created_at}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </InfiniteScroll>
                                    {loader}
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </section>
    )
}
export default AffiliatesSection