import React, { useState, useEffect } from "react";
import { setCurrentUser, getCurrentUser } from "../../helpers/Utils";
import axios from "axios";
import {
  convertFromHTML,
  ContentState,
  EditorState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import MaskInput from "react-maskinput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../components/loader";

let deleteCardId;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "845px",
    width: "100%",
  },
};

function PaymentMethod() {
  const [cardLoader, setCardLoader] = useState(false);
  const [defaultCard, setDefaultCard] = useState("");
  const [card, setCard] = useState("");
  const [newCardModal, setNewCardModal] = useState(false);
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState("");
  const [sure, setSure] = useState(false);

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const addNewCard = (e) => {
    e.preventDefault();
    setLoading(true);
    setLoader(<Loader />);
    var cardDetails = {
      "card[number]": e.target.card_number.value,
      "card[exp_year]": e.target.exp_date.value.split("/")[1],
      "card[exp_month]": e.target.exp_date.value.split("/")[0],
      "card[cvc]": e.target.cvc.value,
      "card[name]": e.target.name.value,
    };
    var formBody = [];
    for (var property in cardDetails) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(cardDetails[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    axios
      .post(`${process.env.REACT_APP_API_URL}/stripe/tokens`, formBody, {
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        // },
      })
      .then(function (res) {
        var cardToken = {
          source: res.data.id,
        };
        var formBodyToken = [];
        for (var property in cardToken) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(cardToken[property]);
          formBodyToken.push(encodedKey + "=" + encodedValue);
        }
        formBodyToken = formBodyToken.join("&");
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/stripe/customers/${
              getCurrentUser().stripe_customer_id
            }/sources`,
            formBodyToken
            // {
            //   headers: {
            //     "Content-Type": "application/x-www-form-urlencoded",
            //     Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
            //   },
            // }
          )
          .then(function (res) {
            getCustomerFromStripe();
            setLoading(false);
            setLoader("");
            setNewCardModal(false);
          })
          .catch(function (err) {
            toast.error(err.response.data.error.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
            setLoader("");
          });
      })
      .catch(function (err) {
        toast.error(err.response.data.error.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
        setLoader("");
      });
  };

  const deleteCard = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/stripe/customers/${
          getCurrentUser().stripe_customer_id
        }/sources/${deleteCardId}`
        // {
        //   headers: {
        //     "content-type": "application/json",
        //     Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        //   },
        // }
      )
      .then(function (res) {
        setSure(false);
        getCustomerFromStripe();
      });
  };

  const getPaymentMethod = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/stripe/customers/${
          getCurrentUser().stripe_customer_id
        }/sources`
        // {
        //   headers: {
        //     "content-type": "application/json",
        //     Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        //   },
        // }
      )
      .then(function (res) {
        setCard(res.data.data);
      });
  };

  const getCustomerFromStripe = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/stripe/customers/${
          getCurrentUser().stripe_customer_id
        }`
        // {
        //   headers: {
        //     "content-type": "application/json",
        //     Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        //   },
        // }
      )
      .then(function (res) {
        setDefaultCard(res.data.default_source);
        getPaymentMethod();
      });
  };

  const updateDefaultCard = (cardId) => {
    setCardLoader(true);
    var cardDetails = {
      default_source: cardId,
    };
    var formBody = [];
    for (var property in cardDetails) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(cardDetails[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/stripe/customers/${
          getCurrentUser().stripe_customer_id
        }`,
        formBody
        // {
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/x-www-form-urlencoded",
        //     Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        //   },
        // }
      )
      .then(function (res) {
        setDefaultCard(res.data.default_source);
        setCardLoader(false);
      });
  };

  const defaultCardSet = (e) => {
    deleteCardId = e;
    setSure(true);
  };

  useEffect(() => {
    getCustomerFromStripe();
  }, []);

  return (
    <>
      <div className="bp-widget base mb-3">
        <div className="profile-group-title mt-3 pb-0">
          <h3 className="screen-heading float-left">
            Card Details{" "}
            {cardLoader ? (
              <div className="small_loader">
                <Loader />
              </div>
            ) : (
              ""
            )}
          </h3>
          <button
            className="float-right btn btn-primary btn-sm"
            onClick={() => setNewCardModal(true)}
            style={{
              position: "relative",
              top: "-5px",
            }}
          >
            Add new card
          </button>
          <div
            style={{
              clear: "both",
            }}
          />
        </div>
        <div className="row my-4">
          {card &&
            card.map((item, i) => (
              <div className="col-md-3 mb-3" key={i}>
                <div
                  className={`border py-2 px-3 position-relative ${
                    defaultCard === item.id ? "border-primary" : ""
                  }`}
                >
                  {defaultCard === item.id ? (
                    <span className="primaryaccount">Primary</span>
                  ) : (
                    ""
                  )}
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="card-info">
                      <h6 className="m-0">.... .... .... {item.last4}</h6>
                      <small>
                        Expires {item.exp_month}/{item.exp_year}
                      </small>
                    </div>
                    <div className="card-edit">
                      {defaultCard === item.id ? (
                        <button
                          title="Set as primary"
                          onClick={() => updateDefaultCard(item.id)}
                          className="btn btn-outline-primary p-1 rounded mx-1 border-dark bg-primary text-white"
                        >
                          <i className="fa fa-check" />
                        </button>
                      ) : (
                        <button
                          onClick={() => updateDefaultCard(item.id)}
                          title="Set as primary"
                          className="btn btn-outline-primary p-1 rounded mx-1 border-dark bg-transparent text-primary"
                        >
                          <i className="fa fa-check" />
                        </button>
                      )}
                      <button
                        title="Delete this card"
                        onClick={() => defaultCardSet(item.id)}
                        className="btn btn-outline-primary bg-transparent p-1 border-danger rounded"
                      >
                        <i className="fa fa-trash text-danger" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Modal isOpen={newCardModal} style={customStyles} ariaHideApp={false}>
        <i
          className="fa fa-window-close float-right"
          onClick={(e) => setNewCardModal(false)}
          aria-hidden="true"
        ></i>
        <div id="PaymentForm">
          {loader}
          <h4>Set up your credit or debit card</h4>
          <div className="row">
            <div className="col-md-5">
              <Cards
                cvc={cvc}
                expiry={expiry}
                focused={focus}
                name={name}
                number={number}
                className="m-0"
              />
              <div className="card_demo">
                <img
                  style={{
                    marginRight: "5px",
                  }}
                  src={`${process.env.PUBLIC_URL}/images/1.png`}
                />
                <img
                  style={{
                    marginRight: "5px",
                  }}
                  src={`${process.env.PUBLIC_URL}/images/2.png`}
                />
                <img
                  style={{
                    marginRight: "5px",
                  }}
                  src={`${process.env.PUBLIC_URL}/images/3.png`}
                />
                <img
                  style={{
                    marginRight: "5px",
                  }}
                  src={`${process.env.PUBLIC_URL}/images/4.png`}
                />
              </div>
            </div>
            <div className="col-md-7">
              <form className="w-100" onSubmit={(e) => addNewCard(e)}>
                <div className="row">
                  <div className="col-12">
                    <MaskInput
                      autofocus="true"
                      alwaysShowMask
                      maskChar="_"
                      mask="0000 0000 0000 0000"
                      size={16}
                      name="card_number"
                      placeholder="Card Number"
                      onChange={(e) => setNumber(e.target.value)}
                      className="form-control mb-3"
                      onFocus={(e) => handleInputFocus(e)}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      name="name"
                      placeholder="Card Holder Name"
                      onChange={(e) => setName(e.target.value)}
                      className="form-control mb-3"
                      onFocus={(e) => handleInputFocus(e)}
                      maxLength="24"
                    />
                  </div>
                  <div className="col-8">
                    <MaskInput
                      alwaysShowMask
                      maskChar="_"
                      mask="00/00"
                      size={4}
                      name="exp_date"
                      placeholder="Card Number"
                      onChange={(e) => setExpiry(e.target.value)}
                      className="form-control mb-3"
                      onFocus={(e) => handleInputFocus(e)}
                    />
                  </div>
                  <div className="col-4">
                    <input
                      type="text"
                      name="cvc"
                      placeholder="CVV"
                      onChange={(e) => setCvc(e.target.value)}
                      className="form-control mb-3"
                      onFocus={(e) => handleInputFocus(e)}
                      maxLength="3"
                    />
                  </div>
                  <div className="col-12 text-right">
                    {number ? (
                      <>
                        {loading ? (
                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled
                          >
                            Loading...
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-primary">
                            Proceed to pay
                          </button>
                        )}
                      </>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled
                      >
                        Proceed to pay
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      {sure && (
        <SweetAlert
          showCancel
          confirmBtnText="Yes, remove it!"
          confirmBtnBsStyle="btn btn-primary"
          title="Are you sure?"
          btnSize="sm"
          onConfirm={() => deleteCard()}
          onCancel={() => setSure(false)}
        ></SweetAlert>
      )}
    </>
  );
}
export default PaymentMethod;
