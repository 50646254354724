import React, { useState, useEffect } from "react";
import { getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NavLink } from "react-router-dom";
import Modal from "react-modal";

const customStyles = {
    content: {
        position: "unset",
        maxWidth: "calc(100% - 50px)",
        width: "100%",
        height: "calc(100% - 50px)",
        right: "auto",
        border: "none",
        bottom: "auto",
        padding: "0 11px",
        margin: "0 auto",
    },
};


let tribeDocumentId = ''

const TribeDocument = (props) => {
	const [tribeDocument, setTribeDocument] = useState(props.tribeDocuments);
	const [selectedPhoto, setSelectedPhoto] = useState(false);
	const [loading, setLoading] = useState(false)
	const [sure, setSure] = useState(false);
	const [pdfView, setPdfView] = useState(false);
	// const [tribeDocumentId, settribeDocumentId] = useState([]);

	const downloadfile = (fileDownload) => {
		window.open(fileDownload);
	};
	
	const deleteTribeDocument = (e) => {
	setSure(false);
    axios
        .post(
            `${process.env.REACT_APP_API_URL}/tribe/document/delete`,
            {
                document_ids: [props.tribeDocuments.id],
            },
            {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {
        	setTribeDocument('');
        	toast.success(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    };

    if (tribeDocument !== '') {
		return (
			<>
				<Modal ariaHideApp={false} isOpen={pdfView} style={customStyles} contentLabel="Add Tribe Photo">
	                <div className="modal-header px-0">
	                    <h5>{tribeDocument.name}</h5>
	                    <i onClick={() => setPdfView(false)} className="fa fa-times" />
	                </div>
					<iframe width="100%" src={tribeDocument.document} style={{height: 'calc(100% - 65px)'}}></iframe>
	            </Modal>
				<tr key={tribeDocument.id}>
		            <td>
		                <button onClick={() => setPdfView(true)} type="button" className="d-flex text-dark">
		                    <i style={{
		                    	position: 'relative',
		                    	top: '3px'
		                    }} className="text-dark fa fa-file"></i> &nbsp;
		                    <div className="document-name">
		                    {tribeDocument.name}
		                    </div>
		                </button>
		            </td>
		            <td>{tribeDocument.modified_date}</td>
		            <td className="text-capitalize">
		            	Group Members
		            </td>
		            <td className="text-right">
		                <i
		                    onClick={(e) =>
		                        downloadfile(tribeDocument.document)
		                    }
		                    className="fa fa-eye"
		                ></i>
		                &nbsp; &nbsp; 
		                {loading ? (
		            		<i className="fa fa-spinner"></i>
		            	) : (
		            		<i
		            			onClick={() => setSure(true)}
			                    className="fa fa-trash"
			                ></i>
		            	)}
		            </td>
		        </tr>
		        {sure && <SweetAlert
	                showCancel
	                confirmBtnText="Yes, delete it!"
	                confirmBtnBsStyle="btn btn-primary"
	                title="Are you sure?"
	                btnSize="sm"
	                onConfirm={() => deleteTribeDocument(tribeDocument.id)}
	                onCancel={() => setSure(false)}
	            >
	            </SweetAlert>}
        	</>
		);
	} else {
		return "";
	}
};

export default TribeDocument;
