import React, { Component } from "react";
import axios from "axios";
import { getCurrentUser } from "../../helpers/Utils";
import {
    convertFromHTML,
    ContentState,
    EditorState,
    convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

var moment = require("moment");
var categories_arr = [];

var length_arr = [];
var level_arr = [];

class EditClassForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            video_embed_code: "",
            type: "",
            teacher_id: "",
            category_id: [],
            level_selected: [],
            checked_items: [],
            vimeoId: "",
            image: "",
            imagePreview: "",
            checked_length: [],
            catgoryChecked: false,
            checked_catgory: [],
            status: "",
            time_zome: "",
            submitButton: false,
        };

        this.onCreateClass = this.onCreateClass.bind(this);
        this.state = { isChecked: false };
        this.handleCategoryCheckbox = this.handleCategoryCheckbox.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }
    // // //Check sate change on every trigger
    componentDidMount() {
        var html = this.props.data.description
            ? this.props.data.description
            : "<p></p>";
        var blocksFromHTML = convertFromHTML(html);
        var content = ContentState.createFromBlockArray(blocksFromHTML);
        this.setState({
            title: this.props.data.title && this.props.data.title,
            image: "",
            imagePreview: this.props.data.image && this.props.data.image,
            vimeoId: this.props.data.vimeoId && this.props.data.vimeoId,
            about: EditorState.createWithContent(content),
        });
    }
    componentWillMount() {
        if (categories_arr.length !== undefined) {
            this.setState({
                checked_catgory: categories_arr,
            });
        }
        if (length_arr.length !== undefined) {
            this.setState({
                checked_length: length_arr,
            });
        }
        this.setState({
            status: this.props.data.status,
        });
        let date = this.props.data.publish_date;
        let frmt_dte = moment(date).format("YYYY-MM-DD");
        this.setState({ publish_date: frmt_dte });
        this.setState({ video_embed_code: this.props.data.video_embed_code });
        this.setState({ description: this.props.data.description });
        this.setState({
            membership_viewability: this.props.data.membership_viewability,
        });
    }

    handleCategoryCheckbox(e) {
        const new_value = parseInt(e.target.value);
        if (e.target.checked) {
            categories_arr = categories_arr.concat(new_value);
        } else {
            const index = categories_arr.indexOf(new_value);
            if (index > -1) {
                categories_arr.splice(index, 1);
            }
        }
    }
    //Handel length checkbox
    handleLengthCheckbox(e) {
        const new_value = parseInt(e.target.value);
        if (e.target.checked) {
            length_arr = length_arr.concat(new_value);
        } else {
            const index = length_arr.indexOf(new_value);
            if (index > -1) {
                length_arr.splice(index, 1);
            }
        }
    }

    //Level condition for every check
    handleLevelCheckbox(e) {
        const new_value = parseInt(e.target.value);

        level_arr = [];
        level_arr = level_arr.concat(new_value);
    }

    onCreateClass = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        var cate = categories_arr.toString();
        var level = level_arr.toString();
        var length = length_arr.toString();
        var self = this;

        var formData = new FormData();
        formData.append("class_id", this.props.data.id);
        formData.append("title", this.state.title);
        formData.append("vimeo_id", this.state.vimeoId);
        formData.append("image", this.state.image);
        formData.append(
            "description",
            draftToHtml(convertToRaw(this.state.about.getCurrentContent()))
        );
        formData.append("category_id", cate);
        formData.append("level_id", level);
        formData.append("length_id", length);

        axios
            .post(`${process.env.REACT_APP_API_URL}/update-class`, formData, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.success === false) {
                    self.setState({
                        error: res.data.message,
                        success: "",
                        isLoading: false,
                    });
                } else {
                    self.setState({
                        error: "",
                        success: res.data.message,
                        isLoading: false,
                    });
                }
            });
    };

    onEditorStateChange = (about) => {
        this.setState({
            about: about,
        });
    };

    render() {
        const { error, success, isLoading } = this.state;
        let errorMessage = "";
        let successMessage = "";
        let button = (
            <button
                id="contactSubmit"
                type="submit"
                className="btn btn-dark btn-hover-dark"
            >
                Update
            </button>
        );
        if (error) {
            errorMessage = (
                <span className="alert p-2 py-1 alert-danger d-inline-block">
                    {error}
                </span>
            );
        }
        if (success) {
            successMessage = (
                <span className="alert p-2 py-1 alert-success d-inline-block">
                    {success}
                </span>
            );
        }
        if (isLoading === true) {
            button = (
                <button
                    id="contactSubmit"
                    className="btn btn-dark btn-hover-dark"
                    disabled
                >
                    Loading...
                </button>
            );
        }

        //Check for checked items
        let cateData = "";

        if (this.props.utilities.categories !== undefined) {
            if (this.props.data.categories !== undefined) {
                if (categories_arr.length === 0) {
                    this.props.data.categories &&
                        this.props.data.categories.map((item, i) =>
                            categories_arr.push(item.id)
                        );
                }
            }
            cateData = (
                <>
                    {this.props.utilities.categories &&
                        this.props.utilities.categories.map((item, i) => (
                            <div className="col-4" key={i}>
                                <label
                                    key={i}
                                    className="pr-3 m-0"
                                    htmlFor={item.id}
                                >
                                    <input
                                        type="checkbox"
                                        name="category_id"
                                        className="m-1"
                                        value={item.id}
                                        id={item.id}
                                        defaultChecked={this.state.checked_catgory.includes(
                                            item.id
                                        )}
                                        onChange={(e) =>
                                            this.handleCategoryCheckbox(e)
                                        }
                                    />
                                    {item.name}
                                </label>
                            </div>
                        ))}
                </>
            );
        }
        let lenghtData = "";
        if (this.props.utilities.lengths !== undefined) {
            if (this.props.data.length !== undefined) {
                if (length_arr.length === 0) {
                    this.props.data.length &&
                        this.props.data.length.map((length, i) =>
                            length_arr.push(length.id)
                        );
                }
            }
            //Check the checkd length
            lenghtData = (
                <>
                    {this.props.utilities.lengths &&
                        this.props.utilities.lengths.map((item, i) => (
                            <div className="col-4">
                                <label
                                    key={i}
                                    className="pr-3 m-0"
                                    htmlFor={item.id}
                                >
                                    <input
                                        type="checkbox"
                                        name="length_id"
                                        value={item.id}
                                        id={item.id}
                                        defaultChecked={this.state.checked_length.includes(
                                            item.id
                                        )}
                                        onChange={(e) =>
                                            this.handleLengthCheckbox(e)
                                        }
                                    />{" "}
                                    {item.name}
                                </label>
                            </div>
                        ))}
                </>
            );
        }

        //Check for selected level
        let levelData = "";

        if (this.props.utilities.levels !== undefined) {
            if (this.props.data.levels !== undefined) {
                if (level_arr.length === 0) {
                    this.props.data.levels &&
                        this.props.data.levels.map((item, i) =>
                            level_arr.push(item.id)
                        );
                }
            }

            levelData = (
                <>
                    {this.props.utilities.levels &&
                        this.props.utilities.levels.map((item, i) => (
                            <option
                                value={item.id}
                                defaultSelected={level_arr.includes(item.id)}
                            >
                                {item.name}
                            </option>
                        ))}
                </>
            );
        }

        const handleChangeStatus = ({ meta, file, xhr }, status) => {
            if (status === "done") {
                let response = JSON.parse(xhr.response);
                this.setState({
                    vimeoId: response.data,
                    submitButton: true,
                });
            }
        };

        const getUploadParams = ({ meta }) => ({
            url: `${process.env.REACT_APP_API_URL}/upload-video-to-vimeo`,
        });

        const handleSubmit = (files, allFiles) => {
            allFiles.forEach((f) => f.remove());
        };

        const changeImg = (e) => {
            this.setState({
                image: e.target.files[0],
                imagePreview: URL.createObjectURL(e.target.files[0]),
            });
        };

        return (
            <section className="container section-py">
                <form
                    onSubmit={this.onCreateClass}
                    id="contactForm"
                    className="row"
                >
                    <div className="col-12">
                        <div className="col-12 mb-7">
                            Title
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="title"
                                defaultValue={this.state.title}
                                onChange={(e) =>
                                    this.setState({ title: e.target.value })
                                }
                            />
                        </div>

                        <input
                            type="hidden"
                            className="form-control"
                            id="type"
                            name="type"
                            placeholder="Type"
                            defaultValue={this.state.title}
                            onChange={(e) =>
                                this.setState({ type: e.target.value })
                            }
                        />

                        <div className="col-12 mb-7">
                            <div className="row">
                                <div className="col-sm-2 border py-2">
                                    <iframe
                                        src={this.state.video_embed_code}
                                        width="100%"
                                        height="120"
                                        allowFullScreen
                                        title={this.state.title}
                                    />
                                </div>
                                <div className="col-sm-10">
                                    Video Embed Code
                                    <Dropzone
                                        getUploadParams={getUploadParams}
                                        onChangeStatus={handleChangeStatus}
                                        onSubmit={handleSubmit}
                                        // PreviewComponent={Preview}
                                        inputContent="Drag Video or Click to Browse Video"
                                        multiple={false}
                                        accept="video/*"
                                        disabled={(files) =>
                                            files.some((f) =>
                                                [
                                                    "preparing",
                                                    "getting_upload_params",
                                                    "uploading",
                                                ].includes(f.meta.status)
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-7">
                            <div className="row">
                                <div className="col-sm-2 border py-2">
                                    <img
                                        alt="ss"
                                        src={this.state.imagePreview}
                                    />
                                </div>
                                <div className="col-sm-10">
                                    Image
                                    <input
                                        type="file"
                                        accept="image/*"
                                        className="form-control"
                                        id="image"
                                        name="image"
                                        onChange={(e) => changeImg(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-7">
                            Description
                            <div className="border bg-light">
                                <Editor
                                    editorState={this.state.about}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={
                                        this.onEditorStateChange
                                    }
                                    toolbar={{
                                        options: ["inline", "list"],
                                        inline: {
                                            options: [
                                                "bold",
                                                "italic",
                                                "underline",
                                                "strikethrough",
                                            ],
                                        },
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-12 mb-7">
                            Categories
                            {cateData}
                        </div>

                        <div className="col-12 mb-7">
                            Length
                            {lenghtData}
                        </div>

                        <div className="col-12 mb-7">
                            Level
                            <select
                                name="level_id"
                                className="form-control"
                                onChange={(e) => this.handleLevelCheckbox(e)}
                            >
                                {levelData}
                            </select>
                        </div>
                    </div>

                    <div className="col-12">{button}</div>
                    <div className="col-12 mt-4">
                        {errorMessage}
                        {successMessage}
                    </div>
                </form>
            </section>
        );
    }
}

export default EditClassForm;
