import React, { useState, useEffect } from "react";
import { getCurrentUser } from "../../helpers/Utils";
import axios from "axios";
import { convertFromHTML, ContentState, EditorState, convertToRaw } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";  
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SweetAlert from "react-bootstrap-sweetalert";
import Modal from "react-modal";

const customStyles = {
    content: {
        position: "unset",
        maxWidth: "550px",
        width: "100%",
        right: "auto",
        border: "none",
        bottom: "auto",
        padding: "0",
        margin: "30px auto",
    },
};

function SingleMessage(props) {
    const [data, setData] = useState(props.item);
    const [sure, setSure] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [singleMessage, setSingleMessage] = useState(props.item.body);
    const html = props.item.body
    const blocksFromHTML = convertFromHTML(html)
    const content = ContentState.createFromBlockArray(blocksFromHTML)
    
    const [message, setMessage] = useState(EditorState.createWithContent(content))

    const deleteMessage = () => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/delete-message`,
                {
                    message_id: data.id,
                    thread_id: localStorage.getItem("thread_id"),
                    user_id: getCurrentUser().id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${
                            getCurrentUser() && getCurrentUser().token
                        }`,
                    },
                }
            )
            .then(function (res) {
                setData("");
                toast.success(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    const updateMessage = () => {
        setLoading(true)
        axios
        .post(
            `${process.env.REACT_APP_API_URL}/message/edit`,
            {
                id: data.id,
                thread_id: localStorage.getItem("thread_id"),
                body: draftToHtml(convertToRaw(message.getCurrentContent())),
            },
            {
                headers: {
                    Authorization: `Bearer ${
                        getCurrentUser() && getCurrentUser().token
                    }`,
                },
            }
        )
        .then(function (res) {
            setLoading(false)
            setSingleMessage(res.data.data.body)
            setModalIsOpen(false)
            toast.success(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    const onEditorStateChange = (message) => {
        setMessage(message)
    };

    useEffect(() => {
        function replaceURLs(message) {
            if (!message) return;

            var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
            return message.replace(urlRegex, function (url) {
                var hyperlink = url.replace("</p>", "");
                if (!hyperlink.match("^https?://")) {
                    hyperlink = "http://" + hyperlink;
                }
                return (
                    '<a href="' +
                    hyperlink +
                    '" target="_blank" rel="noopener noreferrer">' +
                    url +
                    "</a>"
                );
            });
        }
        // setSingleMessage(replaceURLs(props.item.body));
    }, []);

    if (data !== "") {
        return (
            <>
                {sure && (
                    <SweetAlert
                        showCancel
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="btn btn-primary"
                        title="Are you sure?"
                        btnSize="sm"
                        onConfirm={() => deleteMessage()}
                        onCancel={() => setSure(false)}
                    ></SweetAlert>
                )}
                <li
                    className={
                        data.message_user &&
                        data.message_user.id !== getCurrentUser().id
                            ? "sent"
                            : "replies"
                    }
                >
                    <img
                        className="thumbnail"
                        src={data.message_user && data.message_user.image}
                        alt=""
                    />
                    <div className="message_box">
                        <h6 className="user_name">
                            {data.message_user && data.message_user.name}
                        </h6>
                        {data.video ? (
                            <iframe
                                title="Attached video"
                                style={{
                                    display: "block",
                                }}
                                className="w-100"
                                src={data.video}
                                allowFullScreen
                            />
                        ) : (
                            ""
                        )}
                        {data.image ? (
                            <img
                                alt="ss"
                                style={{
                                    display: "block",
                                }}
                                className="w-100"
                                src={data.image}
                            />
                        ) : (
                            ""
                        )}
                        <p>
                            <p
                                className="m-0 p-0 chat-message"
                                dangerouslySetInnerHTML={{
                                    __html: singleMessage,
                                }}
                            />
                            <span>{data.formatted_created_time}</span>
                        </p>
                        {data.message_user.id === getCurrentUser().id ? (
                            <>
                                <button
                                    className="delete-message"
                                    type="button"
                                    title="Delete"
                                    onClick={() => setSure(true)}
                                >
                                    <i className="fa fa-trash"></i>
                                </button>
                                <button
                                    className="delete-message edit"
                                    type="button"
                                    title="Edit"
                                    onClick={() => setModalIsOpen(true)}
                                >
                                    <i className="fa fa-pencil"></i>
                                </button>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    <Modal
                        ariaHideApp={false}
                        isOpen={modalIsOpen}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="modal-header pt-1 pb-0">
                            <h5 className="text-center w-100 d-inline-block">Update message</h5>
                            <button
                                onClick={() => setModalIsOpen(false)}
                                className="close p-0"
                            >
                                <span
                                    style={{
                                        fontSize: "28px",
                                    }}
                                    aria-hidden="true"
                                >
                                    ×
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="border bg-light">
                                <Editor
                                    editorState={message}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={onEditorStateChange}
                                    toolbar={{
                                        options: ['inline', 'list'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline', 'strikethrough']
                                        }
                                    }}
                                />
                            </div>
                            <div className="text-center mt-3">
                                {loading ? <button className="btn btn-primary btn-sm" type="button">Loading</button> : <button className="btn btn-primary btn-sm" onClick={() => updateMessage()}>Update</button>}
                            </div>
                        </div>
                    </Modal>
                </li>
            </>
        );
    } else {
        return <></>;
    }
}
export default SingleMessage;
