import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import BlogGrid from "../../../components/blog";
import Loader from "../../../components/loader";
import axios from "axios";

const BlogItemContainer = () => {
    const [loader, setLoader] = useState(<Loader />)
    const [data, setData] = useState([])

    const callApi = () => {
        axios
        .get(
            `${process.env.REACT_APP_API_URL}/blogs`
        )
        .then(function (res) {
            setLoader('')
            setData(res.data.data)
        });
    }

    useEffect(() => {
        callApi();
    }, [])

    return (
        <section className="blog-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    {loader}
                    {data &&
                        data.map((classes, key) => {
                            return (
                                <div
                                    key={key}
                                    className="col-lg-4 col-6 mb-7"
                                >
                                    <BlogGrid
                                        classOption="p-0"
                                        key={key}
                                        data={classes}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </section>
    );
};

BlogItemContainer.propTypes = {
    data: PropTypes.array,
};

export default BlogItemContainer;
