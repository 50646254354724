import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";

const Logo = ({ image, classOption }) => {
    const location = useLocation();

    return (
        <>
            {location.pathname !== "/join-for-free" ? (
                <Link
                    className={`${classOption}`}
                    to={process.env.PUBLIC_URL + "/"}
                >
                    <img
                        className="logo-main"
                        src={process.env.PUBLIC_URL + image}
                        alt="Logo"
                    />
                </Link>
            ) : (
                <img
                    className="logo-main"
                    src={process.env.PUBLIC_URL + image}
                    alt="Logo"
                />
            )}
        </>
    );
};

Logo.propTypes = {
    image: PropTypes.string,
    classOption: PropTypes.string,
};

Logo.defaultProps = {
    classOption: "text-center",
};

export default Logo;
