import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import EditProfileTabs from "../components/edit-profile/Form";
import PageLoader from '../components/page-loader'

function EditProfile() {
	// const [user, setUser] = useState([])

	return (
		<React.Fragment>
        <PageLoader />
            <Layout>
                <SEO title="Soul Tribe Online Community – Update Profile" />
                <div className="wrapper">
                    <Header />
                    <PageBanner title="Update Profile" />
                    <EditProfileTabs />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
	)
}
export default EditProfile;
