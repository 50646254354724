import React from "react";
import { Fragment, useEffect, useState, useCallback } from "react";
import Logo from "../../components/logo";
import MainMenu from "../../components/menu/main-menu";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import MobileMenu from "../../components/menu/mobile-menu";
import { setCurrentUser, getCurrentUser } from "../../helpers/Utils";
import axios from "axios";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";

//Funtion for getting the router params
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

let unreadMessagesCount = 0;
let unreadNotificationCount = 0;

const Header = () => {
  let query = useQuery();
  const [token] = useState(query.get("token"));
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const [error, setError] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [noMessage, setNoMessage] = useState("");
  const [thread, setThread] = useState([]);
  const [isTeacher, setisTeacher] = useState("");
  const [subscriptionError, setSubscriptionError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  let user = "";
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  if (getCurrentUser() && getCurrentUser().token !== "") {
    user = getCurrentUser().token;
  }

  //Get the role of user
  let teacher = "";

  const getRole = () => {
    let role = [];

    if (getCurrentUser()) {
      role = getCurrentUser().role;
      if (user && role !== null) {
        role.map((item) => {
          return (teacher = item.label_teacher);
        });
      }
    }
  };

  useEffect(() => {
    if (token !== null) {
      loginWithToken();
    } else {
      if (getCurrentUser()) {
        getRole();
        setisTeacher(teacher);
      }
    }
  }, [isTeacher]);

  useEffect(() => {
    const header = document.querySelector(".sticky-header");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const handleLogout = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        localStorage.clear();
        history.push("/login");
      });
  };

  const loginWithToken = () => {
    setCurrentUser("");
    axios
      .post(`${process.env.REACT_APP_API_URL}/login-as-token?token=${token}`)
      .then(function (res) {
        const user_data = res.data.data.user;
        setCurrentUser(user_data);
        getRole();
        setisTeacher(teacher);
      });
  };

  const getNotifications = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/member/get-notifications`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        setError(res.data.message);
        setNotificationData(res.data.data?.data);
        if (res.data.data.length !== 0) {
          unreadNotificationCount = res.data.data.total;
        }
      });
  };

  const setProfileData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/member/profile`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        const user_data = res.data.data;
        setCurrentUser(user_data);
        unreadMessagesCount = res.data.data.unread_message_count;
      })
      .catch(function (res) {
        localStorage.clear();
        history.push("/login");
      });
  };

  const threads = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/messages`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        if (res.data.success === true) {
          setThread(res.data.data);
        } else {
          setNoMessage(res.data.message);
        }
      });
  };

  // const getUnreadMessage = () => {
  //     axios
  //         .get(`${process.env.REACT_APP_API_URL}/unread-messages`, {
  //             headers: {
  //                 "content-type": "application/json",
  //                 Authorization: `Bearer ${getCurrentUser().token}`,
  //             },
  //         })
  //         .then(function (res) {
  //             setUnreadMessage(res.data.data);
  //         });
  // };

  const handleNotification = useCallback((type, message) => {
    if (type === "header-notification") {
      setError("");
      setNotificationData((prevState) => [message, ...prevState]);
    }
  }, []);

  useEffect(() => {
    console.log("getCurrentUser-", getCurrentUser());
    if (getCurrentUser() && getCurrentUser().stripe_subscription_id !== null) {
      getSubscription();
    }
    if (getCurrentUser()) {
      // getUnreadMessage();
      setProfileData();
      getNotifications();
      threads();

      const socket = io("https://sockets.soultribeonline.com");
      socket.on(getCurrentUser().id, handleNotification);
      return () => {
        socket.off(getCurrentUser().id, handleNotification);
      };
    }
  }, []);

  const markRead = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/read-all-notification`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        setError(res.data.message);
        setNotificationData([]);
      });
  };

  const getSubscription = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/stripe/subscriptions/${
          getCurrentUser().stripe_subscription_id
        }`
        // {
        //   headers: {
        //     "content-type": "application/json",
        //     Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        //   },
        // }
      )
      .then(function (res) {
        var currentTimestamp = Math.floor(new Date() / 1000);
        const endDate = res.data.current_period_end;
        if (
          res.data.status == "active" ||
          res.data.status == "trialing" ||
          endDate > currentTimestamp
        ) {
          setSubscriptionError("");
        } else {
          setSubscriptionError(
            'We are facing some problem with payments from your credit card. Please visit <a href="/membership-account"> Membership Page</a> for more details.'
          );
        }
      })
      .catch(function (err) {
        console.log(err.response);
        setSubscriptionError("");
      });
  };

  const readNotification = (id, link) => {
    history.push(link);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/read-notification`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser() && getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        console.log(res);
        getNotifications();
      });
  };

  return (
    <Fragment>
      <header
        className={`header-section sticky-header ${
          scroll > headerTop ? "is-sticky" : ""
        }`}
      >
        {isTeacher && isTeacher === "teacher" ? (
          ""
        ) : (
          <>
            {subscriptionError !== "" ? (
              <div className="top__alert alert alert-danger text-center">
                <p
                  className="m-0"
                  dangerouslySetInnerHTML={{
                    __html: subscriptionError,
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </>
        )}

        <div className="container mobile-header-style">
          <div className="d-flex align-items-center justify-content-between">
            <div className="toggle-space-res">
              <button
                className="toggle m-0 toggle-res"
                onClick={onCanvasHandler}
              >
                <span className="icon-top"></span>
                <span className="icon-middle"></span>
                <span className="icon-bottom"></span>
              </button>
            </div>
            <div className="header-logo">
              <Logo
                classOption="d-block"
                image={`${process.env.PUBLIC_URL}/images/logo/soultribe.png`}
              />
            </div>
            <>
              {getCurrentUser() ? (
                <div className="main-menu mobile-notification">
                  <ul className="d-flex align-items-center justify-content-between">
                    <li className="notification_bar">
                      <span className="unread">{unreadMessagesCount}</span>
                      <i className="notification_bar-icon fa fa-comment" />
                      <div className="sub-menu">
                        <div className="d-flex px-3">
                          <h5>Messages</h5>
                        </div>
                        <ul>
                          {noMessage ? (
                            <li>
                              <div className="profile_sec">
                                <a className="d-flex align-items-center">
                                  <p className="user-name">
                                    {noMessage} <br />
                                  </p>
                                </a>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}

                          {thread.map((member, i) => (
                            <li key={i}>
                              <NavLink to={`/messages/${member.thread_id}`}>
                                <div className="profile_sec">
                                  <a className="d-flex align-items-center">
                                    <img
                                      className="user-image"
                                      src={
                                        member.user
                                          ? member.user.image
                                          : `${process.env.PUBLIC_URL}/images/noimg.png`
                                      }
                                      alt="s"
                                    />
                                    <p className="user-name">
                                      {member.user
                                        ? member.user.name
                                        : "Deleted User"}
                                      <br />
                                      <span>
                                        {member.sender_id ===
                                        getCurrentUser().id
                                          ? "You: "
                                          : ""}{" "}
                                        {member.last_message}
                                      </span>
                                    </p>
                                  </a>
                                </div>
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                        <footer className="notification-footer text-center">
                          <NavLink to={process.env.PUBLIC_URL + "/messages"}>
                            <span>
                              {" "}
                              View Inbox <i className="fa fa-angle-right"></i>
                            </span>
                          </NavLink>
                        </footer>
                      </div>
                    </li>
                    <li className="notification_bar">
                      <span className="unread">{unreadNotificationCount}</span>
                      <i className="notification_bar-icon fa fa-bell" />
                      <div className="sub-menu">
                        <div className="d-flex px-3">
                          <h5>Notification</h5>
                          <span className="cursor-pointer" onClick={markRead}>
                            Mark all as read
                          </span>
                        </div>
                        <ul>
                          {error ? (
                            <li>
                              <div className="profile_sec">
                                <a className="d-flex align-items-center">
                                  <p className="user-name">
                                    {error} <br />
                                  </p>
                                </a>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}

                          {notificationData &&
                            notificationData.map((member, i) => (
                              <li
                                key={i}
                                onClick={() =>
                                  readNotification(
                                    member.id,
                                    member.notification_link
                                  )
                                }
                              >
                                <div className="profile_sec">
                                  <a className="d-flex align-items-center">
                                    <img
                                      className="user-image"
                                      src={member.sender_profile_pic}
                                      alt="ss"
                                    />
                                    <div>
                                      <p
                                        className="user-name d-inline"
                                        dangerouslySetInnerHTML={{
                                          __html: member.data.message,
                                        }}
                                      />
                                      <span className="d-block font-weight-normal mt-1">
                                        {member.created_time_ago}
                                      </span>
                                    </div>
                                  </a>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <footer className="notification-footer text-center">
                          <NavLink
                            to={process.env.PUBLIC_URL + "/notifications"}
                          >
                            <span>
                              {" "}
                              View Notifications{" "}
                              <i className="fa fa-angle-right"></i>
                            </span>
                          </NavLink>
                        </footer>
                      </div>
                    </li>
                  </ul>
                </div>
              ) : (
                ""
              )}
            </>

            <div className="menu menu-responsive">
              <MainMenu />
            </div>
            {getCurrentUser() ? (
              ""
            ) : (
              <div
                style={{
                  width: "70px",
                }}
              ></div>
            )}
            <div className="menu menu-responsive">
              <nav className="main-menu d-none d-xl-block">
                <ul className="d-flex justify-content-end">
                  {getCurrentUser() ? (
                    <>
                      <li>
                        <>
                          <div className="profile_sec">
                            <NavLink
                              to={process.env.PUBLIC_URL + "/profile"}
                              className="user-link new-class d-flex align-items-center"
                            >
                              <span className="user-name">
                                {getCurrentUser().name}
                              </span>
                              <i className="user-arrow fa fa-angle-down"></i>
                              <img
                                className="user-image"
                                src={getCurrentUser().image}
                                alt=""
                              />
                            </NavLink>
                          </div>
                          <ul className="sub-menu">
                            <li className="sub-menu-item">
                              <NavLink
                                to={"/profile/timeline"}
                                className="sub-menu-link pointer"
                              >
                                Account
                              </NavLink>
                            </li>
                            <li className="sub-menu-item">
                              <NavLink
                                to={`/profile/classes`}
                                className="sub-menu-link pointer"
                              >
                                Classes
                              </NavLink>

                              {isTeacher && isTeacher === "teacher" ? (
                                <ul className="sub-sub-menu">
                                  <li className="sub-menu-item">
                                    <NavLink
                                      to={"/create-live-class"}
                                      className="sub-menu-link pointer"
                                    >
                                      Create Live Class
                                    </NavLink>
                                  </li>
                                  <li className="sub-menu-item">
                                    <NavLink
                                      to={"/profile/classes"}
                                      className="sub-menu-link pointer"
                                    >
                                      Upload Class
                                    </NavLink>
                                  </li>
                                </ul>
                              ) : (
                                ""
                              )}
                            </li>
                            <li className="sub-menu-item">
                              <NavLink
                                to={"/profile/tribes"}
                                className="sub-menu-link pointer"
                              >
                                Tribes
                              </NavLink>
                            </li>
                            {isTeacher && isTeacher === "teacher" ? (
                              <>
                                <li className="sub-menu-item">
                                  <NavLink
                                    to={"/affiliates"}
                                    className="sub-menu-link pointer"
                                  >
                                    Affiliate Area
                                  </NavLink>
                                </li>
                                <li className="sub-menu-item">
                                  <NavLink
                                    to={"/membership-account"}
                                    className="sub-menu-link pointer"
                                  >
                                    Membership
                                  </NavLink>
                                </li>
                              </>
                            ) : (
                              <li className="sub-menu-item">
                                <NavLink
                                  to={"/membership-account"}
                                  className="sub-menu-link pointer"
                                >
                                  Membership
                                </NavLink>
                              </li>
                            )}
                            <li className="sub-menu-item">
                              <NavLink
                                to={"/edit-profile"}
                                className="sub-menu-link pointer"
                              >
                                Edit Profile
                              </NavLink>
                            </li>
                            <li className="sub-menu-item cursor-pointer">
                              <span
                                className="sub-menu-link pointer new-class"
                                onClick={() => handleLogout()}
                              >
                                Logout
                              </span>
                            </li>
                          </ul>
                        </>
                      </li>
                      <li className="notification_bar">
                        <span className="unread">{unreadMessagesCount}</span>
                        <i className="notification_bar-icon fa fa-comment" />
                        <div className="sub-menu">
                          <div className="d-flex px-3">
                            <h5>Messages</h5>
                          </div>
                          <ul>
                            {noMessage ? (
                              <li>
                                <div className="profile_sec">
                                  <a className="d-flex align-items-center">
                                    <p className="user-name">
                                      {noMessage} <br />
                                    </p>
                                  </a>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}

                            {thread.map((member, i) => (
                              <li key={i}>
                                <NavLink to={`/messages/${member.thread_id}`}>
                                  <div className="profile_sec">
                                    <a className="d-flex align-items-center">
                                      <img
                                        className="user-image"
                                        src={
                                          member.user
                                            ? member.user.image
                                            : `${process.env.PUBLIC_URL}/images/noimg.png`
                                        }
                                        alt="ss"
                                      />
                                      <p className="user-name">
                                        {member.user
                                          ? member.user.name
                                          : "Deleted User"}
                                        <br />
                                        <span>
                                          {member.sender_id ===
                                          getCurrentUser().id
                                            ? "You: "
                                            : ""}{" "}
                                          {member.last_message}
                                        </span>
                                      </p>
                                    </a>
                                  </div>
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                          <footer className="notification-footer text-center">
                            <NavLink to={process.env.PUBLIC_URL + "/messages"}>
                              <span>
                                {" "}
                                View Inbox <i className="fa fa-angle-right"></i>
                              </span>
                            </NavLink>
                          </footer>
                        </div>
                      </li>
                      <li className="notification_bar">
                        <span className="unread">
                          {unreadNotificationCount}
                        </span>
                        <i className="notification_bar-icon fa fa-bell" />
                        <div className="sub-menu">
                          <div className="d-flex px-3">
                            <h5>Notification</h5>
                            <span className="cursor-pointer" onClick={markRead}>
                              Mark all as read
                            </span>
                          </div>
                          <ul>
                            {error ? (
                              <li>
                                <div className="profile_sec">
                                  <a className="d-flex align-items-center">
                                    <p className="user-name">
                                      {error} <br />
                                    </p>
                                  </a>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}

                            {notificationData &&
                              notificationData.map((member, i) => (
                                <li
                                  key={i}
                                  onClick={() =>
                                    readNotification(
                                      member.id,
                                      member.notification_link
                                    )
                                  }
                                >
                                  <div className="profile_sec">
                                    <a className="d-flex align-items-center">
                                      <img
                                        className="user-image"
                                        src={member.sender_profile_pic}
                                        alt="ss"
                                      />
                                      <div>
                                        <p
                                          className="user-name d-inline"
                                          dangerouslySetInnerHTML={{
                                            __html: member.data.message,
                                          }}
                                        />
                                        <span className="d-block font-weight-normal mt-1">
                                          {member.created_time_ago}
                                        </span>
                                      </div>
                                    </a>
                                  </div>
                                </li>
                              ))}
                          </ul>
                          <footer className="notification-footer text-center">
                            <NavLink
                              to={process.env.PUBLIC_URL + "/notifications"}
                            >
                              <span>
                                {" "}
                                View Notifications{" "}
                                <i className="fa fa-angle-right"></i>
                              </span>
                            </NavLink>
                          </footer>
                        </div>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <NavLink to={process.env.PUBLIC_URL + "/login"}>
                          <span className="btn btn-dark btn-hover-dark">
                            Login
                          </span>
                        </NavLink>
                      </li>
                      <li className="ml-3">
                        <NavLink to={process.env.PUBLIC_URL + "/registration"}>
                          <span className="btn btn-dark text-dark bg-transparent">
                            Sign up
                          </span>
                        </NavLink>
                      </li>
                    </>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </Fragment>
  );
};

export default Header;
