import React, { useState } from 'react'
import { getCurrentUser } from '../../helpers/Utils'
import axios from 'axios'

function BecomeAffiliate() {
	const [message, setMessage] = useState('')
	const [loading, setLoading] = useState(false)
	const [underProcess, setUnderProcess] = useState(false)

	const becomeAffiliate = () => {
		setLoading(true)
		axios.post(`${process.env.REACT_APP_API_URL}/affiliate-request`, {}, {
			headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${getCurrentUser().token}`,
            },
		}).then(function (res) {
			setUnderProcess(true)
			setLoading(false)
		})
	}

	return(
		<section className="contact-section section-py mt-5">
            <div className="container">
				<div className="become_affiliate">
					<h4>To see the Affiliate Area, log in as an existing affiliate, or add your account as an affiliate.</h4>
					{getCurrentUser().affiliate_request === null || getCurrentUser().affiliate_request === '2' ? (
						<div className="text-center mt-5">
							{
								underProcess ? (
									<h5 className="text-center mt-5">Your Request is under process</h5>
									) : (
									<>
										{loading ? (
											<button className="btn btn-primary" disabled>Loading...</button>
										) : (
											<button className="btn btn-primary" onClick={becomeAffiliate}>Click here to become affiliate</button>
										)}
										{message ? (
											<div className="alert alert-info py-2 mt-4" role="alert">
												{message}
											</div>
										) : ''}
									</>
								)
							}
						</div>
					) : (
						<h5 className="text-center mt-5">Your Request is under process</h5>
					)}
				</div>
			</div>
		</section>
	)
}
export default BecomeAffiliate;