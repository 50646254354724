import React, { Component } from "react";
import axios from "axios";
import { getCurrentUser } from "../../helpers/Utils";
import EditorConvertToHTML from "./description";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

var categories_arr = [];
var length_arr = [];
var level = [];

class CreateClassForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            error: "",
            success: "",
            isLoading: false,
            title: "",
            type: "",
            teacher_id: "",
            category_id: [],
            vimeoId: "",
            image: "",
            description: "",
            submitButton: false,
        };
        this.onCreateClass = this.onCreateClass.bind(this);
        this.state = { isChecked: false };
        this.handleCategoryCheckbox = this.handleCategoryCheckbox.bind(this);
    }
    state;
    handleCategoryCheckbox(e) {
        if (!categories_arr.includes(e.target.value)) {
            categories_arr.push(e.target.value);
        } else {
            const index = categories_arr.indexOf(e.target.value);
            if (index > -1) {
                categories_arr.splice(index, 1);
            }
        }
    }

    handleLengthCheckbox(e) {
        if (!length_arr.includes(e.target.value)) {
            length_arr.push(e.target.value);
        } else {
            const index = length_arr.indexOf(e.target.value);
            if (index > -1) {
                length_arr.splice(index, 1);
            }
        }
    }

    handleLevelCheckbox(e) {
        level = e.target.value;
    }

    getEditorVal = (classDescription) => {
        this.setState({
            description: classDescription,
        });
    };

    onCreateClass = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        e.preventDefault();
        var self = this;
        var formData = new FormData();
        formData.append("title", this.state.title);
        formData.append("vimeo_id", this.state.vimeoId);
        formData.append("image", this.state.image);
        formData.append("type", "single");
        formData.append("description", this.state.description);
        formData.append("category_id", categories_arr);
        formData.append("level_id", level);
        formData.append("length_id", length_arr);

        axios
            .post(`${process.env.REACT_APP_API_URL}/add-class`, formData, {
                headers: {
                    "content-type": "multipart/form-data",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.success === false) {
                    self.setState({
                        error: res.data.message,
                        // success: "",
                        isLoading: false,
                    });
                } else {
                    self.setState({
                        error: "",
                        success: res.data.message,
                        isLoading: false,
                    });
                    window.location.href = "/classes";
                }
            });
    };

    render() {
        const { error, isLoading } = this.state;
        let button = (
            <>
                {this.state.submitButton ? (
                    <button
                        id="contactSubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-dark"
                    >
                        Publish
                    </button>
                ) : (
                    <button
                        id="contactSubmit"
                        type="submit"
                        className="btn btn-dark btn-hover-dark disabled"
                        disabled
                    >
                        Publish
                    </button>
                )}
            </>
        );
        if (error) {
            toast.error(error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                error: "",
            });
        }

        if (isLoading === true) {
            button = (
                <button
                    id="contactSubmit"
                    disabled
                    className="btn btn-dark btn-hover-dark"
                >
                    Loading...
                </button>
            );
        }
        let cateData = "";
        if (this.props.data.data.categories !== undefined) {
            cateData = (
                <>
                    {this.props.data.data.categories &&
                        this.props.data.data.categories.map((item, i) => (
                            <div className="col-12" key={i}>
                                <label
                                    htmlFor={item.id}
                                    className="m-0 cursor-pointer"
                                >
                                    <input
                                        type="checkbox"
                                        id={item.id}
                                        name="category_id"
                                        value={item.id}
                                        onChange={(e) =>
                                            this.handleCategoryCheckbox(e)
                                        }
                                    />{" "}
                                    {item.name}
                                </label>
                            </div>
                        ))}
                </>
            );
        }

        let lenghtData = "";
        if (this.props.data.data.lengths !== undefined) {
            lenghtData = (
                <>
                    {this.props.data.data.lengths &&
                        this.props.data.data.lengths.map((item, i) => (
                            <div className="col-12" key={i}>
                                <label
                                    htmlFor={item.id}
                                    className="m-0 cursor-pointer"
                                >
                                    <input
                                        type="checkbox"
                                        id={item.id}
                                        name="length_id"
                                        value={item.id}
                                        onChange={(e) =>
                                            this.handleLengthCheckbox(e)
                                        }
                                    />{" "}
                                    {item.name}
                                </label>
                            </div>
                        ))}
                </>
            );
        }

        let levelData = "";
        if (this.props.data.data.levels !== undefined) {
            levelData = (
                <>
                    {this.props.data.data.levels &&
                        this.props.data.data.levels.map((item, i) => (
                            <option value={item.id}>{item.name}</option>
                        ))}
                </>
            );
        }
        const handleChangeStatus = ({ meta, file, xhr }, status) => {
            if (status === "done") {
                let response = JSON.parse(xhr.response);
                this.setState({
                    vimeoId: response.data,
                    submitButton: true,
                });
            }
        };

        const getUploadParams = ({ meta }) => ({
            url: `${process.env.REACT_APP_API_URL}/upload-video-to-vimeo`,
        });

        const handleSubmit = (files, allFiles) => {
            allFiles.forEach((f) => f.remove());
        };

        return (
            <form
                onSubmit={this.onCreateClass}
                id="contactForm"
                className="row"
            >
                <div className="col-12">
                    <div className="col-12 mb-7">
                        Title
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="title"
                            placeholder="Type the title here"
                            onChange={(e) =>
                                this.setState({ title: e.target.value })
                            }
                        />
                    </div>

                    <input
                        type="hidden"
                        className="form-control"
                        id="type"
                        name="type"
                        placeholder="Type"
                        value="single"
                        onChange={(e) =>
                            this.setState({ type: e.target.value })
                        }
                    />

                    <div className="col-12 mb-7">
                        Video
                        <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus}
                            onSubmit={handleSubmit}
                            inputContent="Drag Video or Click to Browse Video"
                            multiple={false}
                            accept="video/*"
                            disabled={(files) =>
                                files.some((f) =>
                                    [
                                        "preparing",
                                        "getting_upload_params",
                                        "uploading",
                                    ].includes(f.meta.status)
                                )
                            }
                        />
                    </div>
                    <div className="col-12 mb-7">
                        Image
                        <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            id="image"
                            name="image"
                            onChange={(e) =>
                                this.setState({
                                    image: e.target.files[0],
                                })
                            }
                        />
                    </div>
                    <div className="col-12 mb-7">
                        Description
                        <EditorConvertToHTML onChange={this.getEditorVal} />
                    </div>

                    <div className="col-12 mb-7">
                        Categories
                        {cateData}
                    </div>

                    <div className="col-12 mb-7">
                        Length
                        {lenghtData}
                    </div>

                    <div className="col-12 mb-7">
                        Level
                        <select
                            name="level_id"
                            className="form-control"
                            onChange={(e) => this.handleLevelCheckbox(e)}
                        >
                            <option value="" hidden>
                                Select level
                            </option>
                            {levelData}
                        </select>
                    </div>
                </div>
                <div className="col-12">{button}</div>
            </form>
        );
    }
}

export default CreateClassForm;
