import React from "react";
import { NavLink } from "react-router-dom";
import LoginForm from "../../components/login";

const LoginContainer = () => {
    return (
        <section className="contact-section">
            <div className="container">
                <div className="loginsignup">
                    <div className="contact-title-section pb-0">
                        <h3 className="title">Login</h3>
                    </div>
                    <LoginForm />
                    <div className="mt-5">
                        <NavLink
                            className="sub-menu-link"
                            to={process.env.PUBLIC_URL + "/registration"}
                        >
                            Create new account with us.
                        </NavLink><br />
                        <NavLink
                            className="sub-menu-link mt-1 d-inline-block"
                            to={process.env.PUBLIC_URL + "/forgot-password"}
                        >
                            I forgot my password.
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LoginContainer;
