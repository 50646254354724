import React, { useState, useEffect } from "react";
import { setCurrentUser, getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlert from "react-bootstrap-sweetalert";

const DiscussionList = (props) => {
  const [discuss, setDiscuss] = useState(props.data);
  const [sure, setSure] = useState(false);

  const removeDiscussion = (e) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/delete-tribe-discussion/${discuss.id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setSure(false);
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDiscuss("");
      });
  };

  if (discuss !== "") {
    return (
      <>
        <tr key={discuss.id}>
          <td
            role="button"
            className="py-2"
            onClick={() => props.getSingleDiscussion(discuss.id)}
          >
            <div className="d-flex">
              <img
                height="55"
                className="mw-100 rounded-circle"
                src={discuss.user.image}
                alt="imddage"
              />
              <div style={{ marginLeft: "15px" }}>
                <h6>{discuss.title}</h6>
                <span style={{ opacity: "0.9" }}>
                  <i className="fa fa-reply"></i> {discuss.user.name} replied{" "}
                  {discuss.created_at}
                </span>
              </div>
            </div>
          </td>
          <td className="text-right">
            {discuss.user_id === getCurrentUser().id ? (
              <button onClick={() => setSure(true)} className="btn btn-danger">
                Delete
              </button>
            ) : (
              ""
            )}
          </td>
        </tr>
        {sure && (
          <SweetAlert
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="btn btn-primary"
            title="Are you sure?"
            btnSize="sm"
            onConfirm={() => removeDiscussion(discuss.id)}
            onCancel={() => setSure(false)}
          >
            You will not be able to recover this discussion!
          </SweetAlert>
        )}
      </>
    );
  } else {
    return "";
  }
};
export default DiscussionList;
