import React from 'react'
import { NavLink } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
const ClassGrid = ({ data, classOption, pagination, nextLink }) => {
  return (
    <div>
      <InfiniteScroll
        dataLength={data.length}
        next={nextLink}
        hasMore={true}
        loader={
          <h4 className="text-center">{pagination ? 'Loading...' : ''}</h4>
        }
      >
        <div className={`thumb bg-light text-center ${classOption}`}>
          <NavLink to={process.env.PUBLIC_URL + `/class/${data.slug}`}>
            <img src={data.image} alt="img" />
          </NavLink>
        </div>
        <div className="class-content mt-3">
          <h3 className="title text-capitalize">
              <NavLink to={process.env.PUBLIC_URL + `/class/${data.slug}`}>
                {data.title}
              </NavLink>
          </h3>
          <span
            style={{
              fontSize: '12px',
              lineHeight: '24px',
            }}
          >
            {data.teacher && data.teacher.name}
            &nbsp; | &nbsp;
            {data.categories.map((item, i) => (
              <span key={i}>{i < 1 ? <>{item.name}</> : <>, {item.name}</>}</span>
            ))}
            &nbsp; | &nbsp;
            {data.levels.map((item, i) => (
              <span key={i}>{i < 1 ? <>{item.name}</> : <>, {item.name}</>}</span>
            ))}
          </span>
        </div>
      </InfiniteScroll>
    </div>
  )
}
export default ClassGrid
