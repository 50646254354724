import React, { useState, useEffect } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import IconBoxContainer from "../containers/global/icon-box";
import TeamContainer from "../containers/global/team";
import TestimonialContainer from "../containers/global/testimonial";
import HomeAboutContainer from "../containers/home/about";
import Classes from "../containers/home/class";
import Subscription from "../containers/home/subscription";
import IntroContainer from "../containers/home/intro";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import PageLoader from "../components/page-loader";
import { getCurrentUser } from "../helpers/Utils";
import { useLocation, NavLink } from "react-router-dom";
import Modal from "react-modal";
import { Helmet } from "react-helmet";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const customStyles = {
    content: {
        position: "unset",
        maxWidth: "450px",
        width: "100%",
        right: "auto",
        border: "none",
        bottom: "auto",
        padding: "10px",
        margin: "30px auto",
    },
};

const HomePage = () => {
    const [modalIsOpen, setIsOpen] = useState(true);
    const [isTeacher, setisTeacher] = useState("teacher");
    let query = useQuery();

    const getRole = () => {
        let role = [];
        let teacher = "";
        role = getCurrentUser().role;
        if (role !== null) {
            role.map((item) => {
                return (teacher = item.label_teacher);
            });
            setisTeacher(teacher);
        }
    };

    useEffect(() => {
        if (getCurrentUser()) {
            getRole();
        }
    }, []);

    return (
        <React.Fragment>
            <PageLoader />
            <Layout>
                {query.get("membership") ? (
                    <Modal
                        ariaHideApp={false}
                        isOpen={modalIsOpen}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="modal-header pt-1 pb-0">
                            <h4 className="text-center w-100 d-inline-block">
                                Your membership has been updated.
                            </h4>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="close p-0"
                            >
                                <span
                                    style={{
                                        fontSize: "28px",
                                    }}
                                    aria-hidden="true"
                                >
                                    ×
                                </span>
                            </button>
                        </div>
                        <br />
                        <center>
                            <i className="fa fa-5x fa-check-circle text-success"></i>
                        </center>
                        <p>Hello, {getCurrentUser().name}.</p>
                        <p>
                            Your membership plan has been updated successfully.
                            Enjoy browsing premium content on the portal. If you
                            have any feedback, please write to us at{" "}
                            <a href="mailto:info@soultribeonline.com">
                                info@soultribeonline.com
                            </a>
                            .
                        </p>
                        <p>
                            You can manage your membership anytime from Profile
                            > Membership or{" "}
                            <NavLink to="/membership-account">
                                click here
                            </NavLink>
                        </p>
                    </Modal>
                ) : (
                    ""
                )}
                <Helmet>
                    <title>
                        High Vibe Social Network & Sanctuary | Soul Tribe Online
                    </title>
                    <meta
                        name="description"
                        content="Visit the new social network for creating conscious connections, developing your daily spiritual practice, and join soul tribes and online gatherings."
                    />
                    <meta
                        name="image"
                        content="https://soultribeonline.com/images/logo/soultribe.png"
                    />
                </Helmet>
                <div className="wrapper">
                    <Header />
                    <IntroContainer />
                    <IconBoxContainer classOption="section-pb pt-10" />
                    <TeamContainer classOption="section-pb" />
                    <HomeAboutContainer />
                    <Classes />
                    <TestimonialContainer />
                    {isTeacher !== "teacher" ? <Subscription /> : ""}
                    {getCurrentUser() ? "" : <Subscription />}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default HomePage;
