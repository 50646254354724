import React, { useState, useEffect, useCallback } from "react";
import { getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";
import { NavLink, Link } from "react-router-dom";
import DiscussionForm from "./discussion-form";
import Modal from "react-modal";
import Loader from "../../../components/loader";
import DiscussionList from "./discussion-list";
import linkifyHtml from "linkify-html";
import PostLightBox from "../../../components/lovestream/lightbox";
import LoveStreamPost from "../../../components/lovestream/Post";
import Gallery from "react-photo-gallery";

const customStyles = {
    content: {
        position: "unset",
        maxWidth: "650px",
        width: "100%",
        right: "auto",
        border: "none",
        bottom: "auto",
        padding: "0 35px 15px",
        margin: "30px auto",
    },
};
const TribeDiscussion = (props) => {
    const [disModal, setDisModal] = useState(false);
    const [allMember, setAllMember] = useState([]);
    const [discussion, setDiscussion] = useState([]);
    const [currentPage, setCurrentPage] = useState("");
    const [singleDiscussion, setSingleDiscussion] = useState("");
    const [disSingleModal, setDisSingleModal] = useState(false);
    const [loader, setLoader] = useState("");
    const [singleLoader, setSingleLoader] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [discusstionEdit, setDiscusstionEdit] = useState(false);
    const [discusstionEditLoading, setDiscusstionEditLoading] = useState(false);

    const [replyModal, setReplyModal] = useState(false);

    const PostDiscussion = (e) => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/remove-tribe-photos`,
                {
                    tribe_id: props.tribeSingleId,
                    title: "test",
                    topic: "testing",
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {});
    };

    const getDiscussions = () => {
        setLoader(<Loader />);
        setDiscussion([]);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/all-tribe-discussions?tribe_id=${props.tribeSingleId}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                setDiscussion(res.data.data);
            });
    };

    const getSingleDiscussion = (id) => {
        setDisSingleModal(true);
        setSingleLoader(<Loader />);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/all-tribe-discussions?tribe_id=${props.tribeSingleId}&discussion_id=${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setSingleLoader("");
                setSingleDiscussion(res.data.data);
            });
    };

    const getMember = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/show-members-limited-data`, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setAllMember(res.data.data);
            });
    };

    let postImages = [];

    if (singleDiscussion !== "") {
        singleDiscussion.photos &&
            singleDiscussion.photos.map((img) =>
                postImages.push({
                    src: img.photo,
                    width: 3,
                    height: 4,
                })
            );
    }

    const closeModal = () => {
        setDisSingleModal(false);
        setSingleDiscussion("");
    };

    const openReply = () => {
        setDisSingleModal(false);
        setDisModal(true);
    };

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const discussionTitle = (e) => {
        e.preventDefault();
        setDiscusstionEditLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/tribe-discussion/edit`,
                {
                    title: e.target.discussion_title.value,
                    id: singleDiscussion.id,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setDiscusstionEditLoading(false);
                setSingleDiscussion(res.data.data);
                setDiscusstionEdit(false);
                getDiscussions();
            });
    };

    const closeDisModal = () => {
        setDisModal(false);
        setSingleDiscussion("");
    };

    useEffect(() => {
        getMember();
        getDiscussions();
    }, []);

    return (
        <>
            <PostLightBox
                currentImage={currentImage}
                viewerIsOpen={viewerIsOpen}
                postImages={postImages}
                setCurrentImage={setCurrentImage}
                setViewerIsOpen={setViewerIsOpen}
            />
            <Modal
                ariaHideApp={false}
                isOpen={disSingleModal}
                style={customStyles}
                contentLabel="Add Tribe Photo"
            >
                {singleLoader === "" ? (
                    <div className="discussion-single">
                        <div className="modal-header d-block px-0 pb-0 position-relative">
                            <h5>
                                {singleDiscussion.user_id ===
                                getCurrentUser().id ? (
                                    <>
                                        {discusstionEdit ? (
                                            <form
                                                onSubmit={(e) =>
                                                    discussionTitle(e)
                                                }
                                            >
                                                <div className="d-flex">
                                                    <input
                                                        className="form-control py-1 px-2"
                                                        type="text"
                                                        name="discussion_title"
                                                        defaultValue={
                                                            singleDiscussion &&
                                                            singleDiscussion.title
                                                        }
                                                    />
                                                    <div
                                                        style={{
                                                            paddingLeft: "5px",
                                                        }}
                                                    >
                                                        {discusstionEditLoading ? (
                                                            <button
                                                                type="button"
                                                                className="btn btn-success btn-sm p-2"
                                                                style={{
                                                                    borderRadius:
                                                                        "5px",
                                                                }}
                                                            >
                                                                <i class="fa fa-spinner"></i>
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-success btn-sm p-2"
                                                                style={{
                                                                    borderRadius:
                                                                        "5px",
                                                                }}
                                                            >
                                                                <i class="fa fa-check"></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        ) : (
                                            singleDiscussion &&
                                            singleDiscussion.title
                                        )}{" "}
                                        {discusstionEdit ? (
                                            ""
                                        ) : (
                                            <button
                                                onClick={() =>
                                                    setDiscusstionEdit(
                                                        !discusstionEdit
                                                    )
                                                }
                                                className="p-2 py-0 text-primary"
                                                // className="btn btn-warning btn-sm px-2 py-1"
                                                style={{
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <i className="fa fa-pencil"></i>
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    singleDiscussion && singleDiscussion.title
                                )}
                                <small>
                                    <Link
                                        to={`/member/${
                                            singleDiscussion &&
                                            singleDiscussion.user &&
                                            singleDiscussion &&
                                            singleDiscussion.user.id
                                        }`}
                                    >
                                        {singleDiscussion &&
                                            singleDiscussion.user &&
                                            singleDiscussion.user.name}
                                    </Link>{" "}
                                    Updated{" "}
                                    {singleDiscussion &&
                                        singleDiscussion.formatted_created_time}{" "}
                                    &nbsp;{" "}
                                    {singleDiscussion &&
                                        singleDiscussion.member_count}{" "}
                                    Members &nbsp;{" "}
                                    {singleDiscussion &&
                                        singleDiscussion.post_count}{" "}
                                    Post
                                </small>
                            </h5>
                            <i
                                onClick={() => closeModal()}
                                className="fa fa-times close_icon"
                            />
                        </div>
                        <div className="body mt-2">
                            <div className="text-right">
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => openReply()}
                                >
                                    Reply
                                </button>
                                <button
                                    className="btn btn-secondary ml-2 px-2 btn-sm"
                                    onClick={() => closeModal()}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="post">
                                {singleDiscussion.posts &&
                                    singleDiscussion.posts.map((item, i) => (
                                        <LoveStreamPost
                                            post={item}
                                            pageindex={currentPage}
                                            mentionAllMember={allMember}
                                            i={i}
                                            discussionId={singleDiscussion.id}
                                            setDisSingleModal={
                                                setDisSingleModal
                                            }
                                            getDiscussions={getDiscussions}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    singleLoader
                )}
            </Modal>
            <Modal
                ariaHideApp={false}
                isOpen={disModal}
                style={customStyles}
                contentLabel="Add Tribe Photo"
            >
                <div className="discussion-single">
                    <div className="modal-header px-0 pb-0">
                        {singleDiscussion === "" ? (
                            <>
                                <h5>New Discussion</h5>
                                <i
                                    onClick={() => setDisModal(false)}
                                    className="fa fa-times"
                                />
                            </>
                        ) : (
                            <>
                                <h5>
                                    {singleDiscussion && singleDiscussion.title}
                                    <small>
                                        <Link
                                            to={`/member/${
                                                singleDiscussion &&
                                                singleDiscussion.user &&
                                                singleDiscussion &&
                                                singleDiscussion.user.id
                                            }`}
                                        >
                                            {singleDiscussion &&
                                                singleDiscussion.user.name}
                                        </Link>{" "}
                                        Updated{" "}
                                        {singleDiscussion &&
                                            singleDiscussion.formatted_created_time}{" "}
                                        &nbsp;{" "}
                                        {singleDiscussion &&
                                            singleDiscussion.member_count}{" "}
                                        Members &nbsp;{" "}
                                        {singleDiscussion &&
                                            singleDiscussion.post_count}{" "}
                                        Post
                                    </small>
                                </h5>
                                <i
                                    onClick={() => closeDisModal()}
                                    className="fa fa-times"
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className="request-data-style text-center">
                    {singleDiscussion === "" ? (
                        <DiscussionForm
                            callApi={getDiscussions}
                            mentionAllMember={allMember}
                            tribeSingleId={props.tribeSingleId}
                            setDisModal={setDisModal}
                        />
                    ) : (
                        <DiscussionForm
                            callApi={getDiscussions}
                            mentionAllMember={allMember}
                            tribeSingleId={props.tribeSingleId}
                            setDisModal={setDisModal}
                            singleDiscussion={singleDiscussion.id}
                            getSingleDiscussion={getSingleDiscussion}
                        />
                    )}
                </div>
            </Modal>
            <div className="d-flex justify-content-between">
                <h5 className="mb-2">All Discussions</h5>
                <button
                    className="btn btn-primary btn-sm"
                    onClick={() => setDisModal(true)}
                >
                    New Discussion
                </button>
            </div>
            {loader}
            <div className="discussions-list">
                <table className="table table-striped">
                    {discussion &&
                        discussion.map((item, i) => (
                            <DiscussionList
                                data={item}
                                getSingleDiscussion={getSingleDiscussion}
                                getDiscussions={getDiscussions}
                            />
                        ))}
                </table>
            </div>
        </>
    );
};

export default TribeDiscussion;
