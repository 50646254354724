import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import { getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";
import moment from "moment";
// import ReactTooltip from "react-tooltip";
import { Tooltip } from "bootstrap";
import Loader from "../../../components/loader";

let tooltipInstance = null;

function FullCalendarCompo() {
  const [data, setData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const fetchCalendarData = (e) => {
    setIsDisabled(true)
    let startDay = moment(e.start).format("DD-MM-YYYY");
    let endDay = moment(e.end).format("DD-MM-YYYY");
    setData("");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/live-classes-calendar?start_date=${startDay}&end_date=${endDay}`,
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setIsDisabled(false)
        setData(res.data.data);
      });
  };

  // actions
  const handleMouseEnter = (info) => {
    if (info.event.extendedProps.description) {
      tooltipInstance = new Tooltip(info.el, {
        title: info.event.extendedProps.description,
        html: true,
        placement: "top",
        trigger: "hover",
        container: "body"
      });

      tooltipInstance.show();
    }
  };

  const handleMouseLeave = () => {
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
    }
  };

  return (
    <div className="position-relative">
        {isDisabled ? (
          <div className="fullcalendar_loader">
            <Loader />
          </div>
        ) : ''}
      <div className={`container py-5 ${isDisabled ? 'fullcalendar' : ''}`}>
        <FullCalendar
          plugins={[dayGridPlugin]}
          headerToolbar={{
            left: "prev",
            center: "title",
            right: "next",
          }}
          initialView="dayGridMonth"
          events={data}
          datesSet={(e) => fetchCalendarData(e)}
          eventOrder="duration"
          eventMouseEnter={handleMouseEnter}
          eventMouseLeave={handleMouseLeave}
          contentHeight="auto"
        />
      </div>
    </div>
  );
}
export default FullCalendarCompo;
