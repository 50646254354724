import React, { useState } from 'react'
import { getCurrentUser } from "../../helpers/Utils"

function AllThread(props) {	
	const [unreadCount, setUnreadCount] = useState(props.data.unread_messages_count)

	return (
		<li className="contact" key={props.i} onClick={() => setUnreadCount(0)}>
			<input className="d-none" id={props.data.thread_id} onClick={(e) => props.setForSingleThread(props.data.thread_id)} type="radio" name="allThreads" />
			<label htmlFor={props.data.thread_id}>
			    <div className="wrap">
			        <img src={props.data.thread_type === "group" ? props.data.thread.tribe && props.data.thread.tribe.image : props.data.user && props.data.user.image} alt="" />
			        <div className="meta">
			            <p className="name text-capitalize">
			            {props.data.thread_type === "group" ? props.data.thread.tribe && props.data.thread.tribe.title : props.data.user && props.data.user.name}
			            <span style={{
			            	'color': '#a2a7ad',
			            	'fontWeight': '300',
			            	'display': 'block',
			            	'lineHeight': '14px'
			            }}>{props.data.sender_id === getCurrentUser().id ? 'You: ' : ''} {props.data.last_message}</span></p>
			        </div>
			    </div>
			</label>
			{unreadCount !== 0 ? <span className="unread_message-count">{unreadCount}</span> : ''}
		</li>
	)
}
export default AllThread;