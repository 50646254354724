import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../../../helpers/Utils";
import { NavLink } from "react-router-dom";
import Modal from "react-modal";

const changePlanStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "405px",
    width: "100%",
    // height: '80%'
  },
};

const SinglePlan = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="subscription_plans-single">
        <h1 key={props.i}>
          ${props.item.price}{" "}
          <span className="cursor-pointer">
            {props.item.name}{" "}
            <i
              onClick={() => setIsOpen(true)}
              title="Subscription Information"
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "100%",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px",
                background: "#f9a917",
                color: "#fff",
                padding: "0",
                lineHeight: "21px",
                display: "inline-block",
              }}
            >
              i
            </i>
          </span>
          <p>First 10 Days FREE</p>
        </h1>
        <div className="position-relative">
          {props.membership_id === props.item.stripe_price &&
          props.subscriptionStatus ? (
            <button
              type="button"
              className="btn btn-primary btn-hover-dark current-class-color"
            >
              Active Plan
            </button>
          ) : (
            <>
              {getCurrentUser() ? (
                <button
                  onClick={(e) =>
                    props.updatePlanId(
                      props.item.id,
                      props.item.price,
                      props.item
                    )
                  }
                  type="button"
                  className="btn btn-primary btn-hover-dark"
                >
                  {props.subscriptionStatus ? "Change plan" : "Select plan"}
                </button>
              ) : (
                <NavLink to={process.env.PUBLIC_URL + "/registration"}>
                  <button className="btn btn-primary btn-hover-dark">
                    Select plan
                  </button>
                </NavLink>
              )}
            </>
          )}
          <hr />
        </div>
      </div>
      <Modal isOpen={isOpen} style={changePlanStyles} ariaHideApp={false}>
        <i
          className="fa fa-window-close float-right"
          onClick={(e) => setIsOpen(false)}
          aria-hidden="true"
        ></i>
        <div className="plan-selection text-center">
          <h5 className="mb-5">{props.item.name}</h5>
          <p
            dangerouslySetInnerHTML={{
              __html: props.item.description,
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default SinglePlan;
