import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  FacebookButton,
  TwitterButton,
  LinkedInButton,
  EmailButton,
} from "react-social";
import { getCurrentUser } from "../../../helpers/Utils";
const customStyles = {
  content: {
    position: "unset",
    maxWidth: "850px",
    width: "100%",
    right: "auto",
    border: "none",
    bottom: "auto",
    padding: "0",
    margin: "30px auto",
  },
};

const SingleClass = ({ data, classOption }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [readMore, setReadMore] = useState(true);
  const [liveClass, setLiveClass] = useState(data);
  const [sure, setSure] = useState(false);

  const link_url = liveClass.zoom_link;

  let url = `http://phpstack-663622-2172867.cloudwaysapps.com/liveclass/${liveClass.id}`;

  const Completionist = () => (
    <span className="text-danger d-inline-block text-center w-100 mb-3">
      Expired
    </span>
  );

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div className="liveclass_timer">
          <div id="countdown">
            <ul>
              <li>
                <span id="days">{days}</span>days
              </li>
              <li>
                <span id="hours">{hours}</span>Hours
              </li>
              <li>
                <span id="minutes">{minutes}</span>Minutes
              </li>
              <li>
                <span id="seconds">{seconds}</span>Seconds
              </li>
            </ul>
          </div>
        </div>
      );
    }
  };

  const removeClass = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/delete-live-class/${liveClass.id}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setSure(false);
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLiveClass("");
      });
  };

  if (liveClass !== "") {
    return (
      <div className="col-lg-6 mb-5">
        <div className="live_single">
          <div className="row">
            <div className="col-lg-6">
              <div className="live_single-imgs">
                <img
                  className="w-100"
                  src={liveClass.image}
                  alt=""
                  onClick={() => setIsOpen(true)}
                />
              </div>
            </div>
            <div className="col-lg-6 px-5 px-lg-0">
              <div className="live_single-content mt-2">
                <h5 className="text-capitalize" onClick={() => setIsOpen(true)}>
                  {liveClass.title}
                </h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html: liveClass.short_description,
                  }}
                ></p>
              </div>
              <div className="live_single-date mt-3">
                <p className="m-0 mb-1">
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                  {liveClass.start_date}
                </p>
                <p className="m-0">
                  <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                  {liveClass.start_time}
                  {liveClass.start_time ? ` - ${liveClass.end_time}` : ""}
                </p>
              </div>
              <button
                type="button"
                className="btn btn-dark mt-4"
                onClick={() => setIsOpen(true)}
              >
                View Detail
              </button>
              <Link
                className="btn btn-sm bg-transparent border-0 text-dark text-capitalize mt-4 px-3 text-center"
                to={`/edit/${liveClass.slug}`}
              >
                <i className="fa fa-edit m-0" />
              </Link>
              <button onClick={() => setSure(true)} className="mt-4 btn px-0">
                <i className="fa fa-trash" />
              </button>
              {/* <EmailIcon size={32} round={true} /> */}
            </div>
          </div>
        </div>
        {sure && (
          <SweetAlert
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="btn btn-primary"
            title="Are you sure?"
            btnSize="sm"
            onConfirm={() => removeClass()}
            onCancel={() => setSure(false)}
          >
            You will not be able to recover this class!
          </SweetAlert>
        )}
        <Modal
          ariaHideApp={false}
          isOpen={modalIsOpen}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="live_popup">
            <div className="modal-header pt-1 pb-0">
              <h4 className="text-center w-100 d-inline-block">
                {liveClass.title}
              </h4>
              <button onClick={() => setIsOpen(false)} className="close p-0">
                <span
                  style={{
                    fontSize: "28px",
                  }}
                  aria-hidden="true"
                >
                  ×
                </span>
              </button>
            </div>
            <div>
              <div className="p-3">
                <table className="table table-bordered border">
                  <tbody>
                    <tr>
                      <th className="p-2">
                        <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                        Date
                      </th>
                      <th className="p-2">
                        <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                        Time
                      </th>
                    </tr>
                    <tr>
                      <td className="p-2">
                        <span
                          style={{
                            paddingLeft: "17px",
                          }}
                        >
                          {liveClass.start_date}
                        </span>
                      </td>
                      <td className="p-2">
                        <span
                          style={{
                            paddingLeft: "17px",
                          }}
                        >
                          {liveClass.start_time}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <img
                src={liveClass.image}
                alt=""
                className="mw-100 m-auto d-block"
              />
              <div className="mt-5 p-3">
                <div className="row">
                  <div className="col-md-8">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: liveClass.description,
                      }}
                      className="live-adjustment"
                    />
                  </div>
                  <div className="col-md-4">
                    <Countdown
                      date={Date.now() + liveClass.start_time_left}
                      renderer={renderer}
                    >
                      <Completionist />
                    </Countdown>
                    <div className="tribe_info">
                      <div className="share_social"></div>
                      <div className="timer"></div>
                    </div>

                    {link_url !== null ? (
                      <a
                        target="_blank"
                        href={link_url}
                        className="join-style mb-3 d-inline-block text-center m-auto d-block"
                      >
                        Join the session
                      </a>
                    ) : (
                      ""
                    )}
                    <div className="social_share mt-5">
                      <div className="datetime text-center">
                        <h6>SHARE THIS EVENT</h6>
                        <ul className="social-icon">
                          <li>
                            <FacebookButton url={url} appId={417001573477517}>
                              <Link
                                to="https://www.twitter.com/"
                                className="facebook"
                              >
                                <i className="fa fa-facebook"></i>
                              </Link>
                            </FacebookButton>
                          </li>
                          <li>
                            <TwitterButton url={url} appId={417001573477517}>
                              <Link
                                className="twitter"
                                to="https://www.twitter.com/"
                                target="_blank"
                              >
                                <i className="fa fa-twitter"></i>
                              </Link>
                            </TwitterButton>
                          </li>
                          <li>
                            <LinkedInButton url={url} appId={417001573477517}>
                              <Link
                                className="linkedin"
                                to="https://www.linkedin.com/"
                                target="_blank"
                              >
                                <i className="fa fa-linkedin"></i>
                              </Link>
                            </LinkedInButton>
                          </li>
                          <li>
                            <EmailButton url={url} appId={417001573477517}>
                              <Link
                                className="email"
                                to="mailto:"
                                target="_blank"
                              >
                                <i className="fa fa-envelope"></i>
                              </Link>
                            </EmailButton>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="social_share mt-5">
                      <div className="datetime text-center">
                        <h6>LOCAL TIME</h6>
                        <div className="p-3">
                          <h5 style={{ fontSize: "16px" }}>
                            <b>Timezone: </b>
                            {liveClass.local_timezone_data &&
                              liveClass.local_timezone_data.timezone}
                          </h5>
                          <h5 style={{ fontSize: "16px" }}>
                            <b>Date: </b>
                            {liveClass.local_timezone_data &&
                              liveClass.local_timezone_data.start_date}
                          </h5>
                          <h5 style={{ fontSize: "16px" }}>
                            <b>Time: </b>
                            {liveClass.local_timezone_data &&
                              liveClass.local_timezone_data.time}
                          </h5>
                        </div>
                        <a
                          href={liveClass.add_to_calander}
                          target="_blank"
                          className="btn btn-primary mb-2"
                        >
                          <i className="fa fa-calendar" /> Add to My Calendar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  } else {
    return <></>;
  }
};

export default SingleClass;
