import React from "react";
import PropTyps from "prop-types";
import Modal from "react-modal";
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";

const customStyles = {
  content: {
    position: "unset",
    maxWidth: "650px",
    width: "100%",
    right: "auto",
    border: "1px solid rgb(204, 204, 204)",
    bottom: "auto",
    padding: "0",
    margin: "auto",
    overflow: "hidden",
  },
};

const TeamCard = ({ data }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const history = useHistory();

  const openTeacher = (id) => {
    history.push(`/member/${id}`);
  };

  return (
    <>
      <div
        onClick={() => openTeacher(data.id)}
        className="new-team-members-list"
      >
        <div className="thumb">
          <img src={data.image} alt="images" />
        </div>
        <div className="content">
          <h3 className="title">{data.name}</h3>
          <span>{data.designation && data.designation}</span>
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-header pt-1 pb-0">
          <h4 className="text-center w-100 d-inline-block">{data.name}</h4>
          <button onClick={() => setIsOpen(false)} className="close p-0">
            <span
              style={{
                fontSize: "28px",
              }}
              aria-hidden="true"
            >
              ×
            </span>
          </button>
        </div>
        <div
          className="pre_confirm-slots p-3"
          style={{
            maxHeight: "60vh",
            overflow: "auto",
          }}
        >
          <div className="w-100 text-left">
            <p style={{}} dangerouslySetInnerHTML={{ __html: data.about }} />
          </div>
        </div>
      </Modal>
    </>
  );
};

TeamCard.propTypes = {
  data: PropTyps.object,
};

export default TeamCard;
