import React from "react";
import PropTypes from "prop-types";
import MemberGrid from "../../../components/member";
import useSWR from "swr";


const TribeMemberItem = ({ data }) => {


    let members = '';

    if (data !== undefined) {
        members =
        <>
            {
                data.map((member, key) => {
                    return (
                        <div
                            key={key}
                            className="col-lg-4 col-sm-6 mb-7"
                        >
                            <MemberGrid
                                classOption="p-0"
                                key={key}
                                data={member}
                            />
                        </div>
                    );
                })
            }
        </>
    }


    return (
        <section className="blog-section py-4">
            <div className="row mb-n7">
                {members}
            </div>
        </section>
    );
};

TribeMemberItem.propTypes = {
    data: PropTypes.array,
};

export default TribeMemberItem;
