import React, { useEffect, useState } from "react";

const SinglePlan = (props) => {
	const [inHover, setHover] = useState(false);

	return (
		<div className="subscription_plans-single" key={props.i}>
            <h1>
                ${props.item.price}{" "}
                <span>{props.item.name}</span>
                <span>First 10 Days FREE</span>
            </h1>
            <div className="position-relative">
                {inHover && 
                    <div className="plan_popover">
                        <h4>{props.item.name}</h4>
                        <p dangerouslySetInnerHTML={{__html: props.item.description}} />
                    </div>
                }
                <button 
                    onClick={(e) =>
                        props.updatePlanId(
                            props.item.id,
                            props.item.price,
                            props.item
                        )
                    }
                    type="button"
                    className="btn btn-primary btn-hover-dark"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    Select Plan
                </button>
                <hr />
            </div>
        </div>
	)
}

export default SinglePlan;