import React from "react";
import RegistrationForm from "../../components/registration";

const RegistrationPage = () => {
    return (
        <section className="contact-section">
            <div className="container mt-5">
                <div className="w-100">
                    <RegistrationForm />
                </div>
            </div>
        </section>
    );
};

export default RegistrationPage;
