import React, { Component } from "react";
import axios from "axios";
import TeamCard from "./team-card";
import SubscriptionPlans from "./subscription";
import OtherInfo from "./info";
import Loader from "../loader";

let ref_code = localStorage.getItem("ref_id");

class RegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teacherData: "",
            id: "",
            teacherClass: "d-inline-block",
            planClass: "d-none",
            register: "d-none",
            error: "",
            pageLoader: true,
        };
        this.getAffiliates = this.getAffiliates.bind(this);
    }

    componentDidMount() {
        this.getAffiliates();
    }

    firstStep = (e) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        e.preventDefault();
        this.setState({
            teacherClass: "d-none",
            register: "d-block",
        });
    };

    secondStep = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.setState({
            planClass: "d-block",
            register: "d-none",
        });
    };

    getAffiliates = (e) => {
        var url = "";
        ref_code = localStorage.getItem("ref_id");
        if (ref_code > -1) {
            url = `${process.env.REACT_APP_API_URL}/affiliates?ref=${ref_code}`;
        } else if (e !== undefined) {
            url = `${process.env.REACT_APP_API_URL}/affiliates?search=${e.target.value}`;
        } else {
            url = `${process.env.REACT_APP_API_URL}/affiliates`;
        }

        var self = this;
        axios.get(url).then((res) => {
            self.setState({
                pageLoader: false,
                teacherData: res.data,
                error: "",
            });
            if (res.data.success === false) {
                self.setState({
                    error: res.data.message,
                });
            }
        });
    };
    render() {
        const {
            teacherData,
            teacherClass,
            planClass,
            firstStep,
            register,
            pageLoader,
        } = this.state;

        let teacher = this.state.error;
        if (teacherData.data !== undefined) {
            teacher = (
                <>
                    {this.state.error ? (
                        <p className="text-center alert alert-danger d-table p-2 m-auto mb-5 px-10">
                            {this.state.error}
                        </p>
                    ) : (
                        ""
                    )}
                    <div
                        className={`row ${
                            ref_code !== "null" ? "justify-content-center" : ""
                        }`}
                    >
                        {teacherData.data.map((teacher, key) => {
                            return (
                                <div
                                    key={key}
                                    className={`col-6 col-md-6 col-lg-4 col-xl-3 text-center mb-7 register_teachers`}
                                >
                                    <TeamCard
                                        parentCallback={this.handleCallback}
                                        key={key}
                                        data={teacher}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <button
                        id="contactSubmit"
                        type="button"
                        onClick={this.firstStep}
                        className="btn btn-dark btn-hover-dark d-block m-auto"
                    >
                        Continue to create account
                    </button>
                </>
            );
        }
        return (
            <>
                <div
                    className={`${teacherClass} w-100`}
                    style={{
                        padding: "60px 0",
                    }}
                >
                    <div className="contact-title-section pb-0">
                        {pageLoader ? (
                            <Loader />
                        ) : (
                            <>
                                {ref_code > -1 ? (
                                    <h3 className="title text-center mb-8">
                                        The below person has invited you.
                                    </h3>
                                ) : (
                                    <>
                                        <h2 className="text-center mb-3">
                                            How did you find out about us?
                                        </h2>
                                        <h3 className="title text-center mb-2">
                                            New Affiliate Referral - Who invited
                                            you? We want to thank them! *
                                        </h3>
                                        <input
                                            className="m-auto mb-5 d-block form-control"
                                            onChange={(e) =>
                                                this.getAffiliates(e)
                                            }
                                            type="search"
                                            placeholder="Search for names.."
                                            style={{
                                                maxWidth: "250px",
                                            }}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    {teacher}
                </div>
                <div className={`${planClass} w-100`}>
                    <div className="contact-title-section pb-0">
                        <h3 className="title text-center mb-5">
                            Please Select Your Plan
                        </h3>
                    </div>
                    <SubscriptionPlans secondStep={this.secondStep} />
                </div>
                <div className={`${register} w-100`}>
                    <OtherInfo secondStep={this.secondStep} />
                </div>
            </>
        );
    }
}

export default RegistrationForm;
