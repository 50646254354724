import React from "react";
import PropTypes from "prop-types";
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css'

const Testimonial = ({ data }) => {
  //   const Star = (props) => {
  // const starProps = { ...props };
  // const nameMap = {
  //   isDisabled: 'is-disabled',
  //   isActive: 'is-active',
  //   isActiveHalf: 'is-active-half',
  //   willBeActive: 'will-be-active'
  // };
    return (
        <div className="testimonial-slide-item swiper-slide text-center">
            <span className="quote zmdi zmdi-quote"></span>
            <p className="testimonial-text mt-2 mb-5" dangerouslySetInnerHTML={{__html: data.testimonial }} />
            <div className="avater">
                <div className="avater-profile m-0 mb-3">
                    {/* <img */}
                    {/*     src={data.image} */}
                    {/*     alt="avater" */}
                    {/* /> */}
                </div>
                <div className="avater-info">
                    <p>{data.name}</p>
                <Rater total={5} rating={5} interactive={false} />
                 <span dangerouslySetInnerHTML={{__html: data.designation }} />
                </div>
            </div>
        </div>
    );
};

Testimonial.propTypes = {
    data: PropTypes.object,
};

export default Testimonial;