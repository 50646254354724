import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import CreateLiveClassForm from "../components/create-live-class";
import PageLoader from '../components/page-loader'

function CreateLiveClass() {

	return (
		<React.Fragment>
        <PageLoader />
            <Layout>
                <SEO title="Soul Tribe Online Community – Create Live Class" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Create Live Class"
                    />
                    <div className="faq-section section-py">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <CreateLiveClassForm />
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
	)
}
export default CreateLiveClass;
