import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { setCurrentUser, getCurrentUser } from '../../helpers/Utils';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FriendRequest( props ) {
    const [acceptLoading, setAcceptLoading] = useState(false)
    const [rejectLoading, setRejectLoading] = useState(false)
    const [data, setData] = useState(props.data)


    const frientRequest = (requestStatus, id) => {
        props.setLoading(true)
        axios.post(
            `${process.env.REACT_APP_API_URL}/member/friend-request`, {
                status : requestStatus,
                member_id : id
            },
            {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${getCurrentUser().token}`
                },
            }
        )
        .then(function (res) {
            props.setLoading(false);
            setAcceptLoading(false);
            setRejectLoading(false);
            setData('')
            toast.success(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    const accept = (requestStatus, id) => {
        setAcceptLoading(true)
        frientRequest(requestStatus, id);
    }

    const reject = (requestStatus, id) => {
        setRejectLoading(true)
        frientRequest(requestStatus, id);
    }

    return (
        <>
            {data !== '' ? (
                <tr key={props.i}>
                    <td className="w-25">
                        <img src={data.image} alt="img" />
                    </td>
                    <td>
                        <h3 className="text-capitalize font-request">
                            <NavLink to={process.env.PUBLIC_URL + '/member/' + data.id}>
                                {data.name}
                            </NavLink>
                        </h3>
                    </td>
                    <td className="text-right">
                        {acceptLoading ? (
                            <button className="btn btn-primary py-2 px-4 request-width-profile" disabled>Loading</button>
                        ) : (
                            <button onClick={() => accept(1, data.id)} className="btn btn-primary py-2 px-4 request-width-profile">Accept</button>
                        )}
                    </td>
                    <td className="text-right">
                        {rejectLoading ? (
                            <button className="btn btn-primary py-2 px-4 request-width-profile" disabled>Loading</button>
                        ) : (
                            <button onClick={() => reject(2, data.id)} className="btn btn-primary py-2 px-4 request-width-profile">Reject</button>
                        )}
                    </td>
                </tr>
            ) : ''}
        </>
    )
}

export default FriendRequest;