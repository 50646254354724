import React, { useState, useEffect } from "react";
import Layout from "../layouts/index";
import SEO from "../components/seo";
import PageLoader from "../components/page-loader";
import Logo from "../components/logo";
import axios from "axios";
import IntroContainer from "../containers/home/intro";
import { NavLink, Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { setCurrentUser } from "../helpers/Utils";

const NovemberGratitude = () => {
  return (
    <React.Fragment>
      <PageLoader />
      <Layout>
        <SEO title="SoulTribeOnline November Gratitude" />
        <div className="wrapper">
          <header className="header-section position-relative">
            <div className="container">
              <div className="header-logo text-center">
                <Logo
                  classOption="d-block"
                  image={`${process.env.PUBLIC_URL}/images/logo/soultribe.png`}
                />
              </div>
            </div>
          </header>
          <div className="marketing_form november-gratitude mt-5 pt-5">
            <div className="container">
              <div className="title-section title-section text-center">
                <h3 className="title">SoulTribeOnline November Gratitude</h3>
              </div>
              <img
                className="mw-100 m-auto d-block pb-3"
                src={`${process.env.PUBLIC_URL}/images/sto-nov.png`}
                alt="s"
              />
              <p>
                You are invited! Live Daily Meditation and Community Connection
                all month long in November. Join us at{" "}
                <a
                  href="https://us02web.zoom.us/j/84099238305?pwd=S21XKzRJcitDV202VWYyTXVuUVJXQT09"
                  target="_blank"
                  rel="noreferrer"
                  className="highlight"
                >
                  6am-6:30am Pacific
                </a>{" "}
                for light movement, breathwork and the Mantra of Miracles, Guru
                Guru Wahe Guru Guru Ram Das Guru.
              </p>
              <p>
                <a
                  href="https://us02web.zoom.us/j/84099238305?pwd=S21XKzRJcitDV202VWYyTXVuUVJXQT09"
                  target="_blank"
                  rel="noreferrer"
                  className="highlight"
                >
                  Click HERE
                </a>{" "}
                to Join the Session!
              </p>
              <p>
                Join our newsletter to stay updated on events and offerings!
              </p>

              <p>
                Interested in joining our daily practices, yoga classes, and
                astrological and gene keys classes, too? Go to{" "}
                <a href="soultribeonline.com" target="_blank" rel="noreferrer">
                  soultribeonline.com
                </a>{" "}
                for options on a sliding fee scale to fit your budget and your
                heart.{" "}
              </p>
            </div>
          </div>
          <footer className="footer-section mt-4">
            <div className="container">
              <div className="copy-right-section">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="copyright-info text-center">
                        <span>
                          © {new Date().getFullYear()}{" "}
                          <Link
                            to="https://soultribeonline.com/"
                            target="_blank"
                          >
                            Soul Tribe Online
                          </Link>
                          . All rights reserved. &nbsp;
                          <Link to={process.env.PUBLIC_URL + "/privacy"}>
                            Privacy Policy
                          </Link>{" "}
                          &nbsp;| &nbsp;
                          <Link
                            to={process.env.PUBLIC_URL + "/terms-conditions"}
                          >
                            Terms & Conditions
                          </Link>
                        </span>
                        <p className="m-0">
                          Questions? Send us an email at{" "}
                          <Link
                            to="#"
                            onClick={(e) => {
                              window.location.href =
                                "mailto:info@soultribeonline.com";
                              e.preventDefault();
                            }}
                          >
                            info@soultribeonline.com
                          </Link>
                        </p>
                        <p>
                          For support email at{" "}
                          <Link
                            to="#"
                            onClick={(e) => {
                              window.location.href =
                                "mailto:support@soultribeonline.com";
                              e.preventDefault();
                            }}
                          >
                            support@soultribeonline.com
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default NovemberGratitude;
