import React from "react";
import useSWR from "swr";
import Loader from "../../components/loader";

const API_URL =
    `${process.env.REACT_APP_API_URL}/page/privacy-policy`;

async function fetcher(url) {
    const res = await fetch(url);
    const json = await res.json();
    return json;
}

const Privacy = () => {
    const { data } = useSWR(API_URL, fetcher);
    if (!data) return <Loader />
    let content = '';
    if (data !== undefined) {
        content = <div dangerouslySetInnerHTML={{ __html: `${data.data.description}` }}/>;
    }

    return (
        <section className="contact-section section-py mt-5 content">
            <div className="container">
                {content}
            </div>
        </section>
    );
};

export default Privacy;
