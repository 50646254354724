import React, { useState, useEffect } from "react";
import SectionTitle from "../section-title";
import { NavLink } from "react-router-dom";
import Loader from "../loader";
import Payment from "./payment";
import { setPlanId, getPlanId } from "../../utils";
import { getCurrentUser } from "../../helpers/Utils.js";
import axios from "axios";
import Modal from "react-modal";
import SinglePlan from "./single-plan";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "845px",
        width: "100%",
        // height: '80%'
    },
};

const SubscriptionPlans = ({ secondStep }) => {
    const [id, setId] = useState({ teacher_id: "" });
    const [data, setData] = useState([]);
    const [monthly, setMonthlyView] = useState(true);
    const [yearly, setYearlyView] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState("");
    const [selectedPlan, setSelectedPlan] = useState("");

    const createCustomerStripe = () => {
        setLoading(<Loader />);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/member/create-customer`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading("");
                if (res.data.success === true) {
                    setModalIsOpen(true);
                }
            });
    };

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/membership-levels`)
            .then((res) => {
                setData(res.data);
            });
    }, []);

    const updatePlanId = (id, price, item) => {
        setSelectedPlan(item);
        setPlanId(id);
        createCustomerStripe();
    };

    let plan = "";

    if (!data) {
        return <Loader />;
    }

    //Change Plan
    const changeState = () => {
        setMonthlyView(true);
        setYearlyView(false);
    };

    const changeStateYear = () => {
        setMonthlyView(false);
        setYearlyView(true);
    };

    //Check plan
    let monthly_plan = "";

    if (plan !== undefined && data.data !== undefined) {
        monthly_plan = (
            <>
                {data.data.memberShip.map((item, i) => (
                    <>
                        {item.cycle === "month" ? (
                            <SinglePlan
                                item={item}
                                updatePlanId={updatePlanId}
                                i={i}
                            />
                        ) : (
                            ""
                        )}
                    </>
                ))}
            </>
        );
    }

    let yearly_plan = "";
    if (plan !== undefined && data.data !== undefined) {
        yearly_plan = (
            <>
                {data.data.memberShip.map((item, i) => (
                    <>
                        {item.cycle === "year" ? (
                            <SinglePlan
                                item={item}
                                updatePlanId={updatePlanId}
                                i={i}
                            />
                        ) : (
                            ""
                        )}
                    </>
                ))}
            </>
        );
    }

    return (
        <>
            <div className="subscription section-pb">
                <div className="container">
                    <div className="plans">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="subscription_info">
                                    <div className="subscription_info-title">
                                        <h1>Subscribe to Soul Tribe</h1>
                                    </div>
                                    <div className="subscription_info-subtitle">
                                        <span>
                                            FIRST 10 DAYS FREE! <br />
                                            (WITH MONTHLY SUBSCRIPTION)
                                        </span>
                                    </div>
                                    <div className="subscription_info-desc">
                                        <p>
                                            <i className="fa fa-check-circle"></i>{" "}
                                            <span>
                                                Livestream classes every week{" "}
                                            </span>
                                        </p>
                                        <p>
                                            <i className="fa fa-check-circle"></i>{" "}
                                            <span>
                                                Access to full library of
                                                classes and lectures by
                                                certified Kundalini Yoga
                                                teachers{" "}
                                            </span>
                                        </p>
                                        <p>
                                            <i className="fa fa-check-circle"></i>{" "}
                                            <span>
                                                Social network (Lovestream) free
                                                of advertising and dogma{" "}
                                            </span>
                                        </p>
                                        <p>
                                            <i className="fa fa-check-circle"></i>{" "}
                                            <span>
                                                Unlimited participation in
                                                tribes and teachers to create
                                                community{" "}
                                            </span>
                                        </p>
                                        <p>
                                            <i className="fa fa-check-circle"></i>{" "}
                                            <span>
                                                Connect with other members and
                                                create your own tribes!{" "}
                                            </span>
                                        </p>
                                        <div className="clearfix" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="subscription_plans">
                                    {loading}
                                    <ul className="mb-5">
                                        <li
                                            className={`text-decoration-none btn ${
                                                monthly
                                                    ? "btn-primary"
                                                    : "btn-secondary"
                                            }`}
                                            onClick={(e) => changeState()}
                                        >
                                            Monthly
                                        </li>
                                        <li
                                            className={`text-decoration-none btn ${
                                                yearly
                                                    ? "btn-primary"
                                                    : "btn-secondary"
                                            }`}
                                            onClick={(e) => changeStateYear()}
                                        >
                                            Yearly
                                        </li>
                                    </ul>
                                    {monthly ? monthly_plan : yearly_plan}
                                </div>
                            </div>
                        </div>
                        <p className="mt-6">
                            * You may cancel your subscription at any time
                            before your card is charged or before the free trial
                            expires. There is no minimum subscription
                            requirement. Please{" "}
                            <NavLink
                                className="footer-link"
                                to={process.env.PUBLIC_URL + "terms-conditions"}
                            >
                                Please READ TERMS AND CONDITIONS{" "}
                            </NavLink>
                            <br />
                            <br />{" "}
                            <NavLink
                                className="footer-link"
                                to={process.env.PUBLIC_URL + "faqs"}
                            >
                                {" "}
                                FAQ{" "}
                            </NavLink>
                        </p>
                    </div>
                </div>
            </div>
            <Modal isOpen={modalIsOpen} style={customStyles}>
                <i
                    className="fa fa-window-close float-right"
                    onClick={(e) => setModalIsOpen(false)}
                    aria-hidden="true"
                ></i>
                <br />
                <Payment selectedPlan={selectedPlan} />
            </Modal>
        </>
    );
};

export default SubscriptionPlans;
