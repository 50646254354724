import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getCurrentUser } from '../../helpers/Utils';
import axios from "axios";
import Modal from "react-modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';

const customStyles = {
    content: {
        position: "unset",
        maxWidth: "350px",
        width: "100%",
        right: "auto",
        border: "none",
        bottom: "auto",
        padding: "50px",
        margin: "250px auto",
    },
};

const TribeGrid = ({ data, classOption, myTribes }) => {
    const [group, setGroup] = useState(data)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showBox, setShowBox] = useState(true);
    const [isTeacher, setisTeacher] = useState('');
    const [sure, setSure] = useState(false);

    const joinGroup = () => {
        setLoading(true);
        setSure(false);
        axios
            .post(`${process.env.REACT_APP_API_URL}/tribe-group-access`,
                {
                    tribe_id: group.id
                }, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser() && getCurrentUser().token}`,
                },
            }
            )
            .then(function (res) {
                setLoading(false);
                setModalIsOpen(false)
                setGroup(res.data.data);
                myTribes()
                toast.success(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    const getRole = () => {
        let role = [];
        let teacher = '';
        role = getCurrentUser().role;
        if (role !== null) {
            role.map((item) => {
                return (teacher = item.label_teacher)
            });
            setisTeacher(teacher);
        }
    };

    let tribeInfo = ''

    if (getCurrentUser() && getCurrentUser().allow_access !== "0" && isTeacher === 'teacher' || getCurrentUser()) {
        tribeInfo = <div className="d-flex justify-content-around align-content-center align-items-center px-3">
            <div className="w-50 tribes_thumb">
                <img alt="" className="mw-100" src={group.image} />
            </div>
            <div className="tribes_join">
                {group.member_type === null ? (
                    <>
                        {getCurrentUser() ? (
                            <>
                                {group.group_type === 'public' ? (
                                    <>
                                        {group.is_join === 1 ? (<button onClick={() => setSure(true)} type="button">You're a Member</button>) : ''}
                                        {group.is_join === '' ? (<button onClick={() => setSure(true)} type="button">Join tribe</button>) : ''}
                                    </>
                                ) : ''}
                                {group.group_type === 'private' ? (
                                    <>
                                        {group.is_join === 1 ? (<button onClick={() => setSure(true)} type="button">You're a Member</button>) : ''}
                                        {group.is_join === 0 ? (<button>Request Sent</button>) : ''}
                                        {group.is_join === "" ? (<button onClick={() => setSure(true)} type="button">Request Access</button>) : ''}
                                    </>
                                ) : ''}
                            </>
                        ) : ''}</>
                ) : (
                    <button>{group.member_type}</button>
                )}
            </div>

        </div>
    }

    useEffect(() => {
        if (getCurrentUser()) {
            getRole();
        }
    }, [isTeacher])

    return (
        <>
            <div className="blog-card tribes">
                <div className="thumb bg-light text-center p-0">
                    <img alt=""
                        src={group.cover_image}
                    />
                </div>
                {tribeInfo}
                <div className="members mt-3 tribes_content px-3">
                    <h5 className="title">
                        <NavLink
                            to={process.env.PUBLIC_URL + `/tribe/${group.slug}`}
                        >
                            {group.title}
                        </NavLink>
                    </h5>
                    <span className="blog-meta text-capitalize">
                        {group.group_type ? `${group.group_type} Group` : ''}
                    </span>

                    <div className="tribes_content-members">
                        <div className="member_thumbs d-inline-block">
                            {
                                group.group_member_image.map((img, i) => {
                                    return (
                                        <img key={i} alt="" src={img} />
                                    )
                                })
                            }
                        </div>
                        <span className="blog-meta p-3 d-inline-block">
                            {group.group_members_count ? `+ ${group.group_members_count} Members` : ''}
                        </span>
                    </div>
                </div>

            </div>
            {sure && <SweetAlert
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="btn btn-primary"
                title="Are you sure?"
                btnSize="sm"
                onConfirm={() => joinGroup()}
                onCancel={() => setSure(false)}
            >
            </SweetAlert>}
        </>
    );
};


export default TribeGrid;
