import React, {useState} from 'react'
import { getCurrentUser } from '../../helpers/Utils';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ChangePassword() {
    const [loading, setLoading] = useState(false);
    

	const updatePassword = (e) => {
		e.preventDefault();
        setLoading(true)
        axios.post(
            `${process.env.REACT_APP_API_URL}/change-password`, {
                old_password: e.target.old_password.value,
                new_password: e.target.new_password.value,
                new_password_confirmation: e.target.new_password_confirmation.value
            },
            {
                headers: {
                    'Authorization': `Bearer ${getCurrentUser().token}`
                },
            }
        )
        .then(function (res) {
            setLoading(false)
            if (res.data.success === true) {
                document.getElementById("update_password").reset();
                toast.success(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }
	return (
		<div className="change_image">
            <div className="bp-widget base">
               	<h3 className="screen-heading profile-group-title">Change Password</h3>
				<form id="update_password" onSubmit={updatePassword}>
                    <div className="form-group mt-4">
                        <label className="m-0">Old Password</label>
                        <input
                            name="old_password"
                            type="password"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="m-0">New Password</label>
                        <input
                            name="new_password"
                            type="password"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="m-0">Confirm New Password</label>
                        <input
                            name="new_password_confirmation"
                            type="password"
                            className="form-control"
                        />
                    </div>
					<div className="form-group mt-4 text-center">
                        {loading ? (
                             <button className="btn btn-primary m-2 py-3" disabled>Loading</button>
                        ) : (
                            <button className="btn btn-primary m-2 py-3" type="submit">Update Password</button>
                        )}
                        <br />          
					</div>
				</form>
            </div>
        </div>
	)
}
export default ChangePassword;