import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { setCurrentUser, getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';

function SingleClass(classes) {
	const [data, setData] = useState(classes.classes);
	const [sure, setSure] = useState(false);

	const removeClass = (e) => {
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/remove-favourite-class?class_id=${e}`,
				{
					headers: {
						"content-type": "application/json",
						Authorization: `Bearer ${getCurrentUser().token}`,
					},
				}
			)
			.then(function (res) {
				setData("");
				setSure(false);
				toast.success(res.data.message, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});	
	};

	if (data !== '') {
		return (
			<>
				<tr key={data.id}>
					<td>
						<div className="d-flex align-items-center w-100">
							<img src={data.image} alt="img" />
							<h3 className="text-capitalize" style={{marginLeft: '20px'}}>
								<Link
									to={
										process.env.PUBLIC_URL +
										`/class/${data.slug}`
									}
								>
									{data.title}
								</Link>
							</h3>
						</div>
					</td>
					<td className="text-right profile-group-title">
						<button className="px-4 mt-2" onClick={() => setSure(true)}>
							<i className="fa fa-trash"></i>
						</button>
					</td>
				</tr>
				{sure && <SweetAlert
	                showCancel
	                confirmBtnText="Yes, remove it!"
	                confirmBtnBsStyle="btn btn-primary"
	                title="Are you sure?"
	                btnSize="sm"
	                onConfirm={() => removeClass(data.id)}
	                onCancel={() => setSure(false)}
	            >
	            </SweetAlert>}
			</>
		);
	} else {
		return "";
	}
}

export default SingleClass;
