import React from "react";
import { NavLink } from "react-router-dom";

const CourseGrid = ({ course }) => {
    return (
        <div className="col-lg-3 col-md-4 col-sm-6 mb-7">
            <div className={`thumb bg-light text-center`}>
                <NavLink to={process.env.PUBLIC_URL + `/course/${course.slug}`}>
                    <img src={course.image} alt="img" />
                </NavLink>
            </div>
            <div className="class-content mt-3">
                <h3 className="title text-capitalize">
                    <NavLink
                        to={process.env.PUBLIC_URL + `/course/${course.slug}`}
                    >
                        {course.title}
                    </NavLink>
                </h3>
            </div>
            <p>{course.short_description}</p>
            <span
                style={{
                    fontSize: "14px",
                    lineHeight: "24px",
                }}
            >
                {course.author && course.author.name}
                &nbsp; | &nbsp; Price: ${course.price}
            </span>
        </div>
    );
};
export default CourseGrid;
