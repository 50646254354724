import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import LoginContainer from "../containers/login";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import PageLoader from '../components/page-loader'


const LoginPage = () => {
    return (
        <React.Fragment>
        <PageLoader />
            <Layout>
                <SEO title="Soul Tribe Online Community – Login" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Login"
                        excerpt=""
                        image="./images/contact/1.png"
                    />
                    <LoginContainer />
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default LoginPage;
