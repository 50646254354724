import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../../components/section-title";
import useSWR from "swr";
import TeamCard from "../../../components/team-card";
import Loader from "../../../components/loader";
import axios from 'axios';

const TeamContainer = ({ classOption }) => {
    const [data, setData] = useState([])

    const getData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/teachers`, {})
        .then(function (res) {
            setData(res.data.data);
        }).catch(function (err) {
            console.log(err)
        });
    }

    useEffect(() => {
        getData();
    }, []);

    let teacher = '';

    if (data !== undefined) {
        teacher = 
        <>
            {
                data.map((teacher, key) => {
                return (
                    <div
                        key={key}
                        className="col-md-6 col-6 col-lg-4 col-xl-3 text-center"
                    >
                        <TeamCard key={key} data={teacher} />
                    </div>
                );
            })}
        </>
    } else {
        teacher = <Loader />
    }

    return (
        <div className={`team-section team-sectionone overflow-hidden ${classOption}`}>
            <div className="container position-relative">
                <SectionTitle
                    classOption="title-section text-center"
                    title="All TEACHERS"
                    excerptClassOption="mb-5"
                />
                <div className="row align-items-center pt-8">
                    {teacher}
                </div>
            </div>
        </div>
    );
};

TeamContainer.propTypes = {
    classOption: PropTypes.string,
};

TeamContainer.defaultProps = {
    classOption: "section-pb",
};

export default TeamContainer;
