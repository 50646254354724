import React from "react";
import PropTypes from "prop-types";

const BlogDetailsWrap = () => {
    return (
        <div className="blog-details-wrap">
            <div className="blog-details-thumb">
                {/* <img */}
                {/*     src={`${process.env.PUBLIC_URL}/${data.media.largeImage}`} */}
                {/*     alt="img" */}
                {/* /> */}
            </div>
            <p className="blog-details-meta">
                {/* {data.author */}
                <span className="separator">-</span>
                {/* {data.date */}
            </p>
            {/* <h3 className="blog-details-title">{data.title}</h3> */}
        </div>
    );
};

BlogDetailsWrap.propTypes = {
    data: PropTypes.object,
};

export default BlogDetailsWrap;
