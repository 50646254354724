import React, { useState, useEffect, useCallback } from "react";
import { getCurrentUser, setCurrentUser } from "../../helpers/Utils";
import { NavLink } from "react-router-dom";
import ScrollToBottom from "react-scroll-to-bottom";
import io from "socket.io-client";
import axios from "axios";
import SingleMessage from "./single-message";


function SingleThread(props) {
    const [info, setInfo] = useState(false);
    const [chat, setChat] = useState(props.data);
    
    const handleMessageSent = useCallback((type, message) => {
        if (type === "new_message") {
            setChat(prevState => ([...prevState, message]));
        }
    }, []);

    useEffect(() => {
        setChat(props.data)
    }, [props.data]);

    useEffect(() => {
        setProfileData();
        const socket = io("https://sockets.soultribeonline.com");
        socket.on(localStorage.getItem("thread_id"), handleMessageSent);
        return () => {
            socket.off(localStorage.getItem("thread_id"), handleMessageSent);
        };
    }, [chat]);


    const moreInfoToggle = () => {
        if (info === true) {
            setInfo(false);
        } else setInfo(true);
    };

    if (info === true) {
        const concernedElement = document.querySelector(
            ".contact-profile-info"
        );
        document.addEventListener("mousedown", (event) => {
            if (concernedElement.contains(event.target)) {
            } else {
                setInfo(false);
            }
        });
    }

    const setProfileData = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/member/profile`, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                localStorage.setItem(
                    "unreadMessages",
                    res.data.data.unread_message_count
                );
                const user_data = res.data.data;
                setCurrentUser(user_data);
            });
    };

    const loadMoreMessages = () => {
        props.nextPageChat();
    };

    return (
        <>
            <div className="contact-profile">
                {props.threadChatHead.thread_type === "group" ? (
                    <>
                        <NavLink
                            to={`${process.env.PUBLIC_URL}/tribe/${
                                props.threadChatHead.participant.thread.tribe && props.threadChatHead.participant.thread.tribe.slug
                            }`}
                        >
                            <img
                                src={
                                    props.threadChatHead.participant.thread.tribe && props.threadChatHead.participant.thread.tribe.image
                                }
                                alt=""
                            />
                            <div>
                                <p className="text-capitalize">
                                    {props.threadChatHead.participant.thread.tribe && props.threadChatHead.participant.thread.tribe.title}
                                </p>
                            </div>
                        </NavLink>
                    </>
                ) : (
                    <>
                        <NavLink
                            to={`${process.env.PUBLIC_URL}/member/${
                                props.threadChatHead.user_data &&
                                props.threadChatHead.user_data.id
                            }`}
                        >
                            <img
                                src={
                                    props.threadChatHead.user_data
                                        ? props.threadChatHead.user_data.image
                                        : ""
                                }
                                alt=""
                            />
                            <div>
                                <p>
                                    {props.threadChatHead.user_data
                                        ? props.threadChatHead.user_data.name
                                        : "Deleted User"}
                                </p>
                            </div>
                        </NavLink>
                    </>
                )}
                {/* <div className="contact-profile-info"> */}
                {/*     <i onClick={moreInfoToggle} className="fa fa-ellipsis-v"></i> */}
                {/*     {info ? ( */}
                {/*         <div className="info_menu"> */}
                {/*             <ul> */}
                {/*                 <li>Delete conversation</li> */}
                {/*             </ul> */}
                {/*         </div> */}
                {/*     ) : ''} */}
                {/* </div> */}
            </div>
            {/* <div className="" ref={chatContainer}> */}
            <ScrollToBottom className="messages bottomscroll">
                <div className="text-center py-2">
                    {props.nextLoading ? (
                        <button
                            style={{
                                fontSize: "13px",
                                padding: "12px 20px",
                            }}
                            className={`${
                                props.pagination ? "" : "d-none"
                            } btn btn-primary btn-sm text-sm`}
                        >
                            Loading
                        </button>
                    ) : (
                        <button
                            style={{
                                fontSize: "13px",
                                padding: "12px 20px",
                            }}
                            className={`${
                                props.pagination ? "" : "d-none"
                            } btn btn-primary btn-sm text-sm`}
                            onClick={loadMoreMessages}
                        >
                            Load More
                        </button>
                    )}
                </div>
                <ul>
                    {chat && chat.map((item, i) => (
                        <SingleMessage item={item} />
                    ))}
                </ul>
            </ScrollToBottom>
            {/* </div> */}
        </>
    );
}
export default SingleThread;
