import React, { useState } from "react";
import { getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";
import CoursePayment from "./course-payment";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
// import ClassDetailsWrap from '../../../components/class-details'
import { Editor } from "react-draft-wysiwyg";
import Modal from "react-modal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "845px",
        width: "100%",
        // height: '80%'
    },
};

const CourseDetailsContainer = ({ data }) => {
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const checkThread = () => {
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/thread`,
                {
                    message: "",
                    group_id: "",
                    receiver_id: data && data.author && data && data.author.id,
                },
                {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                window.location.href = `/messages?thread_id=${res.data && data.data && data.subject}`;
            });
    };

    return (
        <>
            <div className="blog-section section-py course__page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-7">
                            <p
                                className="mb-5"
                                dangerouslySetInnerHTML={{
                                    __html: `${data && data.description}`,
                                }}
                            />
                            {data && data.is_payment !== 0 ? (
                                <div className="paid_sec mt-5">
                                    {data && data.video &&
                                        data && data.video.map((video) => (
                                            <div
                                                className="mb-3"
                                                key={video.id}
                                            >
                                                <h5>{video.title}</h5>
                                                <iframe
                                                    src={video.video}
                                                ></iframe>
                                            </div>
                                        ))}
                                </div>
                            ) : (
                                ""
                            )}
                            <h5 className="mb-4">About Instructor</h5>
                            <div className="post_header align-items-center d-flex justify-content-between">
                                <div className="current-user">
                                    <img src={data && data.author && data && data.author.image} />
                                    <span>{data && data.author && data && data.author.name}</span>
                                </div>
                                <div className="ss px-2">
                                    {getCurrentUser().id == data.author_id ? '' : (
                                        <>
                                            {data && data.author && data && data.author.member_thread === null ? (
                                                <>
                                                    {loading ? (
                                                        <button
                                                            className="btn btn-sm btn-dark"
                                                            disabled
                                                        >
                                                            <i className="fa fa-comment"></i>{" "}
                                                            Loading
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={
                                                                checkThread
                                                            }
                                                            className="btn btn-sm btn-primary"
                                                        >
                                                            <i className="fa fa-comment"></i>{" "}
                                                            Message
                                                        </button>
                                                    )}
                                                </>
                                            ) : (
                                                <NavLink
                                                    to={
                                                        process.env.PUBLIC_URL +
                                                        `/messages?thread_id=${data && data.author && data && data.author.member_thread}`
                                                    }
                                                >
                                                    <button className="btn btn-sm btn-primary">
                                                        <i className="fa fa-comment"></i>{" "}
                                                        Message
                                                    </button>
                                                </NavLink>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-5">
                            <div className="course__page-sidebar">
                                <img src={data && data.image} />
                                <div className="p-4 text-center">
                                    {getCurrentUser().id == data.author_id ? '' : (
                                        <>
                                            {data && data.is_payment === 0 ? (
                                                <>
                                                    <button className="w-100 btn btn-dark">
                                                        Not Enrolled
                                                    </button>
                                                    <button
                                                        className="w-100 btn btn-primary mt-2"
                                                        onClick={() =>
                                                            setModalIsOpen(true)
                                                        }
                                                    >
                                                        Take this Course
                                                    </button>
                                                </>
                                            ) : (
                                                <button className="w-100 btn btn-primary">
                                                    Enrolled
                                                </button>
                                            )}
                                        </>
                                    )}
                                    <h5 className="text-center mt-4">
                                        ${data && data.price}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={modalIsOpen} style={customStyles}>
                <i
                    className="fa fa-window-close float-right"
                    onClick={(e) => setModalIsOpen(false)}
                    aria-hidden="true"
                ></i>
                <CoursePayment data={data} />
            </Modal>
        </>
    );
};
export default CourseDetailsContainer;
