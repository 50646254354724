import React, { useEffect, useState } from "react";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { useHistory } from "react-router-dom";
import PageBanner from "../containers/global/page-banner";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { getCurrentUser } from "../helpers/Utils";
import Loader from "../components/loader";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import LoveStreamPost from "../components/lovestream/Post";

function SinglePostPage({
    match: {
        params: { id },
    },
}) {
    const history = useHistory();
    const [postId] = useState(parseInt(id, 10));
    const [loader, setLoader] = useState("");
    const [post, setPost] = useState("");
    const [error, setError] = useState("");
    const [allMember, setAllMember] = useState([]);

    const getMember = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/show-members-limited-data`, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setAllMember(res.data.data);
            });
    };

    const getSinglePost = (id) => {
        setLoader(<Loader />);
        setPost("");
        let url = `${process.env.REACT_APP_API_URL}/get-post/${postId}`;
        if (id !== undefined) {
            url = `${process.env.REACT_APP_API_URL}/get-post/${id}`;
        }
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${
                        getCurrentUser() && getCurrentUser().token
                    }`,
                },
            })
            .then(function (res) {
                setLoader("");
                if (res.data.success) {
                    setPost(res.data.data);
                    setError("");
                } else {
                    setError(res.data.message);
                }
            })
            .catch(function (error) {
                if (!error.response) {
                    console.log('network error')
                }
            });
    };

    useEffect(() => {
        history.listen((location) => {
            getSinglePost(location.pathname.replace(/\D/g, ""));
        });
        if (getCurrentUser()) {
            getSinglePost();
            getMember();
        }
    }, []);

    return (
        <React.Fragment>
            <Layout>
                <SEO title="Soul Tribe Online Community – Post" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={`${post.user ? post.user.name : ""}`}
                        excerpt=""
                        image="./images/contact/1.png"
                    />
                    {error ? (
                        <span className="alert alert-danger d-table m-auto mt-5 px-5 py-2">
                            {error}
                        </span>
                    ) : (
                        ""
                    )}
                    {loader}
                    <div className="container mb-5">
                        <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-10">
                                <div className="post">
                                    {post !== "" ? (
                                        <LoveStreamPost
                                            post={post}
                                            mentionAllMember={allMember}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
}

export default SinglePostPage;
