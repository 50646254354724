import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import NavScrollTop from "./components/nav-scroll-top";
import HomePage from "./pages/index";
import AboutPage from "./pages/about";
import LoginPage from "./pages/login";
import EditClassPage from "./pages/editclass";
import CreateLovestreamPage from "./pages/lovestream";
import ServicePage from "./pages/service";
import ServiceDetails from "./templates/service-details";
import TeamPage from "./pages/team";
import FaqPage from "./pages/faqs";
import TeachersPage from "./pages/teachers";
import ClassesPage from "./pages/classes";
import MembersPage from "./pages/members";
import BlogPage from "./pages/blog";
import TribePage from "./pages/tribes";
import PrivacyPage from "./pages/privacy";
import TermsPage from "./pages/terms-conditions";
import LivePage from "./pages/live";
import BlogLeftSidebarPage from "./pages/blog-left-sidebar";
import BlogRightSidebarPage from "./pages/blog-right-sidebar";
import BlogDetailsPage from "./templates/blog-details";
import TribeDetailsPage from "./templates/tribe-details";
import ClassDetailsPage from "./templates/class-details";
import CourseDetailsPage from "./templates/course-details";
import LiveClassDetailsPage from "./templates/liveclass-details";
import BlogAuthor from "./templates/blog-author";
import BlogDate from "./templates/blog-date";
import BlogTag from "./templates/blog-tag";
import BlogCategory from "./templates/blog-category";
import ContactPage from "./pages/contact";
import ForgotPasswordPage from "./pages/forgot-password";
import Resetpassword from "./pages/reset-password";
import RegistrationPage from "./pages/registration";
import ProfilePage from "./pages/ProfilePage";
import CreateGroup from "./pages/create-group";
import EditProfile from "./pages/edit-profile";
import MemberAccountPage from "./pages/membership-account";
import AffiliatesPage from "./pages/affiliates";
import MessagesPage from "./pages/messages";
import CreateLiveClass from "./pages/create-live-class";
import UpdateLiveClass from "./pages/edit-live-class";
import TeacherOrientationMaterial from "./pages/teacher-orientation-materials";
import InternalCalendar from "./pages/internal-calendar";
import NotificationPage from "./pages/notifications";
import CheckoutPage from "./pages/checkout";
import ProfileDetail from "./templates/profile-detail";
import SinglePostPage from "./templates/post";
import SubPlans from "./pages/plans";
import NoPage from "./pages/404";
import JoinForFree from "./pages/join-for-free";
import MotherTreeRetreat from "./pages/mother-tree-retreat";
import NovemberGratitude from "./pages/november-gratitude";
import { setCurrentUser, getCurrentUser } from "./helpers/Utils";
import CoursePage from "./pages/course";
import axios from "axios";
import io from "socket.io-client";
import moment from "moment";
import Loader from "./components/loader";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopBarProgress from "react-topbar-progress-indicator";
import CustomSwitch from "./CustomSwitch";

import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";
import "./assets/css/vendor/metropolis.css";
import "./assets/css/vendor/icofont.min.css";
import "./assets/css/vendor/font-awesome.css";
import "./assets/css/vendor/material-design-iconic.min.css";
import "./assets/css/plugins/animate.min.css";
import "./assets/scss/style.scss";

const App = () => {
  const [isTeacher, setisTeacher] = useState("teacher");
  const [progress, setProgress] = useState(false);
  const [loader, setLoader] = useState(true);
  const [prevLoc, setPrevLoc] = useState({});
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  // const location = useLocation();

  const getSubscription = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/stripe/subscriptions/${
          getCurrentUser().stripe_subscription_id
        }`
        // {
        //   headers: {
        //     "content-type": "application/json",
        //     Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        //   },
        // }
      )
      .then(function (res) {
        var currentTimestamp = Math.floor(new Date() / 1000);
        let endDate;
        if (res.data.ended_at) {
          endDate = res.data.ended_at;
        } else {
          endDate = res.data.current_period_end;
        }
        if (
          res.data.status == "active" ||
          res.data.status == "trialing" ||
          endDate > currentTimestamp
        ) {
          setSubscriptionStatus(true);
        } else {
          setSubscriptionStatus(false);
        }
        setLoader(false);
      })
      .catch(function (err) {
        setLoader(false);
        setSubscriptionStatus(false);
      });
  };

  const setProfileData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/member/profile`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        const user_data = res.data.data;
        setCurrentUser(user_data);
      })
      .catch(function (err) {
        setCurrentUser("");
        window.location.href = "/login";
      });
  };

  const getRole = () => {
    let role = [];
    let teacher = "";
    role = getCurrentUser().role;
    if (role !== null) {
      role.map((item) => {
        return (teacher = item.label_teacher);
      });
      setisTeacher(teacher);
    }
  };

  const PrivateRoute = ({ ...props }) =>
    getCurrentUser() ? (
      <>
        {isTeacher !== "teacher" ? (
          <>
            {subscriptionStatus ? (
              <Route {...props} />
            ) : (
              <Redirect to="/plans" />
            )}
          </>
        ) : (
          <Route {...props} />
        )}
      </>
    ) : (
      <Redirect to="/login" />
    );

  const PublicRoute = ({ ...props }) =>
    getCurrentUser() ? <Redirect to="/" /> : <Route {...props} />;

  useEffect(() => {
    if (getCurrentUser()) {
      if (getCurrentUser().portal_access) {
        setLoader(false);
        setSubscriptionStatus(true);
      } else if (getCurrentUser().stripe_subscription_id !== null) {
        getSubscription();
      } else {
        setLoader(false);
      }
      getRole();
      setProfileData();
      const socket = io("https://sockets.soultribeonline.com");
      socket.on(getCurrentUser().id, (type, data) => {
        if (type === "new_message") {
          toast.success(
            `${data.message_user.name}: ${
              data.body !== null
                ? data.body
                : data.image
                ? "sent you an image"
                : data.video
                ? "sent you a video"
                : ""
            }`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          // localStorage.setItem('unreadMessages', data.unread_message_count)
        } else if (type === "notification") {
          toast.success(`${data}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } else {
      setLoader(false);
    }
  }, []);

  TopBarProgress.config({
    barColors: {
      0: "#060062",
    },
  });

  useEffect(() => {
    setPrevLoc();
    setProgress(true);
  }, []);

  useEffect(() => {
    setProgress(false);
  }, [prevLoc]);

  if (loader) {
    return (
      <div
        className="d-flex w-100 align-items-center justify-content-center"
        style={{
          height: "100vh",
        }}
      >
        <Loader />;
      </div>
    );
  } else {
    return (
      <Router>
        <NavScrollTop>
          {progress && <TopBarProgress />}
          <CustomSwitch>
            <Route path="/join-for-free" exact component={JoinForFree} />
            <Route
              path="/soul-tribe-mother-tree-retreat-san-diego"
              exact
              component={MotherTreeRetreat}
            />
            <Route
              path="/soultribeonline-november-gratitude"
              exact
              component={NovemberGratitude}
            />
            <Route path="/404" exact component={NoPage} />
            <Route path="/plans" exact component={SubPlans} />
            <Route path="/" exact component={HomePage} />
            <Route path="/about" component={AboutPage} />
            <PublicRoute path="/login" component={LoginPage} />
            <Route path="/editclass" component={EditClassPage} />
            <PrivateRoute path="/lovestream" component={CreateLovestreamPage} />
            <Route path="/service" component={ServicePage} />
            <Route path="/service-details/:id" component={ServiceDetails} />
            <Route path="/teacher" component={TeamPage} />
            <Route path="/faqs" component={FaqPage} />
            <Route path="/contact" component={ContactPage} />
            <PublicRoute
              path="/forgot-password"
              component={ForgotPasswordPage}
            />
            <PublicRoute path="/reset-password" component={Resetpassword} />
            <Route path="/registration" component={RegistrationPage} />
            <Route path="/teachers" component={TeachersPage} />
            <Route path="/blog" component={BlogPage} />
            <Route path="/classes" component={ClassesPage} />
            <PrivateRoute path="/members" component={MembersPage} />
            <Route path="/tribes" component={TribePage} />
            <Route path="/privacy" component={PrivacyPage} />
            <Route path="/terms-conditions" component={TermsPage} />
            <PrivateRoute path="/live-classes" component={LivePage} />
            <Route path="/blog-left-sidebar" component={BlogLeftSidebarPage} />
            <Route
              path="/blog-right-sidebar"
              component={BlogRightSidebarPage}
            />
            <Route path="/blog-details/:id" component={BlogDetailsPage} />
            <PrivateRoute path="/class/:slug" component={ClassDetailsPage} />
            <PrivateRoute path="/course/:slug" component={CourseDetailsPage} />
            <PrivateRoute
              path="/event/:slug/:id"
              component={LiveClassDetailsPage}
            />
            <Route path="/member/:id" component={ProfileDetail} />
            <PrivateRoute path="/post/:id" component={SinglePostPage} />
            <PrivateRoute path="/tribe/:slug" component={TribeDetailsPage} />
            <Route path="/author/:author" component={BlogAuthor} />
            <Route path="/date/:date" component={BlogDate} />
            <Route path="/tag/:slug" component={BlogTag} />
            <Route path="/category/:slug" component={BlogCategory} />
            <PrivateRoute path="/profile/:slug?" component={ProfilePage} />
            <Route path="/create-group" component={CreateGroup} />
            <PrivateRoute path="/edit-profile" component={EditProfile} />
            <Route path="/membership-account" component={MemberAccountPage} />
            <PrivateRoute path="/affiliates" component={AffiliatesPage} />
            <PrivateRoute path="/messages" component={MessagesPage} />
            <PrivateRoute
              path="/messages/:thread_id"
              component={MessagesPage}
            />
            <PrivateRoute
              path="/create-live-class"
              component={CreateLiveClass}
            />
            <PrivateRoute path="/edit/:slug/:id" component={UpdateLiveClass} />
            <Route
              path={`${
                process.env.PUBLIC_URL + "/teacher-orientation-materials"
              }`}
              component={TeacherOrientationMaterial}
            />
            <Route path="/internal-calendar" component={InternalCalendar} />
            <PrivateRoute path="/notifications" component={NotificationPage} />
            <Route path="/checkout" component={CheckoutPage} />
            <PrivateRoute path="/course" component={CoursePage} />
            <Redirect to="/404" />
          </CustomSwitch>
        </NavScrollTop>
      </Router>
    );
  }
};
export default App;
