import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactDOM from "react-dom";
import Countdown from "react-countdown";

function LiveClassContainer(data) {
    const [liveClass, setLiveClass] = useState(data.data && data.data);

    const date1 = new Date();
    const date2 = new Date(liveClass.start_date);
    const diffTime = Math.abs(date2 - date1);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return (
                <>
                    {liveClass.start_time_left !== null ? (
                        liveClass.start_time_left < 0 ? (
                            <span className="text-danger d-inline-block text-center w-100 mb-3">
                                Expired!
                            </span>
                        ) : (
                            <span className="text-success d-inline-block text-center w-100 mb-3">
                                Class is going on!
                            </span>
                        )
                    ) : (
                        <span className="text-danger d-inline-block text-center w-100 mb-3">
                            Expired!
                        </span>
                    )}
                </>
            );
        } else {
            // Render a countdown
            return (
                <div className="liveclass_timer">
                    {/* <h5 id="headline">Class live in </h5> */}
                    <div id="countdown">
                        <ul>
                            <li>
                                <span id="days">{days}</span>days
                            </li>
                            <li>
                                <span id="hours">{hours}</span>Hours
                            </li>
                            <li>
                                <span id="minutes">{minutes}</span>Minutes
                            </li>
                            <li>
                                <span id="seconds">{seconds}</span>Seconds
                            </li>
                        </ul>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className="liveclass">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <img
                            className="mw-100 mb-5 mw-100"
                            src={liveClass.image}
                        />
                        {/* <h2 className="my-5">{liveClass.title}</h2> */}
                        <p
                            dangerouslySetInnerHTML={{
                                __html: liveClass.description,
                            }}
                        />
                    </div>
                    <div className="col-md-4">
                        <Countdown
                            date={Date.now() + liveClass.start_time_left}
                            renderer={renderer}
                        />
                        {liveClass.zoom_link ? (
                            <a
                                href={liveClass.zoom_link}
                                target="_blank"
                                className="join-style mb-3 d-inline-block text-center m-auto d-block"
                            >
                                {" "}
                                Join the session
                            </a>
                        ) : (
                            ""
                        )}
                        <div className="card border p-4">
                            <div className="card-body bg-light p-3">
                                <h5 className="card-title">
                                    <i className="fa fa-calendar" /> Date
                                </h5>
                                <p className="card-text">
                                    {liveClass.start_date}
                                </p>
                            </div>
                            <div className="card-body bg-light mt-3 p-3">
                                <h5 className="card-title">
                                    <i
                                        className="fa fa-clock-o"
                                        aria-hidden="true"
                                    ></i>{" "}
                                    Time
                                </h5>
                                <p className="card-text">
                                    {liveClass.local_timezone_data &&
                                        liveClass.local_timezone_data.time}
                                </p>
                            </div>
                        </div>
                        <div className="card border p-4 mt-5">
                            <div className="card-body text-center">
                                <h5 className="card-title">
                                    {" "}
                                    Share This Event
                                </h5>
                                <ul className="social-icon">
                                    <li>
                                        <a
                                            className="facebook"
                                            href={`https://www.facebook.com/sharer.php?u=https://soultribeonline.com/event/${liveClass.slug}`}
                                            target="_blank"
                                        >
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="twitter"
                                            href={`https://twitter.com/intent/tweet?url=https://soultribeonline.com/event/${liveClass.slug}`}
                                            target="_blank"
                                        >
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="linkedin"
                                            href={`https://www.linkedin.com/shareArticle?url=https://soultribeonline.com/event/${liveClass.slug}&title=${liveClass.title}&summary=${liveClass.short_description}`}
                                            target="_blank"
                                        >
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="email"
                                            href={`mailto:?body=https://soultribeonline.com/event/${liveClass.slug}`}
                                        >
                                            <i className="fa fa-envelope"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="social_share mt-5">
                                <div className="datetime text-center">
                                    <h6>LOCAL TIME</h6>
                                    <div className="p-3">
                                        <h5 style={{ fontSize: "16px" }}>
                                            <b>Timezone: </b>
                                            {liveClass.local_timezone_data &&
                                                liveClass.local_timezone_data
                                                    .timezone}
                                        </h5>
                                        <h5 style={{ fontSize: "16px" }}>
                                            <b>Date: </b>
                                            {liveClass.local_timezone_data &&
                                                liveClass.local_timezone_data
                                                    .start_date}
                                        </h5>
                                        <h5 style={{ fontSize: "16px" }}>
                                            <b>Time: </b>
                                            {liveClass.local_timezone_data &&
                                                liveClass.local_timezone_data
                                                    .time}
                                        </h5>
                                    </div>
                                    <a
                                        href={liveClass.add_to_calander}
                                        target="_blank"
                                        className="btn btn-primary mb-2"
                                    >
                                        <i className="fa fa-calendar" /> Add to
                                        My Calendar
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LiveClassContainer;
