import React, { useEffect, useState } from "react";
import Intro from "../../../components/intro";
import useSWR from "swr";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { useHistory } from "react-router-dom";

import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

const IntroContainer = () => {
    const history = useHistory();
    const settings = {
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    const [data, setData] = useState([]);

    const getData = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/banners`, {})
            .then(function (res) {
                setData(res.data.data);
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const openNewPage = () => {
        history.push("/soul-tribe-mother-tree-retreat-san-diego");
    };

    let banner = "";
    if (data !== undefined) {
        banner = (
            <div className="slider-container">
                <Slider {...settings}>
                    {/* <div
                        className="hero-slider custom-slide cursor-pointer"
                        onClick={() => openNewPage()}
                        style={{
                            backgroundImage: `url('${process.env.PUBLIC_URL}/images/soul-tribe-mother-tree-retreat.png')`,
                        }}
                    >
                        <img
                            className="w-100 h-100"
                            src={`${process.env.PUBLIC_URL}/images/soul-tribe-mother-tree-retreat.png`}
                            alt=""
                            style={{
                                objectFit: "cover",
                            }}
                        />
                    </div> */}
                    {data.map((banner, i) => {
                        return <Intro data={banner} key={i} />;
                    })}
                </Slider>
            </div>
        );
    }

    return <div className="section position-relative">{banner}</div>;
};

export default IntroContainer;
