import React, { Component } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

class EditorConvertToHTML extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
        };
    } 

    onEditorStateChange: Function = (editorState) => {
        this.setState({
            editorState,
        });
        this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };

    render() {
        const { editorState } = this.state;
        return (
            <div className="border bg-light">
                <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                        options: ['inline', 'list'],
                        inline: {
                            options: ['bold', 'italic', 'underline', 'strikethrough']
                        }
                    }}
                />
            </div>
        );
    }
}

export default EditorConvertToHTML;
