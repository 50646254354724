import React, { useState, useEffect } from "react";
import LoveStreamPost from "../lovestream/Post";
import LoveStreamForm from "../lovestream/Form";
import { getCurrentUser, getQuotationId } from "../../helpers/Utils";
import axios from "axios";
import Loader from "../loader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import InfiniteScroll from "react-infinite-scroll-component";
import TribeRequest from "./tribe-request";
import MyTribes from "./myTribes";

function Tribes() {
    const [feed, setFeed] = useState([]);
    const [tribesData, setTribesData] = useState([]);
    const [tribeError, setTribeError] = useState("");
    const [loader, setLoader] = useState(<Loader />);
    const [tribeRequest, setTribeRequest] = useState([]);
    const [tribeRequestError, setTribeRequestError] = useState("");
    const [error, setError] = useState([]);
    const [pagination, setPagination] = useState("");
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(false);

    const getTribes = () => {
        setError(<Loader />);
        setTribesData([]);
        axios
            .get(`${process.env.REACT_APP_API_URL}/member/groups`, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                if (res.data.success === true) {
                    setTribeError("");
                    setTribesData(res.data.data.tribes.data);
                    setPagination(res.data.data.tribes.next_page_url);
                } else {
                    setTribeError(res.data.message);
                }
            });
    };

    const nextLink = () => {
        getTribesNext(currentPage + 1);
    };

    const getTribesNext = (page = currentPage) => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/member/groups?page=${page}`,
                {
                    headers: {
                        Authorization: `Bearer ${
                            getCurrentUser() && getCurrentUser().token
                        }`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.success === true) {
                    setCurrentPage(res.data.data.tribes.current_page);
                    let nextData = res.data.data.tribes.data;
                    let setmultiple = [...tribesData, ...nextData];
                    setTribesData(setmultiple);
                    setPagination(res.data.data.tribes.next_page_url);
                }
            });
    };

    const getTribeRequests = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/my-tribe-requests`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                if (res.data.success === true) {
                    setTribeRequest(res.data.data);
                    setTribeRequestError("");
                } else {
                    setTribeRequestError(res.data.message);
                }
            });
    };

    useEffect(() => {
        getTribes();
        getTribeRequests();
    }, []);
    return (
        <Tabs>
            <TabList>
                <Tab onClick={() => getTribeRequests()}>Tribe Requests</Tab>
                <Tab onClick={() => getTribes()}>My Tribes</Tab>
            </TabList>
            <TabPanel>
                <div className="myclasses myrequest-scroll">
                    {tribeRequestError}
                    <table className="table">
                        {tribeRequest.map((item) => (
                            <TribeRequest data={item} />
                        ))}
                    </table>
                </div>
            </TabPanel>
            <TabPanel>
                {tribeError}
                <InfiniteScroll
                    dataLength={tribesData.length}
                    next={pagination ? nextLink : ""}
                    hasMore={true}
                    loader={
                        <h4 className="text-center">
                            {pagination ? "Loading..." : ""}
                        </h4>
                    }
                >
                    <MyTribes data={tribesData} />
                </InfiniteScroll>
            </TabPanel>
        </Tabs>
    );
}

export default Tribes;
