import React, {useState} from "react";
import { getCurrentUser } from '../../helpers/Utils'
import axios from 'axios'
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }


const ResetContainer = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    let query = useQuery();
    let query_url = query.get("token");

    const ResetPassword = (e) => {
        e.preventDefault()
        setLoading(true)
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/update-password`,
            {
                token: query_url,
                password: e.target.password.value,
                confirm_password: e.target.confirm_password.value,
            }
          )
          .then(function (res) {
            setLoading(false)
            if (res.data.success !== false) {
                toast.success(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
        		setError('')
        		window.location.href = "/login"
        	} else {
        		setSuccess('')
                toast.error(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
        	}
          })
    }

    return (
        <section className="contact-section section-py ">
            <div className="container">

                <div className="loginsignup">
                    <div className="contact-title-section pb-3">
                        <h3 className="title text-center">Reset Password</h3>
                    </div>
                    <form className="row" onSubmit={(e) => ResetPassword(e)}>
                        <div className="col-12">
                            </div>
                            <div className="col-12 mb-4">
                                <label>New Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="name"
                                    name="password"
                                    placeholder="New Password"
                                />
                            </div>
                            <div className="col-12 mb-4">
                                <label>Confirm New Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="name"
                                    name="confirm_password"
                                    placeholder="Confirm New Password"
                                />
                            </div>
                    <div className="text-center mt-1">
                        {loading ? (
                         <button className="btn btn-dark w-100" disabled >Loading</button>
                        ) : (
                            <button type="submit" className="btn btn-dark w-100">Update</button>
                            ) }
                    </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ResetContainer;
