import React, { useEffect, useState } from "react";
import SectionTitle from "../../../components/section-title";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { getCurrentUser } from "../../../helpers/Utils";
import Loader from "../../../components/loader";
import Modal from "react-modal";
import Payment from "../../../components/registration/payment";
import { setPlanId } from "../../../utils";
import SinglePlan from "./single-plan";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "845px",
    width: "100%",
  },
};

const changePlanStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "405px",
    width: "100%",
    // height: '80%'
  },
};

const HomeBlog = () => {
  const [plan, setPlan] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [monthly, setMonthlyView] = useState(true);
  const [yearly, setYearlyView] = useState(false);
  const [loading, setLoading] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [inHover, setHover] = useState(false);
  const membership_id = getCurrentUser() && getCurrentUser().membership_id;
  const [cancelPlan, setCancelPlan] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);

  const getPlans = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/membership-levels`)
      .then(function (res) {
        setPlan(res.data.data.memberShip);
        console.log("memREs", res);
      });
  };

  const updatePlanId = (id, price, item) => {
    setSelectedPlan(item);
    setPlanId(id);
    createCustomerStripe();
  };

  const createCustomerStripe = () => {
    setLoading(<Loader />);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/member/create-customer`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading("");
        if (res.data.success === true) {
          setModalIsOpen(true);
        }
      });
  };

  const updateSubscription = (e) => {
    setLoading(<Loader />);
    axios
      .get(`${process.env.REACT_APP_API_URL}/member/update-subscription/${e}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        window.location.href = res.data.data.url;
      });
  };

  let monthly_plan = "";

  if (plan !== undefined) {
    monthly_plan = (
      <>
        {plan &&
          plan.map((item, i) => (
            <>
              {item.cycle === "month" ? (
                <SinglePlan
                  setCancelPlan={setCancelPlan}
                  subscriptionStatus={subscriptionStatus}
                  item={item}
                  updatePlanId={updatePlanId}
                  i={i}
                  membership_id={membership_id}
                />
              ) : (
                ""
              )}
            </>
          ))}
      </>
    );
  }
  let yearly_plan = "";
  if (plan !== undefined) {
    yearly_plan = (
      <>
        {plan &&
          plan.map((item, i) => (
            <>
              {item.cycle === "year" ? (
                <SinglePlan
                  setCancelPlan={setCancelPlan}
                  subscriptionStatus={subscriptionStatus}
                  item={item}
                  updatePlanId={updatePlanId}
                  i={i}
                  membership_id={membership_id}
                />
              ) : (
                ""
              )}
            </>
          ))}
      </>
    );
  }

  const getTimeline = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/membership-levels`, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        setTimeline(res.data.data.memberShip);
      });
  };

  const cancelMembership = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/member/cancel-customer-plan`,
        {},
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.status === true) {
          setSuccess(res.data.message);
          setError("");
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.location.href = "/plans";
        } else {
          setError(res.data.message);
          setSuccess("");
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const getSubscription = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/stripe/subscriptions/${
          getCurrentUser().stripe_subscription_id
        }`
        // {
        //   headers: {
        //     "content-type": "application/json",
        //     Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        //   },
        // }
      )
      .then(function (res) {
        var currentTimestamp = Math.floor(new Date() / 1000);
        const endDate = res.data.current_period_end;
        if (
          res.data.status === "active" ||
          res.data.status === "trialing" ||
          endDate > currentTimestamp
        ) {
          setSubscriptionStatus(true);
        }
      })
      .catch(function (err) {
        setSubscriptionStatus(false);
      });
  };

  useEffect(() => {
    if (getCurrentUser() && getCurrentUser().stripe_subscription_id !== null) {
      getSubscription();
    }
    getPlans();
  }, [monthly, yearly]);

  //Change Plan
  const changeState = () => {
    setMonthlyView(true);
    setYearlyView(false);
  };
  const changeStateYear = () => {
    setMonthlyView(false);
    setYearlyView(true);
  };
  return (
    <>
      <div className="subscription section-pb">
        <div className="container">
          <SectionTitle
            classOption="text-center"
            title="<span class='text-primary'>Your Tribe Awaits</span>"
            subTitle="Join Free / No Obligation"
          />
          <div className="plans">
            <div className="row">
              <div className="col-md-8">
                <div className="subscription_info">
                  <div className="subscription_info-title ">
                    <h1>Subscribe to Soul Tribe</h1>
                  </div>
                  <div className="subscription_info-subtitle">
                    <span>
                      FIRST 10 DAYS FREE! <br />
                      (WITH MONTHLY SUBSCRIPTION)
                    </span>
                  </div>
                  <div className="subscription_info-desc">
                    <p>
                      <i className="fa fa-check-circle"></i>{" "}
                      <span className="subs-css">
                        Livestream classes every week{" "}
                      </span>
                    </p>
                    <p>
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>
                        Access to full library of classes and lectures by
                        certified Kundalini Yoga teachers{" "}
                      </span>
                    </p>
                    <p>
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>
                        Social network (Lovestream) free of advertising and
                        dogma{" "}
                      </span>
                    </p>
                    <p>
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>
                        Unlimited participation in tribes and teachers to create
                        community{" "}
                      </span>
                    </p>
                    <p>
                      <i className="fa fa-check-circle"></i>{" "}
                      <span>
                        Connect with other members and create your own tribes!{" "}
                      </span>
                    </p>
                    <div className="clearfix" />
                  </div>
                </div>
              </div>
              <div className="col-md-4 subcc">
                <div className="subscription_plans">
                  {loading}
                  <ul className="mb-5">
                    <li
                      className={`text-decoration-none btn ${
                        monthly ? "btn-primary" : "btn-secondary"
                      }`}
                      onClick={(e) => changeState()}
                    >
                      Monthly
                    </li>
                    <li
                      className={`text-decoration-none btn ${
                        yearly ? "btn-primary" : "btn-secondary"
                      }`}
                      onClick={(e) => changeStateYear()}
                    >
                      Yearly
                    </li>
                  </ul>
                  {monthly ? monthly_plan : yearly_plan}
                </div>
              </div>
            </div>
            <p className="mt-6">
              * You may cancel your subscription at any time before your card is
              charged or before the free trial expires. There is no minimum
              subscription requirement. Please{" "}
              <NavLink
                className="footer-link"
                to={process.env.PUBLIC_URL + "terms-conditions"}
              >
                READ TERMS AND CONDITIONS{" "}
              </NavLink>
              <br />
              <br />{" "}
              <NavLink
                className="footer-link"
                to={process.env.PUBLIC_URL + "faqs"}
              >
                {" "}
                FAQ{" "}
              </NavLink>
            </p>
          </div>
        </div>
      </div>
      <Modal isOpen={cancelPlan} style={changePlanStyles}>
        <i
          className="fa fa-window-close float-right"
          onClick={(e) => setCancelPlan(false)}
          aria-hidden="true"
        ></i>
        <div className="plan-selection">
          <h5 className="text-center mb-5">
            If you're sure you'd like to cancel your membership, click below.
          </h5>
          <div className="text-center">
            {loading ? (
              <button type="button" className="btn btn-danger" disabled>
                Loading
              </button>
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => cancelMembership()}
                  className="btn btn-danger"
                >
                  Cancel Membership
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <i
          className="fa fa-window-close float-right"
          onClick={(e) => setModalIsOpen(false)}
          aria-hidden="true"
        ></i>
        <Payment
          selectedPlan={selectedPlan}
          subscriptionStatus={subscriptionStatus}
          getPlans={getPlans}
          setModalIsOpen={setModalIsOpen}
        />
      </Modal>
    </>
  );
};

export default HomeBlog;
