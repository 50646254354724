import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Loader from "../../../components/loader";
import { getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";
import Select from "react-select";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TribeEmailOptions(props) {
	const [loading, setLoading] = useState(false);

	const postEmailOption = (e) => {
		e.preventDefault()
        console.log(e.target.email_option.value)
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/tribe-email-subscription-options`,
				{
					tribe_id: props.tribeSingleId,
                    option: e.target.email_option.value
				},
				{
					headers: {
						"content-type": "application/json",
						Authorization: `Bearer ${getCurrentUser().token}`,
					},
				}
			)
			.then(function (res) {
				setLoading(false);
                toast.success(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
			});
	};

	return (
		<div className="email-option">
			<form
				onSubmit={(e) => postEmailOption(e)}
			>
				<h6 className="py-1">How do you want to read this group?</h6>
				<div className="form-check">
					<input
						className="form-check-input"
						type="radio"
						name="email_option"
                        defaultChecked={props.emailSubscription === "no_email" ? 'checked' : ''}
						id="no"
						value="no_email"
					/>
					<label className="form-check-label cursor-pointer" htmlFor="no">
						No Email
						<p className="form-text d-block text-dark">I will read this group on the web</p>
					</label>
				</div>
				<div className="form-check">
					<input
						className="form-check-input"
						type="radio"
						name="email_option"
                        defaultChecked={props.emailSubscription === "weekly_summary_email" ? 'checked' : ''}
						id="weekly"
						value="weekly_summary_email"
					/>
					<label className="form-check-label cursor-pointer" htmlFor="weekly">
						Weekly Summary Email
						<p className="form-text d-block text-dark">Get a summary of new topics each week</p>
					</label>
				</div>
				<div className="form-check">
					<input
						className="form-check-input"
						type="radio"
						name="email_option"
                        defaultChecked={props.emailSubscription === "daily_digest_email" ? 'checked' : ''}
						id="daily"
						value="daily_digest_email"
					/>
					<label className="form-check-label cursor-pointer" htmlFor="daily">
						Daily Digest Email
						<p className="form-text d-block text-dark">Get all the day's activity bundled into a single email</p>
					</label>
				</div>
				<div className="form-check">
					<input
						className="form-check-input"
						type="radio"
						name="email_option"
                        defaultChecked={props.emailSubscription === "new_topics_email" ? 'checked' : ''}
						id="new-topic"
						value="new_topics_email"
					/>
					<label className="form-check-label cursor-pointer" htmlFor="new-topic">
						New Topics Email
						<p className="form-text d-block text-dark">Send new topics as they arrive (but don't send replies)</p>
					</label>
				</div>
				<div className="form-check">
					<input
						className="form-check-input"
						type="radio"
						name="email_option"
                        defaultChecked={props.emailSubscription === "all_email" ? 'checked' : ''}
						id="all"
						value="all_email"
					/>
					<label className="form-check-label cursor-pointer" htmlFor="all">
						All Email
						<p className="form-text d-block text-dark">Send all group activity as it arrives</p>
					</label>
				</div>
                {loading ? <button className="btn btn-primary" type="button">Loading</button> : <button className="btn btn-primary" type="submit">Save Setting</button>}
			</form>
		</div>
	);
}
export default TribeEmailOptions;
