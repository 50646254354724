import React, { Fragment, Component } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const recaptchaRef = React.createRef();

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: "",
            error: "",
            isLoading: false,
            isCaptcha: false,
        };
        this.getInTouch = this.getInTouch.bind(this);
    }


    getInTouch = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        });
        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue === "") {
            this.setState({ error: "Please select captcha" });
        } else {
            this.setState({ error: "" });
            var self = this;
            axios
                .post(`${process.env.REACT_APP_API_URL}/contact-us`, {
                    name: e.target.name.value,
                    email: e.target.email.value,
                    message: e.target.message.value,
                })
                .then(function (res) {
                    this.setState({
                        isLoading: false,
                    });
                    if (res.data.success === true) {
                        self.setState({
                            error: "",
                            success: res.data.message,
                        });
                    } else {
                        self.setState({
                            success: "",
                            error: res.data.message,
                        });
                    }
                });
        }
    };

    setRecaptcha = (value) => {};

    render() {
        const { error, isLoading } = this.state;
        let button = (
            <button
                id="contactSubmit"
                type="submit"
                className="btn btn-dark btn-hover-dark"
                data-complete-text="Well Done!"
            >
                Send Message
            </button>
        );
        if (isLoading === true) {
            button = (
                <button
                    id="contactSubmit"
                    className="btn btn-dark btn-hover-dark"
                    disabled
                >
                    Loading...
                </button>
            );
        }

        if (error) {
            toast.error(error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                error: ''
            })
        }

        return (
            <Fragment>
                <form
                    id="contactForm"
                    className="row"
                    onSubmit={this.getInTouch}
                >
                    <div className="col-12 col-sm-6 mb-4">
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Your Name*"
                        />
                    </div>
                    <div className="col-12 col-sm-6 mb-4">
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Your Email*"
                        />
                    </div>

                    <div className="col-12 mb-4">
                        <textarea
                            className="form-control massage-control"
                            name="message"
                            id="massage"
                            cols="30"
                            rows="10"
                            placeholder="Message"
                        ></textarea>
                    </div>

                    <div className="col-12 mb-4">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            action="submit"
                            sitekey="6Lcdt4waAAAAAIlWrhgMMLLJ-2of9DvMJIUY1eiq"
                            onChange={this.getInTouch}
                        />
                    </div>

                    <div className="col-12 d-flex mb-4">{button}</div>
                    {/* <div className="col-12 mb-3">
                        {successMessage}
                        {errorMessage}
                    </div> */}
                </form>
            </Fragment>
        );
    }
}

export default LoginForm;
