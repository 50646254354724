import React from "react";
import MessagesContainer from "../containers/messages";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import SEO from "../components/seo";
import PageLoader from '../components/page-loader'

const MessagesPage = () => {

    return (
        <React.Fragment>
        <PageLoader />
            <Layout>
                <SEO title="Messages" />
                <div className="wrapper">
                    <Header />
                    <MessagesContainer />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default MessagesPage;
