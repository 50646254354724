import React, {useEffect, useState} from "react";
import Accordion, {
    AccordionItem,
    AccordionTitle,
    AccordionContent,
} from "../../components/accrodion";
import { getCurrentUser } from '../../helpers/Utils';
import axios from "axios";
import Loader from "../../components/loader";

const TeacherOrientationContainer = () => {
    const [data, setData] = useState([])
    const [error, setError] = useState('')
    const [loader, setLoader] = useState(<Loader />)

    const getTOM = () => {
        setLoader(<Loader />);
        axios.get('https://panel.soultribeonline.com/api/teacher-orientation'
        ).then(function(res){
            // setError(res.data.message);
            setLoader('')
            setData(res.data.data);
        })
    }

    useEffect(() => {
        getTOM();
    }, []);

    return (
        <div className="faq-section section-py">
            <div className="container">
                <div className="row">
                    <div className="col">
                        {loader}
                        <Accordion classOption="accordion-style2 no-bg py-5">
                            {data.map((teacher, i) => (
                                <AccordionItem key={i} id={teacher.id} key={i}>
                                    <AccordionTitle id={teacher.id}>
                                        <span className="question-tag"><i className={teacher.icon}></i></span>
                                        {teacher.title}
                                    </AccordionTitle>
                                    <AccordionContent id={teacher.id}>
                                        {teacher.content}
                                    </AccordionContent>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeacherOrientationContainer;
