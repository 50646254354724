import React from "react";
import { NavLink } from "react-router-dom";
import LoginForm from "../../components/forgot-password";

const LoginContainer = () => {
    return (
        <section className="contact-section section-py mt-5">
            <div className="container mt-5">
                <div className="loginsignup">
                    <div className="contact-title-section pb-3">
                        <h3 className="title">Lost Password</h3>
                        <p>Please enter your email address. You will receive a link to create a new password via email.</p>
                    </div>
                    <LoginForm />
                    <div className="text-center mt-1">
                        <NavLink
                            className="sub-menu-link"
                            to={process.env.PUBLIC_URL + "/login"}
                        >
                            Back to login
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LoginContainer;
