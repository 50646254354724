import React, { useEffect, useState } from "react";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { getCurrentUser } from "../helpers/Utils";
import { NavLink, useHistory } from "react-router-dom";
import Loader from "../components/loader";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MyTribes from "../components/profile/myTribes";
import ProfileTimeline from "../components/member-profile/timeline";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";

const customStyles = {
  content: {
    position: "unset",
    maxWidth: "850px",
    width: "550px",
    right: "auto",
    border: "none",
    bottom: "auto",
    padding: "35px",
    margin: "0 auto",
  },
};

function ProfileDetail({
  match: {
    params: { id },
  },
}) {
  const [profileId] = useState(parseInt(id, 10));
  const [tribesData, setTribesData] = useState([]);
  const [error, setError] = useState("");
  const [tribeError, setTribeError] = useState("");
  const [documentError, setDocumentError] = useState("");
  const [profile, setProfile] = useState("");
  const [document, setDocument] = useState([]);
  const [pageLoader, setPageLoader] = useState(<Loader />);
  const [loader, setLoader] = useState(<Loader />);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [data] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState("");
  const [reqAccept, setReqAccept] = useState(false);
  const [disconnect, setDisconnect] = useState(false);

  const history = useHistory();

  const removeMemberReq = () => {
    disconnectMember();
    setStatus(null);
  };

  const disconnectMember = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/member/remove-connection`,
        {
          member_id: profileId,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.success === false) {
        } else {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setDisconnect(false);
        }
      });
  };

  const SingleProfile = () => {
    setPageLoader(<Loader />);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/member/profile-detail?user_id=${profileId}`
      )
      .then(function (res) {
        setPageLoader("");
        setLoader("");
        localStorage.setItem("localKey", id);
        if (res.data.success) {
          setError("");
          setProfile(res.data.data);
          setStatus(res.data.data.request_status);
        } else {
          setProfile("");
          setError(res.data.message);
        }
      });
  };

  const getTribes = () => {
    setLoader(<Loader />);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/member/groups?user_id=${profileId}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        setLoader("");
        if (res.data.success === true) {
          setTribeError("");
          setTribesData(res.data.data.tribes.data);
          setPagination(res.data.data.tribes.next_page_url);
        } else {
          setTribeError(res.data.message);
        }
      });
  };

  //Load more when data is at last index
  const getTribesNext = (page = currentPage) => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/member/groups?user_id=${profileId}&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser() && getCurrentUser()?.token
            }`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        if (res.data.success === true) {
          setCurrentPage(res.data.data.tribes.current_page);
          setPagination(res.data.data.tribes.next_page_url);
          let nextData = res.data.data.tribes.data;
          let setmultiple = [...tribesData, ...nextData];
          setTribesData(setmultiple);
        }
      });
  };
  const nextLink = () => {
    getTribesNext(currentPage + 1);
  };

  const getDocument = () => {
    setLoader(<Loader />);
    setDocument([]);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/member/documents?user_id=${profileId}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        setLoader("");
        if (res.data.success === true) {
          setDocument(res.data.data);
          setDocumentError("");
        } else {
          setDocumentError(res.data.message);
        }
      });
  };
  const downloadfile = (fileDownload) => {
    window.open(fileDownload);
  };

  const sendRequest = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/member/send-friend-request`,
        {
          member_id: profile.id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {});
  };

  const changeStatus = () => {
    if (getCurrentUser()) {
      sendRequest();
      setStatus(0);
    } else {
      history.push("/login");
    }
  };

  const checkThread = () => {
    if (getCurrentUser()) {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/thread`,
          {
            message: "",
            group_id: "",
            receiver_id: profile.id,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${getCurrentUser()?.token}`,
            },
          }
        )
        .then(function (res) {
          window.location.href = `/messages/${res.data.data.id}`;
        });
    } else {
      history.push("/login");
    }
  };

  const frientRequest = (requestStatus, id) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/member/friend-request`,
        {
          status: requestStatus,
          member_id: id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        setLoading(false);
        setReqAccept(true);
        SingleProfile();
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    SingleProfile();
  }, []);

  return (
    <React.Fragment>
      <Layout>
        <SEO title={`Soul Tribe Online Community – ${profile.name}`} />
        <div className="wrapper">
          <Header />
          <Modal
            ariaHideApp={false}
            isOpen={disconnect}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="request-data-style text-center">
              <h5 className="text-center mb-5">
                <i className="fa fa-user-times fa-5x" />
                <br />
                <br />
                Do you really want to perform this action?
              </h5>
              <div>
                {loading ? (
                  <button className="btn btn-primary py-2 px-3 m-1 " disabled>
                    Loading
                  </button>
                ) : (
                  <button
                    onClick={removeMemberReq}
                    className="btn btn-danger py-2 px-3 m-1"
                  >
                    Yes, continue.
                  </button>
                )}
                <button
                  onClick={() => setDisconnect(false)}
                  className="btn btn-warning py-2 px-3 m-1"
                >
                  No, I changed my mind.
                </button>
              </div>
            </div>
          </Modal>
          <div className="tribe_detail">
            <div className="container" style={{ minHeight: "300px" }}>
              {error ? (
                <span className="alert alert-danger mt-4">{error}</span>
              ) : (
                ""
              )}
              {pageLoader}
              {profile ? (
                <div className="row">
                  <div className="col-lg-12">
                    <div id="cover-image-container">
                      <div className="has-cover-image">
                        <img
                          alt="s"
                          className="header-cover-img"
                          src={profile.cover_image}
                        />
                      </div>
                      <div
                        id="item-header-cover-image"
                        className="item-header-wrap bb-enable-cover-img"
                      >
                        <div id="item-header-avatar">
                          <img alt="s" src={profile.image} />
                        </div>
                        <div id="item-header-content">
                          <div className="flex align-items-center bp-group-title-wrap">
                            <h2 className="bb-bp-group-title">
                              {profile.name}
                            </h2>
                          </div>
                          <p className="text-lowercase">
                            @{profile.name} • {profile.created_at}
                          </p>
                          <div className="bp-generic-meta groups-meta action">
                            {getCurrentUser()?.id === profile.id ? (
                              ""
                            ) : (
                              <div className="generic-button">
                                {profile.friend_request === 1 ? (
                                  <>
                                    {reqAccept ? (
                                      ""
                                    ) : (
                                      <>
                                        {loading ? (
                                          <button className="btn btn-primary btn-sm p-2 px-4 send-form-bttn disabled">
                                            Loading
                                          </button>
                                        ) : (
                                          <>
                                            <button
                                              onClick={() =>
                                                frientRequest(1, profile.id)
                                              }
                                              className="btn btn-success btn-sm p-2 px-4 send-form-bttn"
                                            >
                                              <i className="fa fa-check" />{" "}
                                              Accept request
                                            </button>
                                            <button
                                              style={{
                                                marginLeft: "10px",
                                              }}
                                              onClick={() =>
                                                frientRequest(2, profile.id)
                                              }
                                              className="btn btn-danger btn-sm p-2 px-4 send-form-bttn"
                                            >
                                              <i className="fa fa-times" />{" "}
                                              Reject request
                                            </button>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {status === null ? (
                                      <button
                                        onClick={changeStatus}
                                        className="btn btn-primary btn-sm p-2 px-4 send-form-bttn"
                                      >
                                        <i className="fa fa-user-plus"></i> Send
                                        Request
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {/* {status === 2 ? ( */}
                                    {/*         <span className="w-50 text-center py-1 border-top d-block" onClick={sendRequest}> */}
                                    {/*             <i className="fa fa-user-times" aria-hidden="true" title=""></i> */}
                                    {/*         </span> */}
                                    {/* ) : ''} */}
                                    {status === 0 ? (
                                      <span
                                        onClick={() => setDisconnect(true)}
                                        className="btn btn-dark btn-sm p-2 px-4"
                                      >
                                        Cancel request
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {status === 1 ? (
                                      <button
                                        onClick={() => setDisconnect(true)}
                                        className="btn btn-danger btn-sm p-2 px-4 send-form-bttn"
                                      >
                                        <i className="fa fa-user-times"></i>{" "}
                                        Remove connection
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {profile.member_thread === null ? (
                                      <>
                                        {loading ? (
                                          <button
                                            className="btn btn-primary btn-sm p-2 px-4 send-form-bttn"
                                            style={{
                                              marginLeft: "5px",
                                            }}
                                            disabled
                                          >
                                            Loading
                                          </button>
                                        ) : (
                                          <button
                                            onClick={checkThread}
                                            className="btn btn-primary btn-sm p-2 px-4 send-mess-bttn"
                                            style={{
                                              marginLeft: "5px",
                                            }}
                                          >
                                            <i className="fa fa-comment-o"></i>{" "}
                                            Message
                                          </button>
                                        )}
                                      </>
                                    ) : (
                                      <NavLink
                                        to={
                                          process.env.PUBLIC_URL +
                                          `/messages/${profile.member_thread}`
                                        }
                                      >
                                        <button
                                          className="btn btn-primary btn-sm p-2 px-4"
                                          style={{
                                            marginLeft: "5px",
                                          }}
                                        >
                                          <i className="fa fa-comment-o"></i>{" "}
                                          Message
                                        </button>
                                      </NavLink>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tribe_tabs mt-5">
                      <Tabs className="vertical_tabs" defaultIndex="1">
                        <TabList>
                          <Tab>Timeline</Tab>
                          <Tab>Profile</Tab>
                          <Tab onClick={getTribes}>Groups</Tab>
                          <Tab onClick={getDocument}>Documents</Tab>
                        </TabList>

                        <TabPanel>
                          <ProfileTimeline profileId={profileId} />
                        </TabPanel>
                        <TabPanel>
                          {/* <h5>Profile</h5> */}
                          <div className="profile public">
                            <div className="bp-widget base">
                              <h3 className="screen-heading profile-group-title">
                                {" "}
                                Base
                              </h3>
                              <table className="table table-striped table-borderless profile-fields">
                                <tbody>
                                  <tr className="field_1 field_name field_order_1 required-field visibility-public field_type_textbox">
                                    <td className="label">Name</td>
                                    <td className="data">
                                      <p>{profile.name}</p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="bp-widget personal-info">
                              <h3 className="screen-heading profile-group-title">
                                {" "}
                                Personal Info
                              </h3>
                              <table className="table table-striped table-borderless profile-fields">
                                <tbody>
                                  <tr className="field_10 field_birthday field_order_1 optional-field visibility-public field_type_datebox">
                                    <td className="label">About Me</td>
                                    <td className="data">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: profile.about,
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          {tribeError}
                          {loader}
                          <InfiniteScroll
                            dataLength={data.length}
                            next={pagination ? nextLink : ""}
                            hasMore={true}
                            loader={
                              <h4 className="text-center">
                                {pagination ? <Loader /> : ""}
                              </h4>
                            }
                          >
                            <MyTribes data={tribesData} />
                          </InfiniteScroll>
                        </TabPanel>
                        <TabPanel>
                          <h3 className="screen-heading profile-group-title mb-7">
                            {" "}
                            Documents
                          </h3>
                          {loader}
                          {documentError}
                          <table className="table documents table-border">
                            {document.map((item, i) => (
                              <tr>
                                <td>
                                  <span>
                                    <i className="fa fa-file"></i>
                                    {item.title}
                                  </span>
                                </td>
                                <td>{item.created_at}</td>
                                <td className="text-center text-capitalize">
                                  {item.type === "only_me" ? "Only me" : ""}
                                  {item.type === "my_connections"
                                    ? "My Connections"
                                    : ""}
                                  {item.type === "public" ? "Public" : ""}
                                  {item.type === "all_members"
                                    ? "All Members"
                                    : ""}
                                </td>
                                <td className="text-center">
                                  {getCurrentUser() ? (
                                    <i
                                      onClick={(e) =>
                                        downloadfile(item.document)
                                      }
                                      className="fa fa-eye"
                                    ></i>
                                  ) : (
                                    <NavLink
                                      to={process.env.PUBLIC_URL + `/login`}
                                    >
                                      Login to view
                                    </NavLink>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </table>
                        </TabPanel>
                      </Tabs>
                      <div className="tribe_members"></div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
}

export default ProfileDetail;
