import { useState, useEffect, useRef } from "react";
import { Form, Input } from "reactstrap";
import axios from "axios";
import { getCurrentUser, getQuotationId } from "../../../helpers/Utils";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from "react-select";
import ProgressBar from "@ramonak/react-progress-bar";
import { ToastContainer, toast } from "react-toastify";
import Dropzone from "react-dropzone-uploader";
import "react-toastify/dist/ReactToastify.css";
import GetLinkPreview from "../../../components/custom/GetLinkPreview";

function DiscussionForm(props) {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [selectedTribe, setSelectedTribe] = useState("");
    const [discussionTitle, setDiscussionTitle] = useState("");

    const [ifImage, setIfImage] = useState(false);
    const [ifVideo, setIfVideo] = useState(false);
    const [ifDocument, setIfDocument] = useState(false);

    const testAttachment = () => {};

    const attachImage = () => {
        setIfVideo(false);
        setIfDocument(false);
        setIfImage(true);
    };
    const attachVideo = () => {
        setIfImage(false);
        setIfVideo(true);
        setIfDocument(false);
    };
    const attachDocument = () => {
        setIfImage(false);
        setIfVideo(false);
        setIfDocument(true);
    };

    const postAttachedImage = () => {};
    const postAttachedVideo = () => {};
    const postattAchDocedument = () => {};

    const feedPost = (e) => {
        e.preventDefault();
        setLoading(true);
        let url = `${process.env.REACT_APP_API_URL}/tribe-discussion`;
        var formData = new FormData();
        formData.append("tribe_id", props.tribeSingleId);
        formData.append("title", discussionTitle);
        // formData.append("image[]", image);
        // formData.append("document[]", postDocument);
        // formData.append("video", video);
        formData.append("privacy", "public");
        if (props.singleDiscussion !== undefined) {
            formData.append("discussion_id", props.singleDiscussion);
            url = `${process.env.REACT_APP_API_URL}/reply-tribe-discussion`;
            formData.append(
                "message",
                draftToHtml(convertToRaw(editorState.getCurrentContent()))
            );
        } else {
            formData.append(
                "topic",
                draftToHtml(convertToRaw(editorState.getCurrentContent()))
            );
        }
        axios
            .post(url, formData, {
                headers: {
                    "Content-type": "multipart/form-data",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setLoading(false);
                if (props.singleDiscussion !== undefined) {
                    props.getSingleDiscussion(props.singleDiscussion);
                }
                if (res.data.success === true) {
                    setDiscussionTitle("");
                    props.setDisModal(false);
                    props.callApi();
                    toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setTimeout(
                    function () {
                        setError("");
                        setSuccess("");
                    }.bind(),
                    2000
                );
            });
    };

    const list = [];
    props.mentionAllMember &&
        props.mentionAllMember.map((item) => {
            list.push({
                text: item.name,
                value: item.name,
                url: "member/" + item.id + "",
            });
        });

    return (
        <form onSubmit={feedPost}>
            <div className="post_upload">
                <div className="row">
                    <div className="col-12">
                        <div
                            style={{
                                borderBottom: "1px solid #ddd",
                                minHeight: "6em",
                                cursor: "text",
                            }}
                        >
                            <div className="py-3">
                                {props.singleDiscussion === undefined ? (
                                    <input
                                        defaultValue={discussionTitle}
                                        onChange={(e) =>
                                            setDiscussionTitle(e.target.value)
                                        }
                                        placeholder="Discussion Title"
                                        type="text"
                                        name="discussion_title"
                                        className="form-control"
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="bg-light">
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    placeholder={
                                        props.singleDiscussion === undefined
                                            ? "Type your discussion content here"
                                            : "Type your reply here"
                                    }
                                    toolbar={{
                                        options: ["inline", "list"],
                                        inline: {
                                            options: [
                                                "bold",
                                                "italic",
                                                "underline",
                                                "strikethrough",
                                            ],
                                        },
                                        list: {
                                            options: ["unordered", "ordered"],
                                        },
                                    }}
                                    mention={{
                                        separator: " ",
                                        trigger: "@",
                                        suggestions: list,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        {ifImage && (
                            <div className="attached_files-names w-100 p-3 pb-0 uploadphoto">
                                <Dropzone
                                    onSubmit={testAttachment}
                                    inputContent="Drop images here to upload"
                                    onChangeStatus={postAttachedImage}
                                    accept="image/*"
                                />
                            </div>
                        )}
                        {ifVideo && (
                            <div className="attached_files-names w-100 p-3 pb-0 uploadphoto">
                                <Dropzone
                                    onSubmit={testAttachment}
                                    inputContent="Drop video here to upload"
                                    maxFiles="1"
                                    onChangeStatus={postAttachedVideo}
                                    accept="video/*"
                                />
                            </div>
                        )}
                        {ifDocument && (
                            <div className="attached_files-names w-100 p-3 pb-0 uploadphoto">
                                <Dropzone
                                    onSubmit={testAttachment}
                                    inputContent="Drop files here to upload"
                                    onChangeStatus={postattAchDocedument}
                                />
                            </div>
                        )}
                        <div className="text-right p-0">
                            {loading ? (
                                <button
                                    type="button"
                                    className="btn btn-primary my-2 py-3"
                                >
                                    Posting
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="btn btn-primary my-2 py-3"
                                >
                                    Post
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-secondary my-2 px-2"
                                onClick={() => props.setDisModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        {error ? (
                            <span className="alert alert-danger d-table m-auto mb-1 p-1 px-5  ">
                                {error}
                            </span>
                        ) : (
                            ""
                        )}
                        {success ? (
                            <span className="alert alert-success d-table m-auto mb-1 p-1 px-5">
                                {success}
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
}

export default DiscussionForm;
