import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import Loader from "../../../components/loader";
import LiveGrid from "../../../components/live";
import { NavLink } from "react-router-dom";
import { getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

const LiveItemContainer = () => {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(<Loader />);
    const [filter, setFilter] = useState("");
    const [error, setError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [noTimezone, setNoTimezone] = useState(false);
    const [pagination, setPagination] = useState("");
    const [loading, setLoading] = useState(false);

    const getAllClasses = (e) => {
        if (e !== undefined) {
            e.preventDefault();
        }
        setNoTimezone(false);
        setLoader(<Loader />);
        setData([]);
        var url = `${process.env.REACT_APP_API_URL}/all-live-classes`;
        if (e !== undefined) {
            url = `${process.env.REACT_APP_API_URL}/all-live-classes?year=${e.target.year.value}&month=${e.target.month.value}&search=${e.target.ofsearch.value}`;
        }
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${
                        getCurrentUser() && getCurrentUser().token
                    }`,
                },
            })
            .then(function (res) {
                setLoader("");
                if (res.data.success === true) {
                    setData(res.data.data);
                    // setPagination(res.data.data.next_page_url)
                } else if (res.data.success === "no_time_zone") {
                    setNoTimezone(true);
                } else {
                    // setPagination('')
                    setError(res.data.message);
                }
            });
    };
    const resetAlldata = () => {
        setNoTimezone(false);
        var url = `${process.env.REACT_APP_API_URL}/all-live-classes`;
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${
                        getCurrentUser() && getCurrentUser().token
                    }`,
                },
            })
            .then(function (res) {
                setLoader("");
                console.log("HH", res.data.success);
                if (res.data.success === true) {
                    setData(res.data.data);
                    // setPagination(res.data.data.next_page_url)
                } else if (res.data.success === "no_time_zone") {
                    setNoTimezone(true);
                } else {
                    // setPagination('')
                    setError(res.data.message);
                }
            });
    };
    const getAllClassesNext = (page = currentPage) => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/all-live-classes?page=${page}`,
                {
                    headers: {
                        Authorization: `Bearer ${
                            getCurrentUser() && getCurrentUser().token
                        }`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
            });
    };
    const nextLink = () => {
        getAllClassesNext(currentPage + 1);
    };

    useEffect(() => {
        getAllClasses();
    }, []);

    if (!data) return <Loader />;
    return (
        <section className="blog-section section-py">
            <div className="container">
                {getCurrentUser() ? (
                    <>
                        <div className="searchandfilter">
                            <form onSubmit={getAllClasses}>
                                <ul className="m-0 w-100 mt-4">
                                    <li>
                                        <input
                                            type="text"
                                            name="ofsearch"
                                            placeholder="Search …"
                                        />
                                    </li>
                                    <li>
                                        <select
                                            name="month"
                                            id="month"
                                            className="postform"
                                        >
                                            <option value="">
                                                Select Month
                                            </option>
                                            <option value="01">January</option>
                                            <option value="02">Febuary</option>
                                            <option value="03">March</option>
                                            <option value="04">April</option>
                                            <option value="05">May</option>
                                            <option value="06">June</option>
                                            <option value="07">July</option>
                                            <option value="08">August</option>
                                            <option value="09">
                                                September
                                            </option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </select>
                                    </li>
                                    <li>
                                        <select
                                            name="year"
                                            id="year"
                                            className="postform"
                                        >
                                            <option value="">
                                                Select Year
                                            </option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                        </select>
                                    </li>
                                    <li>
                                        <button
                                            className="btn btn-primary d-inline-block"
                                            type="submit"
                                        >
                                            Search
                                        </button>

                                        <button
                                            className="btn class-reset px-1 d-inline-block"
                                            onClick={resetAlldata}
                                            type="reset"
                                        >
                                            Reset
                                        </button>
                                    </li>
                                </ul>
                            </form>
                        </div>
                        <p className="pb-5">
                            To join a LIVE class, please click on today’s date
                            and select a class scheduled for now! Clicking it
                            will take you too the live class Zoom link. Browse
                            all classes scheduled in whatever way suits you,
                            using the different view options available
                        </p>
                        <div className="row mb-n7">
                            <div className="col-12 text-center">
                                {loader}
                                {error}
                            </div>
                            {noTimezone ? (
                                <div className="col-12 text-center">
                                    <b>
                                        Please select timezone from{" "}
                                        <NavLink
                                            className="border-bottom "
                                            to="/edit-profile"
                                        >
                                            {" "}
                                            update profile
                                        </NavLink>{" "}
                                        page.
                                    </b>
                                </div>
                            ) : (
                                ""
                            )}
                            <>
                                <InfiniteScroll
                                    dataLength={data.length}
                                    hasMore={pagination != null ? true : false}
                                >
                                    {data &&
                                        data.map((classes, key) => (
                                            <div
                                                key={key}
                                                className="col-md-12 mb-5"
                                            >
                                                <LiveGrid
                                                    classOption="p-0"
                                                    key={key}
                                                    data={classes}
                                                />
                                            </div>
                                        ))}
                                </InfiniteScroll>
                            </>
                        </div>
                    </>
                ) : (
                    <>
                        <p>
                            We’re sorry! This content is for members only.
                            Please Sign In, or Join Now if you do not have an
                            account with us.
                        </p>
                        <NavLink to={process.env.PUBLIC_URL + "/registration"}>
                            <button className="btn btn-dark btn-hover-dark">
                                Join Now
                            </button>
                        </NavLink>
                    </>
                )}
            </div>
        </section>
    );
};

LiveItemContainer.propTypes = {
    data: PropTypes.array,
};

export default LiveItemContainer;
