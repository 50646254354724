import React from 'react'

const Loader = () => {
    return (
        <div className="loading text-center py-1">
            <img alt="" src={process.env.PUBLIC_URL + "/images/logo/updated-loader.gif"} />
        </div>
    );
};

export default Loader;
