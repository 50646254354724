/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import axios from "axios";
import { getCurrentUser } from "../../helpers/Utils";
import {
    convertFromHTML,
    ContentState,
    EditorState,
    convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditPost(props) {
    const html = props.data.message !== null ? props.data.message : "<p></p>";
    const blocksFromHTML = convertFromHTML(html);
    const content = ContentState.createFromBlockArray(blocksFromHTML);
    const [defPostContent, setDefPostContent] = useState(
        EditorState.createWithContent(content)
    );
    const [updateLoader, setUpdateLoader] = useState(false);
    const onEditorStateChange = (defPostContent) => {
        setDefPostContent(defPostContent);
    };

    const updatePostInfo = () => {
        setUpdateLoader(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/update-post`,
                {
                    message: draftToHtml(
                        convertToRaw(defPostContent.getCurrentContent())
                    ),
                    post_id: props.data.id,
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                props.setModalIsOpen(false);
                setUpdateLoader(false);
                if (props.setInfo !== undefined) {
                    props.setInfo(false);
                }
                if (res.data.success === true) {
                    toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    props.setItem(res.data.data.post);
                } else {
                    toast.error(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
    };

    return (
        <>
            <div className="border">
                <Editor
                    editorState={defPostContent}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                        options: ["inline", "list"],
                        inline: {
                            options: [
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                            ],
                        },
                    }}
                    mention={{
                        separator: " ",
                        trigger: "@",
                        suggestions: props.list,
                    }}
                    hashtag={{
                        separator: " ",
                        trigger: "#",
                    }}
                />
            </div>
            <div className="text-right">
                {updateLoader ? (
                    <button className="btn btn-primary btn-sm mt-3">
                        Loading...
                    </button>
                ) : (
                    <button
                        onClick={() => updatePostInfo()}
                        button="button"
                        className="btn btn-primary btn-sm mt-3"
                    >
                        Update
                    </button>
                )}
                <button
                    onClick={() => {
                        props.setModalIsOpen(false), props.setInfo(false);
                    }}
                    button="button"
                    className="btn btn-secondary btn-sm mt-3 px-2"
                >
                    Cancel
                </button>
            </div>
        </>
    );
}
export default EditPost;
