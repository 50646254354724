import React, { useEffect } from "react";
import { getCurrentUser } from "../../helpers/Utils";

const Profile = () => {
    useEffect(() => {}, []);

    return (
        <div className="profile public">
            <div className="bp-widget base">
                <h3 className="screen-heading profile-group-title">
                    {" "}
                    Personal Info
                </h3>
                <table className="table table-striped table-borderless profile-fields">
                    <tbody>
                        <tr className="field_1 field_name field_order_1 required-field visibility-public field_type_textbox">
                            <td className="label pb-2">Name</td>
                            <td className="data">
                                <p>{getCurrentUser().name}</p>
                            </td>
                        </tr>
                        <tr className="field_10 field_birthday field_order_1 optional-field visibility-public field_type_datebox">
                            <td className="label pb-2">Birthday</td>
                            <td className="data">
                                <p>{getCurrentUser().date_of_birth}</p>
                            </td>
                        </tr>
                        <tr className="field_10 field_birthday field_order_1 optional-field visibility-public field_type_datebox">
                            <td className="label pb-2">Where I Live</td>
                            <td className="data">
                                <p>{getCurrentUser().where_i_live}</p>
                            </td>
                        </tr>
                        <tr className="field_10 field_birthday field_order_1 optional-field visibility-public field_type_datebox">
                            <td className="label pb-2">Astrological Sign</td>
                            <td className="data">
                                <p>{getCurrentUser().astrological_sign}</p>
                            </td>
                        </tr>
                        <tr className="field_10 field_birthday field_order_1 optional-field visibility-public field_type_datebox">
                            <td className="label pb-2">About</td>
                            <td
                                className="data"
                                dangerouslySetInnerHTML={{
                                    __html: getCurrentUser().about,
                                }}
                            />
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Profile;
