import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { setCurrentUser, getCurrentUser } from '../../helpers/Utils';
import axios from "axios";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TribeRequest( props ) {
    const [acceptLoading, setAcceptLoading] = useState(false)
    const [rejectLoading, setRejectLoading] = useState(false)
    const [data, setData] = useState(props.data)

    const frientRequest = (requestStatus, id) => {
        axios.post(
            `${process.env.REACT_APP_API_URL}/accept-reject-tribe-invite`, {
                status : requestStatus,
                tribe_id : id
            },
            {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${getCurrentUser().token}`
                },
            }
        )
        .then(function (res) {
            setAcceptLoading(false);
            setRejectLoading(false);
            setData('')
            toast.success(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    const accept = (requestStatus, id) => {
        setAcceptLoading(true)
        frientRequest(requestStatus, id);
    }

    const reject = (requestStatus, id) => {
        setRejectLoading(true)
        frientRequest(requestStatus, id);
    }

    return (
        <>
            {data !== '' ? (
                <tr key={props.i}>
                    <td>
                        <div className="d-flex align-items-center w-100">
                            <img src={data.image} alt="img" />
                            <h3 className="text-capitalize font-request mx-3">
                                <NavLink to={process.env.PUBLIC_URL + '/tribe/' + data.slug}>
                                    {data.title}
                                </NavLink>
                            </h3>
                        </div>
                    </td>
                    <td className="text-right text-right">
                        <div className="d-flex justify-content-end align-items-center w-100">
                            {acceptLoading ? (
                                <button className="btn btn-primary py-2 px-4 request-width-profile mx-2" disabled>Loading</button>
                            ) : (
                                <button onClick={() => accept(1, data.id)} className="btn btn-primary py-2 px-4 request-width-profile mx-2">Accept</button>
                            )}
                            {rejectLoading ? (
                                <button className="btn btn-primary py-2 px-4 request-width-profile mx-2" disabled>Loading</button>
                            ) : (
                                <button onClick={() => reject(2, data.id)} className="btn btn-primary py-2 px-4 request-width-profile mx-2">Reject</button>
                            )}
                        </div>
                    </td>
                </tr>
            ) : ''}
        </>
    )
}

export default TribeRequest;