import React, { useState } from "react";
import Modal from "react-modal";
import Countdown from "react-countdown";
import axios from 'axios';
import { getCurrentUser } from '../../helpers/Utils';
import Loader from "../loader";

const customStyles = {
    content: {
        position: "unset",
        maxWidth: "850px",
        width: "100%",
        right: "auto",
        border: "none",
        bottom: "auto",
        padding: "0",
        margin: "30px auto",
    },
};

const LiveGrid = ({ data, classOption }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [singleData, setSingleData] = useState('');
    const [loader, setLoader] = useState(<Loader />);

    const link_url = data.zoom_link;

    const Completionist = () => <span className="text-success d-inline-block text-center w-100 mb-3">Class is Going on</span>;
    
    if (data.end_time_left < 0) {
        const Completionist = () => <span className="text-danger d-inline-block text-center w-100 mb-3">Expired</span>;
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <Completionist />;
        } else {
            return (
                <div className="liveclass_timer">
                    <div id="countdown">
                        <ul>
                            <li>
                                <span id="days">{days}</span>days
                            </li>
                            <li>
                                <span id="hours">{hours}</span>Hours
                            </li>
                            <li>
                                <span id="minutes">{minutes}</span>Minutes
                            </li>
                            <li>
                                <span id="seconds">{seconds}</span>Seconds
                            </li>
                        </ul>
                    </div>
                </div>
            );
        }
    };


    const fetchLiveClassDetail = (date) => {
        let formattedDate = date.split(' ')[0]

        setIsOpen(true)
        setLoader(<Loader />)
        axios.get(`${process.env.REACT_APP_API_URL}/live-class/${data.slug}?date=${formattedDate}`, {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${getCurrentUser().token}`,
            },
        })
        .then(function (res) {
            setSingleData(res.data.data);
            setLoader('')
        })
    }

    return (
        <div>
            <div className="live_single">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="live_single-imgs">
                            <img
                                className="w-100 cursor-pointer"
                                src={data.image}
                                alt=""
                                onClick={() => fetchLiveClassDetail(data.date)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="live_single-content mt-2">
                            <h5
                                className="text-capitalize cursor-pointer"
                                onClick={() => fetchLiveClassDetail(data.date)}
                            >
                                {data.title}
                            </h5>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: data.short_description,
                                }}
                            ></p>
                        </div>
                        <div className="live_single-date mt-3">
                            <p className="m-0 mb-1">
                                <i
                                    className="fa fa-calendar"
                                    aria-hidden="true"
                                ></i>
                                {data.modified_class_date}
                            </p>
                            <p className="m-0">
                                <i
                                    className="fa fa-clock-o"
                                    aria-hidden="true"
                                ></i>{" "}
                                {data.start_time}{data.start_time ? ` - ${data.end_time}` : ''}
                            </p>
                        </div>
                        <button
                            type="button"
                            className="btn btn-dark mt-4"
                            onClick={() => fetchLiveClassDetail(data.date)}
                        >
                            View Detail
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                ariaHideApp={false}
                isOpen={modalIsOpen}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {loader === '' ?
                    <div className="live_popup">
                        <div className="modal-header pt-1 pb-0">
                            <h4 className="text-center w-100 d-inline-block">
                                {data.title}
                            </h4>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="close p-0"
                            >
                                <span
                                    style={{
                                        fontSize: "28px",
                                    }}
                                    aria-hidden="true"
                                >
                                    ×
                                </span>
                            </button>
                        </div>
                        <div>
                            <div className="p-3">
                                <table className="table table-bordered border">
                                    <tbody>
                                        <tr>
                                            <th className="p-2">
                                                <i
                                                    className="fa fa-calendar"
                                                    aria-hidden="true"
                                                ></i>{" "}
                                                Date
                                            </th>
                                            <th className="p-2">
                                                <i
                                                    className="fa fa-clock-o"
                                                    aria-hidden="true"
                                                ></i>{" "}
                                                Time
                                            </th>
                                        </tr>
                                        <tr>
                                            <td className="p-2">
                                                <span
                                                    style={{
                                                        paddingLeft: "17px",
                                                    }}
                                                >
                                                    {data.modified_class_date}
                                                </span>
                                            </td>
                                            <td className="p-2">
                                                <span
                                                    style={{
                                                        paddingLeft: "17px",
                                                    }}
                                                >
                                                    {data.local_timezone_data.time}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <img
                                src={data.image}
                                alt=""
                                className="mw-100 m-auto d-block"
                            />
                            <div className="mt-5 p-3">
                                <div className="row">
                                    <div className="col-md-8">
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: data.description,
                                            }}
                                            className="live-adjustment"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Countdown
                                            date={Date.now() + data.start_time_left}
                                            renderer={renderer}
                                        >
                                            <Completionist />
                                        </Countdown>
                                        <div className="tribe_info">
                                            <div className="share_social"></div>
                                            <div className="timer"></div>
                                        </div>

                                        {link_url !== null ? (
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={link_url}
                                                className="join-style mb-3 d-inline-block text-center m-auto d-block"
                                            >
                                                Join the session
                                            </a>
                                        ) : (
                                            ""
                                        )}
                                        <div className="social_share mt-5">
                                            <div className="datetime text-center">
                                                <h6>SHARE THIS EVENT</h6>
                                                <ul className="social-icon">
                                                    <li>
                                                        <a
                                                            className="facebook"
                                                            href={`https://www.facebook.com/sharer.php?u=https://new.soultribeonline.com/event/${data.slug}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <i className="fa fa-facebook"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="twitter"
                                                            href={`https://twitter.com/intent/tweet?url=https://new.soultribeonline.com/event/${data.slug}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <i className="fa fa-twitter"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="linkedin"
                                                            href={`https://www.linkedin.com/shareArticle?url=https://new.soultribeonline.com/event/${data.slug}&title=${data.title}&summary=${data.short_description}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <i className="fa fa-linkedin"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="email"
                                                            href={`mailto:?body=https://new.soultribeonline.com/event/${data.slug}`}
                                                        >
                                                            <i className="fa fa-envelope"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="social_share mt-5">
                                            <div className="datetime text-center">
                                                <h6>LOCAL TIME</h6>
                                                <div className="p-3">
                                                    <h5 style={{fontSize: '16px'}}><b>Timezone: </b>{data.local_timezone_data.timezone}</h5>
                                                    <h5 style={{fontSize: '16px'}}><b>Date: </b>{data.local_timezone_data.start_date}</h5>
                                                    <h5 style={{fontSize: '16px'}}><b>Time: </b>{data.local_timezone_data.time}</h5>
                                                </div>
                                                <a
                                                    href={data.add_to_calander}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="btn btn-primary mb-2"
                                                >
                                                    <i className="fa fa-calendar" />{" "}
                                                    Add to My Calendar
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                : loader}
            </Modal>
        </div>
    );
};

export default LiveGrid;
