import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import TeamCard from "../../components/team-card";
import axios from "axios";

const TeamPageContainer = ({ classOption }) => {
  const [data, setData] = useState([]);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/teachers`, {})
      .then(function (res) {
        setData(res.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  let teacher = "";

  if (data !== undefined) {
    teacher = (
      <>
        {data.map((teacher, key) => {
          return (
            <div
              key={key}
              className="col-6 col-md-6 col-lg-4 col-xl-3 text-center mb-md-7"
            >
              <TeamCard key={key} data={teacher} />
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div className="service-section section-py">
      <div className="container">
        <div className="row align-items-center pt-sm-8 service-text">
          {teacher}
        </div>
      </div>
    </div>
  );
};

TeamPageContainer.propTypes = {
  classOption: PropTypes.string,
};

TeamPageContainer.defaultProps = {
  classOption: "section-pb",
};

export default TeamPageContainer;
