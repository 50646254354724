import React, { useState } from "react";
import axios from "axios";
import { getCurrentUser } from "../../helpers/Utils";
import {
	convertFromHTML,
	ContentState,
	EditorState,
	convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditComment(props) {
	const html = props.data.comment !== null ? props.data.comment : "<p></p>";
	const blocksFromHTML = convertFromHTML(html);
	const content = ContentState.createFromBlockArray(blocksFromHTML);
	const [defPostContent, setDefPostContent] = useState(
		EditorState.createWithContent(content)
	);
    const [updateLoader, setUpdateLoader] = useState(false);
	const onEditorStateChange = (defPostContent) => {
		setDefPostContent(defPostContent);
	};

	const updatePostInfo = () => {
		setUpdateLoader(true);
		axios
		.post(
			`${process.env.REACT_APP_API_URL}/update-tribe-comment`,
			{
				comment: draftToHtml(
					convertToRaw(defPostContent.getCurrentContent())
				),
				post_id: props.postId,
				id: props.data.id,
			},
			{
				headers: {
					"content-type": "application/json",
					Authorization: `Bearer ${getCurrentUser().token}`,
				},
			}
		)
		.then(function (res) {
			props.setModalIsOpen(false);
			props.setMoreInfo(false);
			setUpdateLoader(false);
			if (res.data.success === true) {
				toast.success(res.data.message, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				props.setItem(res.data.data);
			} else {
				toast.error(res.data.message, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}
		});
	};




	const list = [];
    props.allMember && props.allMember.map((item) => {
        list.push({
          text: item.name,
          value: item.name,
          url: 'member/'+item.id+'',
        });
    });


	return (
		<>
			<div className="border">
				<Editor
					editorState={defPostContent}
					wrapperClassName="demo-wrapper"
					editorClassName="demo-editor"
					onEditorStateChange={onEditorStateChange}
					toolbar={{
						options: ["inline", "list"],
						inline: {
							options: [
								"bold",
								"italic",
								"underline",
								"strikethrough",
							],
						},
					}}
					mention={{
						separator: " ",
						trigger: "@",
						suggestions: list,
					}}
					hashtag={{
						separator: " ",
						trigger: "#",
					}}
				/>
			</div>
			<div className="text-right">
				{updateLoader ? (
					<button className="btn btn-primary btn-sm mt-3">
						Updating...
					</button>
				) : (
					<button
						onClick={() => updatePostInfo()}
						button="button"
						className="btn btn-primary btn-sm mt-3"
					>
						Update
					</button>
				)}
			</div>
		</>
	);
}
export default EditComment;
