import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ClassDetailsWrap from "../../../components/class-details";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { getCurrentUser } from "../../../helpers/Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../../../components/loader";
import SingleComment from "./single-comment";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

let list = [];

const ClassDetailsContainer = ({ data }) => {
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(<Loader />);
    const [comments, setComments] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const submitNewComment = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/class-comment`,
                {
                    class_id: data.id,
                    comment: draftToHtml(
                        convertToRaw(editorState.getCurrentContent())
                    ),
                },
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.success) {
                    e.target.reset();
                    getAllComments();
                    toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setEditorState(EditorState.createEmpty());
            });
    };
    const getAllComments = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/get-class-comments?class_id=${data.id}`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                if (res.data.success) {
                    setComments(res.data.data.data);
                }
            });
    };

    const getMember = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/show-members-limited-data`, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                res.data.data.map((item) => {
                    list.push({
                        text: item.name,
                        value: item.name,
                        url: "/member/" + item.id + "",
                    });
                });
            });
    };

    const getContentChange = (e) => {
        setEditorState(e);
    };

    useEffect(() => {
        getMember();
        getAllComments();
    }, []);

    return (
        <div className="blog-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-12 col-lg-12 mb-7">
                        <div className="blog-details-content">
                            <ClassDetailsWrap data={data} />
                            <div className="blog-comments">
                                <h5 className="blog-comment-title">
                                    Share your experience in this class –
                                    comment below!
                                </h5>
                            </div>
                            <div className="comments">
                                {loader !== "" ? (
                                    loader
                                ) : (
                                    <>
                                        {comments &&
                                            comments.map((item) => (
                                                <SingleComment
                                                    data={item}
                                                    classTeacherId={
                                                        data.teacher_id
                                                    }
                                                    list={list}
                                                />
                                            ))}
                                    </>
                                )}
                            </div>
                            <div className="comments-form">
                                <form onSubmit={(e) => submitNewComment(e)}>
                                    <div className="form-group mb-5">
                                        <h6>Submit a Comment</h6>
                                        <div className="border mt-4">
                                            <Editor
                                                editorState={editorState}
                                                onEditorStateChange={(text) =>
                                                    getContentChange(text)
                                                }
                                                placeholder="Write here or use @ to mention someone."
                                                toolbar={{
                                                    options: ["inline", "list"],
                                                    inline: {
                                                        options: [
                                                            "bold",
                                                            "italic",
                                                            "underline",
                                                            "strikethrough",
                                                        ],
                                                    },
                                                    list: {
                                                        options: [
                                                            "unordered",
                                                            "ordered",
                                                        ],
                                                    },
                                                }}
                                                mention={{
                                                    separator: " ",
                                                    trigger: "@",
                                                    suggestions: list,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        {loading ? (
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                            >
                                                Loading
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-primary"
                                                type="submit"
                                            >
                                                Submit
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ClassDetailsContainer.propTypes = {
    data: PropTypes.object,
};

export default ClassDetailsContainer;
