import React from "react";
import LiveItemContainer from "../containers/live/class-item";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ScrollToTop from "../components/scroll-to-top";
import { getCurrentUser } from '../helpers/Utils';
import SEO from "../components/seo";
import FullCalendarCompo from "../containers/live/class-calendar";
import PageLoader from '../components/page-loader'

const LivePage = () => {
    return (
        <React.Fragment>
        <PageLoader />
            <Layout>
                <SEO title="Soul Tribe Online Community – Live Classes" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Live Classes"
                        excerpt=""
                        image="./images/service/6.png"
                    />
                    <LiveItemContainer />
                    {getCurrentUser() ? (
                        <FullCalendarCompo />
                    ) : ''}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default LivePage;