import React from "react";
import SEO from "../components/seo";
import Layout from "../layouts/index";
import PageLoader from '../components/page-loader'
/*import {loadStripe} from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";*/

const CheckoutPage = () => {
    /*const stripePromise = loadStripe("pk_test_51Ilq7XAZSkDKVSD9nIK2XHwvqUiPJWBrsL3Lrcgco200WtYXkJhUEVo7E8HQalMWczUn8hyIXivs0Ee0MT8FITPY00aKSYltxo");

    const handleSubmit = (stripe, elements) => async () => {
        const cardElement = elements.getElement(CardElement);

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

    };
    const stripe = useStripe();
    const elements = useElements();
      */
    return (
        <React.Fragment>
            <Layout>
            <PageLoader />
                <SEO title="Soul Tribe Online Community – Checkout" />
                <div className="wrapper">
                    {/*<section className="section-py">
                        <div className="container">
                            <Elements stripe={stripePromise}>
                                <CardElement />
                                <button onClick={handleSubmit(stripe, elements)}>Buy</button>
                            </Elements>
                        </div>
                    </section>*/}
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default CheckoutPage;
