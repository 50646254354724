import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { getCurrentUser } from "../../helpers/Utils";
import TribePostDocument from "./PostDocument";
import PostComment from "./PostComment";
import Loader from "../loader";
import { NavLink } from "react-router-dom";
import { EditorState } from "draft-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import GetLinkPreview from "../custom/GetLinkPreview";
import EditPost from "./edit-post";
import PostLightBox from "./lightbox";
import Modal from "react-modal";
import linkifyHtml from "linkify-html";
import Gallery from "react-photo-gallery";
import "linkify-plugin-hashtag";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "845px",
    width: "100%",
    maxHeight: "95%",
  },
};
const likeCountsStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    padding: "20px 0",
    bottom: "auto",
    minHeight: "400px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "545px",
    width: "100%",
    // height: '80%'
  },
};

function LovestreamPost(props) {
  const [commentBox] = useState(true);
  // const [commentBox, setCommentBox] = useState(true);
  const [like, setLike] = useState(props.post.liked_icon);
  const [likeCountModal, setLikeCountModal] = useState(false);
  const [item, setItem] = useState(props.post);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [sure, setSure] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(false);
  const [comment, setComment] = useState([]);
  const [loader, setLoader] = useState(<Loader />);
  const [likeCounts, setLikeCounts] = useState(props.post.like_counts);
  const [careCounts, setCareCounts] = useState(props.post.care_counts);
  const [loveCounts, setLoveCounts] = useState(props.post.love_counts);
  const [hidePost, setHidePost] = useState(false);
  const [info, setInfo] = useState(false);
  const [gratefullCounts, setGratefullCounts] = useState(
    props.post.gratefull_counts
  );
  const [commentCount, setCommentCount] = useState(props.post.comment_counts);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const likePost = (postId, checklike) => {
    if (checklike === 0) {
      setLike(0);
      // setLikeCounts(likeCounts - 1)
    } else if (checklike === 1) {
      setLike(1);
    } else if (checklike === 2) {
      setLike(2);
    } else if (checklike === 3) {
      setLike(3);
    } else if (checklike === 4) {
      setLike(4);
    }

    axios
      .post(
        "https://panel.soultribeonline.com/api/tribe-like",
        {
          post_id: postId,
          like_icon: checklike,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        setLikeCounts(res.data.data.post.like_counts);
        setCareCounts(res.data.data.post.care_counts);
        setLoveCounts(res.data.data.post.love_counts);
        setGratefullCounts(res.data.data.post.gratefull_counts);
        if (res.data.success === true) {
          setItem(res.data.data.post);
        }
      });
  };

  const postComment = (e) => {
    e.preventDefault();
    setLoading(true);
    var formData = new FormData();
    formData.append("post_id", item.id);
    formData.append("comment", message);
    formData.append("image", image);
    axios
      .post(`${process.env.REACT_APP_API_URL}/tribe-comment`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
      })
      .then(function (res) {
        setLoading(false);
        e.target.reset();
        if (res.data.success === true) {
          setMessage("");
          setEditorState(() => EditorState.createEmpty());
          getComment();
          // setError(res.data.message)
          setSuccess("");
          setCommentCount(commentCount + 1);
          setImage("");
        } else {
          setError("");
          setSuccess(res.data.message);
        }
      });
  };

  const feedAttach = (e) => {
    setImage(e.target.files[0]);
  };

  const deletePost = (postId) => {
    setDeleteLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/delete-post?post_id=${postId}`,
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        setDeleteLoading(false);
        // setItem("");
        setHidePost(true);
        setInfo(false);
        if (item.discussion_id === props.discussionId) {
          props.setDisSingleModal(false);
          props.getDiscussions();
        }
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const getComment = () => {
    setLoader(<Loader />);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/get-tribe-comment?post_id=${item.id}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        setLoader("");
        if (res.data.success === true) {
          setCurrentPage(res.data.data.current_page);
          setPagination(res.data.data.next_page_url);
          setComment(res.data.data.data);
        } else {
          setComment([]);
        }
      });
  };

  const getCommentsNext = (page = currentPage) => {
    setCommentLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/get-tribe-comment?post_id=${item.id}&page=${page}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser()?.token}`,
          },
        }
      )
      .then(function (res) {
        setCommentLoading(false);
        if (res.data.success !== false) {
          setCurrentPage(res.data.data.current_page);
          let nextData = res.data.data.data;
          let setmultiple = [...comment, ...nextData];
          setComment(setmultiple);
          setPagination(res.data.data.next_page_url);
        }
      });
  };

  const nextLink = () => {
    getCommentsNext(currentPage + 1);
  };

  // if (info) {
  //     const concernedElement = document.querySelector(".info_menu");
  //     document.addEventListener("mousedown", (event) => {
  //         if (!concernedElement.contains(event.target)) {
  //             setInfo(false)
  //         }
  //     });
  // }

  const moreInfoToggle = () => {
    if (info === true) {
      setInfo(false);
    } else setInfo(true);
  };

  // if (info === true) {
  //     const concernedElement = document.querySelector(".contact-profile-info");
  //     document.addEventListener("mousedown", (event) => {
  //       if (concernedElement.contains(event.target)) {
  //       } else {
  //         setInfo(false)
  //       }
  //     });
  // }

  let postImages = [];

  if (item.photos !== undefined) {
    item.photos.map((img) =>
      postImages.push({
        src: img.photo,
        width: 3,
        height: 4,
      })
    );
  }

  let isDeleteOption = "";

  if (
    props.post !== undefined &&
    getCurrentUser()?.id === props.post.user?.id
  ) {
    isDeleteOption = (
      <div>
        <div className="contact-profile-info">
          <button
            onClick={() => moreInfoToggle()}
            className="edit_post px-2"
            title="Edit Post"
          >
            <i className="fa fa-ellipsis-v"></i>
          </button>
          {info ? (
            <div className="info_menu">
              <ul>
                <li
                  className="cursor-pointer"
                  onClick={(e) => setModalIsOpen(true)}
                >
                  <i className="fa fa-edit"></i> Edit
                </li>
                <li className="cursor-pointer">
                  {deleteLoading ? (
                    <span disabled>
                      <i className="fa fa-trash"></i> Loading
                    </span>
                  ) : (
                    <>
                      {sure ? (
                        <span
                          className="text-danger d-inline-block"
                          onClick={(e) => deletePost(item.id)}
                        >
                          <i className="fa fa-trash text-danger"></i> Are you
                          sure?
                        </span>
                      ) : (
                        <span
                          className=" d-inline-block"
                          onClick={(e) => setSure(true)}
                        >
                          <i className="fa fa-trash"></i> Delete
                        </span>
                      )}
                    </>
                  )}
                </li>
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  if (item.is_organizer === 1) {
    isDeleteOption = (
      <div>
        <div className="contact-profile-info">
          <button
            onClick={() => moreInfoToggle()}
            className="edit_post px-2"
            title="Edit Post"
          >
            <i className="fa fa-ellipsis-v"></i>
          </button>
          {info ? (
            <div className="info_menu">
              <ul>
                {item.user && item.user.id == getCurrentUser()?.id ? (
                  <li
                    className="cursor-pointer"
                    onClick={(e) => setModalIsOpen(true)}
                  >
                    <i className="fa fa-edit"></i> Edit
                  </li>
                ) : (
                  ""
                )}
                <li className="cursor-pointer">
                  {deleteLoading ? (
                    <span disabled>
                      <i className="fa fa-trash"></i> Loading
                    </span>
                  ) : (
                    <>
                      {sure ? (
                        <span
                          className="text-danger d-inline-block"
                          onClick={(e) => deletePost(item.id)}
                        >
                          <i className="fa fa-trash text-danger"></i> Are you
                          sure?
                        </span>
                      ) : (
                        <span
                          className=" d-inline-block"
                          onClick={(e) => setSure(true)}
                        >
                          <i className="fa fa-trash"></i> Delete
                        </span>
                      )}
                    </>
                  )}
                </li>
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  } else if (getCurrentUser()?.id === item.user && item.user.id) {
    isDeleteOption = (
      <div className="ognr">
        <div className="contact-profile-info">
          <button
            onClick={() => moreInfoToggle()}
            className="edit_post px-2"
            title="Edit Post"
          >
            <i className="fa fa-ellipsis-v"></i>
          </button>
          {info ? (
            <div className="info_menu">
              <ul>
                <li
                  className="cursor-pointer"
                  onClick={(e) => setModalIsOpen(true)}
                >
                  <i className="fa fa-edit"></i> Edit
                </li>
                <li className="cursor-pointer">
                  {deleteLoading ? (
                    <span disabled>
                      <i className="fa fa-trash"></i> Loading
                    </span>
                  ) : (
                    <>
                      {sure ? (
                        <span
                          className="text-danger d-inline-block"
                          onClick={(e) => deletePost(item.id)}
                        >
                          <i className="fa fa-trash text-danger"></i> Are you
                          sure?
                        </span>
                      ) : (
                        <span
                          className=" d-inline-block"
                          onClick={(e) => setSure(true)}
                        >
                          <i className="fa fa-trash"></i> Delete
                        </span>
                      )}
                    </>
                  )}
                </li>
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  useEffect(() => {
    getComment();
  }, []);

  const utility = {};
  utility["error"] = error;
  utility["success"] = success;
  utility["loading"] = loading;
  utility["loader"] = loader;
  utility["image"] = image;
  utility["message"] = message;
  utility["nextLink"] = nextLink;
  utility["pagination"] = pagination;
  utility["commentLoading"] = commentLoading;

  if (item !== "") {
    return (
      <div className={`${hidePost ? "d-none" : ""}`}>
        <PostLightBox
          currentImage={currentImage}
          viewerIsOpen={viewerIsOpen}
          postImages={postImages}
          setCurrentImage={setCurrentImage}
          setViewerIsOpen={setViewerIsOpen}
        />
        {modalIsOpen ? (
          <Modal isOpen={true} style={customStyles}>
            <i
              className="fa fa-window-close float-right"
              onClick={(e) => setModalIsOpen(false)}
              aria-hidden="true"
            ></i>
            <EditPost
              data={item}
              setInfo={setInfo}
              setItem={setItem}
              setModalIsOpen={setModalIsOpen}
            />
          </Modal>
        ) : (
          ""
        )}
        <Modal isOpen={likeCountModal} style={likeCountsStyles}>
          <i
            className="fa fa-window-close float-right"
            onClick={(e) => setLikeCountModal(false)}
            aria-hidden="true"
            style={{
              marginRight: "15px",
            }}
          ></i>
          <div className="countmodal">
            <Tabs className="vertical_tabs">
              <TabList>
                {item.likes_data.likes ? (
                  <Tab>
                    <img
                      alt="ss"
                      style={{
                        transform: "translate(0, -4px)",
                      }}
                      src={`${process.env.PUBLIC_URL}/images/like.png`}
                      title="Like"
                    />
                    &nbsp;Like
                  </Tab>
                ) : (
                  ""
                )}
                {item.likes_data.gratefuls ? (
                  <Tab>
                    <img
                      alt="ss"
                      src={`${process.env.PUBLIC_URL}/images/greatful.png`}
                      title="Grateful"
                    />
                    &nbsp;Grateful
                  </Tab>
                ) : (
                  ""
                )}
                {item.likes_data.cares ? (
                  <Tab>
                    <img
                      alt="ss"
                      src={`${process.env.PUBLIC_URL}/images/care.png`}
                      title="Care"
                    />
                    &nbsp;Care
                  </Tab>
                ) : (
                  ""
                )}
                {item.likes_data.loves ? (
                  <Tab>
                    <img
                      alt="ss"
                      src={`${process.env.PUBLIC_URL}/images/love.png`}
                      title="Love"
                    />
                    &nbsp;Love
                  </Tab>
                ) : (
                  ""
                )}
              </TabList>
              {item.likes_data.likes ? (
                <TabPanel>
                  <ul className="post">
                    {item.likes_data.likes.map((user, i) => (
                      <li key={i}>
                        <div className="posted border-0 m-0">
                          <div className="posted_header py-2">
                            <a href={`/member/${user && user.id}`}>
                              <img alt="ss" src={user && user.image} />
                            </a>
                            <a href={`/member/${user && user.id}`}>
                              {user && user.name}
                            </a>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </TabPanel>
              ) : (
                ""
              )}
              {item.likes_data.gratefuls ? (
                <TabPanel>
                  <ul className="post">
                    {item.likes_data.gratefuls.map((user, i) => (
                      <li key={i}>
                        <div className="posted border-0 m-0">
                          <div className="posted_header py-2">
                            <a href={`/member/${user && user.id}`}>
                              <img alt="ss" src={user && user.image} />
                            </a>
                            <a href={`/member/${user && user.id}`}>
                              {user && user.name}
                            </a>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </TabPanel>
              ) : (
                ""
              )}
              {item.likes_data.cares ? (
                <TabPanel>
                  <ul className="post">
                    {item.likes_data.cares.map((user, i) => (
                      <li key={i}>
                        <div className="posted border-0 m-0">
                          <div className="posted_header py-2">
                            <a href={`/member/${user && user.id}`}>
                              <img alt="ss" src={user && user.image} />
                            </a>
                            <a href={`/member/${user && user.id}`}>
                              {user && user.name}
                            </a>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </TabPanel>
              ) : (
                ""
              )}
              {item.likes_data.loves ? (
                <TabPanel>
                  <ul className="post">
                    {item.likes_data.loves.map((user, i) => (
                      <li key={i}>
                        <div className="posted border-0 m-0">
                          <div className="posted_header py-2">
                            <a href={`/member/${user && user.id}`}>
                              <img alt="ss" src={user && user.image} />
                            </a>
                            <a href={`/member/${user && user.id}`}>
                              {user && user.name}
                            </a>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </TabPanel>
              ) : (
                ""
              )}
            </Tabs>
          </div>
        </Modal>
        <div className={`posted ${item.id}`} key={props.post.id}>
          <div className="posted_header">
            {item.user ? (
              <>
                <NavLink
                  to={
                    process.env.PUBLIC_URL +
                    `/member/${item.user && item.user.id}`
                  }
                >
                  <img className="img_width" alt="ss" src={item.user.image} />
                </NavLink>
              </>
            ) : (
              <img alt="ss" src="/images/noimg.png" />
            )}
            <span
              style={{
                paddingRight: "40px",
              }}
            >
              {item.user ? (
                <>
                  <NavLink
                    to={
                      process.env.PUBLIC_URL +
                      `/member/${item.user && item.user.id}`
                    }
                  >
                    {item.user.name}
                  </NavLink>{" "}
                  {item.discussion_id !== null ? (
                    <span>
                      posted in discussion in the forum{" "}
                      <NavLink
                        to={
                          process.env.PUBLIC_URL +
                          `/tribe/${item.tribe_slug && item.tribe_slug}`
                        }
                        className="text-capitalize"
                      >
                        {item.tribe}
                      </NavLink>
                    </span>
                  ) : (
                    <>
                      {item.tribe_id !== null ? (
                        <span>
                          posted an update in the group{" "}
                          <NavLink
                            className="text-capitalize"
                            to={
                              process.env.PUBLIC_URL +
                              `/tribe/${item.tribe_slug}`
                            }
                          >
                            {item.tribe}
                          </NavLink>
                        </span>
                      ) : (
                        ""
                      )}
                      {item.member_id !== null ? (
                        <span>
                          posted an update on{" "}
                          <NavLink
                            className="text-capitalize"
                            to={
                              process.env.PUBLIC_URL +
                              `/member/${item.member_id}`
                            }
                          >
                            {item.member_name}'s
                          </NavLink>{" "}
                          timeline
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  <br />
                  <span
                    style={{
                      color: "#888",
                      fontSize: "14px",
                    }}
                  >
                    {item.created_time_ago}{" "}
                    {item.edited === "yes" ? (
                      <>
                        (<em>Edited</em>)
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </>
              ) : (
                "Deleted User"
              )}
            </span>
            {isDeleteOption}
          </div>
          <div className="posted_info">
            <div
              className="posted_info-content"
              dangerouslySetInnerHTML={{
                __html: linkifyHtml(item.message, {
                  formatHref: {
                    hashtag: (href) => "/lovestream?hashtag=" + href.substr(1),
                  },
                }),
              }}
            />
            <div className="imgepost">
              <div className="row">
                <Gallery photos={postImages} onClick={openLightbox} />
              </div>
            </div>
            <div className="documentPost p-3">
              <div className="row">
                {item.documents &&
                  item.documents.map((postdocu) => (
                    <TribePostDocument data={postdocu} />
                  ))}
              </div>
            </div>

            <div className="videopost">
              {item.video ? (
                <video
                  src={item.video}
                  width="350"
                  height="280"
                  controls
                  className="video-css"
                ></video>
              ) : (
                ""
              )}
            </div>

            {props.discussionId === undefined && getCurrentUser() ? (
              <>
                <GetLinkPreview
                  text={item.message.replace(/<\/?[^>]+(>|$)/g, "")}
                />

                <div className="likecommmets d-flex justify-content-between">
                  <div
                    className="cursor-pointer"
                    onClick={() => setLikeCountModal(true)}
                  >
                    {/* <i className="fa fa-thumbs-up"></i> */}
                    {/* <span style={{ */}
                    {/*     marginLeft: '10px', */}
                    {/*     transform: 'translate(0, 0px)', */}
                    {/*     display: 'inline-block' */}
                    {/* }}>{likeCounts}</span> */}

                    {likeCounts !== null ? (
                      <>
                        <img
                          alt="ss"
                          src={`${process.env.PUBLIC_URL}/images/like.png`}
                          title="Like"
                        />{" "}
                        {likeCounts} &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                    {careCounts !== null ? (
                      <>
                        <img
                          alt="ss"
                          src={`${process.env.PUBLIC_URL}/images/care.png`}
                          title="Care"
                        />{" "}
                        {careCounts} &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                    {loveCounts !== null ? (
                      <>
                        <img
                          alt="ss"
                          src={`${process.env.PUBLIC_URL}/images/love.png`}
                          title="Love"
                        />{" "}
                        {loveCounts} &nbsp;
                      </>
                    ) : (
                      ""
                    )}
                    {gratefullCounts !== null ? (
                      <>
                        <img
                          alt="ss"
                          src={`${process.env.PUBLIC_URL}/images/greatful.png`}
                          title="Grateful"
                        />{" "}
                        {gratefullCounts}
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  <div>
                    {/* {item.discussion_id == null ? ( */}
                    <span
                      /*onClick={openComment}*/
                      style={{ cursor: "pointer" }}
                    >
                      {commentCount > 1 ? <>{commentCount} Comments</> : ""}
                      {commentCount === 1 ? <>{commentCount} Comment</> : ""}
                      {commentCount ? "" : <>0 Comment</>}
                    </span>
                    {/* ) : (
                      ""
                    )} */}
                  </div>
                </div>
                {getCurrentUser() ? (
                  <>
                    <div className="posted_info-activity">
                      <div className="generic-button likesec">
                        <div className="like_options">
                          <img
                            alt="ss"
                            onClick={() => likePost(item.id, 1)}
                            src={`${process.env.PUBLIC_URL}/images/like.png`}
                            title="Like"
                          />
                          <img
                            alt="ss"
                            onClick={() => likePost(item.id, 2)}
                            src={`${process.env.PUBLIC_URL}/images/greatful.png`}
                            title="Grateful"
                          />
                          <img
                            alt="ss"
                            onClick={() => likePost(item.id, 3)}
                            src={`${process.env.PUBLIC_URL}/images/care.png`}
                            title="Care"
                          />
                          <img
                            alt="ss"
                            onClick={() => likePost(item.id, 4)}
                            src={`${process.env.PUBLIC_URL}/images/love.png`}
                            title="Love"
                          />
                        </div>
                        {like === 0 ? (
                          <button
                            type="buttton"
                            onClick={() => likePost(item.id, 1)}
                          >
                            <i className="fa fa-thumbs-up"></i> Like
                          </button>
                        ) : (
                          ""
                        )}
                        <button
                          type="buttton"
                          onClick={() => likePost(item.id, 0)}
                          className="active_like"
                        >
                          {like === 1 ? (
                            <>
                              <img
                                alt="ss"
                                style={{
                                  transform: "translate(0, -4px)",
                                }}
                                src={`${process.env.PUBLIC_URL}/images/like.png`}
                                title="Like"
                              />
                              &nbsp;Like
                            </>
                          ) : (
                            ""
                          )}
                          {like === 2 ? (
                            <>
                              <img
                                alt="ss"
                                src={`${process.env.PUBLIC_URL}/images/greatful.png`}
                                title="Grateful"
                              />
                              &nbsp;Grateful
                            </>
                          ) : (
                            ""
                          )}
                          {like === 3 ? (
                            <>
                              <img
                                alt="ss"
                                src={`${process.env.PUBLIC_URL}/images/care.png`}
                                title="Care"
                              />
                              &nbsp;Care
                            </>
                          ) : (
                            ""
                          )}
                          {like === 4 ? (
                            <>
                              <img
                                alt="ss"
                                src={`${process.env.PUBLIC_URL}/images/love.png`}
                                title="Love"
                              />
                              &nbsp;Love
                            </>
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                      <div className="generic-button">
                        {/* {item.discussion_id == null ? ( */}
                        <button type="buttton">
                          <i className="fa fa-comment"></i> Comment
                        </button>
                        {/* ) : (
                      ""
                    )} */}
                      </div>
                    </div>
                    {/* {item.discussion_id == null */}
                    {commentBox && (
                      <PostComment
                        allMember={props.mentionAllMember}
                        data={comment}
                        setMessage={setMessage}
                        feedAttach={feedAttach}
                        postComment={postComment}
                        utility={utility}
                        setEditorState={setEditorState}
                        editorState={editorState}
                        postId={item.id}
                        userId={item.user && item.user.id}
                      />
                    )}
                  </>
                ) : (
                  ""
                )}
                {/* : ""} */}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return "";
  }
}

export default LovestreamPost;
