import React, { useState, useEffect } from "react";
import LoveStreamPost from "../lovestream/Post";
import LoveStreamForm from "../lovestream/Form";
import { getCurrentUser, getQuotationId } from "../../helpers/Utils";
import axios from "axios";
import Loader from "../loader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import InfiniteScroll from "react-infinite-scroll-component";

import MemberGrid from "../member";
import FriendRequest from "./friend-request";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Connections() {

    const [connection, setConnection] = useState([]);
    const [loader, setLoader] = useState(<Loader />);
    const [request, setRequest] = useState([]);
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);

    const getMyConnection = () => {
        setLoader(<Loader />);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/member/my-connections`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoader("");
                setConnection(res.data.data.my_connections);
                setRequest(res.data.data.my_requests);
                setError(res.data.message);
            });
    };

    const utility = {};
    utility["loading"] = loading;
    utility["setLoading"] = setLoading;

    useEffect(() => {
        getMyConnection();
    }, []);

    return (
        <>
            <Tabs>
                <TabList>
                    <Tab>Requests</Tab>
                    <Tab onClick={getMyConnection}>My Connections</Tab>
                </TabList>
                <TabPanel>
                    <div className="myclasses myrequest-scroll">
                        {request.length === 0
                            ? "You have no pending requests to connect."
                            : ""}
                        <table className="table">
                            {request.map((item, i) => (
                                <FriendRequest
                                    data={item}
                                    i={i}
                                    setLoading={setLoading}
                                    getMyConnection={getMyConnection}
                                    utility={utility}
                                />
                            ))}
                        </table>
                    </div>
                </TabPanel>
                <TabPanel>
                    {loader}
                    {connection.length === 0
                        ? "You do not have any member connected with you."
                        : ""}
                    <div className="row members__page">
                        {connection.map((item, i) => (
                            <div key={i} className="col-lg-3 col-sm-6 mb-7">
                                <MemberGrid classOption="p-0" data={item} />
                            </div>
                        ))}
                    </div>
                </TabPanel>
            </Tabs>
        </>
    );
}

export default Connections;
