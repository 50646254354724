import React, {useState} from "react";
import axios from "axios";
import { getCurrentUser } from '../../helpers/Utils';
import EditorConvertToHTML from '../create-class-form/description';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateGroupForm() {
    const [profileImage, setProfileImage] = useState('')
    const [coverImage, setCoverImage] = useState('')
    const [loading, setLoading] = useState(false)
	const [messages, setMessages] = useState('')

	const createTribe = (e) => {
		e.preventDefault();
		setLoading(true)
		var formData = new FormData();
        formData.append('title', e.target.title.value);
        formData.append('group_type', e.target.group_type.value);
        formData.append('description', messages);
        formData.append('image', profileImage);
        formData.append('cover_image', coverImage);

		axios.post(`${process.env.REACT_APP_API_URL}/create-tribe-group`, formData, {
			headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${getCurrentUser().token}`
            },
		}).then(function (res) {
            if (res.data.success === true) {
				toast.success(res.data.message, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
                window.location.reload(true);
            } else {
				setLoading(false)
				toast.error(res.data.message, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
            }
        })
	}

	const profileAttach = (e) => {
      setProfileImage(e.target.files[0])
    }

	const coverAttach = (e) => {
      setCoverImage(e.target.files[0])
    }

	const getNoteTribe = (classDescription) => {
		setMessages(classDescription);
	}

	return (
		<form onSubmit={createTribe}>
			<div className="form-group mb-4">
                <label>Tribe Name</label>
                <input
                    type="text"
                    className="form-control"
                    name="title"
                />
			</div>
			<div className="form-group mb-4">
                <label>Privacy Options</label>
                <div className="bp-radio-wrap">
                	<div className="bp-radio-wrap"> 
                		<input 
                			type="radio" 
                			name="group_type" 
                			id="group_type-public" 
                			className="bs-styled-radio" 
                			value="public"
                		/>
                		<label htmlFor="group_type-public">This is a public tribe</label>
                	</div>
                	<ul className="group-option">
	                	<li>Any site member can join this tribe.</li>
	                	<li>This tribe will be listed in the tribes directory and in search results.</li>
	                	<li>Tribe content and activity will be visible to any site member.</li>
                	</ul>
                	<div className="bp-radio-wrap"> 
                		<input 
                			type="radio" 
                			name="group_type" 
                			id="group_type-private" 
                			className="bs-styled-radio" 
                			value="private"
                		/>
                		<label htmlFor="group_type-private">This is a private tribe</label>
                	</div>
                	<ul className="group-option">
	                	<li>Only people who request membership and are accepted can join the tribe.</li>
	                	<li>This tribe will be listed in the tribes directory and in search results.</li>
	                	<li>Tribe content and activity will only be visible to members of the tribe.</li>
                	</ul>
                	{/* <div className="bp-radio-wrap">  */}
                	{/* 	<input  */}
                	{/* 		type="radio"  */}
                	{/* 		name="group_type"  */}
                	{/* 		id="group_type-hidden"  */}
                	{/* 		className="bs-styled-radio"  */}
                	{/* 		value="hidden" */}
                	{/* 	/> */}
                	{/* 	<label htmlFor="group_type-hidden">This is a hidden tribe</label> */}
                	{/* </div> */}
                	{/* <ul className="group-option"> */}
	                {/* 	<li>Only people who are invited can join the tribe.</li> */}
	                {/* 	<li>This tribe will not be listed in the tribes directory or search results.</li> */}
	                {/* 	<li>Tribe content and activity will only be visible to members of the tribe.</li> */}
                	{/* </ul> */}
                </div>
            </div>
			<div className="form-group mb-4 edit-profile">
                <label>Profile Image</label>
                <div className="upload_opt">
                	<input onChange={profileAttach} id="image" className="d-none" type="file" name="image" />
                	<label htmlFor="image" className="pl-3">
                		<i className="fa fa-camera"></i> Add Profile Image Here
                		{profileImage && profileImage.name ? (
                        	<>
                            	<br />
                            	<span>{profileImage.name}</span>
                            </>
                        ) : ''}
		        	</label>
		        </div>
		    </div>
			<div className="form-group mb-4 edit-profile">
		        <label>Cover Image</label>
		        <div className="upload_opt">
		        	<input onChange={coverAttach} id="cover_image" className="d-none" type="file" name="cover_image" />
		        	<label htmlFor="cover_image" className="pl-3">
		        		<i className="fa fa-camera"></i> Add Cover Image Here
		        		{coverImage && coverImage.name ? (
                        	<>
                            	<br />
                            	<span>{coverImage.name}</span>
                            </>
                        ) : ''}
		            </label>
				</div>
		    </div>
			<div className="form-group mb-4">
		        <label>Tribe Description</label>
		        {/* <textarea className="form-control" name="description"></textarea> */}
				<EditorConvertToHTML onChange={getNoteTribe} />
		    </div>
			<div className="form-group mb-4 text-center">
				{loading ? (
					<button type="button" className="btn btn-primary" disabled>Loading</button> 
				) : (
					<button type="submit" className="btn btn-primary">Submit</button> 
				)}
		    </div>
		</form>
	)
}

export default CreateGroupForm;