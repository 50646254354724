import React, { useState, useEffect } from "react";
import LoveStreamPost from "../lovestream/Post";
import LoveStreamForm from "../lovestream/Form";
import { getCurrentUser, getQuotationId } from "../../helpers/Utils";
import axios from "axios";
import Loader from "../loader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyFavClasses from "./fav-classes";
import MyClasses from "./my-classes";
import MyLiveClasses from "./my-live-classes";
import CreateClassPage from "./create-class";

function Classes() {
    const [isTeacher, setisTeacher] = useState("");
    const [classesData, setClassesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fevError, setFevError] = useState([]);
    const [favClassesData, setFavClassesData] = useState([]);
    const [myClassPagination, setMyClassPagination] = useState('')
    const [liveClassesData, setLiveClassesData] = useState([]);
    const [liveClassPagination, setLiveClassPagination] = useState('')
    const [liveClassCurrentPage, setLiveClassCurrentPage] = useState(1)
    const [myClassCurrentPage, setMyClassCurrentPage] = useState(1)
    const [loader, setLoader] = useState(<Loader />);
    const [liveLoader, setLiveLoader] = useState(<Loader />);
    const [currentPage, setCurrentPage] = useState(1)
    const [error, setError] = useState('');
    const [liveError, setLiveError] = useState('');
    const [success, setSuccess] = useState([]);
    const [data, setData] = useState([]);


    const createClassField = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/create-class-detail`, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                setData(res.data.data);
            });
    };


    const getClasses = () => {
        setLoader(<Loader />)
        axios
        .get(
            `${process.env.REACT_APP_API_URL}/my-classes`,
            {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {
            if (res.data.success === true) {
                setLoader('')
                setError("");
                setClassesData(res.data.data.data);
                setMyClassCurrentPage(res.data.data.current_page)
                setMyClassPagination(res.data.data.next_page_url)
            } else {
                setError(res.data.message);
            }
        });
    };

    const removeFavouriteClasses = () => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/remove-all-favourite-classes`,
                {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                toast.success(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setFavClassesData([]);
            });
    };

    const getClassesNext = (page = currentPage) => {
        axios
        .get(
            `${process.env.REACT_APP_API_URL}/my-classes?page=${page}`,
            {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {
            if (res.data.success === true) {
                let nextData = res.data.data.data
                let setmultiple = [...classesData, ...nextData]
                setClassesData(setmultiple)
                setMyClassCurrentPage(res.data.data.current_page)
                setMyClassPagination(res.data.data.next_page_url)
            }
        });
    };

    const nextMyclass = () => {
        getClassesNext(myClassCurrentPage + 1)
    }

    const getLiveClasses = () => {
        setLiveLoader(<Loader />)
        axios
        .get(
            `${process.env.REACT_APP_API_URL}/get-my-live-classes`,
            {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {
            setLiveLoader("")
            if (res.data.success === true) {
                setLiveError("");
                setLiveClassesData(res.data.data);
            } else {
                setLiveError(res.data.message);
            }
        });
    };

    const getFavClasses = () => {
        setLoader(<Loader />)
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/favourite-classes`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {

                if (res.data.success === true) {
                    setLoader('')
                    setFevError("");
                    setFavClassesData(res.data.data);
                } else {
                    setFevError(res.data.message);
                }
            });
    };

    //Var for storing url parms
    let teacher = "";

    //Get the role of user
    const getRole = () => {
        let role = [];
        role = getCurrentUser().role;
        if (role !== null) {
            role.map((item) => {
                return (teacher = item.label_teacher)
            });
        }
    };

    useEffect(() => {
        createClassField()
        getFavClasses()
        getClasses()
        getRole()
        setisTeacher(teacher)
    }, [isTeacher]);

    return (
        <>
            {!getCurrentUser().role[0]?.label_teacher ? (
                <>
                    <h3 className="screen-heading profile-group-title mb-0">
                        My Favourite Classes
                        {loading ? (
                            <button
                                className="btn btn-primary btn-hover-dark float-right"
                                disabled
                            >
                                Loading
                            </button>
                        ) : (
                            <button
                                onClick={removeFavouriteClasses}
                                className="btn btn-primary btn-hover-dark float-right"
                            >
                                Clear Favorites
                            </button>
                        )}
                        <div className="clearfix"></div>
                    </h3>
                    {fevError}
                    {/* {loader} */}
                    <MyFavClasses data={favClassesData} />
                </>
            ) : (
                <Tabs>
                    <TabList>
                        <Tab>Upload a Class</Tab>
                        <Tab onClick={getClasses}>My Classes</Tab>
                        <Tab onClick={getLiveClasses}>Live Classes</Tab>
                        <Tab onClick={getFavClasses}>Favorites</Tab>
                    </TabList>
                    <TabPanel>
                        <CreateClassPage data={data} />
                    </TabPanel>
                    <TabPanel>
                        {error}
                        {/* {loader} */}
                        <InfiniteScroll
                            dataLength={classesData && classesData.length}
                            next={myClassPagination ? nextMyclass : ""}
                            hasMore={true}
                            loader={
                                <h4 className="text-center">
                                    {myClassPagination ? <Loader /> : ""}
                                </h4>
                            }
                        >
                            <MyClasses
                                data={classesData}
                                getClasses={getClasses}
                            />
                        </InfiniteScroll>
                    </TabPanel>
                    <TabPanel>
                        {liveError}
                        {liveLoader}
                        <MyLiveClasses
                            data={liveClassesData}
                            getLiveClasses={getLiveClasses}
                        />
                    </TabPanel>
                    <TabPanel>
                        <h3 className="screen-heading profile-group-title mb-0">
                            My Favourite Classes
                            {loading ? (
                                <button
                                    className="btn btn-primary btn-hover-dark float-right"
                                    disabled
                                >
                                    Loading
                                </button>
                            ) : (
                                <button
                                    onClick={removeFavouriteClasses}
                                    className="btn btn-primary btn-hover-dark float-right"
                                >
                                    Clear Favorites
                                </button>
                            )}
                            <div className="clearfix"></div>
                        </h3>
                        {fevError}
                        <MyFavClasses data={favClassesData} />
                    </TabPanel>
                </Tabs>
            )}
        </>
    );
}

export default Classes;
