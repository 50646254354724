import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCurrentUser } from "../../helpers/Utils";
import axios from "axios";
import Loader from "../loader";

function EmailSetting() {
	const [loading, setLoading] = useState("");
	const [loader, setLoader] = useState("");
	const [tribe, setTribe] = useState("");
	const [message, setMessage] = useState("");

	const getMailSetting = () => {
		setLoader(true);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/get-user-mail-notification`,
				{
					headers: {
						"content-type": "application/json",
						Authorization: `Bearer ${getCurrentUser().token}`,
					},
				}
			)
			.then(function (res) {
				setLoader(false);
				setTribe(res.data.data.tribe);
				setMessage(res.data.data.message);
			});
	};

	const updateMailSetting = () => {
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/user-mail-notification`,
				{
					message: message,
					tribe: tribe,
				},
				{
					headers: {
						Authorization: `Bearer ${getCurrentUser().token}`,
					},
				}
			)
			.then(function (res) {
				setLoading(false);
				if (res.data.success === true) {
					toast.success(res.data.message, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				} else {
					toast.error(res.data.message, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
			});
	};

	const tribeSettingChange = (e) => {
		if (e.target.checked) {
			setTribe("1");
		} else {
			setTribe("0");
		}
	};

	const messageSettingChange = (e) => {
		if (e.target.checked) {
			setMessage("1");
		} else {
			setMessage("0");
		}
	};

	useEffect(() => {
		getMailSetting();
	}, []);

	return (
		<div
			className="react-tabs__tab-panel react-tabs__tab-panel--selected"
			role="tabpanel"
			id="react-tabs-17"
			aria-labelledby="react-tabs-16"
		>
			<div className="change_image">
				<div className="bp-widget base">
					<h3 className="screen-heading profile-group-title">
						Email Notification Settings
					</h3>
					{loader ? (
						<Loader />
					) : (
						<>
							<div className="form-check">
								<input
									className="form-check-input"
									type="checkbox"
									id="tribe"
									checked={tribe !== "0" ? true : false}
									onChange={(e) => tribeSettingChange(e)}
								/>
								<label className="form-check-label" htmlFor="tribe">
									Tribe Emails.
								</label>
							</div>
							<div className="form-check">
								<input
									className="form-check-input"
									type="checkbox"
									id="message"
									checked={message !== "0" ? true : false}
									onChange={(e) => messageSettingChange(e)}
								/>
								<label
									className="form-check-label"
									htmlFor="message"
								>
									Message Emails.
								</label>
							</div>
							<div className="form-group mt-4">
								{loading ? (
									<button
										className="btn btn-primary m-2 py-3"
										type="button"
									>
										Loading
									</button>
								) : (
									<button
										className="btn btn-primary m-2 py-3"
										type="submit"
										onClick={updateMailSetting}
									>
										Submit
									</button>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default EmailSetting;
