import React from "react";
import PropTypes from "prop-types";
import MemberGrid from "../../../components/member";
import useSWR from "swr";


const TribeItem = ({ data }) => {

    return (
        <section className="blog-section py-4">
            <div className="row mb-n7">
                <div
                    className="col-lg-4 col-sm-6 mb-7"
                >
                    <MemberGrid
                        classOption="p-0"
                        data={data}
                    />
                </div>
            </div>
        </section>
    );
};

TribeItem.propTypes = {
    data: PropTypes.object,
};

export default TribeItem;
