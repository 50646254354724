import React, {useEffect, useState} from "react";
import Accordion, {
    AccordionItem,
    AccordionTitle,
    AccordionContent,
} from "../../components/accrodion";
import { getCurrentUser } from '../../helpers/Utils';
import axios from "axios";
import Loader from "../../components/loader";

const AccordionContainer = () => {
    const [data, setData] = useState([])
    const [error, setError] = useState('')
    const [loader, setLoader] = useState(<Loader />)

    const getTOM = () => {
        setLoader(<Loader />);
        axios.get(`${process.env.REACT_APP_API_URL}/faqs`).then(function(res){
            setLoader('')
            setData(res.data.data);
            setError(res.data.message);
        })
    }

    useEffect(() => {
        getTOM();
    }, []);

    return (
        <div className="faq-section section-py">
            <div className="container">
                <div className="row">
                    <div className="col">
                        {loader}
                        {error ? <p className="alert alert-danger p-2 text-center">{error}</p> : ''}
                        {data && data.map((item, i) => (
                            <>
                                <h3 key={i}>{item.category}</h3>
                                <Accordion classOption="accordion-style2 no-bg py-5">
                                    {item.faqs.map((faq, i) => (
                                        <AccordionItem id={faq.id} key={i}>
                                            <AccordionTitle id={faq.id}>
                                                <span className="question-tag">Q:</span>
                                                {faq.title}
                                            </AccordionTitle>
                                            <AccordionContent id={faq.id}>
                                                {/* {faq.description} */}
                                                <p dangerouslySetInnerHTML={{ __html: faq.description}} />
                                            </AccordionContent>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccordionContainer;
