import React, { useEffect, useState } from "react";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ClassDetailsContainer from "../containers/class/class-details";
// import BlogData from "../data/blog.json";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import axios from "axios";
import { getCurrentUser } from "../helpers/Utils";
import Loader from "../components/loader";
import { Helmet } from "react-helmet";

const ClassDetailsPage = ({
    match: {
        params: { slug },
    },
}) => {
    const [data, setData] = useState("");
    const [classDescription, setClassDescription] = useState("");

    let fetchClassDetail = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/class/${slug}`, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                localStorage.setItem("classKey", slug);
                setData(res.data.data);
                let nextData = res.data.data.description;
                let formattedData = nextData.replace(/(<([^>]+)>)/gi, "");
                setClassDescription(formattedData.substring(0, 150));
            })
            .catch(function (err) {
                console.log("Catch", err);
            });
    };

    useEffect(() => {
        fetchClassDetail();
    }, []);

    return (
        <React.Fragment>
            <Layout>
                <Helmet>
                    <title>Soultribeonline - {data && data.title}</title>
                    <meta name="description" content={classDescription} />
                    <meta name="image" content={data.image} />
                </Helmet>
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title={data.title && data.title}
                        image={data.image}
                    />
                    {data ? <ClassDetailsContainer data={data} /> : <Loader />}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ClassDetailsPage;
