import React, { useState, useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Loader from "../../../components/loader";
import { getCurrentUser } from "../../../helpers/Utils";
import axios from "axios";
import Select from 'react-select'

let SelectedMembers = []

function SendInvite(props) {
	const [allMember, setAllMember] = useState([])
	const [error, setError] = useState('')
	const [success, setSuccess] = useState('')
	const [loading, setLoading] = useState(false)
	const [pendingInvites, setPendingInvites] = useState([])
	const [loader, setLoader] = useState(<Loader />)

	const sendInvite = (e) => {
		setLoading(true)
		e.preventDefault();

		axios.post(`${process.env.REACT_APP_API_URL}/send-tribe-invite`, {
			member_id: SelectedMembers,
			tribe_id : props.tribeSingleId,
			message: e.target.message.value
		}, {
			headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${getCurrentUser().token}`
            },
		}).then(function (res) {
			setLoading(false)
			if (res.data.success !== true) {
				setError(res.data.message)
				setSuccess('')
			} else {
				SelectedMembers = null
				document.getElementById("sendinviteform").reset();
				setError('')
				setSuccess(res.data.message)
			}
			setTimeout(() => {
				setError('')
				setSuccess('')
			}, 3000);
		})
	}


	const getMember = () => {
        axios.get(
            `${process.env.REACT_APP_API_URL}/show-other-tribe-members?tribe_id=${props.tribeSingleId}`,
            {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${getCurrentUser().token}`
                },
            }
        )
        .then(function (res) {
            setAllMember(res.data.data)
        })
    }

    let members = [];
	if (allMember !== undefined) {
		{
			allMember && allMember.map((member) => {
				members.push({value: member.id, label: member.name });
			});
		}
	}

	const getselectedMember = (e) => {
		SelectedMembers = []
		e.map((item) => (
			SelectedMembers.push({id: item.value} )
		))
	}

	const getPendingInvites = () => {
		setPendingInvites([])
		setLoader(<Loader />)
        axios.get(
            `${process.env.REACT_APP_API_URL}/pending-tribe-invites/${props.tribeSingleId}`,
            {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${getCurrentUser().token}`
                },
            }
        )
        .then(function (res) {
        	setLoader('')
            setPendingInvites(res.data.data)
        })
    }

    useEffect(() => {
        getMember()
    }, []);
	return (
		<div className="tribe_tabs">
			<Tabs className="vertical_tabs">
	            <TabList>
	              <Tab>Send Invites</Tab>
	              <Tab onClick={getPendingInvites}>Pending Invites</Tab>
	            </TabList>
	            <TabPanel>
	                <form onSubmit={(e) => sendInvite(e)} id="sendinviteform">
	                    <div className="form-group">
	                    	<label>Select members to invite</label>
	                    	<Select
	                    		onChange={(e) => getselectedMember(e)}
	                    		options={members}
	                    		isMulti={true}
	                    	/>
	                    </div>
	                    <div className="form-group mt-4">
	                    	<label>Customize the message of your invite</label>
	                    	<textarea className="form-control" name="message"></textarea>
	                    </div>
	                    <div className="form-group mt-4">
	                    {loading ? (
	                    	<button type="button" className="btn btn-primary">Loading</button>
	                    ) : (
	                    	<button type="submit" className="btn btn-primary">Send</button>
	                    )}
	                    </div>
	                    <div className="form-group mt-4">
	                    	{error ? (
	                    		<p className="alert alert-danger py-1">{error}</p>
	                    	) : ''}
	                    	{success ? (
	                    		<p className="alert alert-success py-1">{success}</p>
	                    	) : ''}
	                    </div>
					</form>
	            </TabPanel>
	            <TabPanel>
	            	<h3 className="screen-heading profile-group-title">Pending Invites</h3>
	            	<div className="table-responsive tribe_invites">
	            		{loader}
	            		<table className="table">
	            			{pendingInvites.map((item, i) => (
								<tbody>
								<tr key={i}>
									<td>
										<div className="d-flex align-items-center">
											<img width="50" height="50" style={{objectFit: 'cover'}} className="border" src={item.image} alt="img" />
											<h3 className="text-capitalize px-3">{item.name}</h3>
										</div>
									</td>
								</tr>
								</tbody>
	            			))}
						</table>
	            	</div>
	            </TabPanel>
	        </Tabs>
        </div>
	)
}
export default SendInvite;