import React, { useState, useEffect } from "react";
import LoveStreamPost from "../lovestream/Post";
import LoveStreamForm from "../lovestream/Form";
import { getCurrentUser, getQuotationId } from "../../helpers/Utils";
import axios from "axios";
import Loader from "../loader";
import InfiniteScroll from "react-infinite-scroll-component";

function Timeline() {
  const [error, setError] = useState("");
  const [feed, setFeed] = useState([]);
  const [loader, setLoader] = useState(<Loader />);
  const [allMember, setAllMember] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [pagination, setPagination] = useState("");

  const callApi = () => {
    setLoader(<Loader />);
    setFeed([]);
    axios
      .get(`${process.env.REACT_APP_API_URL}/member/timeline`, {
        headers: {
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        setLoader("");
        if (res.data.success === true) {
          setFeed(res.data.data.data);
          setCurrentPage(res.data.data.current_page);
          setPagination(res.data.data.next_page_url);
        } else {
          setError(res.data.message);
        }
      });
  };

  const getAllFeedNext = (page = currentPage) => {
    setPagination("");
    axios
      .get(`${process.env.REACT_APP_API_URL}/member/timeline?page=${page}`, {
        headers: {
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        if (res.data.success === true) {
          setCurrentPage(res.data.data.current_page);
          setPagination(res.data.data.next_page_url);
          let nextData = res.data.data.data;
          let setmultiple = [...feed, ...nextData];
          setFeed(setmultiple);
        }
      });
  };

  const nextLink = () => {
    setPagination("");
    getAllFeedNext(currentPage + 1);
  };

  const getMember = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/show-members-limited-data`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token}`,
        },
      })
      .then(function (res) {
        setAllMember(res.data.data);
      });
  };

  useEffect(() => {
    if (getCurrentUser()) {
      callApi();
      getMember();
    }
  }, []);
  return (
    <div className="post">
      {getCurrentUser() ? (
        <div className="post_header mb-4">
          <LoveStreamForm callApi={callApi} mentionAllMember={allMember} />
        </div>
      ) : (
        ""
      )}
      {error}
      <InfiniteScroll
        dataLength={feed.length}
        next={pagination !== null ? nextLink : ""}
        hasMore={pagination !== null ? true : false}
      >
        {feed &&
          feed.map((item, i) => (
            <LoveStreamPost
              post={item}
              pageindex={currentPage}
              mentionAllMember={allMember}
              i={i}
            />
          ))}
      </InfiniteScroll>
      {loader}
    </div>
  );
}

export default Timeline;
