import React from "react";
import { useEffect, useRef } from "react";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Parallax from "parallax-js";
import { getCurrentUser } from '../../../helpers/Utils'

const HomeAboutContainer = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="about-us position-relative section-pb">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-6 col-lg-6 mb-7">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/about/1.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 mb-7 mt-10 offset-xl-1">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="ABOUT US"
                                title="Connect From  
                                <span class='text-primary'>
                                    Anywhere
                                </span>"
                            />
                            {/* <span className="date-meta"> */}
                            {/*     Scince 2001 <span className="hr"></span> */}
                            {/* </span> */}
                            <p className="mb-5">
                                Soul Tribe gives you access to explore the social network features, practice with experienced teachers, and participate in live community classes from any device.
                            </p>
                            {getCurrentUser() ? '' : (
                                <Button
                                    classOption="btn btn-lg btn-dark btn-hover-dark"
                                    text="GET STARTED"
                                    path="/registration"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <img
                className="about-shape"
                src={`${process.env.PUBLIC_URL}/images/about/shape.png`}
                alt="bg-shape"
            />
        </div>
    );
};

export default HomeAboutContainer;
