import React, { useState } from "react";

function InviteTable(props) {

  const items = {
    name: "",
    email: ""
  };
  const [data, setData] = useState([items]);
  const [serial, setSerial] = useState(0);

  const appendChild = () => {
    setData([...data, items]);
    setSerial(serial + 1);
  };

  const removeROW = (index) => {
    if (index > 0) {
      data.splice(index, 1);
    }
    setData(data);
    setSerial(serial + 1);
  };

  const collectFieldValues = (e, id) => {
    const { name, value } = e.target;
    const list = [...data];
    list[id][name] = value;
    setData(list);
    props.onChange(data);
  };

  return (
    <table className="table m-0">
      <tbody>
        <tr>
            <th>Recipient Name</th>
            <th>Recipient Email</th>
        </tr>
        {data.map((row, id) => (
            <tr key={id}>
                <td>
                    <input type="text" name="name" className="form-control"
                        id={`select-position-${id}`}
                        onChange={(e) => collectFieldValues(e, id)}
                    />
                </td>
                <td>
                    <input type="email" name="email" className="form-control" id={`select-position-${id}`} onChange={(e) => collectFieldValues(e, id)} />
                </td>
                <td className="text-center">
                    <button type="button" className="btn btn-cross" id={`select-position-${id}`} onClick={() => removeROW(id)}>✖</button>
                </td>
            </tr>
        ))}
        <tr>
            <td colSpan="2"></td>
            <td className="text-center">
                <button type="button" className="btn btn-cross" onClick={() => appendChild()}>✚</button>
            </td>
        </tr>
        </tbody>
    </table>
  );
}

export default InviteTable;