import React, { useEffect, useState } from "react";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import CourseDetailsContainer from "../containers/course/course-detail";
// import BlogData from "../data/blog.json";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import axios from "axios";
import { getCurrentUser } from "../helpers/Utils";
import Loader from "../components/loader";

const CourseDetailsPage = ({
    match: {
        params: { slug },
    },
}) => {
    const [data, setData] = useState("");

    let fetchCourseDetail = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/single-course/${slug}`, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            })
            .then(function (res) {
                localStorage.setItem("courseKey", slug);
                setData(res.data.data);
            });
    };
    useEffect(() => {
        fetchCourseDetail();
    }, []);

    return (
        <React.Fragment>
            <Layout>
                <SEO title="Soul Tribe Online Community – Blog Details" />
                <div className="wrapper">
                    <Header />
                    <PageBanner title={data.title && data.title} />
                    {data ? <CourseDetailsContainer data={data} /> : <Loader />}
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default CourseDetailsPage;
