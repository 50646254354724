import React, { useState, useEffect } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import EditClassForm from "../components/edit-class-form";
import PropTypes from "prop-types";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import axios from "axios";
import { getCurrentUser } from "../helpers/Utils";
import PageLoader from "../components/page-loader";
import { useLocation } from "react-router-dom";
import Loader from "../components/loader";

//Funtion for getting the router params
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const EditClassPage = ({
  match: {
    params: { slug },
  },
}) => {

  let query = useQuery();
  let query_url = query.get("ref");
  const [data, setData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [loader, setLoader] = useState(<Loader />);

  let fetchClassDetail = () => {
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/class/${query_url}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token}`,
          },
        }
      )
      .then(function (res) {
        setClassData(res.data.data);
        axios
        .get(
          `${process.env.REACT_APP_API_URL}/create-class-detail`,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${getCurrentUser().token}`,
            },
          }
        )
        .then(function (res) {
          setLoader('')
          setData(res.data.data);
        });
      });
  };
  
  useEffect(() => {
    fetchClassDetail();
  }, []);
  
  return (
    <React.Fragment>
      <PageLoader />
      <Layout>
        <SEO title="Soul Tribe Online Community – Edit Class" />
        <div className="wrapper">
          <Header />
          <PageBanner title="Edit Class" image="./images/contact/1.png" />
          {loader ? loader :
           <EditClassForm data={classData} utilities={data} />
          }
         
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};
EditClassPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default EditClassPage;
