import React, { useEffect, useState, useRef } from "react";
import TribeGrid from "../../../components/tribe";
import Loader from "../../../components/loader";
import CreateGroupForm from "../../../components/create-group/Form";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import { getCurrentUser } from "../../../helpers/Utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { NavLink } from "react-router-dom";

function TribeItemContainer(props) {
    const listInnerRef = useRef();
    const [data, setdata] = useState([]);
    const [myData, setMyData] = useState([]);
    const [loader, setLoader] = useState(<Loader />);
    const [allGroups, setAllGroups] = useState("");
    const [myGroupsCount, setMyGroupsCount] = useState("");
    const [allError, setAllError] = useState("");
    const [myError, setMyError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState("");
    const [myPagination, setMyPagination] = useState("");
    const [loading, setLoading] = useState(false);
    const [lastIndex, setIndex] = useState("");

    //Scroll to bottom function

    const allTribes = (e) => {
        setPagination("");
        setAllError("");
        setLoader(<Loader />);
        setdata([]);
        var url = `${process.env.REACT_APP_API_URL}/tribes`;
        if (e !== undefined) {
            url = `${process.env.REACT_APP_API_URL}/tribes?search=${e}`;
        }
        axios.get(url)
        .then(function (res) {
            setLoader("");
            setAllError(res.data.message);
            setAllGroups(res.data.data.total_tribes_count);
            if (res.data.success !== false) {
                setCurrentPage(res.data.data.tribes.current_page);
                setPagination(res.data.data.tribes.next_page_url);
                setdata(res.data.data.tribes.data);
            } else {
                setdata([]);
            }
        }).catch(function (err) {
            console.log(err)
        });
    };


    //Load more when data is at last index
    const getAllTribeNext = (page = currentPage) => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/tribes?page=${page}`,
                {
                    headers: {
                        Authorization: `Bearer ${
                            getCurrentUser() && getCurrentUser().token
                        }`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.success !== false) {
                    setCurrentPage(res.data.data.tribes.current_page);
                    let nextData = res.data.data.tribes.data;
                    let setmultiple = [...data, ...nextData];
                    setdata(setmultiple);
                    setPagination(res.data.data.tribes.next_page_url);
                    setAllError("");
                } else {
                    setAllError(res.data.message);
                }
            }).catch(function (err) {
                console.log(err)
            });
    };
    const nextLink = () => {
        getAllTribeNext(currentPage + 1);
    };

    const myTribes = (e) => {
        setMyPagination("");
        setMyError("");
        setLoader(<Loader />);

        var url = `${process.env.REACT_APP_API_URL}/member/groups`;
        if (e !== undefined) {
            url = `${process.env.REACT_APP_API_URL}/member/groups?search=${e}`;
        }
        setMyData([]);
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${
                        getCurrentUser() && getCurrentUser().token
                    }`,
                },
            })
            .then(function (res) {
                setLoader("");
                setMyGroupsCount(res.data.data.total_tribes_count);
                if (res.data.success !== false) {
                    setMyError("");
                    setMyPagination(res.data.data.tribes.next_page_url);
                    setMyData(res.data.data.tribes.data);
                    let indexOf = myData.length - 1;
                    setIndex(indexOf);
                } else {
                    setMyError(res.data.message);
                    setMyData([]);
                }
            }).catch(function (err) {
                console.log(err)
            });
    };

    const getMyTribeNext = (page = currentPage) => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/member/groups?page=${page}&search=${
                    localStorage.getItem("searchTribeData")
                        ? localStorage.getItem("searchTribeData")
                        : ""
                }`,
                {
                    headers: {
                        Authorization: `Bearer ${
                            getCurrentUser() && getCurrentUser().token
                        }`,
                    },
                }
            )
            .then(function (res) {
                setLoading(false);
                if (res.data.success === true) {
                    setCurrentPage(res.data.data.current_page);
                    let nextData = res.data.data.tribes.data;
                    let setmultiple = [...myData, ...nextData];
                    setMyData(setmultiple);
                    setMyPagination(res.data.data.next_page_url);
                }
            }).catch(function (err) {
            console.log(err)
        });
    };

    const nextMyLink = () => {
        getMyTribeNext(currentPage + 1);
    };

    const filter = (e) => {
        allTribes(e.target.value);
        if (getCurrentUser()) {
            myTribes(e.target.value);
        }
    };

    useEffect(() => {
        allTribes();
        if (getCurrentUser()) {
            myTribes();
        }
    }, []);

    let tribes = "";

    if (data !== undefined) {
        tribes = (
            <>
                {data &&
                    data.map((tribe, key) => {
                        return (
                            <div key={key} className="col-lg-4 col-sm-6 mb-7">
                                <TribeGrid
                                    classOption="p-0"
                                    key={key}
                                    data={tribe}
                                    myTribes={myTribes}
                                />
                            </div>
                        );
                    })}
            </>
        );
    }
    let myGroups = "";
    if (myData !== undefined) {
        myGroups = (
            <>
                {myData &&
                    myData.map((tribe, key) => {
                        return (
                            <div key={key} className="col-lg-4 col-sm-6 mb-7">
                                <TribeGrid
                                    classOption="p-0"
                                    key={key}
                                    data={tribe}
                                />
                            </div>
                        );
                    })}
            </>
        );
    }

    return (
        <section className="blog-section section-py">
            <div className="container">
                {getCurrentUser() ? (
                    <p>
                        Tribes are groups where you can find community support,
                        inspiration, and content on subjects that interest you.
                        Our tribes are led by our teachers who offer their
                        expertise on various topics and hold space for sharing
                        and deeper conversation within these groups. Public
                        tribes are open to everyone and a private tribe requires
                        you to request access to join. Feel into which tribes
                        resonate with you and join us today!{" "}
                    </p>
                ) : (
                    <>
                        <p>
                            We’re sorry! This content is for members only.
                            Please Sign In, or Join Now if you do not have an
                            account with us.
                        </p>
                        <NavLink to={process.env.PUBLIC_URL + "/registration"}>
                            <button className="btn btn-dark btn-hover-dark">
                                Join Now
                            </button>
                        </NavLink>
                    </>
                )}
                <div className="searchandfilter text-right mb-md-0">
                    <ul className="m-0">
                        <li className="w-auto">
                            <input
                                type="text"
                                name="ofsearch"
                                defaultValue={localStorage.getItem(
                                    "searchTribeData"
                                )}
                                onChange={filter}
                                placeholder="Search …"
                            />
                        </li>
                    </ul>
                </div>
                <Tabs className="vertical_tabs">
                    <TabList>
                        <Tab onClick={() => allTribes()}>All Tribes ({allGroups})</Tab>
                        {getCurrentUser() ? (
                            <>
                                <Tab onClick={() => myTribes()}>
                                    My Tribes ({myGroupsCount})
                                </Tab>
                                <Tab>Create Tribe</Tab>
                            </>
                        ) : (
                            ""
                        )}
                    </TabList>
                    <TabPanel>
                        {loader}
                        {allError}
                        <InfiniteScroll
                            dataLength={data.length}
                            next={pagination ? nextLink : ""}
                            hasMore={true}
                            loader={
                                <h4 className="text-center">
                                    {pagination ? <Loader /> : ""}
                                </h4>
                            }
                        >
                            <div className="row mb-n7 mt-8">{tribes}</div>
                        </InfiniteScroll>
                    </TabPanel>

                    <TabPanel>
                        {loader}
                        {myError}
                        <InfiniteScroll
                            dataLength={data.length}
                            next={myPagination ? nextMyLink : ""}
                            hasMore={true}
                            loader={
                                <h4 className="text-center">
                                    {myPagination ? <Loader /> : ""}
                                </h4>
                            }
                        >
                            <div className="row mb-n7 mt-8">{myGroups}</div>
                        </InfiniteScroll>
                    </TabPanel>
                    <TabPanel>
                        <div className="create_group w-75 m-auto">
                            <h3 className="py-4 text-center">
                                Create New Tribe
                            </h3>
                            <CreateGroupForm />
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </section>
    );
}

export default TribeItemContainer;
