import { useState, useEffect } from "react";
import axios from "axios";
import { getCurrentUser } from "../../helpers/Utils";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from "react-select";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone-uploader";
import "react-toastify/dist/ReactToastify.css";
import GetLinkPreview from "../custom/GetLinkPreview";

function LovesteamForm(props) {
  const [tribeId] = useState(props.data && props.data.id);
  const [error, setError] = useState("");
  const [privacy, setPrivacy] = useState("public");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [postInGroup, setPostInGroup] = useState(false);
  const [allTribesNames, setAllTribesNames] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [selectedTribe, setSelectedTribe] = useState("");
  const [previewLink, setPreviewLink] = useState("");
  const [previewLinkThumbnail, setPreviewLinkThumbnail] = useState();

  const [ifImage, setIfImage] = useState(false);
  const [ifVideo, setIfVideo] = useState(false);
  const [ifDocument, setIfDocument] = useState(false);

  const [attachedImage, setAttachedImage] = useState([]);
  const [attachedVideo, setAttachedVideo] = useState([]);
  const [attachedDocument, setAttachedDocument] = useState([]);

  const processLink = (contentForPreview) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    contentForPreview.replace(urlRegex, function (url) {
      if (previewLink !== url) {
        setPreviewLink(url);
        setPreviewLinkThumbnail(<GetLinkPreview text={url} />);
      }
    });
  };

  const testAttachment = () => {};

  const attachImage = () => {
    setIfVideo(false);
    setIfDocument(false);
    setIfImage(true);
    setAttachedVideo([]);
    setAttachedDocument([]);
  };
  const attachVideo = () => {
    setIfImage(false);
    setIfVideo(true);
    setIfDocument(false);
    setAttachedImage([]);
    setAttachedDocument([]);
  };
  const attachDocument = () => {
    setIfImage(false);
    setIfVideo(false);
    setIfDocument(true);
    setAttachedImage([]);
    setAttachedVideo([]);
  };

  const postAttachedImage = (fileWithMeta, status, fileArray) => {
    setAttachedImage(fileArray);
  };
  const postAttachedVideo = (fileWithMeta, status, fileArray) => {
    setAttachedVideo(fileArray);
  };
  const postattAchDocedument = (fileWithMeta, status, fileArray) => {
    setAttachedDocument(fileArray);
  };

  const feedPost = (e) => {
    e.preventDefault();
    setEditorState("");
    setLoading(true);
    var formData = new FormData();
    if (props.memberId !== undefined) {
      formData.append("member_id", props.memberId);
    }
    if (tribeId !== undefined) {
      formData.append("tribe_id", props.data.id);
    } else formData.append("tribe_id", selectedTribe);
    formData.append(
      "message",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    let selectedImages = [];
    attachedImage.map((item) => selectedImages.push(item.file));
    for (const key of Object.keys(selectedImages)) {
      formData.append("image[]", selectedImages[key]);
    }
    let selectedVideo = [];
    attachedVideo.map((item) => selectedVideo.push(item.file));
    for (const key of Object.keys(selectedVideo)) {
      formData.append("video", selectedVideo[key]);
    }
    let selectedDocument = [];
    attachedDocument.map((item) => selectedDocument.push(item.file));
    for (const key of Object.keys(selectedDocument)) {
      formData.append("document[]", selectedDocument[key]);
    }

    formData.append("privacy", privacy);
    axios
      .post(`${process.env.REACT_APP_API_URL}/add-post`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
      })
      .then(function (res) {
        setLoading(false);
        setPreviewLinkThumbnail("");
        if (res.data.success === true) {
          setIfImage(false);
          setIfVideo(false);
          setIfDocument(false);
          props.callApi();
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setTimeout(
          function () {
            setError("");
            setSuccess("");
          }.bind(),
          2000
        );
      });
  };

  const getAllTribes = (e) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/without-paginate-tribes`, {
        headers: {
          Authorization: `Bearer ${getCurrentUser()?.token}`,
        },
      })
      .then(function (res) {
        setAllTribesNames(res.data.data.tribes);
      });
  };

  const postInfo = (e) => {
    if (e.target.value === "2") {
      setPostInGroup(true);
    } else setPostInGroup(false);
  };

  let tribeData = [];
  if (allTribesNames !== undefined) {
    allTribesNames.map((item) =>
      tribeData.push({ value: item.id, label: item.title })
    );
  }

  const getSelectedTribe = (e) => {
    setSelectedTribe(e.value);
  };
  const getPrivacy = (e) => {
    setPrivacy(e.target.value);
  };

  const list = [];

  props.mentionAllMember &&
    props.mentionAllMember.map((item) => {
      list.push({
        text: item.name,
        value: item.name,
        url: "/member/" + item.id + "",
      });
    });

  useEffect(() => {
    if (getCurrentUser()) {
      getAllTribes();
    }
  }, []);

  const getContentChange = (e) => {
    setEditorState(e);
    processLink(e.getCurrentContent().getPlainText("\u0001"));
  };

  return (
    <form onSubmit={feedPost}>
      <div className="post_upload">
        <div className="row">
          <div className="col-12">
            <div
              style={{
                borderBottom: "1px solid #ddd",
                minHeight: "6em",
                cursor: "text",
              }}
            >
              <Editor
                editorState={editorState}
                onEditorStateChange={(text) => getContentChange(text)}
                placeholder="Write here or use @ to mention someone."
                toolbar={{
                  options: ["inline", "list"],
                  inline: {
                    options: ["bold", "italic", "underline", "strikethrough"],
                  },
                  list: {
                    options: ["unordered", "ordered"],
                  },
                }}
                mention={{
                  separator: " ",
                  trigger: "@",
                  suggestions: list,
                }}
                hashtag={{
                  separator: " ",
                  trigger: "#",
                }}
              />
              {previewLinkThumbnail}
            </div>
          </div>
          <div className="col-12">
            {ifImage && (
              <div className="attached_files-names w-100 p-3 pb-0 uploadphoto">
                <Dropzone
                  onSubmit={testAttachment}
                  inputContent="Drop images here to upload"
                  onChangeStatus={postAttachedImage}
                  accept="image/*"
                />
              </div>
            )}
            {ifVideo && (
              <div className="attached_files-names w-100 p-3 pb-0 uploadphoto">
                <Dropzone
                  onSubmit={testAttachment}
                  inputContent="Drop video here to upload"
                  maxFiles="1"
                  onChangeStatus={postAttachedVideo}
                  accept="video/*"
                />
              </div>
            )}
            {ifDocument && (
              <div className="attached_files-names w-100 p-3 pb-0 uploadphoto">
                <Dropzone
                  onSubmit={testAttachment}
                  inputContent="Drop files here to upload"
                  onChangeStatus={postattAchDocedument}
                  accept=".pdf, .docx, .doc"
                />
              </div>
            )}
            <div className="post_upload-attach">
              <div className="post_other_info d-flex">
                <div className="upload_opt upload_opt_resp d-flex align-items-center">
                  <label onClick={() => attachImage()} className="pl-3">
                    <i className="fa fa-picture-o"></i>
                  </label>
                  <label onClick={() => attachVideo()} className="pl-5">
                    <i className="fa fa-video-camera"></i>
                  </label>
                  <label
                    style={{
                      marginLeft: "10px",
                    }}
                    onClick={() => attachDocument()}
                    className="pl-5"
                  >
                    <i className="fa fa-file"></i>
                  </label>
                </div>
                {props.memberId !== undefined ? (
                  ""
                ) : (
                  <>
                    &nbsp; &nbsp;
                    {tribeId === undefined ? (
                      <>
                        <div className="p-1 w-100 searchandfilter m-0">
                          <select
                            className="form-control p-1 postin"
                            onChange={(e) => postInfo(e)}
                          >
                            <option value="1">Post in: Profile</option>
                            <option value="2">Post in: Tribe</option>
                          </select>
                        </div>
                        <div
                          className="p-1 w-100"
                          style={{
                            minWidth: "170px",
                          }}
                        >
                          {postInGroup ? (
                            <Select
                              placeholder="Select Tribe"
                              options={tribeData}
                              onChange={(e) => getSelectedTribe(e)}
                            />
                          ) : (
                            <select
                              onChange={(e) => getPrivacy(e)}
                              className="form-control p-1"
                            >
                              <option value="public">Public</option>
                              <option value="loggedin">All Members</option>
                              <option value="friends">My Connections</option>
                              <option value="onlyme">Only Me</option>
                            </select>
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
              <button type="submit" className="btn btn-primary m-2 py-3">
                {loading ? "Posting" : "Post"}
              </button>
            </div>
          </div>
          <div className="col-12 text-center">
            {error ? (
              <span className="alert alert-danger d-table m-auto mb-1 p-1 px-5  ">
                {error}
              </span>
            ) : (
              ""
            )}
            {success ? (
              <span className="alert alert-success d-table m-auto mb-1 p-1 px-5">
                {success}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default LovesteamForm;
