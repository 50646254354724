import React from "react";
import { NavLink } from "react-router-dom";

const MainMenu = () => {
  const local = localStorage.getItem('localKey')
  const classlocal = localStorage.getItem('classKey')
  const tribelocal = localStorage.getItem('tribeKey')
  


  return (
    <nav className="main-menu navmenu d-none d-xl-block">
        <ul className="d-flex justify-content-end">
          <li>
              <NavLink
                exact
                isactiveactiveclassname="active"
                to={process.env.PUBLIC_URL + "/live-classes"}
              >
                  Live
              </NavLink>
          </li>
          <li>
              <NavLink
              isactiveactiveclassname="active"
              to={process.env.PUBLIC_URL + '/classes'}
              isActive={(match,location)=> {
                if(
                  location.pathname === `/class/${classlocal}` || location.pathname.startsWith("/classes")
                )
                {
                  return true;
                }
              }}
              >
                  Classes
              </NavLink>
          </li>
          <li>
            <NavLink
              isactiveactiveclassname="active"
              to={process.env.PUBLIC_URL + "/tribes"}
              isActive={(match,location)=> {
                if(
                  location.pathname === `/tribe/${tribelocal}` || location.pathname.startsWith("/tribes")
                )
                {
                  return true;
                }
              }}
            >
              Tribes
            </NavLink>
          </li>
          <li>
            <NavLink
              isactiveactiveclassname="active"
              to={process.env.PUBLIC_URL + "/teacher"}
            >
              Teachers
            </NavLink>
          </li>
            <li>
              <NavLink
                isactiveactiveclassname="active"
                to={process.env.PUBLIC_URL + "/lovestream"}
              >
                Love Stream
              </NavLink>
            </li>
            <li>
              <NavLink
                to={process.env.PUBLIC_URL + "/members"}
                activeClassName="active"
                isActive={(match,location)=> {
                  if(
                    location.pathname === `/member/${local}` || location.pathname.startsWith("/members")
                  )
                  {
                    return true;
                  }
                }}
              >
                Members
              </NavLink>
            </li>
      </ul>
    </nav>
  );
};

export default MainMenu;
