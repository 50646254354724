import React, {useEffect , useState} from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../components/logo";
import SocialIcon from "../../components/social-icon";
import axios from "axios";
import { getCurrentUser } from '../../helpers/Utils';

function Footer() {
    const [data, setdata] = useState([]);

    const callApi = () => {
      axios.get(
        `${process.env.REACT_APP_API_URL}/tribes`
      )
      .then(function (res) {
        if (res.data.success === false) {
        } else {
            setdata(res.data.data.tribes.data);
        }
      })
    }

    useEffect(() => {
        callApi();
    }, []);
    
    let tribes = '';
    if (data !== undefined) {
        tribes =
        <>
            {data && data.map((tribe, key) => (
                <li
                    key={key}
                    className="footer-link" 
                    // to={process.env.PUBLIC_URL + "/about"}
                >
                    {key < 4 ? (
                        <>
                            {getCurrentUser() ? (
                                <NavLink
                                    className="footer-link"
                                    to={process.env.PUBLIC_URL + `/tribe/${tribe.slug}`}
                                >
                                    {tribe.title}
                                    {tribe.group_members_count ? (
                                        <span>{tribe.group_members_count} Members</span>
                                    ) : ''}
                                </NavLink>
                            ) : (
                                <NavLink
                                    className="footer-link"
                                    to={process.env.PUBLIC_URL + `/login`}
                                >
                                    {tribe.title}
                                    {tribe.group_members_count ? (
                                        <span>{tribe.group_members_count} Members</span>
                                    ) : ''}
                                    <span></span>
                                </NavLink>
                            )}
                        </>
                    ) : "" }
                </li>
                ))
            }
        </>
    }

    return (
        <footer className="footer-section">
            <div className="footer-top position-relative">
                <div className="container">
                    <div className="row mb-n7">
                        <div className="col-lg-4 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <Logo
                                    classOption="footer-logo mb-9"
                                    image={`${process.env.PUBLIC_URL}/images/logo/soultribe.png`}
                                />
                                <p>Soul Tribe is a global community for members to expand their consciousness together. Get instant access to yoga classes, meditations and lectures from certified teachers in a social networking interface.</p>
                                <ul className="footer-social-links">
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.facebook.com/soultribeonline"
                                            icon="icofont-facebook"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.instagram.com/soultribeonline"
                                            icon="icofont-instagram"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mb-7 offset-lg-1">
                            <div className="footer-widget">
                                <h4 className="title">Helpful Links</h4>
                                <ul className="footer-menu">
                                    <li>
                                        <NavLink className="footer-link" to={process.env.PUBLIC_URL + "/about"}>
                                            About
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="footer-link"
                                            to={process.env.PUBLIC_URL + "/classes"}
                                        >
                                            Classes
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink
                                            className="footer-link"
                                            to={process.env.PUBLIC_URL + "/blog"}
                                        >
                                            Blogs
                                        </NavLink>
                                    </li> */}
                                    <li>
                                        <NavLink
                                            className="footer-link"
                                            to={process.env.PUBLIC_URL + "/teacher"}
                                        >
                                            Teachers
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="footer-link"
                                            to={process.env.PUBLIC_URL + "/tribes"}
                                        >
                                            Tribes
                                        </NavLink>
                                    </li>
                                    {getCurrentUser() ? (
                                        <li>
                                            <NavLink
                                                className="footer-link"
                                                to={process.env.PUBLIC_URL + "/members"}
                                            >
                                                Members
                                            </NavLink>
                                        </li>
                                    ) : ''}
                                    <li>
                                        <NavLink
                                            className="footer-link"
                                            to={process.env.PUBLIC_URL + "/faqs"}
                                        >
                                            FAQ
                                        </NavLink>
                                    </li>
                                     {getCurrentUser() && getCurrentUser().role === "teacher" ? (
                                        <>
                                            <li>
                                                <NavLink
                                                    className="footer-link"
                                                    to={process.env.PUBLIC_URL + "/internal-calendar"}
                                                >
                                                    Internal Calendar
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    className="footer-link"
                                                    to={process.env.PUBLIC_URL + "/teacher-orientation-materials"}
                                                >
                                                    Teacher Orientation Materials
                                                </NavLink>
                                            </li>
                                        </>
                                    ) : ''}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mb-7 offset-lg-1">
                            <div className="footer-widget">
                                <h4 className="title">Tribes</h4>
                                <ul className="footer-menu">
                                    {tribes}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-info text-center">
                                <span>
                                    © {(new Date().getFullYear())} <Link to="https://soultribeonline.com/" target="_blank">Soul Tribe Online</Link>. All rights reserved. &nbsp;
                                    <Link
                                        to={process.env.PUBLIC_URL + "/privacy"}
                                    >
                                        Privacy Policy
                                    </Link> &nbsp;| &nbsp;
                                    <Link
                                        to={process.env.PUBLIC_URL + "/terms-conditions"}
                                    >
                                        Terms & Conditions
                                    </Link> 
                                </span>
                                <p className="m-0">Questions? Send us an email at <Link to="#" onClick={(e) => {
                                    window.location.href = 'mailto:info@soultribeonline.com';
                                    e.preventDefault();
                                }}>info@soultribeonline.com</Link></p>
                                <p>For support email at <Link to="#" onClick={(e) => {
                                    window.location.href = 'mailto:support@soultribeonline.com';
                                    e.preventDefault();
                                }}>support@soultribeonline.com</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
