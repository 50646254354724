import React, { useEffect, useState } from "react";
import axios from "axios";
import { getCurrentUser } from "../../helpers/Utils";
import Loader from "../loader";
import SweetAlert from 'react-bootstrap-sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Document = ( props ) => {
	const [item, setItem] = useState(props.item)
	const [loading, setLoading] = useState(false)
	const [sure, setSure] = useState(false);

	const deleteFile = (documentId) => {
		// setLoading(true)
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/member/documents/delete?id=${documentId}`,
                {},
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
            	setSure(false);
            	if (res.data.success === true) {
            		setItem('')
            		toast.success(res.data.message, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
            	}
            });
    };

    const downloadfile = (fileDownload) => {
        window.open(fileDownload);
    };

    let item_privacy = '';
    if (item.type === 'only_me') {
    	item_privacy = 'Only me'
    } else if (item.type === 'my_connections') {
    	item_privacy = 'My Connections'
    } else if (item.type === 'public') {
    	item_privacy = 'Public'
    } else if (item.type === 'all_members') {
    	item_privacy = 'All Members'
    }

    if (item != '') {
		return (
			<>
				<tr key={props.i}>
		            <td>
		                <span>
		                    <i className="fa fa-file"></i>
		                    <div className="document-name">
		                    {item.title}
		                    </div>
		                </span>
		            </td>
		            <td>{item.created_at}</td>
		            <td className="text-center text-capitalize">
		            	{item_privacy}
		            </td>
		            <td className="text-center">
		                <i
		                    onClick={(e) =>
		                        downloadfile(item.document)
		                    }
		                    className="fa fa-eye"
		                ></i>
		            </td>
		            <td className="text-center">
		            	{loading ? (
		            		<i className="fa fa-spinner"></i>
		            	) : (
		            		<i
		            			onClick={() => setSure(true)}
			                    className="fa fa-trash"
			                ></i>
		            	)}
		            </td>
		        </tr>
		        {sure && <SweetAlert
	                showCancel
	                confirmBtnText="Yes, delete it!"
	                confirmBtnBsStyle="btn btn-primary"
	                title="Are you sure?"
	                btnSize="sm"
	                onConfirm={() => deleteFile(item.id)}
	                onCancel={() => setSure(false)}
	            >
	            </SweetAlert>}
        	</>
		)
	} else {
        return <></>;
    }
}

export default Document;