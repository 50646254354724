import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";

function PostLightBox(props) {

    const closeLightbox = () => {
        props.setCurrentImage(0);
        props.setViewerIsOpen(false);
    };

	return (
		<ModalGateway>
            {props.viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                    <Carousel
                        currentIndex={props.currentImage}
                        views={props.postImages.map(x => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title
                        }))}
                    />
                </Modal>
            ) : null}
        </ModalGateway>
	)
}
export default PostLightBox;