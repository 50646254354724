import React, { useEffect, useState } from 'react'
import ReadNotification from '../../components/notification/read'
import UnreadNotification from '../../components/notification/unread'
import { getCurrentUser } from '../../helpers/Utils';
import axios from "axios";
import Loader from "../../components/loader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import InfiniteScroll from "react-infinite-scroll-component";

let readCounter = 0
let unReadCounter = 0

function NotificationsContainer() {
	const [dataUnread, setDataUnread] = useState([])
	const [dataRead, setDataRead] = useState('')
    const [loader, setLoader] = useState(<Loader />)
    const [error, setError] = useState('');
    const [errorRead, setErrorRead] = useState('');
    const [sure, setSure] = useState(false);
    const [readCurrentPage, setReadCurrentPage] = useState(1);
    const [readPagination, setReadPagination] = useState('');
    const [unReadCurrentPage, setUnReadCurrentPage] = useState(1);
    const [unReadPagination, setUnReadPagination] = useState('');

    const getNotification = () => {
    	setError('')
    	setLoader(<Loader />);
        setDataUnread([])
        axios
        .get( `${process.env.REACT_APP_API_URL}/member/get-notifications`, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser() && getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {
        	setLoader('');
        	setError(res.data.message);
            setDataUnread(res.data.data.data);
            readCounter = res.data.data.total
        });
    };


    const getNotificationNext = (page = readCurrentPage) => {
        setReadPagination('')
        setLoader(<Loader />);
        axios
        .get(
            `${process.env.REACT_APP_API_URL}/member/get-notifications?page=${page}`,
            {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {
            setLoader('');
            if (res.data.success === true) {
                setReadCurrentPage(res.data.data.current_page);
                setReadPagination(res.data.data.next_page_url);
                let nextData = res.data.data.data;
                let setmultiple = [...dataUnread, ...nextData];
                setDataUnread(setmultiple);
            }
        });
    };


    const nextLink = () => {
        setReadPagination('')
        getNotificationNext(readCurrentPage + 1);
    };

    const getReadNotification = () => {
    	setErrorRead([])
    	setLoader(<Loader />);
        axios
        .get(`${process.env.REACT_APP_API_URL}/member/get-read-notifications`, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser() && getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {
        	setLoader('');
        	setErrorRead(res.data.message);
            setDataRead(res.data.data.data);
            unReadCounter = res.data.data.total
        });
    };



    const getReadNotificationNext = (page = unReadCurrentPage) => {
        setUnReadPagination('')
        setLoader(<Loader />);
        axios
        .get(
            `${process.env.REACT_APP_API_URL}/member/get-read-notifications?page=${page}`,
            {
                headers: {
                    Authorization: `Bearer ${getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {
            setLoader('');
            if (res.data.success === true) {
                setUnReadCurrentPage(res.data.data.current_page);
                setUnReadPagination(res.data.data.next_page_url);
                let nextData = res.data.data.data;
                let setmultiple = [...dataRead, ...nextData];
                setDataRead(setmultiple);
            }
        });
    };


    const getReadNotiNextLink = () => {
        setUnReadPagination('')
        getReadNotificationNext(unReadCurrentPage + 1);
    };


    const markRead = () => {
        setSure(false)
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/read-all-notification`,
                {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                toast.success(res.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setDataUnread([])
                getNotification()
            });
    };

    useEffect(() => {
    	getNotification();
    }, []);

	return (
		<section className="allnotifications section-py">
            <div className="container">
                <Tabs className="vertical_tabs">
                    <TabList>
                        <Tab onClick={getNotification}>Unread Notification {readCounter ? (<>({readCounter})</>) : ''}</Tab>
                        <Tab onClick={getReadNotification}>All Notifications {unReadCounter ? (<>({unReadCounter})</>) : ''}</Tab>
                    </TabList>
                    <TabPanel>
                    	<div className="pt-3">
                            {dataUnread && dataUnread.length > 0 ? (
                                <div className="text-right mb-5">
                                    <button onClick={() => setSure(true)}>Mark as read</button>
                                </div>
                            ) : ''}
							{error ? (<div className="text-center">{error}</div>): ''}
                            <InfiniteScroll
                                dataLength={dataUnread && dataUnread.length}
                                next={readPagination !== null ? nextLink : ''}
                                hasMore={readPagination !== null ? true : false}
                            >
    							<table className="table table-bordered table-striped border">
    								<tbody>
        								{dataUnread && dataUnread.map((item, i) => (
        									<UnreadNotification data={item} />
        								))}
    								</tbody>
    							</table>
                            </InfiniteScroll>
							{loader}
						</div>
                    </TabPanel>
                    <TabPanel>
                    	{errorRead ? (<div className="text-center">{errorRead}</div>): ''}
                            <InfiniteScroll
                                dataLength={dataRead && dataRead.length}
                                next={unReadPagination !== null ? getReadNotiNextLink : ''}
                                hasMore={unReadPagination !== null ? true : false}
                            >
    							<table className="table table-bordered table-striped border">
    								<tbody>
    								{dataRead && dataRead.map((item, i) => (
    									<ReadNotification data={item} />
    								))}
    								</tbody>
    							</table>
                            </InfiniteScroll>
							{loader}
                    </TabPanel>
                </Tabs>
            </div>
            {sure && <SweetAlert
                showCancel
                confirmBtnText="Yes"
                confirmBtnBsStyle="btn btn-primary"
                title="Are you sure?"
                btnSize="sm"
                onConfirm={() => markRead()}
                onCancel={() => setSure(false)}
            >
            </SweetAlert>}
        </section>
	)
}
export default NotificationsContainer;