import React, { useEffect, useState } from "react";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { getCurrentUser } from "../helpers/Utils";
import { NavLink, useHistory } from "react-router-dom";
import { Tab, Tabs, TabList } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Timeline from "../components/profile/timeline";
import Profile from "../components/profile";
import Tribes from "../components/profile/tribes";
/*import Courses from "../components/profile/Courses";*/
import Classes from "../components/profile/classes";
import InviteMembers from "../components/profile/InviteMembers";
import Connections from "../components/profile/Connections";
import Documents from "../components/profile/Documents";

import PageLoader from "../components/page-loader";

const ProfilePage = () => {
    const history = useHistory()
    const [profilePage, setProfilePage] = useState('')
    const [selectedTab, setSelectedTab] = useState(0);

    const changePorfilePage = (pathname) => {
        switch (pathname) {
            case '/profile/timeline':
                setSelectedTab(0)
                setProfilePage(<Timeline />)
                return 
            case '/profile/tribes':
                setSelectedTab(2)
                setProfilePage(<Tribes />)
                return 
            /*case '/profile/courses':
                setSelectedTab(3)
                setProfilePage(<Courses />)
                return */
            case '/profile/classes':
                setSelectedTab(3)
                setProfilePage(<Classes />)
                return
            case '/profile/invite-members':
                setSelectedTab(4)
                setProfilePage(<InviteMembers />)
                return 
            case '/profile/connections':
                setSelectedTab(5)
                setProfilePage(<Connections />)
                return 
            case '/profile/documents':
                setSelectedTab(6)
                setProfilePage(<Documents />)
                return 
            default:
                setSelectedTab(1)
                setProfilePage(<Profile />)
                return 
        }
    }


    useEffect(() => {
        changePorfilePage(history.location.pathname)
        history.listen((location) => {
            changePorfilePage(location.pathname);
        })
    }, [history])

    return (
        <React.Fragment>
            <PageLoader />
            <Layout>
                <SEO title="Soul Tribe Online Community – Privacy Policy" />
                <div className="wrapper">
                    <Header />
                    <div className="tribe_detail">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div id="cover-image-container">
                                        <div className="has-cover-image">
                                            <img
                                                className="header-cover-img"
                                                src={
                                                    getCurrentUser().cover_image
                                                }
                                                alt=""
                                            />
                                        </div>
                                        <div
                                            id="item-header-cover-image"
                                            className="item-header-wrap bb-enable-cover-img"
                                        >
                                            <div id="item-header-avatar">
                                                <img
                                                    src={getCurrentUser().image}
                                                    alt=""
                                                />
                                            </div>
                                            <div id="item-header-content">
                                                <div className="flex align-items-center bp-group-title-wrap">
                                                    <h2 className="bb-bp-group-title">
                                                        {getCurrentUser().name}
                                                    </h2>
                                                </div>
                                                <p className="text-lowercase">
                                                    @{getCurrentUser().name} •{" "}
                                                    {
                                                        getCurrentUser()
                                                            .created_at
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tribe_tabs mt-5">
                                        <Tabs selectedIndex={selectedTab}>
                                            <TabList>
                                                <Tab>
                                                    <NavLink className="d-block p-1" to="/profile/timeline">
                                                        Timeline
                                                    </NavLink>
                                                </Tab>
                                                <Tab>
                                                    <NavLink className="d-block p-1" to="/profile">
                                                        Profile
                                                    </NavLink>
                                                </Tab>
                                                <Tab>
                                                    <NavLink className="d-block p-1" to="/profile/tribes">
                                                        Tribes
                                                    </NavLink>
                                                </Tab>
                                               {/* <Tab>
                                                    <NavLink className="d-block p-1" to="/profile/courses">
                                                        Courses
                                                    </NavLink>
                                                </Tab>*/}
                                                <Tab>
                                                    <NavLink className="d-block p-1" to="/profile/classes">
                                                        Classes
                                                    </NavLink>
                                                </Tab>
                                                <Tab>
                                                    <NavLink className="d-block p-1" to="/profile/invite-members">
                                                        Email Invites
                                                    </NavLink>
                                                </Tab>
                                                <Tab>
                                                    <NavLink className="d-block p-1" to="/profile/connections">
                                                        Connections
                                                    </NavLink>
                                                </Tab>
                                                <Tab>
                                                    <NavLink className="d-block p-1" to="/profile/documents">
                                                        Documents
                                                    </NavLink>
                                                </Tab>
                                            </TabList>

                                            <div className="p-5">
                                                { profilePage }
                                            </div>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default ProfilePage;
