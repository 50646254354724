import React from "react";
import { NavLink } from "react-router-dom";

function MyTribes(data) {
    let classList = '';
    if (data.data !== undefined) {
        classList = 
        <div className="row">
            {
                data.data && data.data.map((tribe, key) => {
                  return (
                    <>
                      <div
                        key={key}
                        className="col-md-6 mb-7"
                      >
                      <div className="blog-card tribes">
                        <div className="thumb bg-light text-center p-0">
                          <img alt=""
                            src={tribe.cover_image}
                          />
                        </div>
                        <div className="d-flex justify-content-around px-3">
                          <div className="w-50 tribes_thumb">
                              <img alt="" className="mw-100" src={tribe.image} />
                          </div>
                          <div className="tribes_join">
                              <button type="button">{tribe.member_type}</button> 
                          </div>
                        </div>
                        <div className="members mt-3 tribes_content px-3">
                          <h5 className="title">
                            <NavLink
                              to={process.env.PUBLIC_URL + `/tribe/${tribe.slug}`}
                            >
                              {tribe.title}
                            </NavLink>
                          </h5>
                          <span className="blog-meta text-capitalize">
                            {tribe.group_type ? `${tribe.group_type} Group` : ''}
                          </span>

                          <div className="tribes_content-members">
                            <div className="member_thumbs d-inline-block">
                              {
                                tribe.group_member_image.map((img, i) => {
                                  return (
                                    <img alt="" src={img} key={i}/>
                                  )
                                })
                              }
                            </div>
                            <span className="blog-meta p-3 d-inline-block">{tribe.group_members ? `+ ${tribe.group_members} Members` : ''}</span>
                          </div>
                        </div>
                    </div>
                  </div>
                </>
                );
                })
            }
        </div>
    }
    return (
        <>
          {classList}
        </>
    );
}

export default MyTribes;