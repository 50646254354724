import React, { useEffect, useState } from "react";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import { useLocation } from "react-router-dom";
import LiveClassContainer from "../containers/live-class";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import axios from "axios";
import { getCurrentUser } from "../helpers/Utils";
import Loader from "../components/loader";

//Funtion for getting the router params
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LiveClassDetailsPage = ({
    match: {
        params: { slug, id },
    },
}) => {
    const [data, setData] = useState("");
    const [error, setError] = useState("");

    let query = useQuery();
    const [date] = useState(query.get("date"));

    let fetchLiveClassDetail = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/live-class/${slug}/${id}?date=${date}`,
                {
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${getCurrentUser().token}`,
                    },
                }
            )
            .then(function (res) {
                setData(res.data.data);
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchLiveClassDetail();
    }, []);

    return (
        <React.Fragment>
            <Layout>
                <SEO title="Soul Tribe Online Community – Blog Details" />
                <div className="wrapper">
                    <Header />
                    <PageBanner title={data.title && data.title} />
                    <section className="section-py">
                        {data ? <LiveClassContainer data={data} /> : <Loader />}
                    </section>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default LiveClassDetailsPage;
