import React from "react";
import { getCurrentUser } from "../../helpers/Utils";
import SingleComment from "./SingleComment";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function PostComment(props) {
  const list = [];
  props.allMember &&
    props.allMember.map((item) => {
      list.push({
        text: item.name,
        value: item.name,
        url: "member/" + item.id + "",
      });
    });

  const setCommentContent = () => {
    props.setMessage(
      draftToHtml(convertToRaw(props.editorState.getCurrentContent()))
    );
  };

  return (
    <div className="posted_info-comment">
      {props.utility.loader}
      <div className="all_comments comment_scroll">
        {props.data &&
          props.data.map((data, i) => (
            <SingleComment
              userId={props.userId}
              allMember={props.allMember}
              data={data}
              postId={props.postId}
            />
          ))}
        {props.data.length < 10 ? (
          ""
        ) : (
          <>
            {props.utility.pagination !== null &&
            props.utility.pagination !== undefined ? (
              <>
                {props.utility.commentLoading ? (
                  <button
                    type="button"
                    style={{ marginLeft: "10px" }}
                    className="btn-sm"
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    type="button"
                    style={{ marginLeft: "10px" }}
                    className="btn-sm"
                    onClick={props.utility.nextLink}
                  >
                    View more comments
                  </button>
                )}
              </>
            ) : (
              <button
                type="button"
                style={{
                  marginLeft: "10px",
                  opacity: 0.5,
                  cursor: "not-allowed",
                }}
                className="btn-sm"
              >
                View more comments
              </button>
            )}
          </>
        )}
      </div>

      <div className="comment-single">
        <div className="posted_header">
          {getCurrentUser() ? <img src={getCurrentUser().image} alt="" /> : ""}
          <div className="comment_info w-100">
            <form onSubmit={props.postComment}>
              <div className="post_upload">
                <div className="row">
                  <div className="col-12">
                    {/* <textarea  */}
                    {/* 	onChange={(e) => props.setMessage(e.target.value) } */}
                    {/* /> */}
                    <div className="border">
                      <Editor
                        onChange={() => setCommentContent()}
                        editorState={props.editorState}
                        onEditorStateChange={props.setEditorState}
                        placeholder="Write something!"
                        toolbar={{
                          options: ["inline", "list"],
                          inline: {
                            options: [
                              "bold",
                              "italic",
                              "underline",
                              "strikethrough",
                            ],
                          },
                        }}
                        mention={{
                          separator: " ",
                          trigger: "@",
                          suggestions: list,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="post_upload-attach">
                      <div className="upload_opt">
                        <input
                          accept="image/*"
                          id={`comment_attached${props.postId}`}
                          className="d-none"
                          type="file"
                          name="image"
                          onChange={(e) => props.feedAttach(e)}
                        />
                        <label
                          for={`comment_attached${props.postId}`}
                          className="pl-3"
                        >
                          <i className="fa fa-camera"></i>
                        </label>
                        <br />
                        {props.utility.image ? (
                          <>
                            <span className="img_name">
                              {props.utility.image.name}
                            </span>
                            {/* <button type="button" className="remove_attached">x</button> */}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {props.utility.error}
                      {props.utility.success}
                      {props.utility.loading ? (
                        <button
                          type="button"
                          className="btn btn-primary py-3 disabled"
                        >
                          Posting
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary py-3">
                          Post
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostComment;
