import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const BlogGrid = ({ data, classOption }) => {
    return (
        <div className="blog-card">
            <div className={`thumb bg-light text-center ${classOption}`}>
                <NavLink to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>
                    <img
                        src={data.image}
                        alt="img"
                    />
                </NavLink>
            </div>
            <div className="blog-content">
                {/* <NavLink */}
                {/*     to={ */}
                {/*         process.env.PUBLIC_URL + */}
                {/*         `/author/${slugify(data.author)}` */}
                {/*     } */}
                {/* > */}
                    <span className="blog-meta author">{data.author}</span>
                {/* </NavLink> */}
                <span className="separator">-</span>
                {/* <NavLink */}
                {/*     to={process.env.PUBLIC_URL + `/date/${slugify(data.date)}`} */}
                {/* > */}
                    <span className="blog-meta date">{data.created_at}</span>
                {/* </NavLink> */}
                <h3 className="title text-capitalize">
                    {data.title}
                </h3>
            </div>
        </div>
    );
};

BlogGrid.propTypes = {
    data: PropTypes.object,
    classOption: PropTypes.string,
};

BlogGrid.defaultProps = {
    classOption: "thumb",
};

export default BlogGrid;
