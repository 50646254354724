import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PageBanner from "../containers/global/page-banner";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import PageLoader from '../components/page-loader'

const InternalCalendar = () => {
    return (
        <React.Fragment>
        <PageLoader />
            <Layout>
                <SEO title="Soul Tribe Online Community – Internal Calendar" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Internal Calendar"
                    />
                    <div className="text-center section-py">
                        <iframe title="google" width="800" height="600" frameborder="0" scrolling="no" data-src="https://calendar.google.com/calendar/embed?src=info%40soultribeonline.com&amp;ctz=America%2FLos_Angeles" className="lazyloaded" src="https://calendar.google.com/calendar/embed?src=info%40soultribeonline.com&amp;ctz=America%2FLos_Angeles"></iframe>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default InternalCalendar;
