import React, { useState } from 'react'
import { getCurrentUser } from '../../helpers/Utils';
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ReadNotification(props) {
	const [data, setData] = useState(props.data)

    const deleteNotification = () => {
        axios
        .post( `${process.env.REACT_APP_API_URL}/delete-notification`, {
        	id: data.id
        }, {
                headers: {
                    Authorization: `Bearer ${getCurrentUser() && getCurrentUser().token}`,
                },
            }
        )
        .then(function (res) {
           	setData('');
            toast.success(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    };

    if (data !== '') {
    	return (
			<tr>
				<td className="text-left">
                    <Link to={data.notification_link}>
    					<div className="d-flex align-items-center">
    						<img className="thumb" src={data.sender_profile_pic} />
    						<div>
                                <h4 className="mt-md-0 mt-2" style={{
                                    display: 'block',
                                    width: '100%'
                                }} dangerouslySetInnerHTML={{__html: data.data.message}} />
                                <small style={{
                                    color: '#faa916'
                                }}>{data.created_time_ago}</small>
                            </div>
    					</div>
                    </Link>
				</td>
                <td className="text-right">
                    <button title="Delete" className="text-black-50" onClick={() => deleteNotification()}>
                        <i className="fa fa-trash"></i>
                    </button>
                </td>
			</tr>
		)
    } else {
        return (
            <>
                
            </>
        )
    }
}
export default ReadNotification;